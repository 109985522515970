import { Button, Card, Classes, NonIdealState, Spinner } from '@blueprintjs/core'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { AddAggregatedProjectButton } from '../../Containers/Economy/Projects/AddAggregateProject'
import { OALCompanies } from '../../Domain/Common/Company'
import { AggregatedProject } from '../../Domain/Projects/AggregatedProject'
import { TripletexTerseProject } from '../../Domain/Projects/TripletexProject'
import { deleteAggregatedProject, getProjectTimesheet, updateAggregatedProject } from '../../Services/OalApi'
import { flatten } from 'lodash'
import { TripletexTimesheet, TripletexTimesheetResult } from '../../Domain/Economy/Projects'
import { PageContext } from '../../Utils/LoginContext'
import { Popover2, Classes as Classes2 } from '@blueprintjs/popover2'

type CustomTripletexTimesheetResult = TripletexTimesheetResult & { tripletexId: number; name: string }
type TimesheetState = { [company: string]: CustomTripletexTimesheetResult[] }
type TimesheetAction = { company: string; tripletexId: number; name: string; value: TripletexTimesheetResult }

const timesheetReducer = (state: TimesheetState, action: TimesheetAction | 'reset'): TimesheetState => {
	if (action === 'reset') {
		return {}
	}

	return {
		...state,
		[action.company]: [
			...(state[action.company] || []),
			{ ...action.value, tripletexId: action.tripletexId, name: action.name },
		],
	}
}

const reduceHoursPerActivity = (timesheet: TripletexTimesheetResult[]): { activity: string; hours: number }[] => {
	if (!timesheet) return []

	const result = timesheet?.reduce((acc, curr) => {
		return {
			...acc,
			...curr.values.reduce(
				(acc2, curr2) => ({ ...acc2, [curr2.activity.name]: (acc2[curr2.activity.name] || 0) + curr2.hours }),
				{} as { [activity: string]: number }
			),
		}
	}, {} as { [activity: string]: number })

	return Object.keys(result).map((key) => ({ activity: key, hours: result[key] }))
}

const selectContents = (el: any) => {
	let range = document.createRange()
	range.selectNodeContents(el)
	let sel = window.getSelection()!
	sel.removeAllRanges()
	sel.addRange(range)
}

export const AggregateProject = ({
	project,
	onUpdateProject,
	showAddButton,
}: {
	project: AggregatedProject
	onUpdateProject?: (projectId: number) => void
	showAddButton?: boolean
}) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [timesheets, timesheetDispatch] = React.useReducer(timesheetReducer, {})
	const [companies, setCompanies] = React.useState<string[]>([])
	const pageContext = React.useContext(PageContext)
	const url = new URL(window.location.href)
	const sharecode = url.searchParams.get('sc') || undefined
	const isOwner = pageContext?.user?.sAMAccountName === project.authorId

	const removeProject = () => {
		if (!isOwner) return

		deleteAggregatedProject(project.id).then(() => {
			onUpdateProject?.(project.id)
		})
	}

	const removeSubProject = (company: string, subProjectId: number) => {
		let newProject = {
			...project,
			configuration: {
				...project.configuration,
				[company]: project.configuration[company].filter((p: { id: number }) => p.id !== subProjectId),
			},
		}
		if (newProject.configuration[company].length === 0) {
			delete newProject.configuration[company]
		}

		updateAggregatedProject(newProject).then(() => {
			if (onUpdateProject) {
				onUpdateProject(newProject.id)
			}
		})
	}

	const reloadTimesheets = (companiesRef: string[]) => {
		timesheetDispatch('reset')
		setIsLoading(true)

		const all: Promise<TimesheetAction>[] = companiesRef
			? flatten(
					companiesRef.map((company) => {
						return project.configuration[company]?.map(
							async (tripletexProject: TripletexTerseProject): Promise<TimesheetAction> => ({
								company,
								tripletexId: tripletexProject.id,
								name: tripletexProject.name,
								value: await getProjectTimesheet(company, tripletexProject.id, project.id, sharecode),
							})
						)
					})
			  )
			: []

		Promise.all(all)
			.then((values) => {
				values.forEach((value) => timesheetDispatch(value))
				setIsLoading(false)
			})
			.catch((err) => {
				console.error(err)
				setIsLoading(false)
			})
	}

	// Fetch all timesheets for the project
	useEffect((): void => {
		if (isLoading) return

		let companies = Object.keys(project?.configuration)
		setCompanies(companies)

		setImmediate(() => reloadTimesheets(companies))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project])

	const loadingClass = isLoading ? ' bp3-skeleton' : ''

	return (
		<Card key={project.id} elevation={2} style={{ zIndex: 200 }} className="mt-4">
			<Row>
				<Col md={11}>
					<h3 className={'mt-1' + loadingClass}>{project.name}</h3>
				</Col>
				<Col md={1}>
					{isOwner && (
						<Popover2
							interactionKind="click"
							popoverClassName={Classes.POPOVER_CONTENT_SIZING}
							placement="auto"
							usePortal={true}
							content={
								<div className="p-4">
									<h4 className="mt-0">Slette prosjekt</h4>
									<p className="mb-4">Er du sikker på at du vil slette dette prosjektet?</p>
									<Button
										intent="danger"
										text="Ja"
										className={Classes2.POPOVER2_DISMISS}
										onClick={() => removeProject()}
									/>{' '}
									<Button className={Classes2.POPOVER2_DISMISS} text="Avbryt" />
								</div>
							}
						>
							<Button icon="cross" intent="danger" />
						</Popover2>
					)}
				</Col>
			</Row>
			{/*            <Row>
                <Col md={10} className={loadingClass}>
                    <span>
                        {(project.visibility.length &&
                            project.visibility.map((group) => (
                                <span key={group}>
                                    {' '}
                                    <OALGroup hideUnresolved={false} id={group} />
                                </span>
                            ))) ||
                            ''}
                    </span>
                </Col>
            </Row>
*/}
			<Row>
				<Col md={12} className={loadingClass}>
					<>
						{companies?.map((company: string) => (
							<div key={company} style={{ marginTop: '1em' }}>
								<span style={{ textTransform: 'uppercase', fontSize: '1.2em', fontWeight: 700 }}>
									{' ' + OALCompanies?.find((comp) => comp.value === company)?.label + ': '}
								</span>
								{timesheets[company]?.map((timesheetResult) => (
									<div
										key={timesheetResult.tripletexId}
										style={{
											marginTop: '1em',
										}}
									>
										<strong>
											{timesheetResult.name}: {timesheetResult.tripletexId}
										</strong>
										<br />
										<TimesheetInfo
											timesheet={timesheetResult.values}
											company={company}
											isLoading={isLoading}
											projectId={timesheetResult.tripletexId}
										/>
										{isOwner && (
											<Button
												icon="cross"
												intent="danger"
												text="Fjern prosjekt"
												small
												className="float-right"
												onClick={() => removeSubProject(company, timesheetResult.tripletexId)}
											/>
										)}
									</div>
								))}
							</div>
						))}
						<p className="mt-4">
							<b>Totalt timer:</b>
							{reduceHoursPerActivity(flatten(Object.values(timesheets))).map(({ activity, hours }) => (
								<Row key={activity}>
									<Col md={2} style={{ paddingLeft: 20, textTransform: 'capitalize' }}>
										{activity}
									</Col>
									<Col md={10}>{hours}</Col>
								</Row>
							))}
							<p></p>
							<b>
								Sum total:{' '}
								{reduceHoursPerActivity(flatten(Object.values(timesheets))).reduce(
									(total, { activity, hours }) => (total += hours),
									0
								)}
							</b>
						</p>
					</>
				</Col>
			</Row>
			{showAddButton && (
				<AddAggregatedProjectButton
					className={'mt-4' + loadingClass}
					project={project}
					onChange={(company, tripletexProject) => {
						addTripletexProject(project, company, tripletexProject).then(() =>
							onUpdateProject ? onUpdateProject(project.id) : null
						)
					}}
				/>
			)}
			{showAddButton && (
				<span title="Sharable public link" className="float-right" onClick={(e) => selectContents(e.target)}>
					{window.location.href}?sc={project.sharecode}
				</span>
			)}
		</Card>
	)
}

const addTripletexProject = async (
	project: AggregatedProject,
	company: string,
	tripletexProject: TripletexTerseProject
) => {
	try {
		if (project) {
			await updateAggregatedProject({
				...project,
				configuration: {
					...project.configuration,
					[company]: [...(project.configuration[company] || []), tripletexProject],
				},
			})
		}
	} catch (e) {
		console.error(
			'Error adding tripletex project: ',
			{ projectId: project.id, tripletexProject: tripletexProject },
			e
		)
	}
}

const TimesheetInfo = ({
	isLoading,
	company,
	projectId,
	timesheet,
}: {
	isLoading: boolean
	company: string
	projectId: number
	timesheet: TripletexTimesheet[]
}) => {
	if (isLoading) {
		return <Spinner />
	}

	const from = timesheet.reduce((res, cur) => {
		const time = new Date(cur.date)
		if (time < res) {
			return time
		} else {
			return res
		}
	}, new Date())
	const to = new Date()

	let result: {
		[date: string]: {
			[name: string]: {
				[activity: string]: number
			}
		}
	} = {}

	const monthString = (date: Date): string => {
		return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).substr(-2)}-01`
	}

	for (let year = from.getFullYear(); year <= to.getFullYear(); year++) {
		for (let month = 1; month <= 12; month++) {
			const curTime = new Date(year, month - 1, 1)
			if (year === from.getFullYear() && month < from.getMonth() + 1) {
				continue
			}
			if (year === to.getFullYear() && month > to.getMonth() + 1) {
				continue
			}

			if (result[monthString(curTime)] === undefined) {
				result[monthString(curTime)] = {}
			}
		}
	}

	timesheet.forEach((time) => {
		const curTime = new Date(time.date)
		const ref = result[monthString(curTime)]

		if (ref[time.employee.displayName] === undefined) {
			ref[time.employee.displayName] = {}
		}

		ref[time.employee.displayName][time.activity.name] =
			(ref[time.employee.displayName][time.activity.name] || 0) + time.hours
	})

	return (
		<>
			{result ? (
				Object.keys(result).map((yearmonth) => {
					const curTime = new Date(yearmonth)

					return (
						<Row key={yearmonth}>
							<Col md={2} style={{ textTransform: 'capitalize' }}>
								{curTime.toLocaleString(['no', 'en'], { month: 'long' })} {curTime.getFullYear()}
							</Col>
							<Col md={10}>
								{Object.keys(result[yearmonth]).map((employee) => (
									<Row key={employee}>
										<Col>{employee}</Col>
										<Col>
											{Object.keys(result[yearmonth][employee]).map((activity) => (
												<Row key={activity}>
													<Col>{activity}</Col>
													<Col>{result[yearmonth][employee][activity]}</Col>
												</Row>
											))}
										</Col>
									</Row>
								))}
							</Col>
						</Row>
					)
				})
			) : (
				<NonIdealState title="Ingen tidsregistreringer ennå!" icon="search" />
			)}
		</>
	)
}
