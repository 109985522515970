import { Card, Colors } from '@blueprintjs/core'
import Moment from 'react-moment'
import 'moment/locale/nb'
import { OALUser } from '../OAL/User'
import { ProfileImageThumbnail } from '../../Services/OalApi'
import { Comment } from './types'
import Editor from 'rich-markdown-editor'
import { ourTheme } from '../DynamicContent/module/MD'

export interface CommentItemProps {
	item: Comment
}

export function CommentsCardListItem(props: CommentItemProps) {
	return (
		<div style={{ borderLeft: '1px solid #ddd', marginLeft: 15, paddingTop: 15 }}>
			<div
				style={{
					display: 'flex',
					marginLeft: -15,
					width: 'calc(100% + 15px)',
					paddingBottom: 20,
				}}
			>
				<div style={{ marginRight: 10 }}>
					<ProfileImageThumbnail
						style={{ maxWidth: 30, maxHeight: 30, marginTop: 3, borderRadius: '100%' }}
						username={props.item.authorId}
					/>
				</div>
				<Card style={{ width: '100%', padding: 0 }}>
					<div style={{ borderBottom: '1px solid #eee', padding: 10, backgroundColor: Colors.LIGHT_GRAY4 }}>
						<OALUser id={props.item.authorId} /> kommenterte <Moment fromNow>{props.item.createdAt}</Moment>
					</div>
					<div style={{ padding: 10 }}>
						<Editor defaultValue={props.item.payload || ''} readOnly theme={ourTheme} />
					</div>
				</Card>
			</div>
		</div>
	)
}
