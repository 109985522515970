import React, { useEffect } from 'react'
import { RentmanFolder, setConfig } from '../../../Services/OalApi'
import { useApiData } from '../../../Utils/UseAPI'
import { Col } from 'reactstrap'
import { Button, Callout, Card, H4, H5, HTMLSelect, Label } from '@blueprintjs/core'

const rentmanFolderOptions = (
	data: RentmanFolder[],
	chosen: number[],
	parentId: number | null,
	prefix = ''
): JSX.Element[] => {
	if (!data) return []
	const children = data.filter((item) => item.parent_id === parentId).sort((a, b) => a.order - b.order)

	return children.flatMap((child) => [
		<option key={child.id} value={child.id}>
			{(chosen.includes(child.id) ? 'X ' : '') + prefix + ' ' + child.name}
		</option>,
		...rentmanFolderOptions(data, chosen, child.id, prefix + '--'),
	])
}

// Hack to make typescript happy
function useApiDataRentmanFolders() {
	return useApiData<RentmanFolder[]>(() => {
		return new Promise((resolve) => resolve([]))
	})
}

type RentmanConfigProps = {
	type: 'internal' | 'external'
	company: string
	rentmanFolders: ReturnType<typeof useApiDataRentmanFolders>
	list: number[]
	onUpdate: () => void
}

export const RentmanConfig = ({ type, company, list, rentmanFolders, onUpdate }: RentmanConfigProps) => {
	const [saving, setSaving] = React.useState(false)
	const [error, setError] = React.useState<string | null>(null)
	const [folder, setFolder] = React.useState('')

	const { data: rentmanData, error: rentmanError } = rentmanFolders

	const save = async (data: typeof list) => {
		//console.log('Saving', account.key, data)
		setSaving(true)
		setError(null)
		try {
			await setConfig(`pa-rentman-folder-${type}-${company}`, data)
			onUpdate()
		} catch (e: any) {
			setError(e.message)
		} finally {
			setSaving(false)
		}
	}

	useEffect(() => {
		if (rentmanError) {
			setError(rentmanError)
		}
	}, [rentmanError])

	const addItem = () => {
		if (list.includes(parseInt(folder))) {
			return
		}

		if (folder) {
			save([...list, parseInt(folder)])
			setFolder('')
		}
	}

	const remove = (i: number) => {
		console.log('remove index', i, list)
		save(list.filter((_, idx) => idx !== i))
	}

	return (
		<Col lg={4} md={6}>
			<Card elevation={1} className={'mb-4'}>
				<H4>{{ internal: 'Mappe for intern crew', external: 'Mappe for ekstern crew' }[type]}</H4>
				<ul style={{ opacity: saving ? 0.5 : 1 }}>
					{list.map((id, i) => (
						<li key={i}>
							<Button
								icon="cross"
								onClick={() => remove(i)}
								style={{ marginRight: '.5em' }}
								small
								intent="danger"
							/>
							{rentmanData?.find((item) => item.id === id)?.name || id}
						</li>
					))}
				</ul>
				<H5>Legg til mappe:</H5>
				<Label className="mb-4">
					Mappe
					<HTMLSelect value={folder} onChange={(e) => setFolder(e.currentTarget.value)}>
						<option value="">- Mappe -</option>
						{rentmanFolderOptions(
							rentmanData?.filter((item) => item.itemtype === 'user') || [],
							list,
							null
						)}
					</HTMLSelect>
				</Label>

				<Button
					intent="success"
					icon="plus"
					className="mt-2"
					type="button"
					disabled={!folder || saving}
					loading={saving}
					onClick={() => addItem()}
				>
					Legg til
				</Button>
				{error ? (
					<Callout intent={'danger'} className="mt-3">
						{error}
					</Callout>
				) : null}
			</Card>
		</Col>
	)
}
