import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApiData } from '../../../Utils/UseAPI'
import {
	Config,
	RentmanProjectAnalytics,
	RentmanProjectAnalyticsHours,
	RentmanSubProjectTP,
	TripletexLedgerPosting,
	TripletexTimesheetEntry,
	getConfigs,
	getRentmanProjectAnalytics,
	getRentmanProjectHourAnalytics,
	getRentmanSubprojectPrices,
	getTripletexAcccountPostings,
	getTripletexTimesheet,
} from '../../../Services/OalApi'
import { ProjectAnalyticsContainer } from './Container'
import { Card, Callout, H2, Spinner, H4, Icon, ProgressBar, Button } from '@blueprintjs/core'
import { Col, Row } from 'reactstrap'
import { companies } from './RentmanCompanies'

import './Analytics.scss'
import tripletexLogo from './tripletex-hvit.png'
import rentmanLogo from './rentman-hvit.png'
import { Tooltip2 } from '@blueprintjs/popover2'

const companyDetails = companies.reduce((acc, company) => {
	acc[company.company] = {
		rentmanId: company.rentmanId,
		tripletexContext: company.tripletexContextId,
	}
	return acc
}, {} as { [key: string]: { rentmanId: string; tripletexContext: string } })

const numberFormatting = (num: number) => {
	return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

const hourFormatting = (num: number) => {
	return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 1 })
}

const inRange = (number: number, range: { from: number; to?: number }[]) => {
	return range.some((r) => {
		if (r.to) {
			return number >= r.from && number <= r.to
		} else {
			return number === r.from
		}
	})
}

const TripletexLogo = () => (
	<img src={tripletexLogo} width={16} height={16} className="bp3-icon mr-0" alt="tripletex logo" />
)
const RentmanLogo = () => <img src={rentmanLogo} width={16} height={16} className="bp3-icon mr-0" alt="rentman logo" />

export const ProjectAnalytics = ({ company, project }: { company: string; project: string }) => {
	const [externalFolders, setExternalFolders] = useState<number[]>([])
	const [internalFolders, setInternalFolders] = useState<number[]>([])
	const [projectAnalytics, setProjectAnalytics] = useState<RentmanProjectAnalytics | null>(null)
	const [projectAnalyticsLoading, setProjectAnalyticsLoading] = useState<boolean>(true)
	const [projectAnalyticsError, setProjectAnalyticsError] = useState<string | null>(null)
	const [tripletexData, setTripletexData] = useState<TripletexLedgerPosting[] | null>(null)
	const [tripletexDataLoading, setLoadingTripletexData] = useState<boolean>(true)
	const [tripletexDataError, setTripletexDataError] = useState<string | null>(null)
	const [tripletexHours, setTripletexHours] = useState<TripletexTimesheetEntry[] | null>(null)
	const [tripletexHoursLoading, setLoadingTripletexHours] = useState<boolean>(true)
	const [tripletexHoursError, setTripletexHoursError] = useState<string | null>(null)
	const [rentmanHoursData, setRentmanHoursData] = useState<RentmanProjectAnalyticsHours[] | null>(null)
	const [rentmanHoursDataLoading, setLoadingRentmanHoursData] = useState<boolean>(true)
	const [rentmanHoursDataError, setRentmanHoursDataError] = useState<string | null>(null)
	const [progress, setProgress] = useState<number>(0)

	//const companyInfo = companies.find((c) => c.company === company)

	const {
		data: configuration,
		loading: configurationLoading,
		error: configurationError,
	} = useApiData<Config[]>(() => getConfigs(`pa-*-${company},pa-tooltip-*`), [])

	const {
		data: subprojects,
		loading: subprojectsLoading,
		error: subprojectsError,
	} = useApiData<RentmanSubProjectTP[]>(getRentmanSubprojectPrices, company, project)

	const get = useCallback(
		(key: string) => {
			const config = configuration?.find((config) => config.key === key)
			return config?.value
		},
		[configuration]
	)

	useEffect(() => {
		setExternalFolders(get('pa-rentman-folder-external-' + company) ?? [])
		setInternalFolders(get('pa-rentman-folder-internal-' + company) ?? [])
	}, [configuration, get, company])

	useEffect(() => {
		if (externalFolders?.length > 0 && internalFolders?.length > 0 && company && project) {
			setProjectAnalyticsLoading(true)
			setProjectAnalyticsError(null)
			setProjectAnalytics(null)
			getRentmanProjectAnalytics(company, '', '', externalFolders, internalFolders, parseInt(project))
				.then((data) => {
					setProjectAnalytics(data?.length > 0 ? data[0] : null)
					setProjectAnalyticsLoading(false)
				})
				.catch((e: any) => {
					setProjectAnalyticsError(
						e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
					)
					setProjectAnalyticsLoading(false)
				})
		}
	}, [externalFolders, internalFolders, company, project])

	useEffect(() => {
		if (!company || !project || !projectAnalytics?.usageperiod_start) {
			return
		}

		setTripletexData(null)
		setTripletexDataError(null)
		setLoadingTripletexData(true)
		;(async () => {
			try {
				const newData = await getTripletexAcccountPostings(
					company,
					new Date(projectAnalytics.usageperiod_start).toISOString().substr(0, 10),
					new Date(projectAnalytics.usageperiod_start).toISOString().substr(0, 10),
					[projectAnalytics.number],
					true
				)
				setTripletexData((old) => newData)
			} catch (e: any) {
				console.error(e)
				setTripletexDataError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
			} finally {
				setLoadingTripletexData(false)
			}
		})()

		setTripletexHours(null)
		setTripletexHoursError(null)
		setLoadingTripletexHours(true)
		;(async () => {
			try {
				const newData = await getTripletexTimesheet(
					company,
					new Date(projectAnalytics.usageperiod_start).toISOString().substr(0, 10),
					new Date(projectAnalytics.usageperiod_start).toISOString().substr(0, 10),
					[projectAnalytics.number],
					true
				)
				setTripletexHours((old) => newData)
			} catch (e: any) {
				console.error(e)
				setTripletexHoursError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
			} finally {
				setLoadingTripletexHours(false)
			}
		})()

		setRentmanHoursData(null)
		setRentmanHoursDataError(null)
		setLoadingRentmanHoursData(true)
		;(async () => {
			try {
				const newData = await getRentmanProjectHourAnalytics(
					company,
					'',
					'',
					internalFolders,
					parseInt(project)
				)
				setRentmanHoursData((old) => newData)
			} catch (e: any) {
				console.error(e)
				setRentmanHoursDataError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
			} finally {
				setLoadingRentmanHoursData(false)
			}
		})()
	}, [company, project, projectAnalytics, internalFolders])

	const getTripletexSum = useCallback(
		(type: 'revenue' | 'subhire' | 'crew-internal' | 'crew-external' | 'transport' | 'rest') => {
			return Math.abs(
				tripletexData
					?.filter((posting) => {
						return inRange(posting.account, get('pa-tripletex-' + type + '-' + company))
					})
					.reduce((acc, posting) => {
						acc += posting.amount
						return acc
					}, 0) || 0
			)
		},
		[tripletexData, company, get]
	)

	const getTripletexHours = useCallback(
		(activity: 'tekniker' | 'prosjektleder' | 'forarbeid' | '*') => {
			return Math.abs(
				tripletexHours
					?.filter((entry) => {
						return (
							activity === '*' ||
							(get(`pa-tripletex-activity-${activity}-${company}`) || []).includes(entry.activity)
						)
					})
					.reduce((acc, posting) => {
						acc += posting.hours
						return acc
					}, 0) || 0
			)
		},
		[tripletexHours, company, get]
	)

	const dekningsbidrag = projectAnalytics
		? projectAnalytics.revenue -
		  (projectAnalytics.subhire_costs +
				projectAnalytics.external_crew_cost +
				projectAnalytics.internal_crew_cost +
				projectAnalytics.transport +
				projectAnalytics.other_costs)
		: 0
	const tdekningsbidrag = tripletexData
		? getTripletexSum('revenue') -
		  (getTripletexSum('subhire') +
				getTripletexSum('crew-internal') +
				getTripletexSum('crew-external') +
				getTripletexSum('transport'))
		: 0

	const tother =
		getTripletexSum('rest') -
		(getTripletexSum('subhire') +
			getTripletexSum('crew-internal') +
			getTripletexSum('crew-external') +
			getTripletexSum('transport'))

	const projectId = useMemo(() => {
		return tripletexData?.find((p) => p.account === 3000)?.project_id ?? 0
	}, [tripletexData])

	// Progressbar for apicalls 🫣
	useEffect(() => {
		const p =
			((configurationLoading ? 0 : 1) +
				(projectAnalyticsLoading ? 0 : 1) +
				(tripletexDataLoading ? 0 : 1) +
				(tripletexHoursLoading ? 0 : 1) +
				(rentmanHoursDataLoading ? 0 : 1)) /
			5

		setProgress(p)

		if (p === 1) {
			setTimeout(() => {
				setProgress(-1)
			}, 500)
		}
	}, [
		configurationLoading,
		projectAnalyticsLoading,
		tripletexDataLoading,
		tripletexHoursLoading,
		rentmanHoursDataLoading,
	])

	/* Show timing for api calls in console
		useEffect(() => {
			if (configurationLoading) {
				console.time('configuration')
			} else {
				console.timeEnd('configuration')
			}
		}, [configurationLoading])

		useEffect(() => {
			if (projectAnalyticsLoading) {
				console.time('projectAnalytics')
			} else {
				console.timeEnd('projectAnalytics')
			}
		}, [projectAnalyticsLoading])

		useEffect(() => {
			if (tripletexDataLoading) {
				console.time('tripletexData')
			} else {
				console.timeEnd('tripletexData')
			}
		}, [tripletexDataLoading])

		useEffect(() => {
			if (tripletexHoursLoading) {
				console.time('tripletexHours')
			} else {
				console.timeEnd('tripletexHours')
			}
		}, [tripletexHoursLoading])

		useEffect(() => {
			if (rentmanHoursDataLoading) {
				console.time('rentmanHoursData')
			} else {
				console.timeEnd('rentmanHoursData')
			}
		}, [rentmanHoursDataLoading])
	}
	*/

	let rentmanHoursSum = 0

	return (
		<ProjectAnalyticsContainer company={company} project={projectAnalytics ? projectAnalytics.displayname : true}>
			<div style={{ position: 'relative' }}>
				{progress !== -1 ? (
					<div style={{ position: 'absolute', right: 0, top: 0, width: '10vw' }}>
						<ProgressBar value={progress} intent="success" stripes={false} />
					</div>
				) : null}

				{configurationError && (
					<Callout intent="warning" className="mb-3">
						Configurationdata: {configurationError}
					</Callout>
				)}
				{projectAnalyticsError && (
					<Callout intent="warning" className="mb-3">
						Rentman Project data: {projectAnalyticsError}
					</Callout>
				)}
				{tripletexDataError && (
					<Callout intent="warning" className="mb-3">
						Tripletex postings: {tripletexDataError}
					</Callout>
				)}
				{projectAnalyticsLoading || tripletexDataLoading ? (
					<div style={{ textAlign: 'center' }}>
						<Spinner />
						{projectAnalyticsLoading ? (
							<div className="loadingstatus">Laster data fra rentman...</div>
						) : tripletexDataLoading ? (
							<div className="loadingstatus">Laster nyeste data direkte fra tripletex...</div>
						) : null}
					</div>
				) : null}
				{projectAnalytics && tripletexData ? (
					<div>
						<H2>
							{projectAnalytics.number} {projectAnalytics.displayname}
						</H2>
						<Row>
							<Col lg={6} md={6} sm={12} xs={1}>
								<Card elevation={1} className={'mb-4'}>
									<H4>Omsetning og kostnader</H4>
									<table className="bp3-html-table lefttop-table">
										<thead>
											<tr>
												<th></th>
												<th>Rentman</th>
												<th>Tripletex</th>
												<th>Differanse</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th>Omsetning</th>
												<td>{numberFormatting(projectAnalytics.revenue)}</td>
												<td>{numberFormatting(getTripletexSum('revenue'))}</td>
												<td>
													{numberFormatting(
														projectAnalytics.revenue - getTripletexSum('revenue')
													)}
												</td>
											</tr>
											<tr>
												<th>Subhire</th>
												<td>{numberFormatting(projectAnalytics.subhire_costs)}</td>
												<td>{numberFormatting(getTripletexSum('subhire'))}</td>
												<td>
													{numberFormatting(
														projectAnalytics.subhire_costs - getTripletexSum('subhire')
													)}
												</td>
											</tr>
											<tr>
												<th>Bemanning ekstern</th>
												<td>{numberFormatting(projectAnalytics.external_crew_cost)}</td>
												<td>{numberFormatting(getTripletexSum('crew-external'))}</td>
												<td>
													{numberFormatting(
														projectAnalytics.external_crew_cost -
															getTripletexSum('crew-external')
													)}
												</td>
											</tr>
											<tr>
												<th>Bemanning intern</th>
												<td>{numberFormatting(projectAnalytics.internal_crew_cost)}</td>
												<td>{numberFormatting(getTripletexSum('crew-internal'))}</td>
												<td>
													{numberFormatting(
														projectAnalytics.internal_crew_cost -
															getTripletexSum('crew-internal')
													)}
												</td>
											</tr>
											<tr>
												<th>Transport</th>
												<td>{numberFormatting(projectAnalytics.transport)}</td>
												<td>{numberFormatting(getTripletexSum('transport'))}</td>
												<td>
													{numberFormatting(
														projectAnalytics.transport - getTripletexSum('transport')
													)}
												</td>
											</tr>
											<tr>
												<th>Annet</th>
												<td>{numberFormatting(projectAnalytics.other_costs)}</td>
												<td>{numberFormatting(tother)}</td>
												<td>{numberFormatting(projectAnalytics.other_costs - tother)}</td>
											</tr>
											<tr>
												<th>DB</th>
												<td>{numberFormatting(dekningsbidrag)}</td>
												<td>{numberFormatting(tdekningsbidrag)}</td>
												<td>{numberFormatting(dekningsbidrag - tdekningsbidrag)}</td>
											</tr>
										</tbody>
									</table>
								</Card>
							</Col>
							<Col md={6} sm={12} lg={6}>
								<Card elevation={1} className={'mb-4'}>
									<H4>Subprosjekter</H4>
									{subprojectsError ? (
										<Callout intent={'danger'} className="mt-3">
											{subprojectsError}
										</Callout>
									) : (
										<table
											className={
												'bp3-html-table lefttop-table' +
												(subprojectsLoading ? ' bp3-skeleton' : '')
											}
										>
											<thead>
												<tr>
													<th></th>
													<th>Rentman</th>
												</tr>
											</thead>
											<tbody>
												{subprojects
													?.sort((a, b) => a.order - b.order)
													.map((subproject) => (
														<tr key={subproject.id}>
															<th>{subproject.displayname}</th>
															<td>{numberFormatting(subproject.project_total_price)}</td>
														</tr>
													))}
											</tbody>
										</table>
									)}
								</Card>

								<Row>
									<Col md={12} lg={6}>
										<Card elevation={1} className={'mb-4'}>
											<H4>Eksterne lenker til prosjektet</H4>
											<div className="mb-2">
												<a
													href={`https://${companyDetails[company].rentmanId}.rentmanapp.com/#/projects/${project}/details`}
													target="_blank"
													rel="noreferrer"
												>
													<Button fill intent="primary">
														<RentmanLogo /> Rentman: Prosjektoversikt
													</Button>
												</a>
											</div>
											<div className="mb-2">
												<a
													href={`https://tripletex.no/execute/projectMenu?projectId=${projectId}&contextId=${companyDetails[company].tripletexContext}#resultReport2`}
													target="_blank"
													rel="noreferrer"
												>
													<Button fill intent="primary">
														<TripletexLogo /> Tripletex: Resultat
													</Button>
												</a>
											</div>
											<div className="mb-2">
												<a
													href={`https://tripletex.no/execute/projectMenu?projectId=${projectId}&contextId=${companyDetails[company].tripletexContext}#hourList`}
													target="_blank"
													rel="noreferrer"
												>
													<Button fill intent="primary">
														<TripletexLogo /> Tripletex: Timeføring
													</Button>
												</a>
											</div>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col sm={12} md={6} lg={6}>
								<Card elevation={1} className={'mb-4'}>
									<H4>Timer</H4>
									{tripletexHoursError ? (
										<Callout intent={'danger'} className="mt-3">
											{tripletexHoursError}
										</Callout>
									) : (
										<table
											className={
												'bp3-html-table lefttop-table' +
												(tripletexHoursLoading ? ' bp3-skeleton' : '')
											}
										>
											<thead>
												<tr>
													<th></th>
													<th>Rentman</th>
													<th>Tripletex</th>
													<th>Differanse</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<th>Tekniker</th>
													<td>
														<div className="not-active">-</div>
													</td>
													<td>{hourFormatting(getTripletexHours('tekniker'))}</td>
													<td>
														<div className="not-active">-</div>
													</td>
												</tr>
												<tr>
													<th>Prosjektleder</th>
													<td>
														<div className="not-active">-</div>
													</td>
													<td>{hourFormatting(getTripletexHours('prosjektleder'))}</td>
													<td>
														<div className="not-active">-</div>
													</td>
												</tr>
												<tr>
													<th>Teknisk forarbeid</th>
													<td>
														<div className="not-active">-</div>
													</td>
													<td>{hourFormatting(getTripletexHours('forarbeid'))}</td>
													<td>
														<div className="not-active">-</div>
													</td>
												</tr>
												<tr>
													<th>Annet</th>
													<td>
														<span className={rentmanHoursDataLoading ? 'bp3-skeleton' : ''}>
															{rentmanHoursData
																? hourFormatting(
																		(rentmanHoursSum +=
																			rentmanHoursData[0].sold_hours)
																  )
																: rentmanHoursDataError
																? ''
																: '--'}
														</span>
														{rentmanHoursDataError ? (
															<Tooltip2
																content={
																	<span>
																		<Icon icon="warning-sign" /> Rentman hours data:{' '}
																		{rentmanHoursDataError}
																	</span>
																}
																intent="warning"
															>
																<Icon icon="warning-sign" intent="danger" />
															</Tooltip2>
														) : null}
													</td>
													<td>
														{hourFormatting(
															getTripletexHours('*') -
																getTripletexHours('forarbeid') -
																getTripletexHours('prosjektleder') -
																getTripletexHours('tekniker')
														)}
													</td>
													<td>
														{hourFormatting(
															(rentmanHoursData && rentmanHoursData?.length > 0
																? rentmanHoursData[0].sold_hours
																: 0) -
																(getTripletexHours('*') -
																	getTripletexHours('forarbeid') -
																	getTripletexHours('prosjektleder') -
																	getTripletexHours('tekniker'))
														)}
													</td>
												</tr>
												<tr>
													<th>Sum</th>
													<td>{hourFormatting(rentmanHoursSum)}</td>
													<td>{hourFormatting(getTripletexHours('*'))}</td>
													<td>{hourFormatting(rentmanHoursSum - getTripletexHours('*'))}</td>
												</tr>
											</tbody>
										</table>
									)}
								</Card>
							</Col>
							<Col md={4} sm={6} lg={3}>
								<Card elevation={1} className={'mb-4'}>
									<H4>KPI fra Rentman</H4>
									<table className="bp3-html-table lefttop-table">
										<thead>
											<tr>
												<th></th>
												<th>KPI</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th>Subhire</th>
												<td>
													{Math.round(
														(projectAnalytics.subhire_costs / projectAnalytics.revenue) *
															100
													)}
													%
												</td>
											</tr>
											<tr>
												<th>Bemanning ekstern</th>
												<td>
													{Math.round(
														(projectAnalytics.external_crew_cost /
															projectAnalytics.revenue) *
															100
													)}
													%
												</td>
											</tr>
										</tbody>
									</table>
								</Card>
							</Col>
						</Row>
					</div>
				) : null}
			</div>
		</ProjectAnalyticsContainer>
	)
}

export default ProjectAnalytics
