import { NonIdealState } from '@blueprintjs/core'
import React, { useContext } from 'react'

import { addAggregatedProject, getAggregatedProjects } from '../../../Services/OalApi'

import { useEffect } from 'react'
import Container from './Container'
import { AggregatedProject, NewAggregatedProject } from '../../../Domain/Projects/AggregatedProject'
import { AddProject } from './AddProject'
import { AggregateProject } from '../../../Components/Projects/AggregateProject'
import { PageContext } from '../../../Utils/LoginContext'
import DynamicContent from '../../../Components/DynamicContent'

export const EconomyProjects = (props: {}) => {
	const pageContext = useContext(PageContext)
	const [isLoading, setIsLoading] = React.useState<boolean>(true)
	const [isSavingNew, setIsSavingNew] = React.useState<boolean>(false)
	const [hasError, setHasError] = React.useState<string>('')
	const [projects, setProjects] = React.useState<AggregatedProject[]>([])
	const [newProjectIsOpen, setNewProjectIsOpen] = React.useState<boolean>(false)

	useEffect(() => {
		updateProjects()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updateProjects = async (show: boolean = true) => {
		if (show) setIsLoading(true)
		try {
			const res = await getAggregatedProjects(pageContext.sharecode)
			setProjects(res)
		} catch (e) {
			setHasError('En feil oppstod under henting av prosjekter')
		}
		if (show) setIsLoading(false)
	}

	const addProject = async (newProject: NewAggregatedProject) => {
		try {
			setIsSavingNew(true)
			await addAggregatedProject(newProject)
			await updateProjects(false)
			setNewProjectIsOpen(false)
		} catch (e) {
			console.error('Error adding project: ', e)
		} finally {
			setIsSavingNew(false)
		}
	}

	return (
		<Container>
			{(pageContext.authenticated && <DynamicContent uri="prosjektoversikt" />) || <h1>Prosjektoversikt</h1>}

			<p></p>
			{pageContext.authenticated && (
				<AddProject
					isSaving={isSavingNew}
					onOpen={() => setNewProjectIsOpen(true)}
					isOpen={newProjectIsOpen}
					onChange={(project) => addProject(project)}
					onClose={() => setNewProjectIsOpen(false)}
				/>
			)}

			{isLoading ? (
				<NonIdealState title="Laster prosjekter..." icon="refresh" />
			) : hasError ? (
				<NonIdealState title={hasError} icon="error" />
			) : projects.length === 0 ? (
				<NonIdealState title="Ingen prosjekter ennå!" icon="search" />
			) : (
				projects.map((project: AggregatedProject) => (
					<AggregateProject
						key={project.id}
						showAddButton={pageContext.authenticated}
						project={project}
						onUpdateProject={(id) => updateProjects(true)}
					/>
				))
			)}
		</Container>
	)
}

export default EconomyProjects
