import React from 'react'
import { TicketUpdateState } from './types'
import { Icon, Tag } from '@blueprintjs/core'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { stateIntent, stateLabelPast } from './api'

export function RenderTicketUpdateState({ change }: { change: TicketUpdateState }) {
	return (
		<RenderTicketWrapper change={change}>
			<Icon icon="issue" /> <Tag intent={stateIntent[change.payload[1]]}>{stateLabelPast[change.payload[1]]}</Tag>
		</RenderTicketWrapper>
	)
}
