import { IconName, Intent } from '@blueprintjs/core'
import axios, { AxiosInstance } from 'axios'
import storage from 'local-storage-fallback'
import { CSSProperties } from 'react'
import Configuration from '../../Utils/Configuration'
import { TicketState, Ticket, NewTicket } from './types'

function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export const stateColor: { [key in TicketState]: string } = {
	open: 'black',
	closed: 'green',
	deleted: 'red',
}

export const stateIcon: { [key in TicketState]: IconName } = {
	open: 'issue',
	closed: 'tick',
	deleted: 'trash',
}

export const stateIntent: { [key in TicketState]: Intent } = {
	open: 'success',
	closed: 'danger',
	deleted: 'danger',
}

export const stateLabel: { [key in TicketState]: string } = {
	open: 'Åpen',
	closed: 'Lukket',
	deleted: 'Arkivert',
}

export const stateLabelPast: { [key in TicketState]: string } = {
	open: 'Åpnet',
	closed: 'Lukket',
	deleted: 'Arkivert',
}
export const stateStyle: { [key in TicketState]: CSSProperties } = {
	open: {},
	closed: { textDecoration: 'line-through' },
	deleted: {},
}

let api: AxiosInstance

// race condition shait. dette må vi fikse ordentlig

function refresh_api() {
	const oaljwt = storage.getItem('oaljwt')
	api = axios.create({
		baseURL: Configuration.OAL_API_URL,
		headers: { oaljwt },
		timeout: 10000,
	})
	if (!oaljwt) {
		console.log('missing oaljwt, retrying in 10ms')
		setTimeout(refresh_api, 10)
	}
	return api
}

api = refresh_api()

const apiDelay = 0

export const getTickets = async (showDeleted?: boolean, tags?: string[]): Promise<Ticket[]> => {
	let params: { showDeleted?: string; tags?: string } = {}

	if (showDeleted) params.showDeleted = 'true'
	if (tags && tags.length > 0) params.tags = tags.join(',')

	const response = await api.get('/tickets', {
		params,
	})

	await sleep(apiDelay)
	return response.data?.result || []
}

export const getTicket = async (id: number): Promise<Ticket> => {
	const response = await api.get('/ticket/' + id)
	await sleep(apiDelay)
	return response.data.result
}

export const putTicket = async (ticket: Ticket): Promise<Ticket> => {
	const response = await api.put('/ticket/' + ticket.id, ticket)
	console.log('PUT', ticket)
	await sleep(apiDelay)
	return response.data.result
}

export const deleteTicket = async (ticket: Ticket): Promise<void> => {
	await api.delete('/ticket/' + ticket.id)
	await sleep(apiDelay)
}

export const postTicket = async (newTicket: NewTicket): Promise<Ticket> => {
	const response = await api.post('/ticket', newTicket)
	await sleep(apiDelay)
	return response.data?.result
}

export default api
