import React, { useState, useContext, useEffect } from 'react'
import { Suggest } from '@blueprintjs/select'
import { MenuItem } from '@blueprintjs/core'
import { TESContext } from '../../Utils/TESContext'
import { useHistory } from 'react-router-dom'
import { TESPageSearch } from '../../Domain/Common/TesSearch'

type itemtype = { id: string; path: string; uri: string; title: string; updatedAt: string }

export default function Search(props: { large?: boolean; onSelect?: () => void }) {
	const TES = useContext(TESContext)
	const [items, setItems] = useState<itemtype[]>([])
	const history = useHistory()
	const [query, setQuery] = useState('')

	const handleItemRenderer = (item: itemtype, e: any) => (
		<MenuItem
			style={{ backgroundColor: e.modifiers.active ? '#eee' : 'inherit' }}
			icon="document"
			onClick={e.handleClick}
			key={item.id}
			label={new Date(item.updatedAt).toLocaleString()}
			text={
				<div>
					{item.title}
					<br />
					<small className="bp3-text-muted">{item.path}</small>
				</div>
			}
		/>
	)
	const handleInputValueRenderer = (inputValue: itemtype): string => inputValue.title
	const goToValue = (value: itemtype) => {
		history.push(value.uri)
	}

	const handleFilterItems = (query: string, item: itemtype, index?: number, exactMatch?: boolean) => {
		return true
	}

	useEffect(() => {
		// detect key press on escape
		const handleKeyDown = (e: any) => {
			if (e.keyCode === 27) {
				setQuery('')
			}
		}
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	const searchPages = async (query: any) => {
		if (query.length <= 1) {
			setItems([])
		} else {
			const results: { count: number; data: TESPageSearch[] } = await new Promise((resolve, reject) =>
				TES.rpc('searchPage.dashboard.oal.no', { query: { title: query, blocks: query } }, (err, res) => {
					if (err) {
						return reject(err)
					}
					resolve(res)
				})
			)

			setItems(
				results.data.map((data: TESPageSearch) => ({
					id: data.id,
					title: data.title,
					path: data.path,
					uri: data.uri,
					updatedAt: data.updatedAt,
				}))
			)
		}
		return true
	}
	let extraProps = {}
	if (props.large) {
		extraProps = {
			boxShadow: 'none',
			fontSize: 30,
			height: 70,
		}
	}

	return (
		<Suggest
			inputValueRenderer={handleInputValueRenderer}
			items={items}
			fill
			itemRenderer={handleItemRenderer}
			itemPredicate={handleFilterItems}
			noResults={<MenuItem disabled={true} text="Ingen funn" />}
			onItemSelect={(value) => {
				if (props.onSelect) {
					props.onSelect()
				}
				goToValue(value)
			}}
			popoverProps={{ minimal: true, isOpen: !!query.length }}
			onQueryChange={(query, e) => {
				setQuery(query)
				return searchPages(query)
			}}
			inputProps={{
				large: true,
				id: 'searchTing',
				autoFocus: true,
				placeholder: 'Søk...',
				style: {
					fontSize: 16,
					borderBottomWidth: 0,
					borderTopLeftRadius: 4,
					borderTopRightRadius: 4,
					...extraProps,
				},
			}}
		/>
	)
}
