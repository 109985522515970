import { ProjectAnalyticsContainer, ProjectAnalyticsContainerNoSubmenu } from './Container'
import React, { useCallback, useContext, useEffect } from 'react'
import { PageContext } from '../../../Utils/LoginContext'
import AccessGroups from '../../../Utils/AccessGroups'
import { Button, Callout, Card, FormGroup, H3, H4, InputGroup, Intent, Spinner } from '@blueprintjs/core'
import { Col, Row } from 'reactstrap'
import {
	Config,
	getAccountingAccounts,
	getAccountingActivities,
	getConfigs,
	getRentmanFolders,
	setConfig,
} from '../../../Services/OalApi'
import { useApiData } from '../../../Utils/UseAPI'
import { AddAccountConfig } from './AccountConfig'
import { RentmanConfig } from './RentmanConfig'
import { AccountingActivityConfig } from './AccountingActivityConfig'
import { capitalize } from 'lodash'

export const ProjectAnalyticsConfig = (props: { company: string }) => {
	const context = useContext(PageContext)
	let groups = context.user?.groups || []
	const isAdmin = groups.some((group) => AccessGroups.DashboardAdmin.includes(group))

	const [tripletexDB, setTripletexDB] = React.useState('')
	const [rentmanDB, setRentmanDB] = React.useState('')

	const [company] = React.useState(props.company)
	const {
		data: configuration,
		error: configurationError,
		loading,
		reload: refetchConfiguration,
	} = useApiData<Config[]>(() => getConfigs(`pa-*-${company},pa-tooltip-*`))

	const accountingAccounts = useApiData(() => getAccountingAccounts(company))
	const rentmanFolders = useApiData(() => getRentmanFolders(company))
	const accountingActivites = useApiData(() => getAccountingActivities(company))

	const [tripletexDBIntent, setTripletexDBIntent] = React.useState<Intent | null>()
	const [rentmanDBIntent, setRentmanDBIntent] = React.useState<Intent | null>()
	const [savingDB, setSavingDB] = React.useState(false)
	const [errorDB, setErrorDB] = React.useState<string | null>(null)

	const [tooltips, setTooltips] = React.useState<Record<string, string>>({})

	const accounts = [
		{
			label: 'Omsetning',
			key: 'pa-tripletex-revenue',
		},
		{
			label: 'Subhirekostnad',
			key: 'pa-tripletex-subhire',
		},
		{
			label: 'Intern bemanningskostnad',
			key: 'pa-tripletex-crew-internal',
		},
		{
			label: 'Ekstern bemanningskostnad',
			key: 'pa-tripletex-crew-external',
		},
		{
			label: 'Transportkostnad',
			key: 'pa-tripletex-transport',
		},
		{
			label: 'Total kost (brukes av rest-utregning)',
			key: 'pa-tripletex-rest',
		},
	].map((account) => ({ ...account, key: account.key + '-' + company }))

	const get = useCallback(
		(key: string) => {
			const config = configuration?.find((config) => config.key === key)
			return config?.value
		},
		[configuration]
	)

	useEffect(() => {
		const tooltips: Record<string, string> =
			configuration
				?.filter((conf) => conf.key.startsWith('pa-tooltip-'))
				.reduce((acc, config) => {
					acc[config.key] = config.value
					return acc
				}, {} as Record<string, string>) || {}
		setTooltips(tooltips)
	}, [configuration])

	const saveTooltips = async () => {
		for (const key in tooltips) {
			if (configuration?.find((conf) => conf.key === key)?.value !== tooltips[key]) {
				await setConfig(key, tooltips[key])
			}
		}
	}

	useEffect(() => {
		if (configuration && !configurationError) {
			console.log('Triggered config-loaded,', configuration)
			setTripletexDB(get('pa-tripletex-db-' + company) || '')
			setRentmanDB(get('pa-rentman-db-' + company) || '')
		}
	}, [company, configuration, configurationError, get])

	useEffect(() => {
		if (!tripletexDB.match(/^\d+$/) || parseInt(tripletexDB) > 100) {
			setTripletexDBIntent('danger')
		} else {
			setTripletexDBIntent(null)
		}
	}, [tripletexDB])

	useEffect(() => {
		if (!rentmanDB.match(/^\d+$/) || parseInt(rentmanDB) > 100) {
			setRentmanDBIntent('danger')
		} else {
			setRentmanDBIntent(null)
		}
	}, [rentmanDB])

	const saveDB = async () => {
		setErrorDB(null)
		setSavingDB(true)

		try {
			await setConfig('pa-tripletex-db-' + company, tripletexDB)
			await setConfig('pa-rentman-db-' + company, rentmanDB)
			refetchConfiguration()
		} catch (e: any) {
			setErrorDB(e.message)
		} finally {
			setSavingDB(false)
		}
	}

	if (!isAdmin) {
		return (
			<ProjectAnalyticsContainerNoSubmenu>
				<p>Du har ikke tilgang til denne siden</p>
			</ProjectAnalyticsContainerNoSubmenu>
		)
	}

	return (
		<ProjectAnalyticsContainer company={company}>
			<H3>Konfigurasjon</H3>

			{!configuration && loading ? (
				<Spinner size={64} />
			) : (
				<>
					{configurationError ? (
						<Callout intent={'danger'} className="mt-3">
							{configurationError}
						</Callout>
					) : null}
					{configuration ? (
						<div className="configpage">
							<Row>
								<Col lg={4} md={6} sm={12}>
									<Card elevation={1} className={'mb-4'}>
										<H4>Dekningsbidrag</H4>
										<FormGroup label="Minimum DB i Tripletex" labelFor="tripletexdb">
											<InputGroup
												id="tripletexdb"
												value={tripletexDB}
												onChange={(e) => setTripletexDB(e.target.value)}
												placeholder="23"
												leftIcon="percentage"
												intent={tripletexDBIntent ?? undefined}
											/>
										</FormGroup>
										<FormGroup label="Minimum DB i Rentman" labelFor="rentmandb">
											<InputGroup
												id="rentmandb"
												placeholder="23"
												leftIcon="percentage"
												value={rentmanDB}
												onChange={(e) => setRentmanDB(e.target.value)}
												intent={rentmanDBIntent ?? undefined}
											/>
										</FormGroup>

										<Button
											intent="success"
											icon="floppy-disk"
											className="mt-2"
											type="button"
											onClick={() => saveDB()}
											loading={savingDB}
											disabled={
												savingDB ||
												!(
													get('pa-tripletex-db-' + company) !== tripletexDB ||
													get('pa-rentman-db-' + company) !== rentmanDB
												) ||
												tripletexDBIntent ||
												rentmanDBIntent
													? true
													: false
											}
										>
											Lagre
										</Button>
										{errorDB ? (
											<Callout intent={'danger'} className="mt-3">
												{errorDB}
											</Callout>
										) : null}
									</Card>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<H3>Rentman-crew</H3>
								</Col>
								<RentmanConfig
									type="internal"
									company={company}
									onUpdate={refetchConfiguration}
									list={(get('pa-rentman-folder-internal-' + company) || []) as number[]}
									rentmanFolders={rentmanFolders}
								/>
								<RentmanConfig
									type="external"
									company={company}
									onUpdate={refetchConfiguration}
									list={(get('pa-rentman-folder-external-' + company) || []) as number[]}
									rentmanFolders={rentmanFolders}
								/>
							</Row>
							<Row>
								<Col lg={12}>
									<H3>Tripletexkontoer</H3>
								</Col>
								{accounts.map((account) => {
									return (
										<AddAccountConfig
											key={account.key}
											onUpdate={refetchConfiguration}
											account={account}
											accountingAccountsApi={accountingAccounts}
											list={(get(account.key) || []) as { from: number; to?: number }[]}
										/>
									)
								})}
							</Row>
							<Row>
								<Col lg={12}>
									<H3>Tripletex-timeføring</H3>
								</Col>
								{['tekniker', 'prosjektleder', 'forarbeid'].map((type: any) => (
									<AccountingActivityConfig
										type={type}
										company={company}
										accountingActivities={accountingActivites}
										onUpdate={refetchConfiguration}
										list={get('pa-tripletex-activity-' + type + '-' + company)}
									/>
								))}
							</Row>
							<Row>
								<Col lg={12}>
									<H3>Tooltips / forklaringer</H3>
									<Card elevation={1} className={'mb-4'}>
										{configuration
											.filter((config) => config.key.startsWith('pa-tooltip-'))
											.map((config) => (
												<FormGroup
													label={capitalize(
														config.key.replace(/^pa-tooltip-/, '').replace(/_/g, ' ')
													)}
													labelFor={config.key}
												>
													<InputGroup
														id={config.key}
														value={tooltips[config.key] || ''}
														onChange={(e) => {
															setTooltips({
																...tooltips,
																[config.key]: e.target.value,
															})
														}}
														placeholder={
															'Forklaring for ' + config.key.replace(/^pa-tooltip-/, '')
														}
														leftIcon="info-sign"
														intent="primary"
													/>
												</FormGroup>
											))}
										<Button
											intent="success"
											icon="floppy-disk"
											className="mt-2"
											type="button"
											onClick={() => saveTooltips()}
										>
											Lagre
										</Button>
									</Card>
								</Col>
							</Row>
						</div>
					) : null}
				</>
			)}
		</ProjectAnalyticsContainer>
	)
}

export default ProjectAnalyticsConfig
