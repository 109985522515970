import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

class ServicesContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'services-index',
						title: 'Tjenester',
						icon: 'dashboard',
						link: '/services',
						active: isActivePage(['/services']),
					},
					/*{
						id: 'services-projects',
						title: 'Prosjekter',
						icon: 'edit',
						link: '/services/projects',
						active: isActivePage(['/services/projects']),
					},*/
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default ServicesContainer
