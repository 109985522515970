import React, { useEffect } from "react"
import { AccountingActivity, setConfig } from "../../../Services/OalApi"
import { useApiData } from "../../../Utils/UseAPI"
import { Col } from "reactstrap"
import { Button, Callout, Card, H4, H5, HTMLSelect, Label } from "@blueprintjs/core"

// Hack to make typescript happy
function useApiDataAccountingActivities() {
	return useApiData<AccountingActivity[]>(() => {
		return new Promise((resolve) => resolve([]))
	})
}

type AccountingActivityConfigProps = {
	type: 'tekniker' | 'prosjektleder' | 'forarbeid'
	company: string
	accountingActivities: ReturnType<typeof useApiDataAccountingActivities>
	list: number[]
	onUpdate: () => void
}

export const AccountingActivityConfig = ({ type, company, list, accountingActivities, onUpdate }: AccountingActivityConfigProps) => {
	const [saving, setSaving] = React.useState(false)
	const [error, setError] = React.useState<string | null>(null)
	const [folder, setFolder] = React.useState('')

	const { data: rentmanData, error: rentmanError } = accountingActivities

	const save = async (data: typeof list) => {
		//console.log('Saving', account.key, data)
		setSaving(true)
		setError(null)
		try {
			await setConfig(`pa-tripletex-activity-${type}-${company}`, data)
			onUpdate()
		} catch (e: any) {
			setError(e.message)
		} finally {
			setSaving(false)
		}
	}

	useEffect(() => {
		if (rentmanError) {
			setError(rentmanError)
		}
	}, [rentmanError])

	const addItem = () => {
		if (folder) {
			save([...list, parseInt(folder)])
			setFolder('')
		}
	}

	const remove = (i: number) => {
		console.log('remove index', i, list)
		save(list.filter((_, idx) => idx !== i))
	}

	return (
		<Col lg={4} md={6}>
			<Card elevation={1} className={'mb-4'}>
				<H4>Aktiviteter for {{ tekniker: 'tekniker', prosjektleder: 'prosjektleder', forarbeid: 'teknisk forarbeid' }[type]}</H4>
				<ul style={{ opacity: saving ? 0.5 : 1 }}>
					{(list||[]).map((id, i) => (
						<li key={i}>
							<Button
								icon="cross"
								onClick={() => remove(i)}
								style={{ marginRight: '.5em' }}
								small
								intent="danger"
							/>
							{rentmanData?.find((item) => item.id === id)?.name || id}
						</li>
					))}
				</ul>
				<H5>Legg til mappe:</H5>
				<Label className="mb-4">
					Mappe
					<HTMLSelect value={folder} onChange={(e) => setFolder(e.currentTarget.value)}>
						<option value="">- Mappe -</option>
						{accountingActivities.data?.filter(item => !list.includes(item.id)).map((item) => (
							<option value={item.id} key={item.id}>
								{item.name}
							</option>
						))}
					</HTMLSelect>
				</Label>

				<Button
					intent="success"
					icon="plus"
					className="mt-2"
					type="button"
					disabled={!folder || saving}
					loading={saving}
					onClick={() => addItem()}
				>
					Legg til
				</Button>
				{error ? (
					<Callout intent={'danger'} className="mt-3">
						{error}
					</Callout>
				) : null}
			</Card>
		</Col>
	)
}