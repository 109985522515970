import { NonIdealState } from '@blueprintjs/core'
import React, { Fragment } from 'react'

export default class Error extends React.Component<any, any> {
	render() {
		return (
			<Fragment>
				<div className="pt-5" style={{ minHeight: '100vh' }}>
					<NonIdealState
						icon={'warning-sign'}
						title="Not found"
						description="Hvordan du har havnet her et godt spørsmål. Send gjerne en epost til alpha@trippelm.no om du tror det er en feil"
					/>
				</div>
			</Fragment>
		)
	}
}
