import { DynamicContentModuleProps, DynamicContentModuleEditProps } from '../interface'
import { Button, Callout, Card } from '@blueprintjs/core'
import { getTemporaryPincode } from '../../../Services/OalApi'
import React, { useContext } from 'react'
import { TESContext } from '../../../Utils/TESContext'
import { Col } from 'reactstrap'

export const View = (props: DynamicContentModuleProps) => {
	const TES = useContext(TESContext)
	const [successful, setSuccessful] = React.useState<boolean>(false)
	const [failed, setFailed] = React.useState<string>('')
	const [sendingSMS, setSendingSMS] = React.useState<boolean>(false)

	const sendCode = async () => {
		try {
			setSuccessful(false)
			setFailed('')

			setSendingSMS(true)
			await getTemporaryPincode();
			setSuccessful(true);

			setTimeout(() => {
				setSuccessful(false);
			}, 5000);
		} catch (e: any) {
			console.error(e);
			setFailed(String(e.message));

			setTimeout(() => {
				setFailed('');
			}, 5000);
		} finally {
			setSendingSMS(false);
		}
	}

	return (
		<div style={{ marginBottom: 20 }}>
			<h3>Midlertidig tilgangskode</h3>
			<p>
				Klikk på knappen nedenfor for å få tilsendt en midlertidig tilgangskode på SMS, som du kan bruke til å komme deg inn dørene i lokalene våre. Koden
				er gyldig i <b>15 minutter</b>.
			</p>
			<p>
				<b>NB:</b> Koden er personlig og ikke tillatt å dele med andre.
			</p>
			<Col xs={6} md={4}>
				{ successful &&
					<Card elevation={1} className="mt-4 mb-4 p-0">
						<Callout intent="success" icon="tick">
								<b>Koden er sendt til <i>{TES?.authenticatedUser.telephoneNumber}</i></b>
						</Callout>
					</Card>
				}
				{ failed &&
					<Callout intent="danger" icon="error" className="mt-2 mb-4">
						<p>
							<b>Koden kunne ikke sendes til <i>{TES?.authenticatedUser.telephoneNumber}</i>: {failed}</b>
						</p>
					</Callout>
				}
			</Col>
			<Button intent="primary" icon="mobile-phone" className="mb-2" loading={sendingSMS} disabled={sendingSMS} onClick={() => !props.editMode && sendCode()}>Send midlertidig kode</Button>
		</div>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	return View({ ...props, editMode: true })
}