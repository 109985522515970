import { DynamicContentModuleProps, DynamicMDModuleEditProps } from '../interface'
import Editor, { theme } from 'rich-markdown-editor'
import { getPresignedUploadUrl } from '../../../Services/OalApi'
import { useContext } from 'react'
import { TESContext } from '../../../Utils/TESContext'

export const ourTheme = {
	...theme,
	link: '#ac1c21',
	noticeInfoBackground: 'rgba(100,190,255,0.2)',
	noticeWarningBackground: 'rgba(230,110,0,1)',
	noticeTipBackground: 'rgb(92, 112, 128)',
}

export const View = (props: DynamicContentModuleProps) => {
	return (
		<div style={{ lineHeight: '1.4em' }}>
			<Editor defaultValue={props.block.payload} readOnly theme={ourTheme} />
		</div>
	)
}

export const Edit = (props: DynamicMDModuleEditProps) => {
	const TES = useContext(TESContext)

	const searchLink = async (searchTerm: string) => {
		if (searchTerm.length > 2 && !searchTerm.match(/^http/)) {
			const results: any = await new Promise((resolve, reject) =>
				TES.rpc('searchPage.dashboard.oal.no', { query: { title: searchTerm } }, (err, res) => {
					if (err) {
						return reject(err)
					}
					resolve(res)
				})
			)

			if (results.count) {
				return results.data.map((data: any) => ({
					title: data.title,
					subtitle: data.path + ' - ' + data.createdBy + ', ' + new Date(data.createdAt).toLocaleString(),
					url: data.uri,
				}))
			}
		}
		return
	}

	const uploadImage = async (file: File): Promise<string> => {
		const uid = props.uid
		const extension = file.name.split('.').pop() || 'jpg'
		const filename = `${Date.now()}.${extension}`
		const info = await getPresignedUploadUrl(filename, uid)

		return new Promise((resolve, reject) => {
			fetch(info.url, {
				method: 'PUT',
				body: file,
			})
				.then((res) => {
					resolve(info.finalURL)
				})
				.catch(() => {
					reject('Noe gikk galt under opplastingen av filen')
				})
		})
	}

	return (
		<div style={{ paddingBottom: 20 }}>
			<Editor
				defaultValue={props.block.payload}
				onChange={(val) => {
					props.onChange({ ...props.block, payload: val() || '' })
				}}
				style={{ minHeight: 20 }}
				uploadImage={uploadImage}
				autoFocus
				onSearchLink={searchLink}
				theme={ourTheme}
			/>
		</div>
	)
}
