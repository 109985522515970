import { Button, ButtonGroup } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import { ToolsContactsProps } from '../../Domain/Tools/Contacts'

import Container from './Container'
import { OALContactCard } from '../../Domain/Common/OAL'
import { useEffect } from 'react'
import { getContactsAll } from '../../Services/Contacts'

const humanReadableGroups: { [group: string]: string } = {
	'DA-ANY-EMPLOYED': 'Ansatte i Frontir (Hele konsernet)',
	'DA-OAL-EMPLOYED': 'Ansatte i Frontir Media & Technology Group',
	'DA-TMLB-EMPLOYED': 'Ansatte i Trippel-m',
	'DA-OSS-EMPLOYED': 'Ansatte i Oslo Streamingsenter',
	'DA-OPTI-EMPLOYED': 'Ansatte i Optilux',
	'DA-TMCV-EMPLOYED': 'Ansatte i Connected Venues',
	'DA-ANY-FREELANCE': 'Freelancere i Frontir (Hele konsernet)',
	'DA-TMLB-FREELANCE': 'Freelancere i Trippel-m',
	'DA-OSS-FREELANCE': 'Freelancere i Oslo Streamingsenter',
	'DA-OPTI-FREELANCE': 'Freelancere i Optilux',
	'DA-TMCV-FREELANCE': 'Freelancere i Connected Venues',
	'ORG-NRK': 'NRK (Kontaktpersoner i kirkeprosjektet)',
}

export const ToolsIndex = (props: ToolsContactsProps) => {
	const [contacts, setContacts] = React.useState<OALContactCard[]>([])
	const [mode, setMode] = React.useState<'email' | 'phone' | 'name' | 'namephone' | 'nameemail'>('email')
	const [separator, setSeparator] = React.useState<';' | ',' | ''>(';')
	const [newline, setNewline] = React.useState<boolean>(false)
	const [groups, setGroups] = React.useState<string[]>([])
	const [activeGroup, setActiveGroup] = React.useState<string | ''>('')



	useEffect(() => {
		let crude: string[] = []
		contacts.forEach((contact) => {
			contact.groups.forEach((group) => {
				if (!crude.includes(group)) {
					crude.push(group)
				}
			})
		})

		// sort crude alphabetically
		crude.sort((a, b) => {
			if (a < b) return -1
			if (a > b) return 1
			return 0
		})

		// sort crude by human readable groups
		const humanList = Object.keys(humanReadableGroups).reverse()
		for (let key of humanList) {
			let idx = crude.indexOf(key)

			if (idx > -1) {
				crude.splice(idx, 1)
				crude.unshift(key)
			}
		}

		setGroups(crude)
	}, [setGroups, contacts])

	useEffect(() => {
		; (async () => {
			const res = await getContactsAll()
			// sort res object by name
			res.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})

			setContacts(res)
		})()
	}, [setContacts])

	return (
		<Container>
			<ButtonGroup className="mb-3">
				<Button disabled outlined>
					Modus
				</Button>
				<Button onClick={() => setMode('nameemail')} active={mode === 'nameemail'}>
					Navn+epost
				</Button>
				<Button onClick={() => setMode('namephone')} active={mode === 'namephone'}>
					Navn+num
				</Button>
				<Button onClick={() => setMode('email')} active={mode === 'email'}>
					Epost
				</Button>
				<Button onClick={() => setMode('phone')} active={mode === 'phone'}>
					Telefon
				</Button>
				<Button onClick={() => setMode('name')} active={mode === 'name'}>
					Navn
				</Button>

				<Button disabled outlined>
					Skille
				</Button>

				<Button onClick={() => setSeparator(';')} active={separator === ';'}>
					Semikolon
				</Button>
				<Button onClick={() => setSeparator(',')} active={separator === ','}>
					Komma
				</Button>
				<Button onClick={() => setSeparator('')} active={separator === ''}>
					Ingen
				</Button>

				<Button disabled outlined>
					Linjeskift
				</Button>

				<Button onClick={() => setNewline(true)} active={newline}>
					Linjeskift
				</Button>
				<Button onClick={() => setNewline(false)} active={!newline}>
					Ingen linjeskift
				</Button>
			</ButtonGroup>

			<select
				className="form-control"
				onChange={(e) => {
					setActiveGroup(e.target.value)
				}}
				value={activeGroup || ''}
			>
				{activeGroup === '' && <option value="">Velg sikkerhetsgruppe</option>}

				{groups.map((group) => (
					<option key={group} value={group}>
						{humanReadableGroups[group] ? humanReadableGroups[group] : group}
					</option>
				))}
			</select>

			{activeGroup !== '' && (
				<div className="mt-3">
					<div
						className="p-4 mb-4"
						style={{
							backgroundColor: 'rgba(128,128,128,0.1)',
							borderRadius: '5px',
							border: '1px solid rgba(128,128,128,0.2)',
						}}
					>
						{contacts
							.filter((contact) => contact.groups.includes(activeGroup))
							.map((contact) => {
								if (mode === 'nameemail') return `"${contact['name']}" <${contact['email']}>`
								if (mode === 'namephone') return `${contact['name']} (${contact['phone']})`

								return contact[mode]
							})
							.map((content) => (
								<Fragment>
									{content}
									{separator}
									{newline && (
										<Fragment>
											{'\n'}
											<br />
										</Fragment>
									)}{' '}
								</Fragment>
							))}
					</div>
				</div>
			)}
		</Container>
	)
}

export default ToolsIndex
