import { Button, ButtonGroup, TagInput } from '@blueprintjs/core'

const presets = [
	{ label: 'FMTG Ansatt', group: 'DA-ANY-EMPLOYED' },
	{ label: 'FMTG Frilans', group: 'DA-ANY-FREELANCE' },
	{ label: 'Samla CEO', group: 'DA-SAMLA-CEO' },
	{ label: 'Samla Bruker', group: 'DA-SAMLA-USER' },
]

export const AccessEditor = (props: {
	style?: React.CSSProperties
	id?: string
	values: string[]
	onChange: (newArray: string[]) => void
	disabled?: boolean
}) => {
	const { values, onChange } = props
	return (
		<div style={props.style}>
			<ButtonGroup fill>
				{presets.map(({ group, label }) => (
					<Button
						disabled={props.disabled}
						key={group}
						onClick={() => {
							if (values.indexOf(group) === -1) {
								onChange([...values, group])
							} else {
								onChange(values.filter((v) => v !== group))
							}
						}}
						icon={values.includes(group) ? 'tick' : 'cross'}
						intent={values.includes(group) ? 'success' : 'danger'}
					>
						{label}
					</Button>
				))}
			</ButtonGroup>
			<TagInput
				inputProps={{ id: props.id, disabled: props.disabled }}
				values={values}
				onChange={(val) => {
					const newArray = val as string[]
					onChange(newArray)
				}}
				tagProps={{ minimal: true }}
			/>
		</div>
	)
}
