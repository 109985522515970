import React from 'react'
import { Button, ButtonGroup, Tag } from '@blueprintjs/core'
import { TESContext } from '../../../Utils/TESContext'

interface PendingChange {
	id: string
	switchId: string
	function: string
	arguments: { [key: string]: any }
	description: string
	state: 'pending' | 'comitting' | 'error'
	author: string
	authorId: string
	requestedCommitTime?: number
	committingTime?: number
	addedTime: number
	errorTime?: number
	error?: string
}

interface Props {}
interface State {
	PendingChanges: PendingChange[]
	commiting: boolean
}
interface PendingChangeRenderItemProps {
	PendingChange: PendingChange
	rpc: any
}
function PendingChangeRenderItem(props: PendingChangeRenderItemProps) {
	return (
		<div
			style={{
				paddingBottom: 4,
			}}
		>
			<Button
				onClick={() => {
					props.rpc(
						'deletePendingChange.switch.network.oal.no',
						{
							id: props.PendingChange.id,
						},
						(err: string, res: string) => {

						}
					)
				}}
				icon="cross"
				minimal
				intent="danger"
				style={{
					float: 'right',
				}}
			/>
			<div
				style={{
					borderLeft: '4px solid #e8e8e8',
					borderRadius: 4,
					padding: 5,
					marginBottom: 10,
				}}
			>
				{props.PendingChange.state === 'comitting' && <Tag intent="warning">Committing</Tag>}
				{props.PendingChange.state === 'error' && <Tag intent="danger">Error</Tag>}
				{props.PendingChange.state === 'pending' && <Tag intent="success">Pending</Tag>}
				<div style={{ fontSize: 12, color: '#444', paddingLeft: 2, fontWeight: 'bold', marginTop: 5 }}>
					{props.PendingChange.description}
				</div>
				<div style={{ fontSize: 11, marginTop: 5 }}>- {props.PendingChange.author}</div>
			</div>
		</div>
	)
}

export class SwitchPendingChanges extends React.Component<Props, State> {
	static contextType = TESContext

	state: State = {
		PendingChanges: [],
		commiting: false,
	}

	private subscriptionReferenceSwitchChanges: any = this.handleUpdateSwitches.bind(this)

	handleUpdateSwitches(key: string, PendingChanges: PendingChange[]) {
		this.setState({ PendingChanges })
	}

	componentDidMount() {
		this.context.subscribe('PendingChanges.switch.network.oal.no', this.subscriptionReferenceSwitchChanges)
		this.context.get('PendingChanges.switch.network.oal.no', (err: any, res: any) => {
			if (!err) this.handleUpdateSwitches('PendingChanges.switch.network.oal.no', res)
		})
	}

	componentWillUnmount() {
		this.context.unsubscribe('PendingChanges.switch.network.oal.no', this.subscriptionReferenceSwitchChanges)
	}

	render() {
		const otherChanges = this.state.PendingChanges.filter(
			(pc) => pc.authorId !== this.context?.authenticatedUser?.sAMAccountName
		)

		const yourChanges = this.state.PendingChanges.filter(
			(pc) => pc.authorId === this.context?.authenticatedUser?.sAMAccountName
		)

		return (
			<div
				style={{
					display: 'block',
				}}
			>
				<ButtonGroup>
					{yourChanges.filter((c) => c.state === 'pending').length > 0 && (
						<Button
							icon="confirm"
							disabled={!this.state.PendingChanges.length || this.state.commiting}
							onClick={() => {
								this.setState({ commiting: true })
								this.context.rpc(
									'commitChanges.switch.network.oal.no',
									{},
									(err: string | null, res: any) => {
										this.setState({ commiting: false })
									}
								)
							}}
						>
							Commit your changes
						</Button>
					)}
				</ButtonGroup>
				{yourChanges.length > 0 && (
					<div style={{ marginTop: 10 }}>
						<p>
							<strong>Your pending changes</strong>
						</p>
						{yourChanges.map((PendingChange: PendingChange, index: number) => {
							return (
								<PendingChangeRenderItem
									key={index}
									rpc={this.context.rpc}
									PendingChange={PendingChange}
								></PendingChangeRenderItem>
							)
						})}
					</div>
				)}
				<div style={{ marginTop: 10 }}>
					{otherChanges.length > 0 && (
						<>
							<p>
								<strong>Other pending changes worth knowing about</strong>
							</p>
							{otherChanges.map((PendingChange: PendingChange, index: number) => {
								return (
									<PendingChangeRenderItem
										key={index}
										rpc={this.context.rpc}
										PendingChange={PendingChange}
									></PendingChangeRenderItem>
								)
							})}
						</>
					)}
				</div>
			</div>
		)
	}
}
