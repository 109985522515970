import { Colors, Dialog, Icon } from '@blueprintjs/core'
import { DIALOG_BODY } from '@blueprintjs/core/lib/esm/common/classes'
import { find } from 'lodash'
import React from 'react'
import Container from '../../Net/Container'
import './table.css'

export type Crosspoint = {
	id: string
	label: string
	type: 'rack' | 'vlan' | 'loc'
}

const crosspoints: Crosspoint[] = [
	{ id: '1', label: 'OBR1', type: 'rack' },
	{ id: '2', label: 'OBR2', type: 'rack' },
	{ id: '3', label: 'OBR3', type: 'rack' },
	{ id: '4', label: 'OBR5', type: 'rack' },
	{ id: '5', label: 'OBR6', type: 'rack' },
	{ id: '6', label: 'OBR7', type: 'rack' },
	{ id: '7', label: 'OBR8', type: 'rack' },
	{ id: '8', label: 'OBX1', type: 'rack' },
	{ id: '9', label: 'OBX2', type: 'rack' },
	{ id: '10', label: 'AVR1', type: 'rack' },
	{ id: '11', label: 'AVR2', type: 'rack' },
	{ id: '12', label: 'OBA1', type: 'rack' },
	{ id: '13', label: 'OBA2', type: 'rack' },
	{ id: '14', label: 'OBA3', type: 'rack' },
	{ id: '15', label: 'OBA4', type: 'rack' },
	{ id: '16', label: 'OBA5', type: 'rack' },
	{ id: '17', label: 'OBA6', type: 'rack' },
	{ id: '18', label: 'OBA7', type: 'rack' },
	{ id: '19', label: 'CVR1', type: 'rack' },
	{ id: '20', label: 'CVR2', type: 'rack' },
	{ id: '21', label: 'CVR3', type: 'rack' },
	{ id: '22', label: 'CVR4', type: 'rack' },
	{ id: '23', label: 'CVR5', type: 'rack' },
	{ id: '24', label: 'CCR1', type: 'rack' },
	{ id: '25', label: 'CCR2', type: 'rack' },
	{ id: '26', label: 'CCR3', type: 'rack' },
	{ id: '27', label: 'UV89', type: 'loc' },
	{ id: '28', label: 'KG5-OFFICE', type: 'loc' },
	{ id: '29', label: 'SHP4-OFFICE', type: 'loc' },
	{ id: '30', label: 'SHP4-TECH', type: 'loc' },
]

type ActiveCrosspoint = {
	x: string
	y: string
	validUntil: number
}

export default function NetFirewall() {
	let legendcopy = [...crosspoints]
	const [hoverX, setHoverX] = React.useState<string | null>(null)
	const [hoverY, setHoverY] = React.useState<string | null>(null)
	const [activeEditor, setActiveEditor] = React.useState<{ x: string; y: string } | null>({ x: '1', y: '2' })
	const [activeCrosspoints, setActiveCrosspoints] = React.useState<ActiveCrosspoint[]>([])

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const create_crosspoint = (x: string, y: string) => {
		setActiveCrosspoints([...activeCrosspoints, { x, y, validUntil: Date.now() + 1000 * 60 * 60 * 24 }])
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const delete_crosspoint = (x: string, y: string) => {
		setActiveCrosspoints(activeCrosspoints.filter((c) => c.x !== x || c.y !== y))
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const is_crosspoint_active = (x: string, y: string) => {
		return !!find(activeCrosspoints, (c) => c.x === x && c.y === y)
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const is_crosspoint_valid = (x: string, y: string) => {
		const c = find(activeCrosspoints, (c) => c.x === x && c.y === y)
		if (!c) return false
		return c.validUntil > Date.now()
	}

	const is_crosspoint_hovered = (x: string, y: string) => {
		return hoverX === x && hoverY === y
	}

	return (
		<Container>
			<CrosspointEditor
				activeEditor={activeEditor}
				crosspoints={crosspoints}
				onClose={() => {
					setActiveEditor(null)
				}}
			/>
			<table
				className="xpt-table"
				onMouseLeave={() => {
					setHoverY(null)
					setHoverX(null)
				}}
			>
				<thead>
					<tr>
						<th>&nbsp;</th>
						{crosspoints
							.filter((find) => find.type !== 'loc')
							.map((crosspoint_x) => (
								<th
									key={crosspoint_x.id}
									style={{
										height: 30,
										backgroundColor: crosspoint_x.id === hoverX ? Colors.GOLD5 : '#f3f3f3',
									}}
									onMouseEnter={() => {
										setHoverY(null)
										setHoverX(null)
									}}
								>
									{crosspoint_x.label}
								</th>
							))}
					</tr>
				</thead>
				<tbody>
					{crosspoints.map((crosspoint_y) => (
						<tr key={crosspoint_y.id}>
							<th
								style={{
									height: 30,
									padding: 5,
									backgroundColor: hoverY === crosspoint_y.id ? Colors.GOLD5 : '#f3f3f3',
									borderBottom: '1px solid #e0e0e0',
									textAlign: 'left',
								}}
								onMouseEnter={() => {
									setHoverY(null)
									setHoverX(null)
								}}
							>
								{legendcopy.shift()?.label || 'Unknown'}
							</th>
							{crosspoints.map((crosspoint_x) => (
								<td
									key={crosspoint_x.id}
									onMouseEnter={(e) => {
										setHoverX(crosspoint_x.id)
										setHoverY(crosspoint_y.id)
									}}
									onClick={(e) => {
										setActiveEditor({
											x: crosspoint_x.id,
											y: crosspoint_y.id,
										})
									}}
									style={{
										cursor: 'pointer',
										backgroundColor: is_crosspoint_hovered(crosspoint_x.id, crosspoint_y.id)
											? Colors.GOLD5
											: activeCrosspoints.find(
													(o) => o.x === crosspoint_x.id && o.y === crosspoint_y.id
											  )
											? Colors.GOLD5
											: 'white',

										boxShadow: 'inset 0 0 0 1px #aaa3',
									}}
									className={crosspoint_x.id === crosspoint_y.id ? 'crosshatch' : ''}
								>
									{activeCrosspoints.find(
										(o) => o.x === crosspoint_x.id && o.y === crosspoint_y.id
									) ? (
										<div style={{ textAlign: 'center', fontSize: 11 }}>
											<Icon icon="time" iconSize={13} color={Colors.BLACK} /> 23m
										</div>
									) : undefined}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</Container>
	)
}

function CrosspointEditor({
	activeEditor,
	crosspoints,
	onClose,
}: {
	activeEditor: { x: string; y: string } | null
	crosspoints: Crosspoint[]
	onClose: () => void
}) {
	const crosspoint_x = crosspoints.find((o) => o.id === activeEditor?.x)
	const crosspoint_y = crosspoints.find((o) => o.id === activeEditor?.y)

	return (
		<Dialog isOpen={activeEditor !== null} title={<>IP Crosspoint Editor</>} onClose={onClose}>
			<div className={DIALOG_BODY}>
				<div style={{ textAlign: 'center', fontSize: 30 }}>
					{crosspoint_x?.label} <Icon icon="swap-horizontal" iconSize={30} color={Colors.GOLD3} />{' '}
					{crosspoint_y?.label}
				</div>
			</div>
		</Dialog>
	)
}
