import { Button, ControlGroup, InputGroup, ResizeEntry } from '@blueprintjs/core'
import { DynamicContentModuleProps, DynamicContentModuleEditProps } from '../interface'
import { ResizeSensor2 } from '@blueprintjs/popover2'
import { useState } from 'react'

export const View = (props: DynamicContentModuleProps) => {
	const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 })

	const handleResize = (entries: ResizeEntry[]) => {
		const e = entries[0]
		setSize({ width: e.contentRect.width, height: (e.contentRect.width / 16) * 9 })
	}

	return (
		<div style={{ width: '100%', display: 'block' }}>
			<ResizeSensor2 onResize={(entries) => handleResize(entries)}>
				<div>
					<iframe
						frameBorder="0"
						style={{ border: '1px solid black' }}
						title={props.block.payload.url}
						width={size.width}
						height={size.height}
						src={props.block.payload.url}
					/>
				</div>
			</ResizeSensor2>
		</div>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	const [testUrl, setTestUrl] = useState<string>(props.block.payload.url)

	return (
		<div className="pt-3 pb-3">
			<ControlGroup>
				<InputGroup
					fill
					placeholder="Iframe adresse"
					autoComplete="off"
					autoCorrect="off"
					autoFocus
					value={props.block.payload.url}
					style={
						props.block.payload.url !== testUrl
							? {
									borderColor: 'yellow',
									boxShadow:
										'0 0 0 1px yellow, 0 0 0 3px rgb(19 124 189 / 30%), inset 0 1px 1px rgb(16 22 26 / 20%)',
							  }
							: {}
					}
					onChange={(e) => {
						props.onChange({
							...props.block,
							payload: {
								...props.block.payload,
								url: e.target.value,
							},
						})
					}}
				/>
				<Button
					type="button"
					icon="eye-open"
					onClick={() => {
						setTestUrl(props.block.payload.url)
					}}
				>
					Test URL
				</Button>
			</ControlGroup>
			<div>
				<View block={{ ...props.block, payload: { ...props.block.payload, url: testUrl } }} />
			</div>
		</div>
	)
}
