import React, { useContext } from 'react'
import { PageContext } from '../../Utils/LoginContext'
import { ProfileImageThumbnail, uploadProfileImage } from '../../Services/OalApi'
import Container from './Container'
import { User } from '../../Domain/Common/User'
import { H3, Button, Dialog, Callout } from '@blueprintjs/core'
import { Row, Col } from 'reactstrap'
import ProfilePicture from '@dsalvagni/react-profile-picture'
import '@dsalvagni/react-profile-picture/dist/ProfilePicture.css'
import './custom.css'

interface UserIndexProps {
	id?: string
}

const UserIndex = (props: UserIndexProps) => {
	const context = useContext(PageContext)
	const profilePictureRef = React.useRef<any>(null)
	const [showImageEditorDialog, setShowImageEditorDialog] = React.useState(false)
	const [saving, setSaving] = React.useState(false)
	const [savingSuccess, setSavingSuccess] = React.useState(false)
	const [savingError, setSavingError] = React.useState('')

	const profile: User | null = context?.user
	if (!profile) {
		return <></>
	}

	const saveImage = async () => {
		try {
			setSaving(true)
			const data = await fetch(profilePictureRef.current?.getImageAsDataUrl())
			const file = new File([await data.arrayBuffer()], 'profile.jpg', { type: 'image/jpeg' })

			const result = await uploadProfileImage(file)
			if (result.result === 'ok') {
				setSavingSuccess(true)
				setSaving(false)
				setTimeout(() => {
					window.location.reload()
				}, 2000)
			}
		} catch (e: any) {
			setSaving(false)
			setSavingError(e.message)
		}
	}

	return (
		<Container>
			<H3>Din profil</H3>
			<Row>
				<Col xs={12} xl={8}>
					<Callout intent="primary" title="Oppdatere informasjon" className="mb-4">
						Hvis du trenger å forandre mobilnummer, epost-adresse, navn eller lignende, så må dette gjøres i
						din Rentman- eller HiBobkonto. Det vil automatisk oppdateres hit periodisk.
					</Callout>
				</Col>
			</Row>
			<div style={{ display: 'flex' }}>
				<div>
					<ProfileImageThumbnail username={profile.sAMAccountName} style={{ borderRadius: '100%' }} />
				</div>
				<div style={{ paddingTop: 13, paddingLeft: 15 }}>
					<H3 style={{ marginBottom: 5 }}>{profile?.cn}</H3>
					<p style={{ fontSize: '1.2em' }}>
						{profile?.sAMAccountName}
						<br />
						{profile?.mail}
					</p>
				</div>
			</div>
			<div>
				<Button
					className="mt-4"
					intent="success"
					text="Endre profilbilde"
					icon="user"
					large
					onClick={() => setShowImageEditorDialog(true)}
				/>
			</div>

			<Dialog
				isOpen={showImageEditorDialog}
				onClose={() => setShowImageEditorDialog(false)}
				title="Bytte profilbilde"
				className="pb-1"
			>
				<div style={{ padding: '1em' }}>
					<ProfilePicture
						ref={profilePictureRef}
						useHelper={true}
						debug={false}
						minImageSize={350}
						onImagePropertiesChange={(imageProperties: any) => {}}
						frameSize={400}
						cropSize={300}
						frameFormat="circle"
						messages={{
							DEFAULT: 'Slipp et bilde av deg her, eller trykk for å velge.',
							DRAGOVER: 'Slipp her',
							INVALID_FILE_TYPE: 'Støtter kun bilder.',
							INVALID_IMAGE_SIZE: 'Bildet ditt må være større enn 350 piksler bredt/høyt.',
						}}
					/>
					{savingSuccess && (
						<Callout className="mt-3" intent="success" icon="tick">
							Ditt nye profilbilde er nå oppdatert!
						</Callout>
					)}
					{savingError && (
						<Callout className="mt-3" intent="danger" icon="error">
							Det oppstod en feil ved lagring av bildet. Prøv igjen senere.
						</Callout>
					)}
					<Button
						intent="success"
						text="Last opp"
						icon="cloud-upload"
						large
						disabled={saving}
						loading={saving}
						className="mt-3"
						onClick={() => saveImage()}
					/>
				</div>
			</Dialog>
		</Container>
	)
}

export default UserIndex
