import { Button, Classes, Dialog, HTMLSelect } from '@blueprintjs/core'
import { useEffect, useState } from 'react'
import { OALContactCard } from '../../Domain/Common/OAL'
import { getContactsAll } from '../../Services/Contacts'

/*
import { Suggest } from '@blueprintjs/select'

interface AMenuItem {
	label: string
	value: string
}

function SelectSuggest<T extends AMenuItem>({
	onItemSelect,
	items,
	value,
}: {
	onItemSelect: (item: T) => void
	items: T[]
	value?: T['value']
}) {
	const itemSubRenderer = (categories: T[] | null, item: T): string => {
		if (!item) {
			return ''
		}
		return item.label
	}

	return (
		<Suggest
			fill
			openOnKeyDown
			inputProps={{ autoFocus: true }}
			itemListPredicate={(query, items) => {
				if (query === '') return []
				return items
					.filter((item) => item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0)
					.slice(0, 5)
			}}
			itemsEqual={(a, b) => a.value === b.value}
			inputValueRenderer={(item) => itemSubRenderer(items, item)}
			onItemSelect={(item) => {
				console.log('Selected: ', item)
				onItemSelect(item)
			}}
			selectedItem={items.find((item) => item.value === value)}
			noResults={<MenuItem disabled={true} text="Fant ingen tilsvarende kategori" />}
			itemRenderer={(item, { handleClick, modifiers, query }) => {
				return (
					<MenuItem
						active={modifiers.active}
						onClick={(e) => { e.preventDefault(); onItemSelect(item) }}
						text={itemSubRenderer(items, item)}
						itemID={String(item.value)}
					/>
				)
			}}
			items={items}
		/>
	)
}

export const OwnerEditor = (props: {
	style?: React.CSSProperties
	id?: string
	value: OALContactCard['id']
	onChange: (newValue: OALContactCard['id']) => void
	disabled?: boolean
}) => {
	const [contacts, setContacts] = useState<OALContactCard[]>([])

	useEffect(() => {
		;(async () => {
			const res = await getContactsAll()
			// sort res object by name
			res.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})

			setContacts(res)
		})()
	}, [setContacts])

	//	const { values, onChange } = props
	return (
		<div style={props.style}>
			<SelectSuggest
				items={contacts.map((contact) => ({ label: contact.name, value: contact.id }))}
				onItemSelect={(item) => console.log('Selected: ', item)}
			/>
		</div>
	)
}
*/
export const OwnerEditor = (props: {
	style?: React.CSSProperties
	id?: string
	value: OALContactCard['id']
	onChange: (newValue: OALContactCard['id']) => void
	disabled?: boolean
}) => {
	const [dialogOpen, setDialogOpen] = useState<OALContactCard['id'] | false>(false)
	const [contacts, setContacts] = useState<OALContactCard[]>([])

	useEffect(() => {
		;(async () => {
			let res = await getContactsAll()

			res = res.filter((contact) => contact.groups.includes('DA-ANY-EMPLOYED'))

			// sort res object by name
			res.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})

			setContacts(res)
		})()
	}, [setContacts])

	//	const { values, onChange } = props
	return (
		<>
			<Dialog icon={"person"} title="Velg eierskap" isOpen={dialogOpen !== false} onClose={() => setDialogOpen(false)}>
				<div className={Classes.DIALOG_BODY}>
					<p className='bp3-running-text'>
						Er du sikker på at du vil flytte eierskap på denne blokken over til <strong>{contacts.find(c => c.id === dialogOpen)?.name || 'UKJENT??'}</strong>?
					</p>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => setDialogOpen(false)} intent="warning">
							Avbryt
						</Button>
						<Button onClick={() => {
							props.onChange(dialogOpen||'')
							setDialogOpen(false)
						}} intent="success">
							Bytt
						</Button>
					</div>
				</div>
			</Dialog>
			<div style={props.style}>
				<HTMLSelect
					value={props.value}
					onChange={(e) => setDialogOpen(e.target.value as OALContactCard['id'])}
					large
					fill
				>
					{contacts.map((contact) => (
						<option key={contact.id} value={contact.id}>
							{contact.name}
						</option>
					))}
				</HTMLSelect>
			</div>
		</>
	)
}
