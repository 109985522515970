import { Icon, ControlGroup, InputGroup, Button, Spinner, SpinnerSize } from '@blueprintjs/core'
import React from 'react'
import { TicketState } from './types'
import { useTicketListMine } from './hooks/useTicketListMine'
import { useHistory } from 'react-router-dom'

export function TicketsList(props: {
	states?: TicketState[]
	wrapperStyle?: React.CSSProperties
	listStyle?: React.CSSProperties
}) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { tickets, loading, refreshTickets } = useTicketListMine(
		props.states === undefined ? ['open', 'closed'] : props.states
	)

	const history = useHistory()
	/*
	const [showTicket, setShowTicket] = React.useState<number | null>(null)

	useEffect(() => {
		if (showTicket === null) {
			refreshTickets()
		}
	}, [showTicket])
	*/

	return (
		<>
			<div style={{ ...props.wrapperStyle }}>
				<ControlGroup fill>
					<Button icon="issue-closed"></Button>
					<InputGroup placeholder="Søk.." leftIcon={'search'} type="text" />
				</ControlGroup>
				<div>
					{loading ? (
						<div style={{ textAlign: 'center' }}>
							<Spinner size={SpinnerSize.SMALL} />
						</div>
					) : (
						<>
							{tickets.map((ticket) => (
								<div
									key={ticket.id}
									style={{ ...props.listStyle }}
									onClick={() => {
										history.push('/sak/' + ticket.id)
									}}
								>
									<div
										style={{
											border: '1px solid #eee',
											padding: '4px 6px',
											marginTop: 2,
											borderRadius: 3,
											cursor: 'pointer',
											marginLeft: -1,
											marginRight: -1,
										}}
									>
										<Icon icon="issue" /> #{ticket.id}: {ticket.title || 'No title'}
									</div>
								</div>
							))}
						</>
					)}
				</div>
			</div>
		</>
	)
}
