export function isActivePage(checklist: Array<string | RegExp>): boolean {
	const url = window.location.pathname
	let exists: boolean = false

	checklist.forEach((test) => {
		if (typeof test === 'object' && url.match(test)) exists = true
		else if (url === test) exists = true
	})

	return exists
}

export function escapeRegExp(str: string) {
	return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}