import { Filter } from './api'

export const filterGen = (purpose: 'source' | 'target'): Filter[] => {
	const filters: Filter[] = [
		{ label: 'Oslo Streamingsenter', cat: 'org', regex: /(OSS)/, source: true, target: false },
		{ label: 'Connected Venues', cat: 'org', regex: /(CV)[- ]/, source: true, target: false },
		{ label: 'Kirker', cat: 'org', regex: /(CV-K\d+-[A-Z]+)/, source: true, target: false },
		{ label: 'Trippel-M', cat: 'org', regex: /(TMLB)/, source: true, target: false },
		{ label: 'Norway Live', cat: 'org', regex: /(NLTV)/, source: true, target: false },
		{ label: 'Datacenter', cat: 'loc', regex: /UV89/, source: true, target: false },
		{ label: 'SIENNA', cat: 'loc', regex: /SIENNA/, source: true, target: false },
		{ label: 'Mac Mini', cat: 'loc', regex: / - MINI ?\d+/, source: true, target: false },
		{ label: 'MIX 1', cat: 'mix', regex: /OSS.+-MIX1 /, source: true, target: false },
		{ label: 'MIX 2', cat: 'mix', regex: /OSS.+-MIX2/, source: true, target: false },
		{ label: 'MIX 3', cat: 'mix', regex: /OSS.+-MIX3/, source: true, target: false },
		{ label: 'MIX 11', cat: 'mix', regex: /CV.+-MIX11 /, source: true, target: false },
		{ label: 'VideoXLink', regex: /VIDX/, source: true, target: false },

		{ label: 'PCR 1', cat: 'loc', regex: /PCR ?1[\b -.]/, source: false, target: true },
		{ label: 'ACR 1', cat: 'loc', regex: /ACR ?1[\b -.]/, source: false, target: true },
		{ label: 'PCR 2', cat: 'loc', regex: /PCR ?2[\b -.]/, source: false, target: true },
		{ label: 'ACR 2', cat: 'loc', regex: /ACR ?2[\b -.]/, source: false, target: true },
		{ label: 'PCR 3', cat: 'loc', regex: /PCR ?3[\b -.]/, source: false, target: true },

		{ label: 'S1', cat: 'loc', regex: /S ?1[\b -.]/, source: false, target: true },
		{ label: 'S2', cat: 'loc', regex: /S ?2[\b -.]/, source: false, target: true },
		{ label: 'S3', cat: 'loc', regex: /S ?3[\b -.]/, source: false, target: true },

		{ label: 'PCR 11', cat: 'loc', regex: /PCR ?11[\b -.]/, source: false, target: true },
		{ label: 'PCR 12', cat: 'loc', regex: /PCR ?12[\b -.]/, source: false, target: true },
		{ label: 'ACR 11', cat: 'loc', regex: /ACR ?11[\b -.]/, source: false, target: true },

		{ label: 'FLEX 11', cat: 'loc', regex: /FLEX ?11[\b -.]/, source: false, target: true },
		{ label: 'FLEX 12', cat: 'loc', regex: /FLEX ?12[\b -.]/, source: false, target: true },
		{ label: 'FLEX 13', cat: 'loc', regex: /FLEX ?13[\b -.]/, source: false, target: true },

		{ label: 'MEET 1', cat: 'loc', regex: /MEET ?1[\b -.]/, source: true, target: true },
		{ label: 'MEET 11', cat: 'loc', regex: /MEET ?11[\b -.]/, source: true, target: true },

		{ label: 'MCR', cat: 'loc', regex: /MCR/, source: false, target: true },
	]

	if (purpose === 'source') return filters.filter((f) => f.source)
	else return filters.filter((f) => f.target)
}
