export type Company = 'tmlb' | 'oss' | 'tmcv' | 'opti' | 'bf' | 'vot' | 'oalm'
export type RentmanCompanies = 'tmlb' | 'oss' | 'tmcv' | 'opti' | 'oalm'

export const OALCompanies: { label: string, value: Company }[] = [
	{ label: 'Trippel-M Levende Bilder AS', value: 'tmlb' },
	{ label: 'Oslo Streamingsenter AS', value: 'oss' },
	{ label: 'Trippel-M Connected Venues AS', value: 'tmcv' },
	{ label: 'Optilux AS', value: 'opti' },
	{ label: 'Bitfocus AS', value: 'bf' },
	{ label: 'Voter AS', value: 'vot' },
	{ label: 'OAL Media AS', value: 'oalm' },
];

export const OALMediaCompanies: { label: string, value: RentmanCompanies }[] = [
	{ label: 'Trippel-M Levende Bilder AS', value: 'tmlb' },
	{ label: 'Oslo Streamingsenter AS', value: 'oss' },
	{ label: 'Trippel-M Connected Venues AS', value: 'tmcv' },
	{ label: 'Optilux AS', value: 'opti' },
	{ label: 'OAL Media AS', value: 'oalm' }
];