import { Block, PageV1 } from '.'
import { OALUser } from '../OAL/User'
import { OALGroup } from '../OAL/Group'
import { Popover2 } from '@blueprintjs/popover2'
import { Button, Icon, Intent } from '@blueprintjs/core'
import { PageContext } from '../../Utils/LoginContext'
import { useContext } from 'react'
import { TESContext } from '../../Utils/TESContext'

interface DynamicContentBlockOwnershipProps {
	page: PageV1
	block: Block
	public: boolean
	style?: React.CSSProperties
	onChanged: () => void
}

export const DynamicContentBlockOwnership = (props: DynamicContentBlockOwnershipProps) => {
	let style = props.style || {}
	const context = useContext(PageContext)
	const tesContext = useContext(TESContext)

	let comment: string | null = null

	let iconIntent: Intent | undefined = undefined

	if (props.block.markedAt) {
		iconIntent = Intent.DANGER
		comment = 'Merket som utdatert av ' + props.block.markedBy
	} else if (
		props.block.updatedAt &&
		new Date(props.block.updatedAt) < new Date(new Date().getTime() - 1000 * 3600 * 24 * 182)
	) {
		// halvt år?
		iconIntent = Intent.WARNING
		comment = 'Ikke oppdatert på over 6 måneder'
	}

	return (
		<div style={{ ...style }} className="blockownership">
			{props.block.accessRead.map((group) => (
				<OALGroup key={group} id={group} />
			))}
			{props.public ? <OALGroup id="EVERYONE" /> : null}
			<Popover2
				content={
					<div className="p-3">
						Opprettet av <OALUser id={props.block.createdBy} />, sist endret{' '}
						{props.block.updatedAt && new Date(props.block.updatedAt).toLocaleString()} av{' '}
						<OALUser id={props.block.updatedBy} />.<br />
						Eies av <OALUser id={props.block.owner} />.{comment && <b>{comment}</b>}
						<p className="mt-4 mb-0">
							<Button
								small
								intent="warning"
								icon="outdated"
								className="mb-2"
								onClick={() => {
									tesContext.rpc('markBlock.dashboard.oal.no', { blockId: props.block.id, mark: props.block.markedAt ? false : true }, (err: Error) => {
										if (!err && props.onChanged) {
											props.onChanged()
										} else if (err) {
											console.log("Err: ", err);
										}
									})
								}}
								disabled={props.block.markedAt && props.block.markedBy !== context?.user?.sAMAccountName ? true : false}
							>
								{props.block.markedAt ? 'Fjern utdatert-markering' : 'Merk utdatert'}
							</Button>
							<br />
							{props.block.markedAt ? (
								<small style={{ opacity: 0.5 }}>
									Markert som utdatert av <OALUser id={props.block.markedBy ?? ''} />,{' '}
									{props.block.updatedAt && new Date(props.block.markedAt).toLocaleString()}
								</small>
							) : (
								<small>Klikk på denne knappen for å melde til eier at innholdet er utdatert.</small>
							)}
						</p>
					</div>
				}
			>
				<Icon icon="info-sign" intent={iconIntent} title={comment} />
			</Popover2>
		</div>
	)
}
