import React from 'react'
import { Tag, TagProps } from '@blueprintjs/core'

export function RenderTag(props: {
	id: string
	fill?: boolean
	tagProps?: TagProps
	wrapper?: (children: JSX.Element) => JSX.Element
}) {
	// ignore some tags
	if (props.id.match(/source:/)) return <></>

	if (props.wrapper !== undefined) return props.wrapper(<Tag {...props.tagProps}>{props.id}</Tag>)
	return <Tag {...props.tagProps}>{props.id}</Tag>
}
