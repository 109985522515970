import React from 'react'
import Nav from '../../../Components/Navigation/Nav'
import { isActivePage } from '../../../Utils/React'
import { LoyalityCustomerWithUsers, LoyalityLevel, getLoyalityLevels } from '../../../Services/OalApi'
import { SubMenuItem } from '../../../Domain/Navigation/Nav'
import { LoyalityContext } from '../../../Components/Loyality/Context/LoyalityContext'

export default function LoyalityContainer({
	children,
	customer,
	customer_id,
}: {
	children: React.ReactNode
	customer_id?: string
	customer?: LoyalityCustomerWithUsers | null
}) {
	const [globalLevels, setGlobalLevels] = React.useState<LoyalityLevel[]>([])

	React.useEffect(() => {
		getLoyalityLevels()
			.then((levels) => {
				setGlobalLevels(levels)
			})
			.catch((err) => {
				console.error(err)
			})

	}, [])

	return (
		<LoyalityContext.Provider value={{ globalLevels }}>
			<Nav
				subMenuItems={[
					{
						id: 'loyality-customers',
						title: 'Oversikt',
						icon: 'user',
						link: '/economy/loyality',
						active: isActivePage(['/economy/loyality']),
					},
					...(customer === null
						? [
								{
									id: 'loyality-customer',
									title: 'Loading...',
									icon: 'trending-up',
									link: `/economy/loyality/${customer_id}`,
									active: isActivePage([`/economy/loyality/${customer_id}`]),
								} as SubMenuItem,
						  ]
						: customer
						? [
								{
									id: 'loyality-customer',
									title: customer.name,
									icon: 'trending-up',
									link: `/economy/loyality/${customer.id}`,
									active: isActivePage([`/economy/loyality/${customer.id}`]),
								} as SubMenuItem,
						  ]
						: []),
				]}
			>
				{children}
			</Nav>
		</LoyalityContext.Provider>
	)
}
