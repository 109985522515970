import { Button, ControlGroup, Icon, InputGroup } from '@blueprintjs/core'
import {
	DynamicContentModuleProps,
	DynamicContentModuleEditProps,
	UploadButtonProps,
	UploadBlobParameters,
} from '../interface'
import { useRef, useState } from 'react'
import { getPresignedUploadUrl } from '../../../Services/OalApi'
import { Col, Row } from 'reactstrap'
import { uuid4 } from '@sentry/utils'

const basename = (path: string) => decodeURIComponent(path.split('/').pop() as string)

export const View = (props: DynamicContentModuleProps) => {
	return (
		<div style={{ width: '100%', display: 'block' }}>
			{(props.block?.payload?.files || []).map((file: { url: string; name: string }) => (
				<p key={file.url}>
					<Icon icon="document" color="gray" style={{ marginRight: '4px' }} />
					<a href={file.url} target="_top">
						{file.name}
					</a>
				</p>
			))}
		</div>
	)
}

export const uploadBlob = async (props: UploadBlobParameters) => {
	const { blob, ext, uid } = props
	const filename = uuid4() + '.' + ext

	if (!blob) {
		return
	}
	const info = await getPresignedUploadUrl(filename, uid)
	void (await fetch(info.url, {
		method: 'PUT',
		body: blob,
	}))

	return info.finalURL
}

export const UploadButton = (props: UploadButtonProps) => {
	const { onUploaded, onFilename, inputProps, text, uid, ...rest } = props
	const fileRef = useRef<HTMLInputElement>(null)
	const [uploading, setUploading] = useState(false)

	const upload = async () => {
		if (!fileRef.current?.files?.[0]) return
		const filename = fileRef.current!.files![0].name.split('/').pop() || 'unknown.dat'
		if (typeof onFilename === 'function') {
			onFilename(filename)
		}

		setUploading(true)
		const info = await getPresignedUploadUrl(filename, uid)
		const file = fileRef.current?.files?.[0]
		if (file) {
			fetch(info.url, {
				method: 'PUT',
				body: file,
			})
				.then((res) => {
					setUploading(false)
					onUploaded(info.finalURL)
				})
				.catch(() => {
					setUploading(false)
					alert('Noe gikk galt under opplastingen av filen')
				})
		}
	}

	return (
		<>
			<input type="file" ref={fileRef} onChange={() => upload()} hidden {...props.inputProps} />
			<Button {...rest} loading={uploading} disabled={uploading} onClick={() => fileRef?.current?.click()}>
				{text}
			</Button>
		</>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	const removeFile = (url: string) => {
		const newFiles = props.block.payload.files.filter((file: { url: string }) => file.url !== url)

		props.onChange({
			...props.block,
			payload: {
				...props.block.payload,
				files: newFiles,
			},
		})
	}

	const renameFile = (url: string, name: string) => {
		const newFiles = props.block.payload.files.map((file: { url: string; name: string }) => {
			if (file.url === url) {
				return {
					url,
					name,
				}
			}
			return file
		})

		props.onChange({
			...props.block,
			payload: {
				...props.block.payload,
				files: newFiles,
			},
		})
	}

	return (
		<div className="pt-3 pb-3">
			<div style={{ width: '100%', display: 'block' }}>
				{(props.block?.payload?.files || []).map((file: { url: string; name: string }) => (
					<Row key={file.url}>
						<Col md={6}>
							<ControlGroup vertical={false} className="mb-2" fill>
								<InputGroup
									value={file.name}
									fill
									onChange={(e) => renameFile(file.url, e.target.value)}
								/>
								<Button
									icon="cross"
									intent="danger"
									title="Slett filen"
									onClick={() => removeFile(file.url)}
								/>
							</ControlGroup>
						</Col>
					</Row>
				))}
			</div>
			<ControlGroup>
				<UploadButton
					uid={props.block.id}
					intent="primary"
					className="mb-3"
					text="Last opp ny fil"
					icon="document-share"
					onUploaded={(url) => {
						props.onChange({
							...props.block,
							payload: {
								...props.block.payload,
								files: [...(props.block.payload.files || []), { url, name: basename(url) }],
							},
						})
					}}
				/>
			</ControlGroup>
		</div>
	)
}
