import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import { FocusStyleManager } from '@blueprintjs/core'
import './index.css'

FocusStyleManager.onlyShowFocusOnTabs()


if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		/*		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,*/
	})
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
