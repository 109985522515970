import { Button, ControlGroup, InputGroup } from '@blueprintjs/core'
import { useState } from 'react'
import { Col, Row } from 'reactstrap'
import { TicketShowMode } from "../../Components/Ticket/types"
import { TicketsLargeList } from '../../Components/Ticket/LargeList'
import { TicketMineProps } from '../../Domain/Ticket'
import Container from './Container'

export function TicketMine(props: TicketMineProps) {
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [showMode, setShowMode] = useState<TicketShowMode>('owner')

	return (
		<Container>
			<div>
				<Row>
					<Col md={4} style={{ marginBottom: 10 }}>
						<ControlGroup fill>
							<Button
								large
								intent={showMode === 'owner' ? 'primary' : undefined}
								onClick={(e) => setShowMode('owner')}
							>
								Opprettet
							</Button>
							<Button
								large
								intent={showMode === 'assigned' ? 'primary' : undefined}
								onClick={(e) => setShowMode('assigned')}
							>
								Delegert
							</Button>
							{/*<Button large>Nevnt</Button>*/}
						</ControlGroup>
					</Col>
					<Col md={8} style={{ marginBottom: 10 }}>
						<InputGroup
							fill
							large
							type="text"
							placeholder="Søk"
							leftIcon="search"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</Col>
				</Row>
			</div>
			<TicketsLargeList
				searchQuery={searchQuery}
				showMode={showMode}
				wrapperStyle={{
					padding: '0px 6px',
					border: '1px solid #eee',
				}}
			/>
		</Container>
	)
}

export default TicketMine
