import { useContext, useEffect, useState } from 'react'
import { TESContext } from '../../../Utils/TESContext'
import { Switch } from '../Switch/interface'
import { Interface } from '../SwitchInterface'

interface InterfaceChartProps {
	interface: Interface
	switch: Switch
}

export const InterfaceChart = (props: InterfaceChartProps) => {
	const [chartUrl, setChartUrl] = useState<null | string>(null)

	const TES = useContext(TESContext)

	useEffect(() => {
		TES.rpc(
			'getInterfaceGraph.zabbix.network.oal.no',
			{ switchName: props.switch.label, interfaceName: props.interface.id },
			(err, res) => {
				if (err) {
					console.error(err)
					return
				}
				setChartUrl(res.url)
			}
		)
	}, [TES, props.switch.label, props.interface.id])

	return (
		<div className="p-3">
			{chartUrl !== null ? (
				<img style={{ borderRadius: 5 }} src={chartUrl} alt="chart" />
			) : (
				'No graph available from zabbix'
			)}
		</div>
	)
}
