import { MenuItem } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import { useEffect, useState } from 'react'
import { type RentmanContact, searchRentmanContacts } from '../../Services/OalApi'

export function SuggestRentmanContact({
	onItemSelect,
	value,
	company,
	tryToFind,
	autoFocus = false,
}: {
	onItemSelect: (item: RentmanContact) => void
	value: { displayname: string; id: number } | null
	company: string
	tryToFind?: string
	autoFocus?: boolean
}) {
	const [contacts, setContacts] = useState<RentmanContact[]>(value ? [value as RentmanContact] : [])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		setLoading(true)
		setError(null)
		searchRentmanContacts(company, '')
			.then((results) => {
				setContacts(results)
				setLoading(false)
			})
			.catch((err) => {
				console.error(err)
				setError('Kunne ikke hente data fra Rentman')
				setContacts([])
				setLoading(false)
			})
	}, [company])

	useEffect(() => {
		if (tryToFind && contacts.length > 0) {
			const found = contacts.find((contact) => contact.VAT_code === tryToFind)
			if (found) {
				onItemSelect(found)
			}
		}
	}, [tryToFind, contacts, onItemSelect])

	const itemSubRenderer = (items: RentmanContact[] | null, item: RentmanContact): string => {
		if (!item || !item?.id) {
			return ''
		}
		return item.displayname
	}

	const style: React.CSSProperties = {}
	if (error) {
		style['border'] = '1px solid red'
	}

	return (
		<>
			<Suggest
				fill
				openOnKeyDown
				resetOnSelect
				inputProps={{ autoFocus: autoFocus, placeholder: loading ? 'Laster...' : 'Søk...', style }}
				disabled={loading}
				popoverProps={{ minimal: true }}
				itemListPredicate={(query, items) => {
					if (query === '') return []
					return items
						.filter((item) => item.displayname.toLowerCase().includes(query.toLowerCase()))
						.slice(0, 5)
				}}
				itemsEqual={(a, b) => a.id === b.id}
				inputValueRenderer={(item) => itemSubRenderer(contacts, item)}
				onItemSelect={(item) => {
					onItemSelect(item)
				}}
				selectedItem={value === null ? null : contacts.find((item) => item.id === value?.id)}
				noResults={<MenuItem disabled={true} text="Fant ingen enheter med det displaynameet" />}
				itemRenderer={(item, { handleClick, modifiers, query }) => {
					return (
						<MenuItem
							key={item.id}
							active={modifiers.active}
							onClick={(e) => {
								e.preventDefault()
								onItemSelect(item)
							}}
							text={itemSubRenderer(contacts, item)}
							itemID={String(item.id)}
						/>
					)
				}}
				items={contacts}
			/>
			<small>{error && <p style={{ color: 'red' }}>{error}</p>}</small>
		</>
	)
}
