import { Icon, Dialog } from '@blueprintjs/core'
import React, { useEffect, useMemo } from 'react'
import { DialogStyle } from '.'
import { useHistory } from 'react-router-dom'
import { OALUser } from '../OAL/User'
import { stateColor, stateIcon, stateStyle } from './api'
import { Ticket, TicketShowMode, TicketState } from './types'
import { TicketCard } from './Card'
import { useTicketListMine } from './hooks/useTicketListMine'
import Moment from 'react-moment'
import 'moment/locale/nb'
import { useMe } from './hooks/useMe'

import { LoadingLarge } from './LoadingLarge'
import { RenderTag } from './RenderTag'

export function TicketsLargeList(props: {
	searchQuery?: string
	showMode: TicketShowMode
	states?: TicketState[]
	wrapperStyle?: React.CSSProperties
	listStyle?: React.CSSProperties
}) {
	const history = useHistory()
	const { me } = useMe()
	const [showType, setShowType] = React.useState<TicketState>('open')
	const { tickets, loading, refreshTickets } = useTicketListMine(
		props.states === undefined ? ['open', 'closed'] : props.states
	)

	const [showTicket, setShowTicket] = React.useState<number | null>(null)

	useEffect(() => {
		if (showTicket === null) {
			refreshTickets()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showTicket])

	const filteredTickets = useMemo(() => {
		let results: Ticket[] = []
		if (props.showMode === 'owner') {
			results = tickets.filter((ticket) => ticket.authorId === me)
		} else if (props.showMode === 'assigned') {
			results = tickets.filter((ticket) => ticket.assignedId === me)
		}
		return results
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tickets, me, props.showMode, showType])

	const ticketsDeleted = filteredTickets.filter((t) => t.state === 'deleted')
	const ticketsClosed = filteredTickets.filter((t) => t.state === 'closed')
	const ticketsOpen = filteredTickets.filter((t) => t.state === 'open')

	return (
		<>
			{showTicket !== null && (
				<Dialog isOpen={true} onClose={() => setShowTicket(null)} style={DialogStyle}>
					<TicketCard
						id={showTicket}
						onSave={() => {
							setShowTicket(null)
						}}
					/>
				</Dialog>
			)}

			<div style={{ ...props.wrapperStyle }}>
				<div>
					{loading || !me ? (
						<LoadingLarge></LoadingLarge>
					) : (
						<>
							<div style={{ ...props.listStyle }}>
								<div
									style={{
										borderBottom: '1px solid #e0e0e0',
										padding: '14px 12px',
										marginTop: 2,
										marginLeft: -1,
										marginRight: -1,
									}}
								>
									{ticketsOpen.length > 0 && (
										<span onClick={() => setShowType('open')}>
											<Icon icon={'issue'} color="success" />{' '}
											<span
												style={{
													marginRight: 10,
													fontWeight: showType === 'open' ? 'bold' : undefined,
													cursor: 'pointer',
												}}
											>
												{ticketsOpen.length} {ticketsOpen.length === 1 ? 'åpen' : 'åpne'}
											</span>
										</span>
									)}

									{ticketsClosed.length > 0 && (
										<span onClick={() => setShowType('closed')}>
											<Icon icon={'tick'} color="danger" />{' '}
											<span
												style={{
													marginRight: 10,
													fontWeight: showType === 'closed' ? 'bold' : undefined,
													cursor: 'pointer',
												}}
											>
												{ticketsClosed.length} {ticketsOpen.length === 1 ? 'lukket' : 'lukkede'}
											</span>
										</span>
									)}

									{ticketsDeleted.length > 0 && (
										<span onClick={() => setShowType('deleted')}>
											<Icon icon={'trash'} color="danger" />{' '}
											<span
												style={{
													marginRight: 10,
													fontWeight: showType === 'deleted' ? 'bold' : undefined,
													cursor: 'pointer',
												}}
											>
												{ticketsDeleted.length}{' '}
												{ticketsDeleted.length === 1 ? 'slettet' : 'slettede'}
											</span>
										</span>
									)}
								</div>
							</div>

							{filteredTickets
								.filter((ticket) => ticket.state === showType)
								.map((ticket) => (
									<div key={ticket.id} style={{ ...props.listStyle }}>
										<div
											style={{
												borderBottom: '1px solid #eee',
												padding: '10px 12px',
												marginTop: 2,
												borderRadius: 3,
												marginLeft: -1,
												marginRight: -1,
											}}
										>
											<Icon icon={stateIcon[ticket.state]} color={stateColor[ticket.state]} />{' '}
											<span
												style={{
													cursor: 'pointer',
													...stateStyle[ticket.state],
												}}
												onClick={(e) => {
													history.push('/sak/' + ticket.id)
												}}
											>
												<strong>{ticket.title || 'No title'}</strong>
											</span>
											<span>
												{ticket.tags.map((tag) => {
													return (
														<RenderTag
															key={tag}
															id={tag}
															wrapper={(content) => (
																<span style={{ margin: 3, display: 'inline-block' }}>
																	{content}
																</span>
															)}
															tagProps={{ minimal: true }}
														/>
													)
												})}
											</span>
											<div
												style={{
													marginTop: 8,
													display: 'block',
													color: '#000',
													opacity: 0.8,
													fontSize: 12,
												}}
											>
												#{ticket.id} - opened <Moment fromNow>{ticket.createdAt}</Moment> by{' '}
												<OALUser id={ticket.authorId} />
											</div>
										</div>
									</div>
								))}
						</>
					)}
				</div>
			</div>
		</>
	)
}
