import {
	Button,
	ControlGroup,
	Spinner,
	FormGroup,
	HTMLSelect,
	InputGroup,
	SpinnerSize,
	Menu,
	MenuItem,
} from '@blueprintjs/core'
import { Classes as P2Classes, Popover2 } from '@blueprintjs/popover2'
import React, { useEffect } from 'react'
import { OALMediaCompanies } from '../../../Domain/Common/Company'
import { AggregatedProject } from '../../../Domain/Projects/AggregatedProject'
import { TripletexTerseProject } from '../../../Domain/Projects/TripletexProject'
import { searchProjects } from '../../../Services/OalApi'
import './AddAggregatePopover.css'

export const AddAggregatedProjectButton = (props: {
	isOpen?: boolean
	className?: string
	project: AggregatedProject
	onChange: (company: string, project: TripletexTerseProject) => void
}) => {
	const [company, setCompany] = React.useState<string>('')
	const [query, setQuery] = React.useState<string>('')
	const [isSearching, setIsSearching] = React.useState<boolean>(false)
	const searchRef = React.useRef<HTMLInputElement>(null)
	const [projects, setProjects] = React.useState<TripletexTerseProject[]>([])

	// Fokuser på søkefeltet når man har valgt firma
	useEffect(() => {
		if (company !== '') {
			setTimeout(() => {
				searchRef.current?.focus()
			}, 10)
		}
	}, [company, projects])

	const clearForm = () => {
		setCompany('')
		setQuery('')
		setProjects([])
	}

	const searchProject = async () => {
		setIsSearching(true)
		try {
			const result = await searchProjects(company, query, 30)

			if (result?.result?.count > 0) {
				setProjects(
					result.result.values
						.map((p) => ({
							id: p.id,
							name: p.name,
							customer: p.customer?.name,
							startDate: new Date(p.startDate),
						}))
						.sort((a, b) => (new Date(a.startDate) > new Date(b.startDate) ? -1 : 1))
				)
			} else {
				setProjects([])
			}
		} catch (e) {
			setProjects([])
			console.error(e)
		} finally {
			setIsSearching(false)
		}
	}

	return (
		<Popover2
			usePortal={false}
			position="auto"
			isOpen={props.isOpen}
			popoverClassName={P2Classes.POPOVER2_CONTENT_SIZING + ' add-aggregate-project-popover'}
			onClose={() => clearForm()}
			content={
				<div style={{ width: '100%' }}>
					<FormGroup label="Velg firma" labelFor="company">
						<HTMLSelect id="company" fill value={company} onChange={(e) => setCompany(e.target.value)}>
							<option value="" disabled>
								Velg firma
							</option>
							{OALMediaCompanies.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</HTMLSelect>
					</FormGroup>
					<ControlGroup fill>
						<FormGroup label="Søk etter prosjekt" labelFor="agg-project-name">
							<InputGroup
								inputRef={searchRef}
								disabled={company === '' || isSearching}
								id="agg-project-name"
								placeholder="Prosjektnavn"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								fill
								autoFocus
								onKeyDown={(e) => e.key === 'Enter' && searchProject()}
							/>
						</FormGroup>
						<FormGroup label="&nbsp;">
							<Button
								icon="search"
								disabled={company === '' || isSearching}
								onClick={() => searchProject()}
							/>
						</FormGroup>
					</ControlGroup>
					{isSearching && <Spinner size={SpinnerSize.SMALL} intent="primary" />}
					<Menu>
						{!isSearching &&
							projects.map((project) => (
								<MenuItem
									icon="document"
									key={project.id}
									onClick={() => props.onChange(company, project)}
									text={
										<div>
											{project.name}
											<br />
											<small className="bp3-text-muted">{project.customer}</small>
										</div>
									}
								/>
							))}
					</Menu>
				</div>
			}
		>
			<Button className={props.className} text="Legg til prosjekt fra firma" icon="add" />
		</Popover2>
	)
}
