import { Icon } from '@blueprintjs/core'
import React, { LiHTMLAttributes } from 'react'
import { Link } from 'react-router-dom'
import { NavMainMenuItemProps, NavMainMenuItemState } from '../../Domain/Navigation/Nav'

export class MenuItem extends React.Component<LiHTMLAttributes<HTMLLIElement> & NavMainMenuItemProps, NavMainMenuItemState> {
	render() {
		const { link, icon, active, children, ...htmlrest } = this.props

		return (
			<li {...htmlrest}>
				<Link to={link} className={active ? 'active muted-link' : 'muted-link'}>
					<Icon icon={icon} />
					&nbsp;&nbsp;{children}
				</Link>
			</li>
		)
	}
}
