import React, { Component } from 'react'
import Nav from '../../../Components/Navigation/Nav'
import { isActivePage } from '../../../Utils/React'

class ProjectsContainer extends Component {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'economy-projects',
						title: 'Oversikt',
						icon: 'projects',
						link: '/economy/projects',
						active: isActivePage(['/economy/projects']),
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default ProjectsContainer
