import { useCallback, useEffect, useState } from 'react'
import placeholder from './placeholder.png'
import './carousel.css'
import { Icon, Classes, Dialog, Button } from '@blueprintjs/core'
import UploadCropped from '../Crop/UploadCropped'

type ImageCarouselProps = {
	images: string[]
	editable?: boolean
	uid?: string
	style?: React.CSSProperties
	onChange?: (images: string[]) => void
}

export const ImageCarousel = (props: ImageCarouselProps) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [length, setLength] = useState(props.images.length)
	const [uploadIsOpen, setUploadIsOpen] = useState(false)
	const [areYouSureIsOpen, setAreYouSureIsOpen] = useState<string | null>(null)
	const [uploadTrigger, setUploadTrigger] = useState<() => Promise<string | undefined>>()
	const [cropActive, setCropActive] = useState<boolean>(false)

	const handleUploadTrigger = useCallback((callback) => {
		setUploadTrigger(() => callback)
	}, [])

	// Set to number of images
	useEffect(() => {
		setLength(props.images.length)
	}, [props.images])

	const next = () => {
		if (currentIndex < length - 1) {
			setCurrentIndex((prevState) => prevState + 1)
		}
	}

	const prev = () => {
		if (currentIndex > 0) {
			setCurrentIndex((prevState) => prevState - 1)
		}
	}

	const openAddImage = () => {
		setUploadIsOpen(true)
	}

	const addImage = async () => {
		const file = await uploadTrigger?.();
		console.log("Uploaded ", file);
		if (props.onChange && file) {
			props.onChange([...props.images, file])
		}
		if (file) {
			setUploadIsOpen(false)
		}
	}

	const actuallyRemoveImage = () => {
		if (props.onChange) {
			props.onChange(props.images.filter((image, index) => index !== currentIndex))
		}
		if (currentIndex > 0) {
			setCurrentIndex((prevState) => prevState - 1)
		} else {
			setCurrentIndex(0)
		}
		setAreYouSureIsOpen(null)
	}

	const removeImage = () => {
		setAreYouSureIsOpen(props.images[currentIndex])
	}

	return (
		<>
			<div className="carousel-container" style={props.style || {}}>
				<div className="carousel-wrapper">
					{props.images.length === 0 ? (
						<div className="carousel-content-wrapper">
							<div className="carousel-content" style={{ transform: 'translateX(-0)' }}>
								<div
									style={{
										background: `url(${placeholder}) top center no-repeat fixed padding-box content-box transparent`,
										backgroundSize: 'contain',
										flex: '0 0 auto',
										width: '100%',
										height: '300px',
									}}
								/>
							</div>
						</div>
					) : (
						<>
							{currentIndex > 0 ? (
								<button className="left-arrow" onClick={prev}>
									<Icon icon="chevron-left" />
								</button>
							) : null}
							<div className="carousel-content-wrapper">
								<div
									className="carousel-content"
									style={{ transform: `translateX(-${currentIndex * 100}%)` }}
								>
									{props.images.map((image, index) => (
										<div
											key={index}
											style={{
												background: `url(${typeof image === 'object' ? (image as any).thumbnail : image }) top center no-repeat fixed padding-box content-box transparent`,
												backgroundSize: 'contain',
												flex: '0 0 auto',
												width: '100%',
												height: '300px',
											}}
										/>
									))}
								</div>
							</div>
							{currentIndex < length - 1 ? (
								<button className="right-arrow" onClick={next}>
									<Icon icon="chevron-right" />
								</button>
							) : null}
						</>
					)}
					{props.editable ? (
						<>
							{props.images.length > 0 ? (
								<button className="remove-button" onClick={() => removeImage()}>
									<Icon icon="trash" />
								</button>
							) : null}
							<button className="add-button" onClick={() => openAddImage()}>
								<Icon icon="plus" />
							</button>
						</>
					) : null}
				</div>
			</div>
			<Dialog
				icon="info-sign"
				title="Last opp bilde"
				isOpen={uploadIsOpen}
				onClose={() => setUploadIsOpen(false)}
			>
				<div className={Classes.DIALOG_BODY}>
					<p>Last opp et bilde og velg et passende utsnitt av bildet:</p>
					<UploadCropped
							id={props.uid || 'dashboard'}
							minWidth={512}
							minHeight={512}
							aspect={1}
							maxOutputWidth={1024}
							maxOutputHeight={1024}
							getUploadTrigger={handleUploadTrigger}
							onCropActive={setCropActive}
						/>
						<br />
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => addImage()} disabled={!cropActive} intent="success">
							Lagre
						</Button>
						<Button onClick={() => setUploadIsOpen(false)} intent="warning">
							Avbryt
						</Button>
					</div>
				</div>
			</Dialog>
			<Dialog
				icon="warning-sign"
				title="Slette bilde"
				isOpen={areYouSureIsOpen !== null}
				onClose={() => setAreYouSureIsOpen(null)}
			>
				<div className={Classes.DIALOG_BODY}>
					<p>Er du sikker på at du vil slette bildet?</p>
					<div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'nowrap' }}>
						{areYouSureIsOpen && typeof areYouSureIsOpen === 'object' ? (
							<img
								src={(areYouSureIsOpen as any).thumbnail}
								style={{
									width: '40%',
									height: 'auto',
									alignSelf: 'center',
									border: '2px solid #ddd',
									margin: '10px',
								}}
								alt=""
							/>
						) : (
							<img
								src={areYouSureIsOpen || placeholder}
								style={{
									width: '40%',
									height: 'auto',
									alignSelf: 'center',
									border: '2px solid #ddd',
									margin: '10px',
								}}
								alt=""
							/>
						)}
					</div>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button
							onClick={() => actuallyRemoveImage()}
							disabled={areYouSureIsOpen === null}
							intent="danger"
						>
							Slett
						</Button>
						<Button onClick={() => setAreYouSureIsOpen(null)} intent="warning">
							Avbryt
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	)
}
