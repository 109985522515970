import React from "react"
import { AccountingAccount, setConfig } from "../../../Services/OalApi"
import { useApiData } from "../../../Utils/UseAPI"
import { Col } from "reactstrap"
import { Button, Callout, Card, ControlGroup, H4, H5, HTMLSelect, InputGroup, Label } from "@blueprintjs/core"

type AccountInfo = {
	label: string
	key: string
}

// Hack to make typescript happy
function useApiDataAccounting() {
	return useApiData<AccountingAccount[]>(() => {
		return new Promise((resolve) => resolve([]))
	})
}

type AccountConfigProps = {
	account: AccountInfo
	accountingAccountsApi: ReturnType<typeof useApiDataAccounting>
	list: { from: number; to?: number }[]
	onUpdate: () => void
}

export const AddAccountConfig = ({ account, list, accountingAccountsApi, onUpdate }: AccountConfigProps) => {
	const [saving, setSaving] = React.useState(false)
	const [error, setError] = React.useState<string | null>(null)
	const [from, setFrom] = React.useState('')
	const [to, setTo] = React.useState('')

	const { data: accountlist } = accountingAccountsApi

	React.useEffect(() => {
		if (!from.match(/^\d*$/)) {
			setFrom(from.replace(/[^\d]/g, '')) // Remove non-digits
		}
	}, [from])

	React.useEffect(() => {
		if (!to.match(/^\d*$/)) {
			setTo(to.replace(/[^\d]/g, '')) // Remove non-digits
		}
	}, [to])

	const save = async (data: typeof list) => {
		console.log('Saving', account.key, data)
		setSaving(true)
		setError(null)
		try {
			await setConfig(account.key, data)
			onUpdate()
		} catch (e: any) {
			setError(e.message)
		} finally {
			setSaving(false)
		}
	}

	const addItem = () => {
		if (to) {
			if (parseInt(from) >= parseInt(to)) {
				setError('Fra-konto må være lavere enn til-konto')
				return
			}
		}

		save([...list, { from: parseInt(from), to: to ? parseInt(to) : undefined }])
	}

	const remove = (i: number) => {
		console.log('remove index', i, list)
		save(list.filter((_, idx) => idx !== i))
	}
	const options = (accountlist || []).map((account) => (
		<option value={account.number} key={account.number}>
			{account.number} - {account.name}
		</option>
	))

	return (
		<Col lg={4} md={6}>
			<Card elevation={1} className={'mb-4'}>
				<H4>{account.label}</H4>
				<ul style={{ opacity: saving ? 0.5 : 1 }}>
					{list.map((account, i) => (
						<li key={i}>
							<Button
								icon="cross"
								onClick={() => remove(i)}
								style={{ marginRight: '.5em' }}
								small
								intent="danger"
							/>
							{account.from}
							{account.to ? ' - ' + account.to : null}
						</li>
					))}
				</ul>
				<H5>Legg til kontoer:</H5>
				<Label className="mb-4">
					Fra
					<ControlGroup fill vertical={false}>
						<HTMLSelect value={from} onChange={(e) => setFrom(e.currentTarget.value)}>
							<option value="">- Konto -</option>
							{options}
						</HTMLSelect>
						<InputGroup
							fill
							placeholder="Eller skriv kontonummer..."
							value={from}
							onChange={(e) => setFrom(e.currentTarget.value)}
						/>
					</ControlGroup>
				</Label>

				<Label>
					Til <span className="bp3-text-muted">(valgfri rekkevidde)</span>
					<ControlGroup fill vertical={false}>
						<HTMLSelect value={to} onChange={(e) => setTo(e.currentTarget.value)}>
							<option value="">- Konto -</option>
							{options}
						</HTMLSelect>

						<InputGroup
							fill
							placeholder="Eller skriv kontonummer..."
							value={to}
							onChange={(e) => setTo(e.currentTarget.value)}
						/>
					</ControlGroup>
				</Label>
				<Button
					intent="success"
					icon="plus"
					className="mt-2"
					type="button"
					disabled={!from || saving}
					loading={saving}
					onClick={() => addItem()}
				>
					Legg til
				</Button>
				{error ? (
					<Callout intent={'danger'} className="mt-3">
						{error}
					</Callout>
				) : null}
			</Card>
		</Col>
	)
}