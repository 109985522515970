import { Icon, IconName } from "@blueprintjs/core"
import { Link } from "react-router-dom"
import './index.css'

export const TabItem = ({ link, icon, active, children, className, ...props }: {
	link: string
	icon: IconName
	active: boolean
	children: any
} & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>) => {
	return (
		<li {...props} className={(active ? 'active muted-link' : 'muted-link') + (className ? ' ' + className : '')}>
			<Link to={link} className={'muted-link'}>
				<Icon icon={icon} />
				&nbsp;&nbsp;{children}
			</Link>
		</li>
	)
}

export const TabMenu = ({ className, ...props }: React.HTMLProps<HTMLDivElement>) => {
	return (
		<div className={'TabMenu' + (className ? ' ' + className : '')} {...props}>
			<ul>{props.children}</ul>
		</div>
	)
}
