import { Classes, Popover2 } from '@blueprintjs/popover2'
import { Switch } from '../Switch/interface'
import { Button, ControlGroup, PopoverPosition } from '@blueprintjs/core'
import { useState } from 'react'
import { Col, Row } from 'reactstrap'

interface Props {
	onChange: (switchId: string | null) => void
	value: string | null
	switches: Switch[]
}

export const NetworkSwitchSelect = (props: Props): JSX.Element => {
	let selectedSwitchLabel = 'Alle switcher'
	if (props.value && props.switches)
		selectedSwitchLabel = props.switches.find((s) => s.id === props.value)?.label || 'Alle switcher'

	// sort props.switches by props.switches.label
	const sortedSwitches: Switch[] = props.switches.sort((a, b) => {
		if (a.label < b.label) return -1
		if (a.label > b.label) return 1
		return 0
	})

	return (
		<div>
			<Popover2
				position={PopoverPosition.BOTTOM}
				canEscapeKeyClose
				openOnTargetFocus
				content={
					<>
						<SelectSwitch switches={sortedSwitches} onClick={(id) => props.onChange(id)} />
					</>
				}
			>
				<Button
					fill
					text={selectedSwitchLabel}
					outlined={props.value ? true : undefined}
					intent={props.value ? 'primary' : undefined}
					rightIcon={props.value ? undefined : 'double-caret-vertical'}
				/>
			</Popover2>
			<div style={{ display: 'inline' }}>
				{props.value && (
					<Button
						icon="cross"
						intent="danger"
						minimal
						onClick={(e) => {
							e.preventDefault()
							props.onChange(null)
						}}
					/>
				)}
			</div>
		</div>
	)
}

const SelectSwitch = (props: { switches: Switch[]; onClick: (id: string) => void }): JSX.Element => {
	const [selectedLocation, setSelectedLocation] = useState<string | null>(null)
	const [selectedSubLocation, setSelectedSubLocation] = useState<string | null>(null)

	const locations = props.switches.reduce((acc: string[], curr: Switch) => {
		const currentLocation = curr.label.split('-')[0]
		if (!acc.includes(currentLocation)) acc.push(currentLocation)
		return acc
	}, [])

	// LOC-FLOOR-ROOM
	// The first letter after location F, we want listed separately
	type SubDef = { label: string; switches: Switch[] }

	const currentSubLocations = props.switches.reduce((acc: SubDef[], curr: Switch) => {
		const currentLocation = curr.label.split('-')[0]
		const currentSubLocation = curr.label.split('-')[1]
		const currentSubLocationFirstLetter = currentSubLocation[0]
		if (currentLocation === selectedLocation && currentSubLocationFirstLetter !== 'F') {
			const subDef = acc.find((sub) => sub.label === currentSubLocationFirstLetter)
			if (!subDef) {
				acc.push({
					label: currentSubLocationFirstLetter,
					switches: [curr],
				})
			} else {
				subDef.switches.push(curr)
			}
		}
		return acc
	}, [])

	return (
		<>
			{selectedLocation ? (
				<>
					{selectedSubLocation ? (
						<>
							<div style={{ padding: 20, minWidth: 300 }}>
								<ControlGroup vertical>
									<Button
										onClick={() => setSelectedLocation(null)}
										fill
										rightIcon="chevron-up"
										large
										intent="primary"
									>
										{selectedLocation} - {selectedSubLocation}
									</Button>
									{currentSubLocations
										.find((sl) => sl.label === selectedSubLocation)
										?.switches.map((sw) => (
											<Button
												key={sw.id}
												fill
												alignText="left"
												style={{ padding: '15px 10px' }}
												onClick={() => {
													props.onClick(sw.id)
												}}
												className={Classes.POPOVER2_DISMISS}
											>
												<strong>{sw.label}</strong>
												<div style={{ float: 'right' }}>{sw.ip}</div>
											</Button>
										))}
								</ControlGroup>
							</div>
						</>
					) : (
						<div style={{ padding: 20, minWidth: 300 }}>
							<Button
								onClick={() => {
									setSelectedLocation(null)
									setSelectedSubLocation(null)
								}}
								fill
								rightIcon="chevron-up"
								large
								intent="primary"
							>
								{selectedLocation}
							</Button>
							<Row style={{ padding: 20, paddingBottom: 0 }}>
								{currentSubLocations.map((subloc) => (
									<Col xs={6} key={subloc.label}>
										<Button
											fill
											style={{
												display: 'block',
												fontSize: 25,
												textAlign: 'center',
												border: '1px solid #f0f0f0',
												marginBottom: 20,
											}}
											onClick={() => setSelectedSubLocation(subloc.label)}
										>
											{subloc.label}
										</Button>
									</Col>
								))}
							</Row>
						</div>
					)}
				</>
			) : (
				<>
					<div style={{ textAlign: 'center', paddingTop: 15, fontSize: 17, fontWeight: 'bold' }}>
						Locations
					</div>
					<Row style={{ padding: 20, paddingBottom: 0 }}>
						{locations.map((location) => (
							<Col xs={6} key={location}>
								<Button
									fill
									style={{
										display: 'block',
										fontSize: 17,
										paddingTop: 15,
										paddingBottom: 15,
										textAlign: 'center',
										border: '1px solid #f0f0f0',
										marginBottom: 10,
									}}
									onClick={() => {
										setSelectedLocation(location)
										setSelectedSubLocation(null)
									}}
								>
									{location}
								</Button>
							</Col>
						))}
					</Row>
				</>
			)}
		</>
	)
}
