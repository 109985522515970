import { NonIdealState, Tag } from '@blueprintjs/core'
import Nav from '../../Components/Navigation/Nav'
import { DashboardComponent, PageContext } from '../../Utils/LoginContext'

interface Props {
	attemptedGroups?: Array<string>
}

interface State {}

export class AccessDenied extends DashboardComponent<Props, State> {
	static contextType = PageContext

	render() {
		let groups = this.context.user?.groups

		return (
			<Nav>
				<NonIdealState
					icon="lock"
					title="Ingen tilgang"
					description="Ta gjerne kontakt med alpha@trippelm.no og forklar hvordan du endte opp her. Er det noe du føler du skulle hatt tilgang til må du ta kontakt med din nærmeste overordnede eller prosjektleder."
				/>
				<div style={{ textAlign: 'center' }} className="mt-5">
					<p>
						Denne siden krever
						{this.props.attemptedGroups !== undefined && this.props.attemptedGroups.length > 1
							? ' en av disse'
							: ' denne'}
						:
					</p>
					<p>
						{this.props.attemptedGroups !== undefined &&
							this.props.attemptedGroups.map((group) => (
								<Tag key={group} className="mb-2 mr-1 ml-1">
									{group}
								</Tag>
							))}
					</p>
					<p>Du har:</p>
					<p>
						{groups !== undefined &&
							groups.map((group: string) => (
								<Tag key={group} className="mb-2 mr-1 ml-1">
									{group}
								</Tag>
							))}
					</p>
				</div>
			</Nav>
		)
	}
}
