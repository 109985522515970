import { Button, ButtonGroup, Intent } from '@blueprintjs/core'
import { Fragment } from 'react'
import { Filter } from './api'

export const Filters = ({
	choices,
	onChange,
	value,
	andOr,
	onAndOr,
	search,
	possibilities,
}: {
	choices: Filter[]
	value: Filter[]
	possibilities: string[]
	andOr: 'and' | 'or'
	search: string
	onAndOr: (andOr: 'and' | 'or') => void
	onChange: (filters: Filter[]) => void
}) => {
	let mergedChoices: Filter[] = [...choices]

	const activeChoice = (choice: Filter) => {
		const index = value.findIndex((f) => f.label === choice.label)

		if (index === -1) {
			return false
		}

		return true
	}

	const filterCount = (choice: Filter) => {
		const matches = possibilities.filter((p) => p.match(choice.regex))

		if (matches !== undefined) {
			return matches.length
		}

		return 0
	}

	return (
		<div>
			<ButtonGroup fill className="mb-2">
				<Button intent={andOr === 'and' ? Intent.PRIMARY : Intent.NONE} onClick={() => onAndOr('and')} small>
					And
				</Button>
				<Button intent={andOr !== 'and' ? Intent.PRIMARY : Intent.NONE} onClick={() => onAndOr('or')} small>
					Or
				</Button>
			</ButtonGroup>

			{mergedChoices.map((choice) => (
				<Fragment key={choice.label}>
					{(() => {
						let ac = activeChoice(choice)
						let count = filterCount(choice)
						let intent: Intent | undefined = ac ? Intent.SUCCESS : undefined
						let disabled = false

						if (count === 0) {
							if (ac) {
								intent = Intent.DANGER
							} else {
								disabled = true
							}
						}

						return (
							<Button
								style={{
									marginBottom: 5,
									marginRight: 5,
								}}
								minimal={!ac}
								intent={intent}
								disabled={disabled}
								onClick={() => {
									if (ac) {
										onChange(value.filter((f) => f.label !== choice.label))
									} else {
										onChange([...value, choice])
									}
								}}
							>
								{choice.label}
							</Button>
						)
					})()}
				</Fragment>
			))}
		</div>
	)
}

export default Filters
