import { useCallback, useMemo, useState } from 'react'
import {
	LoyalityCustomerWithUsers,
	LoyalityUser,
	addLoyalityUser,
	addLoyalityUserToCustomer,
	findLoyalityUser,
} from '../../Services/OalApi'
import { Button, Callout, Classes, Dialog, FormGroup, H4, Icon, InputGroup } from '@blueprintjs/core'
import { debounce } from 'lodash'

const useFindLoyalityUser = () => {
	const [error, setError] = useState<string | null>(null)

	return {
		request: useMemo(() => {
			const debounced = debounce((username: string, cb: (result: LoyalityUser | undefined) => void) => {
				setError(null)
				console.log('LUSearch')
				findLoyalityUser({ username })
					.then((result) => {
						console.log('LUSearch result')
						cb(result)
					})
					.catch((err) => {
						console.error('LUSearch err:', err)
						setError('Kunne ikke hente data fra dashboard')
						cb(undefined)
					})
			}, 250)
			return debounced
		}, []),
		error,
	}
}

export const AddUser = ({
	showDialog,
	customer,
	onAddUser,
	onClose,
}: {
	showDialog: boolean
	onClose: () => void
	customer: LoyalityCustomerWithUsers | null
	onAddUser: () => void
}) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [saving, setSaving] = useState(false)
	const [username, setUsername] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [email, setEmail] = useState<string>('')

	const [existingUser, setExistingUser] = useState<LoyalityUser | null>(null)

	const { request: findLoyalityUserRequest, error: findLoyalityUserError } = useFindLoyalityUser()

	const updateUsername = useCallback(
		(username: string) => {
			setUsername(username)
			setExistingUser((last) => {
				if (last !== undefined) {
					setName('')
					setEmail('')
				}

				return null
			})

			if (username.length > 7) {
				findLoyalityUserRequest(username, (result) => {
					if (result) {
						setExistingUser(result)
						setName(result.name)
						setEmail(result.email)
					}
				})
			}
		},
		[findLoyalityUserRequest]
	)

	const emailValid = useMemo(() => {
		return email.length === 0 || email.match(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,5})+$/) !== null
	}, [email])

	const validForm = useMemo(() => {
		return username.length > 7 && name.length > 0 && email.length > 0 && emailValid
	}, [username, name, email, emailValid])

	const resetForm = useCallback(() => {
		setUsername('')
		setName('')
		setEmail('')
		setExistingUser(null)
	}, [])

	const addUser = useCallback((e) => {
		e?.preventDefault()

		if (validForm) {
			setErrorMessage(null)
			setSaving(true)
			;(async () => {
				try {
					if (existingUser !== null) {
						const result = await addLoyalityUserToCustomer(existingUser.id ?? 0, customer?.id ?? 0)
						if (result) {
							onAddUser()
							onClose()
							resetForm()
						} else {
							setErrorMessage('Kunne ikke legge til bruker, ukjent grunn')
						}
					} else {
						const result = await addLoyalityUser({ username, name, email, metadata: {} }, customer?.id ?? 0)
						if (result) {
							onAddUser()
							onClose()
							resetForm()
						}
					}
				} catch (e: any) {
					setErrorMessage('Kunne ikke legge til bruker: ' + (e?.message ?? 'Ukjent feil'))
				} finally {
					setSaving(false)
				}
			})()
		}
	}, [validForm, existingUser, username, name, email, customer, onAddUser, onClose, resetForm])

	return (
		<Dialog isOpen={showDialog} onClose={() => onClose()}>
			<form onSubmit={(e) => addUser(e)} action="">
				<div className={Classes.DIALOG_HEADER}>
					<Icon icon="add" /> <H4>Ny bruker til {customer?.name}</H4>
				</div>
				<div className={Classes.DIALOG_BODY + ' loyalityform'}>
					<FormGroup label="Mobilnummer" labelInfo="(Uten +47)">
						<InputGroup autoFocus value={username} onChange={(e: any) => updateUsername(e.target.value)} />
						{findLoyalityUserError ? (
							<small>
								<p style={{ color: 'red' }}>(OBS: {findLoyalityUserError})</p>
							</small>
						) : null}
					</FormGroup>

					<FormGroup label="Navn">
						<InputGroup
							value={name}
							disabled={existingUser !== null}
							onChange={(e: any) => setName(e.target.value)}
						/>
					</FormGroup>

					<FormGroup label="E-post">
						<InputGroup
							type={'email'}
							value={email}
							disabled={existingUser !== null}
							onChange={(e: any) => setEmail(e.target.value)}
							style={emailValid ? {} : { border: '1px solid red' }}
						/>
					</FormGroup>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					{errorMessage ? (
						<Callout intent="danger" className="mb-2">
							{errorMessage}
						</Callout>
					) : null}
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button disabled={!validForm || saving} onClick={addUser} type="submit" loading={saving} intent='success'>
							Legg til bruker
						</Button>
					</div>
				</div>
			</form>
		</Dialog>
	)
}

export default AddUser
