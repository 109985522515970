import React from 'react'
import { User } from '../Domain/Common/User'
import OalAuth from './OalAuth'
import storage from 'local-storage-fallback'
import { reInit as CrewJobsReInit } from '../Services/CrewJobs'
import { reInit as ContactsReInit } from '../Services/Contacts'
import * as Sentry from '@sentry/react'
import { updateOalJwt } from '../Services/OalApi'

export interface PageContextProviderProps {}

export interface PageContextProviderState {
	user: User | null
	authenticated: boolean
	sharecode?: string
	setState?: (state: PageContextProviderState) => void
	logout?: () => void
}

/* Because our babel doesn't like declarations */
export class DashboardComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
	context: PageContextProviderState = {
		user: null,
		authenticated: false,
	}
}

const PageContext = React.createContext({
	user: null,
	authenticated: false,
} as PageContextProviderState)

class PageContextProvider extends React.Component<PageContextProviderProps, PageContextProviderState> {
	// eslint-disable-next-line
	constructor(props: PageContextProviderProps, state: PageContextProviderState) {
		super(props, state)

		this.state = this.initialState()
	}

	initialState(): PageContextProviderState {
		return {
			user: null,
			authenticated: false,
		}
	}

	checkAPSharecode() {
		let url = new URL(window.location.href)
		let sharecode = url.searchParams.get('sc')

		if (sharecode && sharecode.length > 8) {
			this.setState({
				sharecode,
			})
			return true
		}
		return false
	}

	async checkLogin() {
		let jwtData = storage.getItem('oaljwt') || ''

		if (jwtData === '') {
			jwtData = (await OalAuth.fetchExistingJWT()) || ''
		}

		// Neither local jwt, or automatic jwt via auth.oal.no,
		// try to log in
		if (jwtData === '') {
			OalAuth.redirectLogin()
			return
		}

		// Check validity of JWT data
		const validJwt = await OalAuth.validJWT(jwtData)

		if (validJwt !== false && typeof validJwt.description === 'string') {
			try {
				validJwt.description = JSON.parse(validJwt.description)
			} catch (e) {
				validJwt.description = {}
			}
		}

		if (validJwt !== false) {
			storage.setItem('oaljwt', jwtData)
			updateOalJwt(jwtData)

			// Make sure api knows we have a new token
			CrewJobsReInit(jwtData)
			ContactsReInit(jwtData)

			// Update Sentry with information about the current user
			Sentry.configureScope((scope) => {
				scope.setUser({ id: validJwt.sAMAccountName, name: validJwt.cn })
				validJwt.groups?.forEach((access) => scope.setTag(access, 'yes'))
			})

			this._setState({
				authenticated: true,
				user: validJwt,
			})
		} else {
			// Invalid jwt, redirect to auth
			OalAuth.redirectLogin()
			return
		}
	}

	async componentDidMount() {
		if (this.checkAPSharecode()) {
			return
		}

		this.checkLogin()
	}

	async componentDidUpdate() {}

	_setState(state: PageContextProviderState) {
		this.setState({ ...state })
	}

	async logout() {
		storage.removeItem('oaljwt')
		await OalAuth.logout()
		updateOalJwt('')

		this.setState(this.initialState())

		window.location.href = '/'
	}

	render() {
		return (
			<PageContext.Provider
				value={{
					...this.state,
					setState: (...args) => this._setState(...args),
					logout: () => this.logout(),
				}}
			>
				{this.props.children}
			</PageContext.Provider>
		)
	}
}

export { PageContext, PageContextProvider }
