import { useEffect, useState } from 'react'
import { LoyalityAudit, findLoyalityAuditLog } from '../../Services/OalApi'
import { Callout, Card, Spinner } from '@blueprintjs/core'
import { OALUser } from '../../Components/OAL/User'
import Moment from 'react-moment'
import 'moment/locale/nb'

export const AuditLog = ({
	customer_id,
	user_id,
	contract_id,
}: {
	customer_id?: number
	user_id?: number
	contract_id?: number
}) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	const [auditLog, setAuditLog] = useState<LoyalityAudit[] | null>(null)

	useEffect(() => {
		setLoading(true)
		setError(null)
		;(async () => {
			try {
				const log = await findLoyalityAuditLog({ customer_id, user_id, contract_id })
				setAuditLog(log)
			} catch (err: any) {
				setError(err?.message ?? err ?? 'Uventet internfeil')
			} finally {
				setLoading(false)
			}
		})()
	}, [customer_id, user_id, contract_id])

	return (
		<Card elevation={2} style={{ padding: 0 }}>
			{loading ? <Spinner size={32} /> : null}
			{error ? <Callout intent="danger">{error}</Callout> : null}
			{auditLog ? (
				<div style={{ overflow: 'scroll', overflowX: 'hidden', maxHeight: '200px' }}>
					<table className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered bp3-interactive">
						<thead>
							<tr
								style={{
									position: 'sticky',
									top: 0,
									background: 'white',
									borderBottom: '1px solid gray !important',
								}}
							>
								<th>Beskrivelse</th>
								<th>Endret av</th>
								<th>Endret</th>
							</tr>
						</thead>
						<tbody>
							{auditLog.map((log) => (
								<tr key={log.id}>
									<td>{log.description}</td>
									<td>
										{log.changed_by !== 'system' && log.changed_by !== 'rentman' ? (
											<OALUser id={log.changed_by} />
										) : (
											'System'
										)}
									</td>
									<td>
										<Moment fromNow withTitle>
											{log.changed_at}
										</Moment>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : null}
		</Card>
	)
}
