import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { getTicket, putTicket } from '../api'
import { Ticket } from '../types'
import { useMe } from './useMe'

let backendTicket: Ticket | undefined

export function useTicket(id: number): {
	ticket?: Ticket
	setTicket: Dispatch<SetStateAction<Ticket | undefined>>
	loading: boolean
	setLoading: Dispatch<SetStateAction<boolean>>
	refreshTicket: () => void
} {
	const [ticket, setTicket] = React.useState<Ticket | undefined>()
	const [loading, setLoading] = React.useState(true)

	const { me } = useMe()

	useEffect(() => {
		const fetchTicket = async () => {
			const newTicket = await getTicket(id)
			setLoading(false)
			setTicket(newTicket)
		}

		fetchTicket()

		const timer = setInterval(fetchTicket, 5000)

		return () => clearTimeout(timer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])

	useEffect(() => {
		;(async () => {
			if (!backendTicket) {
				backendTicket = ticket
			} else {
				if (JSON.stringify(ticket) !== JSON.stringify(backendTicket)) {
					backendTicket = ticket
					if (ticket) {
						setLoading(true)
						backendTicket = await putTicket(ticket)
						setTicket(backendTicket)
						setLoading(false)
					}
				}
			}
		})()
	}, [ticket])

	const refreshTicket = async () => {
		const newTicket = await getTicket(id)
		setTicket(newTicket)
	}

	return {
		ticket,
		setTicket,
		setLoading,
		loading,
		refreshTicket,
	}
}
