import React from 'react'
// @ts-ignore
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Error from '../Containers/Error/Error'

import HomeIndex from '../Containers/Home/Index'
import HomeTodo from '../Containers/Home/Todo'
import DocIndex from '../Containers/Doc/Index'
import DocumentMyBlocks from '../Containers/Doc/MyBlocks'
import UserIndex from '../Containers/User/Index'
import McrIndex from '../Containers/Mcr/Index'
import McrEdit from '../Containers/Mcr/Edit'
import NetIndex from '../Containers/Net/Index'
import NetSwitch from '../Containers/Net/Switch'
import NetFirewall from '../Containers/Net/Firewall'
import NetSwitchInterface from '../Containers/Net/SwitchInterface'
import TDACIndex from '../Containers/TDAC/Index'
import ProjectIndex from '../Containers/Economy/Projects/Index'
import InvoiceIndex from '../Containers/Invoice/Index'
import Invoice from '../Containers/Invoice/Invoice'
import InvoiceLoginAs from '../Containers/Invoice/InvoiceLoginAs'
import { DashboardComponent, PageContext, PageContextProvider } from './LoginContext'
import { TESContext, TESContextProvider } from './TESContext'
import AccessGroups from './AccessGroups'
import { AccessDenied } from '../Containers/Error/AccessDenied'
import InvoiceAdmin from '../Containers/Invoice/Admin'
import Delayed from './Delayed'
import ToolsIndex from '../Containers/Tools/Index'
import NetDHCP from '../Containers/Net/DHCP'
import ServicesIndex from '../Containers/Services/Index'
import NdiIndex from '../Containers/Ndi/Index'
import ServicesProjects from '../Containers/Services/Projects'
import TicketMine from '../Containers/Ticket/Mine'
import TicketAll from '../Containers/Ticket/All'
import TicketShow from '../Containers/Ticket/Show'
import Gryn from '../Containers/Economy/Gryn/Index'
import ToolsTripletex from '../Containers/Tools/Tripletex'
import ProjectAnalyticsIndex from '../Containers/Economy/ProjectAnalytics/Index'
import Analytics from '../Containers/Economy/ProjectAnalytics/Analytics'
import ProjectAnalytics from '../Containers/Economy/ProjectAnalytics/ProjectAnalytics'
import ProjectAnalyticsHours from '../Containers/Economy/ProjectAnalytics/HourAnalytics'
import { ProductCategories } from '../Components/Product/Categories'
import ProjectAnalyticsConfig from '../Containers/Economy/ProjectAnalytics/Config'
import LoyalityCustomers from '../Containers/Economy/Loaylity/Index'
import LoyalityCustomer from '../Containers/Economy/Loaylity/Customer/Index'
import ProjectAnalyticsOverview from '../Containers/Economy/ProjectAnalytics/AnalyticsOverview'

class AppRouter extends DashboardComponent<any, any> {
	static contextType = PageContext

	render() {
		let routes: any = [
			{ key: 'HomeIndex', exact: true, path: '/', component: HomeIndex },
			{
				key: 'DocIndex',
				exact: true,
				path: '/doc/',
				component: DocIndex,
			},

			{
				key: 'TicketMine',
				exact: true,
				path: '/ticket/mine',
				component: TicketMine,
			},

			{
				key: 'TicketMine',
				exact: true,
				path: '/sak/:id',
				component: TicketShow,
			},
			{
				key: 'TicketAll',
				exact: true,
				path: '/ticket/all',
				component: TicketAll,
			},
			{
				key: 'DocSelskap',
				exact: false,
				path: '/doc/selskap',
				component: DocIndex,
				props: {
					structure: 'selskap',
					label: 'Selskap',
				},
			},
			{
				key: 'DocAnsatt',
				exact: false,
				path: '/doc/ansatt',
				component: DocIndex,
				props: {
					structure: 'ansatt',
					label: 'Ansatt',
				},
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'DocInfra',
				exact: false,
				path: '/doc/infra',
				component: DocIndex,
				props: {
					structure: 'infra',
					label: 'Infrastruktur & Tjenester',
				},
			},
			{
				key: 'DocLokasjon',
				exact: false,
				path: '/doc/lokasjon',
				component: DocIndex,
				props: {
					structure: 'lokasjon',
					label: 'Lokasjon',
				},
			},
			{
				key: 'DocFrilans',
				exact: false,
				path: '/doc/frilans',
				component: DocIndex,
				props: {
					structure: 'frilans',
					label: 'Frilans',
				},
			},
			{
				key: 'DocSamla',
				exact: false,
				path: '/doc/samla-alle',
				component: DocIndex,
				props: {
					structure: 'samla-alle',
					label: 'Samla',
				},
			},
			{
				key: 'DocSamlaCeo',
				exact: false,
				path: '/doc/samla-ceo',
				component: DocIndex,
				props: {
					structure: 'samla-ceo',
					label: 'Samla CEOs',
				},
			},
			{
				key: 'DocumentMyBlocks',
				exact: true,
				path: '/doc/my-blocks',
				component: DocumentMyBlocks,
			},
			{
				key: 'ProductCategories',
				exact: true,
				path: '/product/categories',
				component: ProductCategories,
				requireAccess: AccessGroups.ProductCategoryAdmin,
			},
			{
				key: 'HomeTodo',
				exact: true,
				path: '/home/todo',
				component: HomeTodo,
			},
			{
				key: 'UserIndex',
				exact: true,
				path: '/user',
				component: UserIndex,
			},
			{
				key: 'McrIndex',
				exact: true,
				path: '/mcr',
				component: McrIndex,
				requireAccess: AccessGroups.Mcr,
			},
			{
				key: 'McrEdit',
				exact: true,
				path: '/mcr/edit',
				component: McrEdit,
				requireAccess: AccessGroups.Mcr,
			},
			{
				key: 'ProjectAnalyticsIndex',
				exact: true,
				path: '/economy/projectanalytics',
				component: ProjectAnalyticsIndex,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'ProjectAnalyticsCost',
				exact: true,
				path: '/economy/projectanalytics/:company',
				component: (props: { match: { params: { company: string } } }) => (
					<Analytics company={props.match.params.company} />
				),
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'ProjectAnalyticsOverview',
				exact: true,
				path: '/economy/projectanalytics/:company/overview',
				component: (props: { match: { params: { company: string } } }) => (
					<ProjectAnalyticsOverview company={props.match.params.company} />
				),
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'ProjectAnalyticsHours',
				exact: true,
				path: '/economy/projectanalytics/:company/hours',
				component: (props: { match: { params: { company: string } } }) => (
					<ProjectAnalyticsHours company={props.match.params.company} />
				),
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'ProjectAnalyticsConfig',
				exact: true,
				path: '/economy/projectanalytics/:company/config',
				component: (props: { match: { params: { company: string } } }) => (
					<ProjectAnalyticsConfig company={props.match.params.company} />
				),
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'ProjectAnalyticsInfo',
				exact: true,
				path: '/economy/projectanalytics/:company/info/:project',
				component: (props: { match: { params: { company: string; project: string } } }) => (
					<ProjectAnalytics company={props.match.params.company} project={props.match.params.project} />
				),
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'Loyality',
				exact: true,
				path: '/economy/loyality',
				component: LoyalityCustomers,
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'LoyalityCustomer',
				exact: true,
				path: '/economy/loyality/:customer',
				component: (props: { match: { params: { customer: string } } }) => (
					<LoyalityCustomer customer_id={props.match.params.customer} />
				),
				requireAccess: AccessGroups.ProjectManagerAny,
			},
			{
				key: 'Gryn',
				exact: true,
				path: '/economy/gryn',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'GrynOpti',
				exact: true,
				path: '/economy/gryn/opti',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'GrynTmlb',
				exact: true,
				path: '/economy/gryn/tmlb',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'GrynCv',
				exact: true,
				path: '/economy/gryn/cv',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'GrynOss',
				exact: true,
				path: '/economy/gryn/oss',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'GrynNltv',
				exact: true,
				path: '/economy/gryn/nltv',
				component: Gryn,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'NetIndex',
				exact: true,
				path: '/net',
				component: NetIndex,
				requireAccess: AccessGroups.Net,
			},
			{
				key: 'NetSwitch',
				exact: true,
				path: '/net/switch',
				component: NetSwitch,
				requireAccess: AccessGroups.Net,
			},
			{
				key: 'NetFirewall',
				exact: true,
				path: '/net/firewall',
				component: NetFirewall,
				requireAccess: AccessGroups.Net,
			},
			{
				key: 'NetSwitchInterface',
				exact: true,
				path: '/net/switch/interface',
				component: NetSwitchInterface,
				requireAccess: AccessGroups.Net,
			},
			{
				key: 'NetDHCP',
				exact: true,
				path: '/net/dhcp',
				component: NetDHCP,
				requireAccess: AccessGroups.Net,
			},
			{
				key: 'ServicesIndex',
				exact: true,
				path: '/services',
				component: ServicesIndex,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'ServicesProjects',
				exact: true,
				path: '/services/projects',
				component: ServicesProjects,
				requireAccess: AccessGroups.EmployedAny,
			},

			{
				key: 'TDACIndex',
				exact: true,
				path: '/tdac',
				component: TDACIndex,
				requireAccess: AccessGroups.EmployedAny,
			},
			{
				key: 'NdiIndex',
				exact: true,
				path: '/ndi',
				component: NdiIndex,
				requireAccess: AccessGroups.NdiRw,
			},
			{
				key: 'ToolsIndex',
				exact: true,
				path: '/tools',
				component: ToolsIndex,
				requireAccess: [...AccessGroups.DashboardOalContacts, ...AccessGroups.AccountingAny],
			},
			{
				key: 'ToolsTripletex',
				exact: true,
				path: '/tools/tripletex',
				component: ToolsTripletex,
				requireAccess: AccessGroups.AccountingAny,
			},
			{
				key: 'InvoiceIndex',
				exact: true,
				path: '/economy/invoice',
				component: InvoiceIndex,
				requireAccess: AccessGroups.InvoiceAny,
			},
			{
				key: 'InvoiceTmlb',
				exact: true,
				path: '/economy/invoice/tmlb',
				component: () => <Invoice company="tmlb" />,
				requireAccess: AccessGroups.InvoiceTmlb,
			},
			{
				key: 'TmcvInvoice',
				exact: true,
				path: '/economy/invoice/tmcv',
				component: () => <Invoice company="tmcv" />,
				requireAccess: AccessGroups.InvoiceTmcv,
			},
			{
				key: 'OptiInvoice',
				exact: true,
				path: '/economy/invoice/opti',
				component: () => <Invoice company="opti" />,
				requireAccess: AccessGroups.InvoiceOpti,
			},
			{
				key: 'OssInvoice',
				exact: true,
				path: '/economy/invoice/oss',
				component: () => <Invoice company="oss" />,
				requireAccess: AccessGroups.InvoiceOss,
			},
			{
				key: 'AnyInvoice',
				exact: true,
				path: '/economy/invoice/any',
				component: () => <InvoiceLoginAs />,
				requireAccess: AccessGroups.DashboardAdmin,
			},

			{
				key: 'InvoiceAdmin',
				exact: true,
				path: '/economy/invoice/admin',
				component: () => <InvoiceAdmin company="tmlb" />,
				requireAccess: AccessGroups.AccountingAny,
			},

			{
				key: 'ProjectIndex',
				exact: true,
				path: '/economy/projects',
				component: () => <ProjectIndex />,
				requireAccess: AccessGroups.EmployedAny,
			},
		]

		return (
			<PageContextProvider>
				<Router>
					<div className="Router">
						<PageContext.Consumer>
							{(context) =>
								(context.authenticated && (
									<TESContextProvider>
										<TESContext.Consumer>
											{(tesContext) => (
												<>
													{tesContext.authenticated !== true ? (
														<Delayed waitBeforeShow={1000}>
															<div
																style={{
																	fontSize: 16,
																	padding: 14,
																	fontWeight: 'bold',
																	backgroundColor: 'red',
																	color: 'white',
																	textAlign: 'center',
																}}
															>
																Sanntidsfunksjoner ser ut til å være utiljengelig for
																øyeblikket.
															</div>
														</Delayed>
													) : (
														<Switch>
															{routes.map((props: any) => {
																if (context.user === null) return null
																if (context.user.groups === null) return null
																let groups = context.user.groups || []
																let accessGranted = false
																if (props.requireAccess === undefined)
																	accessGranted = true
																else if (props.requireAccess !== undefined) {
																	groups.forEach((group) => {
																		if (props.requireAccess.indexOf(group) > -1)
																			accessGranted = true
																	})
																}
																if (accessGranted) {
																	const C = props.component
																	return (
																		<Route
																			key={props.key}
																			exact={props.exact}
																			path={props.path}
																			render={(inProps) =>
																				props.props ? (
																					<C {...inProps} {...props.props} />
																				) : (
																					<C {...inProps} />
																				)
																			}
																		/>
																	)
																}
																return (
																	<Route
																		key={props.key}
																		exact={props.exact}
																		path={props.path}
																		render={() => (
																			<AccessDenied
																				attemptedGroups={props.requireAccess}
																			/>
																		)}
																	/>
																)
															})}
															<Route exact={true} path="/" component={HomeIndex} />
															<Route component={Error} />
														</Switch>
													)}
												</>
											)}
										</TESContext.Consumer>
									</TESContextProvider>
								)) ||
								(context.sharecode && (
									<Switch>
										<Route exact={true} path="/economy/projects" component={ProjectIndex} />
										<Route component={Error} />
									</Switch>
								))
							}
						</PageContext.Consumer>
					</div>
				</Router>
			</PageContextProvider>
		)
	}
}

export default AppRouter
