import React from 'react'
import { Button, Callout, H3, InputGroup, PopoverPosition, Tag } from '@blueprintjs/core'
import { TESContext } from '../../../Utils/TESContext'
import Container from '../Container'
import { InlineEdit } from '../../../Components/InlineEdit'

import { Switch } from './interface'
import { Col, Row } from 'reactstrap'
import { Popover2 } from '@blueprintjs/popover2'

interface Props {}

interface State {
	switches: Switch[]
	newSwitchIp: string
	newSwitchLabel: string
	newSwitchError: null | string
}

class SwitchList extends React.Component<Props, State> {
	static contextType = TESContext

	state: State = {
		switches: [],
		newSwitchIp: '',
		newSwitchLabel: '',
		newSwitchError: null,
	}

	private subscriptionReferenceSwitches: any = this.handleUpdateSwitches.bind(this)

	handleUpdateSwitches(key: string, switches: Switch[]) {
		this.setState({ switches })
	}

	componentDidMount() {
		this.context.subscribe('updates.switch.network.oal.no', this.subscriptionReferenceSwitches)
		this.context.rpc('get.switch.network.oal.no', {}, (err: any, res: any) => {
			if (!err) this.handleUpdateSwitches('switch.network.oal.no', res)
		})
	}

	componentWillUnmount() {
		this.context.unsubscribe('Switch.switch.oal.no', this.subscriptionReferenceSwitches)
	}

	handleChangeNewSwitchLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newSwitchLabel: event.target.value })
	}

	handleChangeNewSwitchIp = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newSwitchIp: event.target.value })
	}

	handleSubmitNewSwitch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		if (this.validateNewSwitch() === true) {
			this.context.rpc(
				'add.switch.network.oal.no',
				{
					label: this.state.newSwitchLabel,
					ip: this.state.newSwitchIp,
				},
				(err: any, res: any) => {}
			)
		}
	}

	validateNewSwitch = () => {
		const { newSwitchIp, newSwitchLabel } = this.state
		let errors = false
		// check if newSwitchIp is formatted as an ip address
		const ipRegex =
			/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
		if (!newSwitchIp.match(ipRegex)) {
			errors = true
		}
		// check if newSwitchLabel is not empty
		if (newSwitchLabel.length === 0) {
			errors = true
		}
		this.setState({ newSwitchError: errors ? 'Please enter a valid IP address and label' : null })
		return !errors
	}

	render() {
		return (
			<Container>
				<div style={{ float: 'right' }}>
					<Popover2
						position={PopoverPosition.BOTTOM_RIGHT}
						content={
							<form className="p-3" onSubmit={this.handleSubmitNewSwitch.bind(this)}>
								<div className="mb-2">
									<label>Switch name</label>
									<InputGroup
										placeholder="Switch label"
										type="text"
										name="label"
										value={this.state.newSwitchLabel}
										onChange={this.handleChangeNewSwitchLabel}
									/>
								</div>
								<div className="mb-2">
									<label>Switch IP</label>
									<InputGroup
										placeholder="Switch IP"
										type="text"
										name="ip"
										value={this.state.newSwitchIp}
										onChange={this.handleChangeNewSwitchIp}
									/>
								</div>
								{this.state.newSwitchError && (
									<Callout intent="danger">{this.state.newSwitchError}</Callout>
								)}
								<Button type="submit" icon="plus">
									Create
								</Button>
							</form>
						}
						onClose={() => {
							this.setState({
								newSwitchIp: '',
								newSwitchLabel: '',
								newSwitchError: null,
							})
						}}
					>
						<Button icon="plus">Switch</Button>
					</Popover2>
				</div>
				<H3>Nettverk &gt; Switch</H3>

				{this.state.switches === null ? (
					<div>Loading..</div>
				) : (
					<div>
						<Row>
							<tr>
								<th>Label</th>
								<th>IP</th>
								<th>Version</th>
								<th>VLAN / Error</th>
								<th>Location</th>
								<th></th>
							</tr>
						</Row>
						<div>
							{this.state?.switches instanceof Array &&
								this.state.switches.map((sw) => (
									<Row key={sw.id} className="table-row">
										<Col lg={2} sm={12} className="table-col">
											<InlineEdit
												value={sw.label}
												minWidth={130}
												onChange={(label) =>
													this.context.rpc('update.switch.network.oal.no', {
														id: sw.id,
														label,
													})
												}
											></InlineEdit>
										</Col>
										<Col>
											<InlineEdit
												value={sw.ip}
												minWidth={100}
												onChange={(ip) =>
													this.context.rpc(
														'update.switch.network.oal.no',
														{
															id: sw.id,
															ip,
														},
														(err: null | string, res: any) => {}
													)
												}
											></InlineEdit>
										</Col>
										<Col>{sw.version}</Col>
										{sw.healthy ? (
											<Col>
												{sw.vlans?.map((vlan) => (
													<React.Fragment key={vlan}>
														<Tag
															minimal
															style={{ marginRight: 4, marginBottom: 2, marginTop: 2 }}
														>
															{vlan}
														</Tag>{' '}
													</React.Fragment>
												))}
												<br />
											</Col>
										) : (
											<Col>
												{sw.vlans?.map((vlan) => (
													<Tag key={vlan} minimal style={{ marginRight: 4 }} intent="danger">
														{sw.error}
													</Tag>
												))}
											</Col>
										)}

										<Col style={{ whiteSpace: 'nowrap' }}>
											{typeof sw.location === 'string'
												? sw.location
												: JSON.stringify(sw.location)}
										</Col>

										<Col
											style={{
												textAlign: 'right',
												minWidth: 150,
												paddingTop: 1,
												paddingBottom: 1,
											}}
										>
											<Button
												icon="trash"
												small
												onClick={() => {
													this.context.rpc(
														'delete.switch.network.oal.no',
														{ id: sw.id },
														(err: any, res: any) => {}
													)
												}}
											>
												Delete
											</Button>{' '}
											<Button
												intent={!sw.enabled ? 'primary' : undefined}
												small
												onClick={() =>
													this.context.rpc('update.switch.network.oal.no', {
														id: sw.id,
														enabled: !sw.enabled,
													})
												}
											>
												{sw.enabled ? 'Disable' : 'Enable'}
											</Button>
										</Col>
									</Row>
								))}
						</div>
					</div>
				)}
			</Container>
		)
	}
}

export default SwitchList
