import { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Button, Checkbox, H5, Text, Dialog, Callout, H4, Icon } from '@blueprintjs/core'
import { InvoicedJob } from '../../Domain/Invoice/Crew'
import { PageContext, PageContextProviderState } from '../../Utils/LoginContext'
import { getCrew, exportJobReference, getNewCrewJobs, getInvoicedCrewJobs } from '../../Services/CrewJobs'
import { InvoiceProps, InvoiceState } from '../../Domain/Invoice/Invoice'
import Loading from '../../Components/Loading/Loading'
import InvoiceContainer from './Container'
import './index.css'
import { Tooltip2 } from '@blueprintjs/popover2'

let selected: any[] = []

class Invoice extends Component<InvoiceProps, InvoiceState> {
	static contextType = PageContext

	constructor(props: InvoiceProps) {
		super(props)

		this.state = {
			showingApproved: false,
			sumSelectedInvoices: 0,
			invoices: [],
			creatingRef: false,
			loading: false,
			error: null,
			showingInfoModal: false,
			showingReferenceModal: false,
			reference: '',
		}
	}

	async updateInvoices() {
		const context: PageContextProviderState = this.context

		if (context.user) {
			let sAMAccountName = context.user.sAMAccountName

			try {
				const crewInfo = await getCrew(sAMAccountName, this.props.company)
				if (!crewInfo) {
					this.setState({
						invoices: [],
						loading: false,
					})
					console.error('Crew information missing!')
					//throw new Error('Crew information missing!')
				} else {

					let newInvoices = await getNewCrewJobs(crewInfo.id, sAMAccountName, this.props.company)
					let oldInvoices = await getInvoicedCrewJobs(sAMAccountName, this.props.company)

					let invoices = newInvoices.concat(oldInvoices)

					this.setState({
						invoices,
						loading: false,
					})
				}
			} catch (error) {
				console.error(error)

				this.setState({
					loading: false,
					error: 'Inern feil, vennligst prøv å last siden på nytt. Hvis det ikke hjelper, ta kontakt med alpha@trippelm.no og forklar problemet.',
				})
			}
		} else {
			this.setState({
				loading: false,
				error: null,
			})
		}
	}

	async componentDidMount() {
		this.setState({
			loading: true,
			error: null,
		})

		await this.updateInvoices()
	}

	handleSelect = (data: any) => {
		if (!selected.includes(data)) {
			selected.push(data)
			if (data.cost) {
				let prevCost = this.state.sumSelectedInvoices
				this.setState({
					sumSelectedInvoices: (prevCost += parseInt(data.cost)),
				})
			}
		} else {
			let index = selected.indexOf(data)
			selected.splice(index, 1)

			if (data.cost) {
				let prevCost = this.state.sumSelectedInvoices
				this.setState({
					sumSelectedInvoices: (prevCost -= parseInt(data.cost)),
				})
			}
		}
	}

	async createReference() {
		const context: PageContextProviderState = this.context
		const sAMAccountName = context.user?.sAMAccountName || ''

		this.setState({
			creatingRef: true,
		})

		let ref = Math.random().toString(36).substr(2, 6).toUpperCase()

		selected.forEach(async (data) => {
			await exportJobReference(data.id, ref, sAMAccountName, this.props.company).catch((error) =>
				console.error(error)
			)
		})

		this.setState({
			showingReferenceModal: true,
			reference: ref,
		})
	}

	async modalClose() {
		this.setState({
			creatingRef: false,
			showingReferenceModal: false,
			reference: '',
			sumSelectedInvoices: 0,
			loading: true,
			error: null,
		})

		selected = []

		await this.updateInvoices()
	}

	async infoModalClose() {
		this.setState({
			showingInfoModal: false,
		})
	}

	async infoModalOpen() {
		this.setState({
			showingInfoModal: true,
		})
	}

	render() {
		/*const hasUnregistered = this.state.invoices.some(
			(invoice) => !invoice.reference && invoice.registered === false
		)*/
		const hasUnknownHire = this.state.invoices.some(
			(invoice) => !invoice.reference && invoice.showHire === 0
		)

		return (
			<InvoiceContainer>
				{this.state.error ? (
					<Callout intent="danger" title="Feil" style={{ marginBottom: '1em' }}>
						{this.state.error}
					</Callout>
				) : this.state.loading ? (
					<>
						<Loading type="rentman" />
					</>
				) : (
					<>
						<Dialog
							title={'Referansekode: ' + this.state.reference}
							onClose={() => this.modalClose()}
							canEscapeKeyClose={true}
							isOpen={this.state.showingReferenceModal}
							style={{ width: '80%' }}
						>
							<Card elevation={1} className={'mb-4'}>
								<table className="bp3-html-table .modifier">
									<thead>
										<tr>
											<th>Prosjekt</th>
											<th>Prosjektnummer</th>
											<th>Funksjon</th>
											<th>Start</th>
											<th>Slutt</th>
											<th>Hyre (eks. mva)</th>
										</tr>
									</thead>
									<tbody>
										{selected.map((data: InvoicedJob, index) => (
											<tr key={index}>
												<td>
													<span title={data.projectName}>{data.projectName}</span>
												</td>
												<td>
													<span title={data.projectNumber}>{data.projectNumber}</span>
												</td>
												<td>
													<span title={data.role}>{data.role}</span>
												</td>
												<td>
													<span title={data.startDate}>
														{new Date(data.startDate).toLocaleString()}
													</span>
												</td>
												<td>
													<span title={data.endDate}>
														{new Date(data.endDate).toLocaleString()}
													</span>
												</td>
												<td>
													<span title={data.cost}>
														{parseInt(data.cost) > 0
															? data.cost.toLocaleString()
															: 'Ingen hyre'}
													</span>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Card>
						</Dialog>
						<Row>
							<Col sm={12} lg={3}>
								<div className={'mb-4'}>
									<H5>Til behandling:</H5>
									<Text>
										Kr:{' '}
										{this.state.invoices
											.filter(
												(data) =>
													data.reference === '' ||
													data.reference === null ||
													data.reference === undefined
											)
											.reduce((prev, cur) => prev + parseInt(cur.cost), 0)
											.toLocaleString()}
									</Text>
								</div>
							</Col>
							<Col sm={12} lg={3}>
								<div className={'mb-4'}>
									<H5>Valgt beløp:</H5>
									<Text>Kr: {this.state.sumSelectedInvoices.toLocaleString()}</Text>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<Callout intent="danger" title="Nice to know" style={{ marginBottom: '1em' }}>
									Jobber blir først mulig å hente referanse på tidligst 24 timer etter jobbslutt
								</Callout>
								<Card elevation={1} className={'mb-4'}>
									<div className="float-right">
										<Button
											disabled={selected.length === 0}
											className="mr-2"
											icon="add"
											intent="success"
											onClick={() => this.createReference()}
										>
											Opprett referanse
										</Button>
									</div>
									<h4 className="mb-4">Hyrer til fakturering</h4>

									<table className="bp3-html-table .modifier">
										<thead>
											<tr>
												<th></th>
												<th>Prosjekt</th>
												<th>Prosjektnummer</th>
												<th>Funksjon</th>
												<th>Start</th>
												<th>Slutt</th>
												<th>Hyre (eks. mva)</th>
											</tr>
										</thead>
										<tbody>
											{this.state.invoices
												.filter(
													(data) =>
														data.reference === '' ||
														data.reference === null ||
														data.reference === undefined
												)
												.map((data: InvoicedJob, index) => (
													<tr key={index} className={data.showHire ? '' : 'unregistered'}>
														<td>
															{data.showHire ? (
																<Checkbox onClick={() => this.handleSelect(data)} />
															) : null}
														</td>
														<td>
															<span title={data.projectName}>
																{data.projectName} {data.registered ? '' : '*'}
															</span>
														</td>
														<td>
															<span title={data.projectNumber}>{data.projectNumber}</span>
														</td>
														<td>
															<span title={data.role}>{data.role}</span>
														</td>
														<td>
															<span title={data.startDate}>
																{new Date(data.startDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.endDate}>
																{new Date(data.endDate).toLocaleString()}
															</span>
														</td>
														<td>
															{ data.showHire ? (
																<span title={data.cost}>
																	{parseInt(data.cost) > 0
																		? data.cost.toLocaleString()
																		: 'Ingen hyre'}
																</span>
															) : (
																<Tooltip2 content="Venter på prosjektleders bekreftelse">
																	<Icon icon="info-sign" />
																</Tooltip2>
															)}
														</td>
													</tr>
												))}
												{this.state.invoices.length === 0 ? (
													<tr>
														<td colSpan={7}>
															Du har ingen jobber som er klar for fakturering.
														</td>
													</tr>
												) : null}
										</tbody>
									</table>
									{hasUnknownHire ? (
										<Callout intent="primary" className="mt-4">
											<p>
												Enkelte jobber i listen din er ikke klar for å faktureres, vent en dag
												og se om jobben er klar til å faktureres. Hvis du mener en jobb burde
												vært tilgjengelig for fakturering, ta kontakt med prosjektleder for
												jobben.
											</p>
										</Callout>
									) : null}
								</Card>
								<div style={{ marginBottom: '2em' }}>
									<Row>
										<Col md={12} xl={6}>
											<Card className="mb-4">
												<H4>Opprett referansekode, før du sender en faktura.</H4>
												<p>
													Velg prosjektene du ønsker å fakturere for og trykk{' '}
													<strong>Opprett referanse</strong>
												</p>
												<p>
													Referansekoden setter du under "Referanse" på fakturaen din, mens på
													hver enkelt ordrelinje setter du inn prosjektnummeret.
												</p>
												<Callout intent="warning" className="mt-3">
													Finner du noe feil i listen, må dette avklares med prosjektleder som
													oppdaterer denne listen før du oppretter referansekoden og sender
													faktura.
												</Callout>
											</Card>
											<Card className="mb-4">
												<H4>Fakturadetaljer</H4>
												<p>
													Vi foretrekker faktura via <b>EHF</b>. Har dere ikke støtte for EHF
													kan vi også motta PDF/JPG som vedlegg i en epost.
												</p>
												<p>
													<b>Selskapsnavn:</b>{' '}
													{
														{
															tmlb: 'Trippel-m levende bilder AS',
															tmcv: 'Trippel-m connected venues AS',
															oss: 'Oslo Streamingsenter AS',
															opti: 'Optilux AS',
														}[this.props.company as 'tmlb' | 'tmcv' | 'oss' | 'opti']
													}
													<br />
													<b>Org.nr:</b>{' '}
													{
														{
															tmlb: 'NO 996 658 619 MVA',
															tmcv: 'NO 826 203 072 MVA',
															oss: 'NO 925 360 384 MVA',
															opti: 'NO 991 152 261 MVA',
														}[this.props.company as 'tmlb' | 'tmcv' | 'oss' | 'opti']
													}
													<br />
													<b>Epost:</b>{' '}
													{
														{
															tmlb: 'faktura@trippelm.no',
															tmcv: 'faktura.tmcv@trippelm.no',
															oss: 'faktura@oslostreamingsenter.no',
															opti: 'faktura@optilux.no',
														}[this.props.company as 'tmlb' | 'tmcv' | 'oss' | 'opti']
													}
													<br />
													<br />
													<b>Bankkonto ved kreditnota:</b>{' '}
													{
														{
															tmlb: '1503.20.07896',
															tmcv: '1506.53.62055',
															oss: '1506.48.47217',
															opti: '2610.18.86920',
														}[this.props.company as 'tmlb' | 'tmcv' | 'oss' | 'opti']
													}
													<br />
												</p>
												<p>
													Referansekoden setter du under "Referanse" på fakturaen din, mens på
													hver enkelt ordrelinje setter du inn prosjektnummeret.
												</p>
												<Callout intent="warning" className="mt-3">
													Alle faktura skal merkes med navn på bestiller og fakturareferanse.
												</Callout>
											</Card>
										</Col>
										<Col md={12} xl={6}>
											<Card>
												<Callout intent="success" className="mb-3">
													Eksempel på en godkjent faktura.
												</Callout>
												<div
													style={{
														border: '1px solid #f0f0f0',
													}}
													className=""
												>
													<div
														style={{
															backgroundColor: 'white',
															position: 'absolute',
															marginTop: 0,
															fontWeight: 'bold',
															paddingTop: 50,
															height: 100,
															width: 300,
															display: 'block',
															marginLeft: 17,
														}}
													>
														{this.props.company === 'tmlb' && (
															<>
																Trippel-M levende bilder AS
																<br />
																Konows Gate 5<br />
																0192 Oslo
																<br />
															</>
														)}
														{this.props.company === 'tmcv' && (
															<>
																Trippel-M connected venues AS
																<br />
																Konows Gate 5<br />
																0192 Oslo
																<br />
															</>
														)}
														{this.props.company === 'oss' && (
															<>
																Oslo Streamingsenter AS
																<br />
																Sonja Henies plass 4<br />
																0185 Oslo
																<br />
															</>
														)}
														{this.props.company === 'opti' && (
															<>
																Optilux AS
																<br />
																Drangedalsvegen 100
																<br />
																3920 Porsgrunn
																<br />
															</>
														)}
													</div>
													<img
														alt="faktura"
														style={{
															width: '100%',
															maxWidth: '100%',
														}}
														src="/faktura.png"
													/>
												</div>
											</Card>
										</Col>
									</Row>
								</div>
								<Card>
									<h4 className="mb-4">Fakturerte hyrer</h4>

									<table className="bp3-html-table .modifier">
										<thead>
											<tr>
												<th>Referanse</th>
												<th>Prosjekt</th>
												<th>Prosjektnummer</th>
												<th>Funksjon</th>
												<th>Start</th>
												<th>Slutt</th>
												<th>Hyre (eks. mva)</th>
											</tr>
										</thead>
										<tbody>
											{this.state.invoices
												.filter(
													(data) =>
														data.reference !== '' &&
														data.reference !== null &&
														data.reference !== undefined
												)
												.map((data: InvoicedJob, index) => (
													<tr key={index}>
														<td>
															<span title={data.reference}>{data.reference}</span>
														</td>
														<td>
															<span title={data.projectName}>{data.projectName}</span>
														</td>
														<td>
															<span title={data.projectNumber}>{data.projectNumber}</span>
														</td>
														<td>
															<span title={data.role}>{data.role}</span>
														</td>
														<td>
															<span title={data.startDate}>
																{new Date(data.startDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.endDate}>
																{new Date(data.endDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.cost}>
																{parseInt(data.cost) > 0
																	? this.price(data.cost, data.costDifference)
																	: 'Ingen hyre'}
															</span>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</InvoiceContainer>
		)
	}

	price(price: string, newPrice: string | null) {
		if (newPrice !== null) {
			return (
				<>
					<span style={{ textDecoration: 'line-through', padding: 4, backgroundColor: 'rgba(200,0,0,0.1)' }}>
						kr {price}
					</span>{' '}
					<span style={{ fontWeight: 'bold', padding: 4, backgroundColor: 'rgba(0,200,0,0.1)' }}>
						kr {newPrice}
					</span>
				</>
			)
		}
		return <span style={{ padding: 4 }}>kr {price.toLocaleString()}</span>
	}
}

export default Invoice
