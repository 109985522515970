import {
	Tabs,
	Tab,
	Card,
	ButtonGroup,
	Button,
	Elevation,
	Intent,
	NonIdealState,
	ControlGroup,
	Menu,
	MenuItem,
	Popover,
	Position,
	Checkbox,
} from '@blueprintjs/core'

import React, { Component, Fragment } from 'react'
import {
	InvoiceAdminProps,
	InvoiceAdminState,
	IInvoiceAdminInvoiceItem,
	IInvoiceAdminInvoiceItemSub,
	IInvoiceAdminInvoiceNoCodeSortKeys,
} from '../../Domain/Invoice/Admin'
import Container from './Container'
import { Row, Col } from 'reactstrap'
import { Loading } from '../../Components/Loading/Loading'
import { Company } from '../../Domain/Common/Company'
import { DateInput, IDateFormatProps } from '@blueprintjs/datetime'
import { getAllInvoicedJobs, exportJobReference, approveInvoice } from '../../Services/InvoiceAdmin'
import { OALLazyUser } from '../../Components/OAL/User'
import { getProfile } from '../../Services/OalApi'
import { OALProfileCard } from '../../Domain/Common/OAL'

function isThisIshTheSame(a: any, b: any) {
	return JSON.stringify(a) === JSON.stringify(b)
}

const groupByKey = (key: any) => (array: any) =>
	array.reduce((objectsByKeyValue: any, obj: any) => {
		const value = obj[key]
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
		return objectsByKeyValue
	}, {})

class InvoiceAdmin extends Component<InvoiceAdminProps, InvoiceAdminState> {
	constructor(props: InvoiceAdminProps) {
		super(props)
		this.state = {
			currentMode: 'codes',
			companyTabActive: 'tmlb',
			loadingData: true,
			sortAsc: true,
			sortKey: 'jobStart',
			sortDateEnd: null,
			sortDateStart: null,
			items: [],
			modeTotalSum: 0,
			checkedItems: [],
			registeringChecked: false,
		}
	}

	async componentDidMount() {
		await this.loadData()
	}

	async loadData() {
		this.setState({ loadingData: true })

		try {
			let data = await getAllInvoicedJobs(this.state.companyTabActive)
			this.setState({
				items: data as IInvoiceAdminInvoiceItem[],
				loadingData: false,
			})

			return data
		} catch (e) {
			this.setState({ loadingData: false, items: [] })
			return []
		}
	}

	async download(data: IInvoiceAdminInvoiceItem[]) {
		import('xlsx').then((XLSX) => {
			const fields = [
				[
					'Navn',
					'Prosjektnummer',
					'Prosjektnavn',
					'Rolle',
					'Referanse',
					'Kostnad',
					'Godkjent',
					'Start',
					'Slutt',
				],
				...data.map((item) => {
					return [
						item.name,
						item.projectNumber,
						item.projectName,
						item.role,
						item.reference,
						item.cost,
						Boolean(item.approved),
						new Date(item.startDate),
						new Date(item.endDate),
					]
				}),
			]
			const wb = XLSX.utils.book_new(
				//@ts-ignore
				{
					type: 'binary',
					cellDates: true,
					cellNF: false,
				}
			)
			const ws = XLSX.utils.aoa_to_sheet(fields)
			XLSX.utils.book_append_sheet(wb, ws)
			XLSX.writeFile(wb, 'dashboard.xlsx')
		})
	}

	csvDate(date: Date) {
		const pad = (num: number) => ('00' + num).slice(-2)
		return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`
	}

	async downloadGBAT(data: IInvoiceAdminInvoiceItem[]) {
		const userCache: { [phone: string]: OALProfileCard } = {}
		let out = []

		for (let i = 0; i < data.length; i++) {
			const item = data[i]
			const month = new Date(item.startDate).getMonth() + 1
			const phone = item.phone.replace(/^\+47/, '').replace(/^\+/, '')

			if (userCache[item.phone] === undefined) {
				try {
					userCache[item.phone] = await getProfile(phone)
				} catch (e) {
					console.error('No profile found for ' + item.phone)
				}
			}

			const name = userCache[item.phone]
				? userCache[item.phone].givenName + ' ' + userCache[item.phone].sn
				: item.phone
			const cost = item.cost.toString().replace('.', ',')

			out.push(
				`GBAT10;${month};${this.csvDate(new Date(item.startDate))};;;;2962;0;-${cost};;;;;;;;;;;;${name};;;${
					item.projectNumber
				};;;T;-${cost}\n`
			)
			out.push(
				`GBAT10;${month};${this.csvDate(new Date(item.startDate))};;;;4500;0;${cost};;;;;;;;;;;;${name};;;${
					item.projectNumber
				};;;T;${cost}\n`
			)
		}

		const blob = new Blob(out, { type: 'text/plain;charset=utf-8' })
		const url = URL.createObjectURL(blob)
		const a = document.createElement('a')
		a.href = url
		a.download = 'GBAT.csv'
		a.click()
		URL.revokeObjectURL(url)
	}

	componentDidUpdate(_prevProps: InvoiceAdminProps, prevState: InvoiceAdminState) {
		const was = [prevState.companyTabActive, prevState.currentMode]
		const now = [this.state.companyTabActive, this.state.currentMode]
		if (!isThisIshTheSame(was, now) && !this.state.loadingData) {
			this.loadData()
		}
	}

	checkItem(id: string) {
		if (this.state.checkedItems.includes(id)) {
			const checkedItems = this.state.checkedItems.filter((item) => item !== id)
			this.setState({
				checkedItems,
			})
		} else {
			this.setState({
				checkedItems: [...this.state.checkedItems, id],
			})
		}
	}

	renderNoCodeItem(item: IInvoiceAdminInvoiceItem) {
		return (
			<div key={item.id}>
				<Card className="mt-3" elevation={Elevation.TWO}>
					<Row>
						<Col lg={1}>
							<Checkbox
								onChange={(e) => this.checkItem(item.jobId)}
								checked={this.state.checkedItems.includes(item.jobId)}
							/>
						</Col>
						<Col lg={1} className="pl-4">
							{item.projectNumber}
						</Col>
						<Col lg={2} className="pl-4">
							<OALLazyUser id={item.phone.replace(/^\+47/, '')}>{item.name}</OALLazyUser>
						</Col>
						<Col lg={3}>{item.projectName}</Col>
						<Col lg={1}>{this.dato(item.startDate)}</Col>
						<Col lg={1}>{this.dato(item.endDate)}</Col>
						<Col
							lg={1}
							title={item.showHire ? '' : 'Summen er ikke godkjent av prosjektleder'}
							style={item.showHire ? {} : { opacity: 0.4 }}
						>
							{this.price(item.cost, item.costDifference)}
						</Col>
						<Col lg={2} style={{ textAlign: 'right' }}>
							<Button onClick={() => this.createReference(item.jobId)} intent={Intent.SUCCESS} small>
								Registrer
							</Button>
						</Col>
					</Row>
				</Card>
			</div>
		)
	}

	async registerCheckedItems() {
		this.setState({ registeringChecked: true })
		const all = this.state.checkedItems.map((id) => this.createReference(id, false))
		await Promise.all(all)
		this.setState({ registeringChecked: false, checkedItems: [] })
		this.loadData()
	}

	async setAsApprovedCheckedItems() {
		this.setState({ registeringChecked: true })
		const all = this.state.checkedItems.map((id) => this.setAsApproved(id, false))
		await Promise.all(all)
		this.setState({ registeringChecked: false, checkedItems: [] })
		this.loadData()
	}

	async createReference(id: string, loadOnSuccess: boolean = true) {
		let ref = Math.random().toString(36).substr(2, 6).toUpperCase()

		await exportJobReference(id, ref, this.state.companyTabActive)
			.then(() => {})
			.catch((error) => console.error(error))

		if (loadOnSuccess) {
			this.loadData()
		}
	}

	async setAsApproved(reference: string, loadOnSuccess: boolean = true) {
		await approveInvoice(reference, this.state.companyTabActive)
			.then(() => {})
			.catch((error) => console.error(error))

		if (loadOnSuccess) {
			this.loadData()
		}
	}

	renderItems(data: IInvoiceAdminInvoiceItem[]) {
		let datafilter = () => {
			if (this.state.currentMode === 'codes') {
				return data.filter((obj: IInvoiceAdminInvoiceItem) => obj.reference !== '' && obj.approved === 0)
			}
			if (this.state.currentMode === 'registered') {
				return data.filter((obj: IInvoiceAdminInvoiceItem) => obj.reference !== '' && obj.approved === 1)
			} else {
				return data
			}
		}

		const groupByRef = groupByKey('reference')
		let groupedData = groupByRef(datafilter())

		return Object.keys(groupedData).map((keys) => (
			<Fragment key={JSON.stringify(keys)}>
				{this.state.currentMode !== 'nocodes' ? (
					<div>
						<Card className="mt-3" elevation={Elevation.TWO}>
							<Row>
								<Col lg={5} style={{ fontWeight: 'bold' }}>
									<OALLazyUser id={groupedData[keys][0].phone.replace(/^\+47/, '')}>
										{groupedData[keys][0].name}
									</OALLazyUser>
								</Col>
								<Col lg={5} style={{ fontWeight: 'bold' }}>
									{groupedData[keys][0].reference}
								</Col>
								<Col lg={1} style={{ fontWeight: 'bold' }}>
									Kr{' '}
									{groupedData[keys]
										.reduce((prev: any, cur: any) => {
											return prev + (cur.costDifference !== null ? cur.costDifference : cur.cost)
										}, 0)
										.toLocaleString()}
								</Col>
								<Col lg={1} style={{ textAlign: 'right' }}>
									{this.state.currentMode === 'codes' && (
										<>
											<Checkbox
												inline
												checked={this.state.checkedItems.includes(
													groupedData[keys][0].reference
												)}
												onChange={(e) => this.checkItem(groupedData[keys][0].reference)}
											/>
											<Button
												onClick={() => this.setAsApproved(groupedData[keys][0].reference)}
												intent={Intent.SUCCESS}
												small
											>
												Mottatt
											</Button>
										</>
									)}
								</Col>
							</Row>
							<Card className="mt-2 p-0">
								{groupedData[keys].map((subItem: IInvoiceAdminInvoiceItemSub) =>
									this.renderItemSub(subItem)
								)}
							</Card>
						</Card>
					</div>
				) : (
					<Card className="mt-2 p-0">
						<Row
							style={{
								borderBottom: '1px solid #eee',
								paddingBottom: 5,
								paddingTop: 5,
								fontSize: 16,
								fontWeight: 600,
							}}
						>
							<Col lg={2} className="pl-4">
								Prosjektnummer
							</Col>
							<Col lg={2} className="pl-4">
								Navn
							</Col>
							<Col lg={3}>Prosjektnavn</Col>
							<Col lg={1}>Start</Col>
							<Col lg={1}>Slutt</Col>
							<Col lg={1}>Pris</Col>
							<Col lg={2} style={{ textAlign: 'right' }}>
								Registrer
							</Col>
						</Row>
						{groupedData[''].map((item: IInvoiceAdminInvoiceItem) => (
							<Row
								style={{
									borderBottom: '1px solid #eee',
									paddingBottom: 5,
									paddingTop: 5,
									fontSize: 13,
								}}
							>
								<Col lg={2} className="pl-4">
									{item.projectNumber}
								</Col>
								<Col lg={2} className="pl-4">
									<OALLazyUser id={item.phone.replace(/^\+47/, '')}>{item.name}</OALLazyUser>
								</Col>
								<Col lg={3}>{item.projectName}</Col>
								<Col lg={1}>{this.dato(item.startDate)}</Col>
								<Col lg={1}>{this.dato(item.endDate)}</Col>
								<Col lg={1}>{this.price(item.cost, item.costDifference)}</Col>
								<Col lg={2} style={{ textAlign: 'right' }}>
									<Button
										onClick={() => this.createReference(item.jobId)}
										intent={Intent.SUCCESS}
										small
									>
										Registrer
									</Button>
								</Col>
							</Row>
						))}
					</Card>
				)}
			</Fragment>
		))
	}

	renderItemSub(subItem: IInvoiceAdminInvoiceItemSub) {
		return (
			<div key={subItem.id}>
				<Row style={{ borderBottom: '1px solid #eee', paddingBottom: 5, paddingTop: 5, fontSize: 13 }}>
					<Col lg={2} className="pl-4">
						{subItem.projectNumber}
					</Col>
					<Col lg={4}>{subItem.projectName}</Col>
					<Col lg={2}>{this.dato(subItem.startDate)}</Col>
					<Col lg={2}>{this.dato(subItem.endDate)}</Col>
					<Col
						lg={2}
						title={subItem.showHire ? '' : 'Summen er ikke godkjent av prosjektleder'}
						style={subItem.showHire ? {} : { opacity: 0.4 }}
					>
						{this.price(subItem.cost, subItem.costDifference)}
					</Col>
				</Row>
			</div>
		)
	}

	renderAdmin(company: Company) {
		const jsDateFormatter: IDateFormatProps = {
			// note that the native implementation of Date functions differs between browsers
			formatDate: (date) => date.toLocaleDateString(),
			parseDate: (str) => new Date(str),
			placeholder: 'M/D/YYYY',
		}

		const sortAlternatives: any = {
			crew: 'Navn',
			jobNumber: 'Prosjektnummer',
			jobName: 'Prosjektnavn',
			jobStart: 'Dato',
			total: 'Total',
		}

		return (
			<div>
				<ButtonGroup>
					<Button
						intent={this.state.currentMode === 'codes' ? Intent.PRIMARY : undefined}
						onClick={() => this.setState({ currentMode: 'codes', checkedItems: [] })}
					>
						Referansekoder
					</Button>
					<Button
						intent={this.state.currentMode === 'nocodes' ? Intent.PRIMARY : undefined}
						onClick={() => this.setState({ currentMode: 'nocodes', checkedItems: [] })}
					>
						Uten referansekode
					</Button>
					<Button
						intent={this.state.currentMode === 'registered' ? Intent.PRIMARY : undefined}
						onClick={() => this.setState({ currentMode: 'registered', checkedItems: [] })}
					>
						Registrerte referansekoder
					</Button>
				</ButtonGroup>

				{this.state.items.length > 0 && this.state.loadingData === false && (
					<div style={{ float: 'right', fontSize: 18, marginTop: 5, fontWeight: 'bold' }}>
						Sum:{' '}
						{this.state.currentMode === 'nocodes'
							? this.noCodeApplyFilters(
									this.state.items.filter((obj: IInvoiceAdminInvoiceItem) => obj.reference === '')
							  )
									.reduce((prev, cur) => {
										return prev + cur.cost
									}, 0)
									.toLocaleString()
							: this.state.currentMode === 'codes'
							? this.state.items
									.filter((obj) => obj.reference !== '' && obj.approved === 0)
									.reduce((prev, cur) => {
										return prev + cur.cost
									}, 0)
									.toLocaleString()
							: this.state.items
									.filter((obj) => obj.reference !== '' && obj.approved === 1)
									.reduce((prev, cur) => {
										return prev + cur.cost
									}, 0)
									.toLocaleString()}
						{''}
						,-
					</div>
				)}

				{this.state.loadingData === true ? (
					<Loading />
				) : (
					<>
						{this.state.currentMode === 'nocodes' ? (
							<div className="mt-3">
								<ControlGroup vertical={false}>
									<Button disabled icon="filter" />
									<DateInput
										{...jsDateFormatter}
										value={this.state.sortDateStart}
										onChange={(date) => this.setState({ sortDateStart: date })}
									/>
									{this.state.sortDateStart !== null && (
										<Button
											onClick={() => this.setState({ sortDateStart: null })}
											intent="danger"
											icon="cross"
										/>
									)}
									<Button disabled icon="arrow-right" />
									<DateInput
										{...jsDateFormatter}
										value={this.state.sortDateEnd}
										onChange={(date) => this.setState({ sortDateEnd: date })}
									/>
									{this.state.sortDateEnd !== null && (
										<Button
											onClick={(e: any) => this.setState({ sortDateEnd: null })}
											intent="danger"
											icon="cross"
										/>
									)}

									<Button
										icon={this.state.sortAsc ? 'sort-asc' : 'sort-desc'}
										intent={this.state.sortAsc ? 'success' : 'warning'}
										onClick={(e: any) => this.setState({ sortAsc: !this.state.sortAsc })}
									/>
									<Popover
										content={
											<Menu>
												{Object.keys(sortAlternatives).map((sortkey) => (
													<MenuItem
														key={sortkey}
														text={sortAlternatives[sortkey]}
														onClick={(e: any) =>
															this.setState({
																sortKey: sortkey as IInvoiceAdminInvoiceNoCodeSortKeys,
															})
														}
													></MenuItem>
												))}
											</Menu>
										}
										position={Position.RIGHT_TOP}
									>
										<Button text={sortAlternatives[this.state.sortKey]} />
									</Popover>
									<Button
										onClick={() =>
											this.download(
												this.noCodeApplyFilters(
													this.state.items.filter(
														(obj: IInvoiceAdminInvoiceItem) => obj.reference === ''
													)
												)
											)
										}
									>
										Last ned
									</Button>
									<Button
										onClick={() =>
											this.downloadGBAT(
												this.noCodeApplyFilters(
													this.state.items.filter(
														(obj: IInvoiceAdminInvoiceItem) => obj.reference === ''
													)
												)
											)
										}
									>
										Last ned GBAT10
									</Button>
								</ControlGroup>

								{this.noCodeApplyFilters(
									this.state.items.filter((obj: IInvoiceAdminInvoiceItem) => obj.reference === '')
								).map((item) => this.renderNoCodeItem(item))}

								{this.state.checkedItems.length > 0 && (
									<div className="mt-4">
										<Button
											loading={this.state.registeringChecked}
											intent="success"
											onClick={() => this.registerCheckedItems()}
										>
											Registrer {this.state.checkedItems.length} koder
										</Button>
									</div>
								)}
							</div>
						) : (
							<>
								{this.state.items?.length > 0 && this.renderItems(this.state.items)}
								{this.state.items?.length === 0 && (
									<Card className="mt-3">
										<NonIdealState
											className="mt-5 mb-5"
											icon="align-justify"
											title="Tom tabell"
											description="Det ser ikke ut til å finnes noen referanser i denne oversikten."
										/>
									</Card>
								)}
								{this.state.checkedItems.length > 0 && (
									<div className="mt-4">
										<Button
											loading={this.state.registeringChecked}
											intent="success"
											onClick={() => this.setAsApprovedCheckedItems()}
										>
											Godkjenn {this.state.checkedItems.length} koder
										</Button>
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>
		)
	}

	noCodeApplyFilters(items: IInvoiceAdminInvoiceItem[]) {
		const dateFilter = () => {
			if ((this.state.sortDateStart && this.state.sortDateEnd) !== null) {
				return items.filter(
					(obj) =>
						new Date(obj.startDate) >= this.state.sortDateStart! &&
						new Date(obj.endDate) <= this.state.sortDateEnd!
				)
			}
			if (this.state.sortDateStart !== null) {
				return items.filter((obj) => new Date(obj.startDate) >= this.state.sortDateStart!)
			}
			if (this.state.sortDateEnd !== null) {
				return items.filter((obj) => new Date(obj.endDate) <= this.state.sortDateEnd!)
			}
			return items
		}

		let data = dateFilter()

		if (this.state.sortKey === 'crew') {
			let sortedItems = data.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})
			if (this.state.sortAsc === true) {
				return sortedItems
			} else {
				return sortedItems.reverse()
			}
		}

		if (this.state.sortKey === 'jobNumber') {
			let sortedItems = data.sort((a, b) => {
				if (a.projectNumber < b.projectNumber) return -1
				if (a.projectNumber > b.projectNumber) return 1
				return 0
			})
			if (this.state.sortAsc === true) {
				return sortedItems
			} else {
				return sortedItems.reverse()
			}
		}

		if (this.state.sortKey === 'jobName') {
			let sortedItems = data.sort((a, b) => {
				if (a.projectName < b.projectName) return -1
				if (a.projectName > b.projectName) return 1
				return 0
			})
			if (this.state.sortAsc === true) {
				return sortedItems
			} else {
				return sortedItems.reverse()
			}
		}

		if (this.state.sortKey === 'total') {
			let sortedItems = data.sort((a, b) => {
				if (a.cost < b.cost) return -1
				if (a.cost > b.cost) return 1
				return 0
			})
			if (this.state.sortAsc === true) {
				return sortedItems
			} else {
				return sortedItems.reverse()
			}
		}

		if (this.state.sortKey === 'jobStart') {
			let sortedItems = data.sort((a, b) => {
				let dateA = new Date(a.startDate)
				let dateB = new Date(b.startDate)
				if (dateA < dateB) return -1
				if (dateA > dateB) return 1
				return 0
			})
			if (this.state.sortAsc === true) {
				return sortedItems
			} else {
				return sortedItems.reverse()
			}
		}

		return data
	}

	render() {
		return (
			<Container>
				<Tabs
					renderActiveTabPanelOnly
					onChange={(companyTabActive: Company) => {
						this.setState({ companyTabActive })
					}}
				>
					<Tab id="tmlb" panel={this.renderAdmin('tmlb')} title="Levende bilder" />
					<Tab id="tmcv" panel={this.renderAdmin('tmcv')} title="Connected venues" />
					<Tab id="oss" panel={this.renderAdmin('oss')} title="OSS" />
					<Tab id="opti" panel={this.renderAdmin('opti')} title="Optilux" />
				</Tabs>
			</Container>
		)
	}

	dato(date: string) {
		return new Date(date).toLocaleString()
	}

	price(price: number, newPrice: number | null) {
		if (newPrice !== null) {
			return (
				<>
					<span style={{ textDecoration: 'line-through', padding: 4, backgroundColor: 'rgba(200,0,0,0.1)' }}>
						kr {price}
					</span>{' '}
					<span style={{ fontWeight: 'bold', padding: 4, backgroundColor: 'rgba(0,200,0,0.1)' }}>
						kr {newPrice}
					</span>
				</>
			)
		}
		return <span style={{ padding: 4 }}>kr {price.toLocaleString()}</span>
	}
}
export default InvoiceAdmin
