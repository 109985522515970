import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

class McrContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'mcr-frontpage',
						title: 'Din mcr',
						icon: 'person',
						link: '/mcr',
						active: isActivePage(['/mcr']),
					},
					{
						id: 'mcr-edit',
						title: 'Endre mcr',
						icon: 'edit',
						link: '/mcr/edit',
						active: isActivePage(['/mcr/edit']),
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default McrContainer
