import { useCallback, useEffect, useState } from 'react'

export const useApiData = <T>(loader: (...args: any) => Promise<T>, ...args: any) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)
	const [data, setData] = useState<T | null>(null)

	const loadData = useCallback(async (...innerArgs: any) => {
		setError(null)
		setLoading(true)
		try {
			setData(await loader(...innerArgs))
		} catch (e) {
			setError('Error loading data')
			console.error(e)
		}
		setLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		loadData(...(args as any))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadData])

	return {
		loading,
		data,
		error,

		reload: loadData,
	}
}

// This uses a dependency list to reload the data when the arguments change
// and to make sure we don't update the stale data if the arguments have changed
export const useApiDataDependency = <T, K extends React.DependencyList>(
	loader: (...args: any) => Promise<T>,
	args: K
) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)
	const [data, setData] = useState<T | null>(null)

	const loadData = useCallback(async (innerArgs?: K, cancelObj?: { canceled: boolean }) => {
		setError(null)
		setLoading(true)
		try {
			const data = await loader.apply(loader, (innerArgs as unknown as any[]) ?? args)
			// Make sure we don't try to update react state if the arguments have changed and this is no longer relevant
			if (!cancelObj || !cancelObj.canceled) {
				setData(data)
			}
		} catch (e: any) {
			setError(e.message)
			console.error('useApi error: ', e)
		} finally {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const cancelObj = { canceled: false }
		loadData(args, cancelObj)

		return () => {
			cancelObj.canceled = true
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, args)

	return {
		loading,
		data,
		error,

		reload: loadData,
	}
}
