import { ButtonGroup, Button, InputGroup, MenuItem, Colors, ControlGroup, Tag, Spinner } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { InlineEdit } from '../../../Components/InlineEdit'
import { Switch } from '../Switch/interface'
import { TESContext } from '../../../Utils/TESContext'
import { InterfaceChart } from './InterfaceChart'
import { Interface } from './index'
import { Popover2 } from '@blueprintjs/popover2'
import { MACInfo } from '../../../Domain/Net/Switch'

interface NetSwitchInterfaceRenderProps {
	selectedVlan?: string | null
	switch: Switch
	interface: Interface
	macs: MACInfo[]
	getIp: (mac: string) => string | null
}

interface VLAN {
	id: string
}


const VLANSelect = Select.ofType<VLAN>()

const NetSwitchInterfaceRender = (props: NetSwitchInterfaceRenderProps) => {
    const context = useContext(TESContext);
	const [vlans, setVlans] = useState<VLAN[]>([])
    const [selectedVlan, setSelectedVlan] = useState<string>('(none)');

	const setVlan = (vlan: string) => {
		context.rpc(
			'setInterfaceVlanMembership.switch.network.oal.no',
			{
				switchId: props.switch.id, // sw id
				arguments: {
					interface: props.interface.id, // ge-0/0/0
					membership: vlan,
				},
			},
			(err: null | string, res: any) => {}
		)
	}

	useEffect(() => {
		if (props.switch !== undefined && props.switch !== null && props.switch.vlans !== undefined) {

			setVlans(props.switch.vlans
				.filter((f) => f !== 'all')
				.map((vlan: string) => {
					return { id: vlan }
				})
			)

			if (props.interface.trunkPort) {
				setSelectedVlan('[TRUNKPORT]')
			} else if (props.interface.vlans[0] !== undefined && props.interface.vlans[0] !== null) {
				setSelectedVlan(props.interface.vlans[0])
			}
		}
	}, [props.interface, props.switch])

	const vlanDisable = props.interface.trunkPort || props.interface.vlans.length > 1

	const macInfo = props.macs?.length > 0 ? props.macs?.filter(mac => mac.portName === props.interface.id) : undefined

	return (
		<Row
			style={{
				marginTop: 2,
				marginBottom: 2,
				marginRight: 0,
				padding: 10,
				paddingRight: 0,
			}}
		>
			<Col md={12}>
				<ButtonGroup>
					<InputGroup
						style={{
							borderLeft:
								'5px solid ' + (props.interface.state === true ? Colors.GREEN4 : Colors.RED4),
							backgroundColor: props.interface.state === true ? 'rgba(0,200,0,0.05)' : undefined,
							width: 100,
						}}
						type="text"
						readOnly
						value={props.interface.id}
					/>

					<InlineEdit
						value={props.interface.description || ''}
						style={{
							display: 'inline-block',
							fontWeight: 'bold',
							paddingTop: 6,
							fontSize: 15,
							paddingLeft: 6,
							maxWidth: '40vw',
						}}
						onChange={(newDescription) => {
							if (newDescription !== props.interface.description) {
								context.rpc(
									'setInterfaceDescription.switch.network.oal.no',
									{
										switchId: props.switch.id, // sw id
										arguments: {
											description: newDescription, // new desc
											interface: props.interface.id, // ge-0/0/0
										},
									},
									(err: null | string, res: any) => {}
								)
							}
						}}
					></InlineEdit>
				</ButtonGroup>
				{/*<div style={{ marginLeft: 105 }}>
					{props.interface.stpState !== 'FORWARDING' && props.interface.state && (
						<Tag intent="danger" style={{ marginRight: 10, marginTop: 5 }}>
							{props.interface.stpState}
						</Tag>
					)}
				</div>*/}
				{ !vlanDisable ? (
					<div>
					{ props.macs?.length === 0 ? (
						<Spinner size={Spinner.SIZE_SMALL} />
					) : (
						<div className='mt-2'>
							{ macInfo ? macInfo.map(info => (
								<div key={info.macAddress} className='mt-2'>
									<Tag intent='success' style={{ marginRight: '10px' }}>{info.macAddress || 'N/A'}</Tag>
									{info.manufacturer ? (
										<Tag intent='warning' style={{ marginRight: '10px' }}>{info.manufacturer}</Tag>
									) : null}
									{props.getIp(info.macAddress) ? (
										<Tag intent='primary' style={{ marginRight: '10px' }}>{props.getIp(info.macAddress)}</Tag>
									): null}
								</div>
							)) : null}
						</div>
					)}
					</div>
				) : null}
				<div style={{ float: 'right', textAlign: 'right' }}>
					<VLANSelect
						items={vlans}
						itemPredicate={(query, vlan, _index) =>
							vlan.id.toLowerCase().indexOf(query.toLowerCase()) >= 0
						}
						itemRenderer={(item) => (
							<MenuItem key={item.id} onClick={(e) => setVlan(item.id)} text={item.id} />
						)}
						noResults={<MenuItem disabled={true} text="No results." />}
						onItemSelect={(vlan) => setVlan(vlan.id)}
						disabled={vlanDisable}
					>
						<Button
							fill
							disabled={vlanDisable}
							title={vlanDisable ? props.interface?.vlans?.join(', ') : undefined}
							text={selectedVlan}
							minimal
							rightIcon="double-caret-vertical"
						/>
					</VLANSelect>

					<Popover2
						content={<InterfaceChart switch={props.switch} interface={props.interface} />}
					>
						<Button icon="timeline-line-chart" style={{ width: 50, marginRight: 3 }} />
					</Popover2>
					<Popover2
						content={
							<div style={{ backgroundColor: 'white', padding: 20, minWidth: 250 }}>
								PoE Control
								<div style={{ marginTop: 10 }}>
									<ControlGroup>
										<Button
											intent={!props.interface.poeEnabled ? 'success' : 'danger'}
											fill
											large
											onClick={(e) => {
												context.rpc(
													'setInterfacePoeStatus.switch.network.oal.no',
													{
														switchId: props.switch.id, // sw id
														arguments: {
															active: !props.interface.poeEnabled,
															interface: props.interface.id, // ge-0/0/0
														},
													},
													(err: null | string, res: any) => {}
												)
											}}
										>
											Turn {!props.interface.poeEnabled ? 'on' : 'off'}
										</Button>
									</ControlGroup>
								</div>
							</div>
						}
					>
						<Button
							icon="reset"
							intent={props.interface.poeEnabled ? 'success' : 'danger'}
							style={{ width: 50 }}
						/>
					</Popover2>
				</div>
			</Col>
		</Row>
	)
}

export default NetSwitchInterfaceRender
