import React, { useCallback, useContext } from 'react'
import { HomeContainerProps } from '../../../Domain/Home/Container'
import Nav from '../../../Components/Navigation/Nav'
import { escapeRegExp, isActivePage } from '../../../Utils/React'
import AccessGroups from '../../../Utils/AccessGroups'
import { PageContext } from '../../../Utils/LoginContext'
import { Icon, IconName } from '@blueprintjs/core'
import { TabItem, TabMenu } from '../../../Components/TabMenu'

import companies from './RentmanCompanies'
import { Tooltip2 } from '@blueprintjs/popover2'
import { VisibleOnlyTo } from '../../../Utils/VisibleOnlyTo'

export interface ProjectAnalyticsContainerProps {
	company: string
	project?: true | string
}

export const ProjectAnalyticsContainer = (props: React.PropsWithChildren<ProjectAnalyticsContainerProps>) => {
	const context = useContext(PageContext)
	let groups = context.user?.groups || []

	const companyAccess = companies.filter((data) =>
		groups.find(
			(group) =>
				AccessGroups.DashboardAdmin.includes(group) ||
				groups.includes('DA-' + data.company.toUpperCase() + '-PROJECTMANAGER')
		)
	)

	return (
		<>
			<Nav
				subMenuItems={[
					...(companyAccess.length > 1
						? [
								{
									id: 'projectanalytics',
									title: 'Frontir',
									icon: 'search-around' as IconName,
									link: '/economy/projectanalytics',
									active: isActivePage(['/economy/projectanalytics']),
								},
						  ]
						: []),
					...companyAccess.map((data) => ({
						id: data.company,
						title: data.name,
						icon: 'office' as IconName,
						link: '/economy/projectanalytics/' + data.company + '/overview',
						active: isActivePage([new RegExp('^/economy/projectanalytics/' + escapeRegExp(data.company))]),
					})),
				]}
			>
				<TabMenu className="mb-4">
					<TabItem
						link={`/economy/projectanalytics/${props.company || ''}/overview`}
						icon={'chart'}
						active={isActivePage([
							new RegExp('^/economy/projectanalytics/' + escapeRegExp(props.company ?? '') + '/overview'),
						])}
					>
						Totaloversikt
					</TabItem>
					<VisibleOnlyTo groups={['DA-' + (props.company ?? 'ANY').toUpperCase() + '-PROJECTMANAGER']}>
						<TabItem
							link={`/economy/projectanalytics/${props.company || ''}`}
							icon={'th'}
							active={isActivePage([
								new RegExp('^/economy/projectanalytics/' + escapeRegExp(props.company) + '/?$'),
							])}
						>
							Kostnadsanalyse
						</TabItem>
						<TabItem
							link={`/economy/projectanalytics/${props.company || ''}/hours`}
							icon={'time'}
							active={isActivePage([
								new RegExp(
									'^/economy/projectanalytics/' + escapeRegExp(props.company ?? '') + '/hours'
								),
							])}
						>
							Timeanalyse
						</TabItem>
						<TabItem
							link={`/economy/projectanalytics/${props.company || ''}/config`}
							icon={'wrench'}
							active={isActivePage([
								new RegExp(
									'^/economy/projectanalytics/' + escapeRegExp(props.company ?? '') + '/config'
								),
							])}
						>
							Konfigurasjon
						</TabItem>
					</VisibleOnlyTo>
					{props.project ? (
						props.project === true ? (
							<TabItem link={window.location.pathname} icon={'info-sign'} active={true}>
								Prosjektinfo
							</TabItem>
						) : (
							<TabItem
								link={`/economy/projectanalytics/${props.company || ''}/config`}
								icon={'info-sign'}
								active={true}
								className={'d-none d-lg-block'}
							>
								{props.project}
							</TabItem>
						)
					) : null}
				</TabMenu>
				{props.children}
			</Nav>
		</>
	)
}

export const ProjectAnalyticsContainerNoSubmenu = (props: React.PropsWithChildren<HomeContainerProps>) => {
	const context = useContext(PageContext)
	let groups = context.user?.groups || []

	const companyAccess = companies.filter((data) =>
		groups.find(
			(group) =>
				AccessGroups.DashboardAdmin.includes(group) ||
				groups.includes('DA-' + data.company.toUpperCase() + '-PROJECTMANAGER') ||
				groups.includes('DA-' + data.company.toUpperCase() + '-EMPLOYED')
		)
	)

	return (
		<Nav
			subMenuItems={[
				{
					id: 'projectanalytics',
					title: 'Frontir',
					icon: 'search-around',
					link: '/economy/projectanalytics',
					active: isActivePage(['/economy/projectanalytics']),
				},
				...companyAccess.map((data) => ({
					id: data.company,
					title: data.name,
					icon: 'office' as IconName,
					link: '/economy/projectanalytics/' + data.company + '/overview',
					active: isActivePage([new RegExp('^/economy/projectanalytics/' + escapeRegExp(data.company))]),
				})),
			]}
		>
			{props.children}
		</Nav>
	)
}

export const useAToolTip = (get: (name: string) => string) =>
	useCallback(
		({ name }: { name: string }) => {
			const info = get(name)
			if (!info) {
				return null
			}

			return (
				<Tooltip2 popoverClassName="pa-tooltip" content={<span>{get(name)}</span>}>
					<Icon icon={'info-sign'} size={14} intent="primary" style={{ marginLeft: '4px' }} />
				</Tooltip2>
			)
		},
		[get]
	)

export const useSortTH: (
	get: (name: string) => string
) => [
	string,
	(prop: { name: string; tooltipName?: string } & React.HTMLProps<HTMLTableHeaderCellElement>) => JSX.Element
] = (get) => {
	const [sorting, setSorting] = React.useState('')
	const AToolTip = useAToolTip(get)

	return [
		sorting,
		useCallback(
			({
				name,
				key,
				tooltipName,
				children,
				...rest
			}: { name: string; tooltipName?: string } & React.HTMLProps<HTMLTableHeaderCellElement>) => {
				let classNames = [...(rest.className ?? '').split(/ +/), 'sortable']

				if (sorting === name) {
					classNames.push('sort-asc')
				} else if (sorting === '-' + name) {
					classNames.push('sort-desc')
				}

				const args = { ...rest, className: classNames.join(' ') }
				return (
					<th
						key={key}
						{...args}
						onClick={() => {
							setSorting(sorting === name ? '-' + name : name)
						}}
					>
						{children}
						{tooltipName ? <AToolTip name={'pa-tooltip-' + tooltipName} /> : null}
					</th>
				)
			},
			[sorting, AToolTip]
		),
	]
}
