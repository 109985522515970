import storage from 'local-storage-fallback'
import React from 'react'
import Configuration from './Configuration'
import { io } from 'socket.io-client'
import _ from 'lodash'

let alreadyMountedOnce = false

let SECRET_TOKEN: null | string = null

const refresh_token = () => {
	SECRET_TOKEN = storage.getItem('oaljwt')
}

refresh_token()

interface TESUserObject {
	c: string
	cn: string
	description: string
	givenName: string
	groups: string[]
	iat: number
	mail: string
	oalad: boolean
	sAMAccountName: string
	sn: string
	telephoneNumber: string
	whenChanged: string
	whenCreated: string
}

interface Subscription {
	key: string
	callback: any
}

interface RPC {
	key: string
	params: any
	callback: any
}

interface Get {
	key: string
	callback: any
}

interface Set {
	key: string
	value: any
	callback: any
}

let subscriptions: Subscription[] = []
let remotecalls: RPC[] = []
let gets: Get[] = []
let sets: Set[] = []

export interface TESContextProviderProps {}

export interface TESSubscription {
	id: string
	cb: (id: string) => void
}

export interface TESContextProviderState {
	authenticated: boolean
	authenticatedUser: any | null
	connected: boolean
	subscriptions: TESSubscription[]
	setState?: (state: TESContextProviderState) => void
	subscribe: (key: string, callback: (key: string, message: any) => void) => void
	unsubscribe: (key: string, callback: (key: string, message: any) => void) => void
	rpc: (key: string, params: any, callback: (err: any, data: any) => void) => void
	set: (key: string, value: any, callback: (err: any) => void) => void
	get: (key: string, callback: (err: any, res: any) => void) => void
}

const socket = io(Configuration.OAL_API_TES_URL, {
	transports: ['websocket'],
})

const initialState: () => TESContextProviderState = () => {
	return {
		authenticated: false,
		authenticatedUser: null,
		connected: false,
		subscriptions: [],
		setState: () => {},
		subscribe: () => {},
		unsubscribe: () => {},
		rpc: () => {},
		set: () => {},
		get: () => {},
	}
}

const TESContext = React.createContext(initialState())

class TESContextProvider extends React.Component<TESContextProviderProps, TESContextProviderState> {
	// eslint-disable-next-line

	private io: any
	private mounted: boolean = false

	constructor(props: TESContextProviderProps, state: TESContextProviderState) {
		super(props, state)
		this.state = initialState()

		socket.on('subscription_message', (key: string, message: any) => {
			if (this.mounted) {
				subscriptions
					.filter((sub) => sub.key === key)
					.forEach((sub) => {
						sub.callback(key, message)
					})
			}
		})

		socket.on('connect', () => {

			if (this.mounted) {
				this.setState({ authenticated: false, connected: true })
				this.connectRoutine()
			}
		})
	}

	connectRoutine() {
		if (!SECRET_TOKEN) {
			refresh_token()
		}
		if (!this.state.authenticated) {
			socket.emit('auth', SECRET_TOKEN, (res: TESUserObject | false) => {
				if (res !== false && res.iat !== undefined) {
					this.setState({ authenticated: true, connected: true, authenticatedUser: res })
					_.uniqBy(subscriptions, 'key').forEach((sub) => {
						socket.emit('subscribe', sub.key, this.handleSubscriptionMessage.bind(this))
					})
					this.runRPCs()
					this.runGets()
					this.runSets()
				} else {

				}
			})
		}
	}

	async componentDidMount() {
		this.mounted = true


		this.setState({
			connected: socket.connected,
			authenticated: alreadyMountedOnce,
		})

		if (!alreadyMountedOnce) {

			alreadyMountedOnce = true
			this.connectRoutine()
		}

		socket.on('disconnect', () => {
			this.setState({ authenticated: false, connected: false, authenticatedUser: null })
		})
	}

	async componentWillUnmount() {
		this.mounted = false
		//socket.off('subscription_message')
	}

	async handleSubscriptionMessage(key: string, data: any) {
		subscriptions
			.filter((sub) => sub.key === key)
			.forEach((sub) => {
				sub.callback(key, data)
			})
	}

	async runRPCs() {
		if (this.state.connected && this.state.authenticated) {
			while (remotecalls.length) {
				const rpc = remotecalls.shift()
				if (rpc) socket.emit('rpc', rpc.key, rpc.params, rpc.callback)
			}
		}
	}

	async runGets() {
		if (this.state.connected && this.state.authenticated) {
			while (gets.length) {
				const get = gets.shift()
				if (get) socket.emit('get', get.key, get.callback)
			}
		}
	}

	async runSets() {
		if (this.state.connected && this.state.authenticated) {
			while (remotecalls.length) {
				const set = sets.shift()
				if (set) socket.emit('set', set.key, set.value, set.callback)
			}
		}
	}

	async set(key: string, value: any, callback: (err: any) => void) {
		sets.push({
			key,
			value,
			callback,
		})
		this.runSets()
	}

	async get(key: string, callback: (err: any, res: any) => void) {
		gets.push({
			key,
			callback,
		})
		this.runGets()
	}

	async rpc(key: string, params: any, callback: (err: any, res: any) => void) {
		remotecalls.push({
			key,
			params,
			callback,
		})
		this.runRPCs()
	}

	async subscribe(key: string, callback: (key: string, message: any) => void) {
		subscriptions.push({
			key,
			callback,
		})

		let subCount = subscriptions.filter((sub) => sub.key === key).length

		if (subCount === 1) {
			socket.emit('subscribe', key, this.handleSubscriptionMessage.bind(this))
		}
	}

	async unsubscribe(key: string, callback: (key: string, message: any) => void) {
		let remainingSubscriptions = subscriptions.filter((sub) => sub.key === key).length
		if (remainingSubscriptions === 1) {
			socket.emit('unsubscribe', key)
		} else {
			//console.log('not sending unsub because remaining', remainingSubscriptions)
		}
		subscriptions = subscriptions.filter((sub) => !(sub.key === key && sub.callback === callback))
	}

	async componentDidUpdate(component: any, previousState: TESContextProviderState) {
		//console.log('componentDidUpdate', this.state)
	}

	_setState(state: Partial<TESContextProviderState>) {
		this.setState({ ...this.state, ...state })
	}

	render() {
		return (
			<TESContext.Provider
				value={{
					...this.state,
					setState: (...args) => this._setState(...args),
					subscribe: this.subscribe.bind(this),
					unsubscribe: this.unsubscribe.bind(this),
					rpc: this.rpc.bind(this),
					get: this.get.bind(this),
					set: this.set.bind(this),
				}}
			>
				{this.props.children}
			</TESContext.Provider>
		)
	}
}

export { TESContext, TESContextProvider }
