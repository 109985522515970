import { Classes as P2Classes, Popover2 } from '@blueprintjs/popover2'
import React from 'react'
import { NewAggregatedProject } from '../../../Domain/Projects/AggregatedProject'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'

export const AddProject = (props: {
	isOpen: boolean
	onOpen?: () => void
	onClose?: () => void
	onChange: (project: NewAggregatedProject) => void
	isSaving?: boolean
}) => {
	const defaultVisibility = ['DA-ANY-EMPLOYED']
	const [newProjectName, setNewProjectName] = React.useState<string>('')
	const [newVisibility, setNewVisibility] = React.useState<string[]>([...defaultVisibility])

	return (
		<Popover2
			usePortal={false}
			position="bottom"
			isOpen={props.isOpen}
			popoverClassName={P2Classes.POPOVER2_CONTENT_SIZING}
			onClose={() => {
				setNewProjectName('')
				setNewVisibility([...defaultVisibility])
				props.onClose && props.onClose()
			}}
			content={
				<div>
					<FormGroup label="Legg til nytt prosjekt" labelFor="project-name">
						<InputGroup
							id="project-name"
							placeholder="Prosjektnavn"
							fill
							autoFocus
							value={newProjectName}
							disabled={props.isSaving}
							onChange={(e) => setNewProjectName(e.target.value)}
						></InputGroup>
					</FormGroup>
					{/*
					Trenger vi egentlig dette? Isåfall hvordan gjør vi med rettigheter?

					<FormGroup label="Visibility" labelFor="project-visibility">
						<AccessEditor
							id="project-visibility"
							values={newVisibility}
							disabled={props.isSaving}
							onChange={(value) => setNewVisibility(value)}
						/>
					</FormGroup>
					*/}
					<Button
						disabled={props.isSaving}
						text="Legg til"
						intent="success"
						onClick={() =>
							props.onChange({
								name: newProjectName,
								visibility: newVisibility,
								configuration: {},
							})
						}
					/>
				</div>
			}
		>
			<Button icon="add" intent="primary" text="Nytt prosjekt" onClick={() => props.onOpen && props.onOpen()} />
		</Popover2>
	)
}
