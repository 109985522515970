/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios'
import Configuration from '../Utils/Configuration'
import storage from 'local-storage-fallback'
import React from 'react'
import placeholder from '../placeholder.jpg'
import { AggregatedProject, NewAggregatedProject } from '../Domain/Projects/AggregatedProject'
import { TripletexTimesheetResult } from '../Domain/Economy/Projects'
import { OALService } from '../Domain/Common/OAL'

const backend = axios.create({
	baseURL: Configuration.OAL_API_URL,
	timeout: 20000,
	headers: { oaljwt: storage.getItem('oaljwt') },
})

backend.interceptors.request.use((config) => {
	if (!config.url) {
		return config
	}

	const currentUrl = new URL(config.baseURL + config.url)
	// parse pathName to implement variables
	Object.entries(config.urlParams || {}).forEach(([k, v]) => {
		currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, encodeURIComponent(v))
	})

	const authPart = currentUrl.username && currentUrl.password ? `${currentUrl.username}:${currentUrl.password}` : ''
	return {
		...config,
		baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
		url: currentUrl.pathname,
	}
})

export type ApiCategory = {
	id: number
	name: string
	image: string
	fullimage: string
	description: string
	parent_id: number
}

const oalbackend = {
	get: async (url: string, config?: any) => {
		try {
			return await backend.get(url, config)
		} catch (e: any) {
			if (e.response?.status >= 400 && e.response?.status < 500) {
				throw new Error(
					(e.response.data?.message || e.response.data?.error || e.response.data || e.message) as string
				)
			}
			throw e
		}
	},
	post: async (url: string, data?: any, config?: any) => {
		try {
			return await backend.post(url, data, config)
		} catch (e: any) {
			if (e.response?.status >= 400 && e.response?.status < 500) {
				console.log(e.response.data)
				throw new Error(
					(e.response.data?.message || e.response.data?.error || e.response.data || e.message) as string
				)
			}
			throw e
		}
	},
	put: async (url: string, data?: any, config?: any) => {
		try {
			return await backend.put(url, data, config)
		} catch (e: any) {
			if (e.response?.status >= 400 && e.response?.status < 500) {
				throw new Error(
					(e.response.data?.message || e.response.data?.error || e.response.data || e.message) as string
				)
			}
			throw e
		}
	},
	delete: async (url: string, config?: any) => {
		try {
			return await backend.delete(url, config)
		} catch (e: any) {
			if (e.response?.status >= 400 && e.response?.status < 500) {
				throw new Error(
					(e.response.data?.message || e.response.data?.error || e.response.data || e.message) as string
				)
			}
			throw e
		}
	},
}

export async function getServices(): Promise<OALService[]> {
	let jsonResponse = await backend.get('/services', {
		params: {},
	})
	if (jsonResponse.status !== 200) {
		throw new Error(`Failed to get services: ${jsonResponse.status}`)
	}
	if (jsonResponse.data.error !== null) {
		throw new Error(`Failed to get services: ${jsonResponse.data.error}`)
	}

	return jsonResponse.data.data as OALService[]
}

export const updateOalJwt = (jwt: string) => {
	backend.defaults.headers.oaljwt = jwt
}

export const setPin = async (pin: string) => {
	const res = await backend.put('/tdac/pincode', { pin })
	return res.data
}

export const getProfile = async (username: string) => {
	const res = await backend.get(`/user/${username}/profile`)
	return res.data
}

export const getTempToken = async () => {
	const res = await backend.get('/auth/tempauthtoken')
	return res.data.token
}

export const getTemporaryPincode = async () => {
	const res = await backend.post('/tdac/generate_pincode')
	return res.data.result
}

export const openGate = async (gate: string) => {
	const res = await backend.post('/sesame/open?gate=' + gate, {}, { params: { gate } })
	return res.data.result
}

export const uploadProfileImage = async (file: File) => {
	const token = await getTempToken()

	const formData = new FormData()
	formData.append('profile', file)

	const res = await backend.post('/user/profileimage', formData, { params: { authtoken: token } })
	return res.data
}

export const getPresignedVideoUploadUrl = async (extension: string) => {
	const res = await backend.get(`/user/presignvideoupload`, { params: { extension } })
	return res.data as { url: string; finalURL: string }
}

export const getPresignedUploadUrl = async (filename: string, uid: string) => {
	const res = await backend.get(`/user/presignupload`, { params: { filename: filename, uid: uid } })
	return res.data as { url: string; finalURL: string }
}

export const ProfileImageThumbnail = (props: { username: string } & React.ImgHTMLAttributes<HTMLImageElement>) => {
	const { username, style, ...rest } = props
	const [loaded, setLoaded] = React.useState<boolean>(false)

	const imgStyle = loaded ? { ...(style || {}) } : { display: 'none' }
	return (
		<>
			<img
				src={`${Configuration.OAL_API_URL}/user/${username}/profileimage`}
				{...rest}
				style={imgStyle}
				onLoad={() => setLoaded(true)}
			/>
			{!loaded && <img src={placeholder} width={100} height={100} style={style || {}} {...rest} />}
		</>
	)
}

export const getAggregatedProjects = async (sharecode?: string): Promise<AggregatedProject[]> => {
	const res = await backend.get(`/projects/aggregate`, { params: { sharecode } })
	return (res.data?.result || []) as AggregatedProject[]
}

export const addAggregatedProject = async (project: NewAggregatedProject) => {
	const res = await backend.post(`/projects/aggregate`, project)
	return res.data
}

export const updateAggregatedProject = async (project: AggregatedProject) => {
	const res = await backend.put(`/projects/aggregate/` + project.id, project)
	return res.data
}

export const deleteAggregatedProject = async (projectId: number) => {
	const res = await backend.delete(`/projects/aggregate/` + projectId)
	return res.data
}

export const getProjectTimesheet = async (
	company: string,
	tripletexProjectId: number,
	projectId: number,
	sharecode?: string
): Promise<TripletexTimesheetResult> => {
	const res = await backend.get(`/projects/${company}/${tripletexProjectId}/timesheet`, {
		params: { sharecode, projectId },
	})
	return res.data?.result as TripletexTimesheetResult
}

export const postCategory = async (category: ApiCategory) => {
	if (category.id) {
		const res = await oalbackend.put(`/product/category/${category.id}`, category)
		return res.data.success as boolean
	} else {
		const res = await oalbackend.post(`/product/category`, category)
		return res.data.data as ApiCategory
	}
}

export const getProductCategories = async (): Promise<ApiCategory[]> => {
	const res = await oalbackend.get(`/product/categories`)
	return res.data as ApiCategory[]
}

export const addProductCategory = async (category: ApiCategory) => {
	void (await oalbackend.post(`/product/category`, category))
}

export const deleteCategory = async (id: number) => {
	void (await oalbackend.delete(`/product/category/${id}`))
}

export const getConfig = async (key: string): Promise<Config | undefined> => {
	try {
		const res = await backend.get(`/config/:key`, { urlParams: { key } })
		return res.data
	} catch (e: any) {
		if (e.response?.status === 404) {
			return undefined
		}
		throw e
	}
}

export const getConfigs = async (search: string): Promise<Config[]> => {
	const res = await backend.get(`/config/:search`, { urlParams: { search } })
	return res.data
}

export const setConfig = async (key: string, value: any) => {
	const res = await backend.put(`/config/:key`, { value }, { urlParams: { key } })
	return res.data
}

export type AccountingAccount = {
	name: string
	number: string
}

export const getAccountingAccounts = async (company: string): Promise<AccountingAccount[]> => {
	const res = await backend.get(`/accounting/accounts`, { params: { company } })
	return res.data
}

export type AccountingActivity = {
	id: number
	name: string
}
export const getAccountingActivities = async (company: string): Promise<AccountingActivity[]> => {
	const res = await backend.get(`/accounting/activities`, {
		params: {
			company,
			isProjectActivity: 'true',
			isInactive: 'false',
		},
	})
	return res.data
}

// [{"id":3944,"number":12103747,"name":"Kirkeflytt 2023 - Finnsnes til Bodø","account_manager_id":682,"already_invoiced":0,"color":"382cfa","conditions":"","created":"2023-09-20T07:59:06.000Z","creator_id":682,"current":0,"cust_contact_id":null,"customer_id":9038,"deposit_status":"no_deposit_paid","displayname":"Kirkeflytt 2023 - Finnsnes til Bodø","equipment_period_from":null,"equipment_period_to":null,"loc_contact":null,"location_id":null,"modified":"2023-09-25T09:59:58.000Z","planperiod_end":"2023-10-06T06:00:00.000Z","planperiod_start":"2023-09-30T22:00:00.000Z","power":0,"project_type_id":130,"purchasecosts":0,"reference":"","refundabledeposit":0,"tags":"magnus","updateHash":"13efd4e4569cb92c611506ab10bdf659","usageperiod_end":"2023-10-05T11:00:00.000Z","usageperiod_start":"2023-10-01T14:00:00.000Z","volume":0,"weight":0,"custom":null}]
export type RentmanProject = {
	id: number
	number: number
	name: string
	reference: string
	planperiod_start: string
	planperiod_end: string
	usageperiod_start: string
	usageperiod_end: string
	equipment_period_from: string
	equipment_period_to: string
	power: number
	volume: number
	weight: number
	purchasecosts: number
	refundabledeposit: number
	already_invoiced: number
	deposit_status: string
	tags: string
	conditions: string
	created: string
	creator_id: number
	customer_id: number
	cust_contact_id: number
	location_id: number
	loc_contact: number
	account_manager_id: number
	project_type_id: number
	current: number
	custom: string
	color: string
	updateHash: string
	modified: string
	displayname: string
}

export const getRentmanProjects = async (company: string, from: string, to: string): Promise<RentmanProject[]> => {
	company = company.replace(/[^\w]+/g, '')
	const res = await backend.get(`/rentman/${company}/projects`, { params: { from, to } })
	return res.data
}

type RentmanProjectType = {
	id: number
	name: string
	color: string
}

export const getRentmanProjectTypes = async (
	company: string,
	from: string,
	to: string
): Promise<RentmanProjectType[]> => {
	company = company.replace(/[^\w]+/g, '')
	const res = await backend.get(`/rentman/${company}/projecttypes`, { params: { from, to } })
	return res.data
}

export type RentmanProjectAnalytics = {
	id: number
	number: number
	displayname: string
	project_type_id: number
	customer_id: number
	customer_displayname: string
	usageperiod_start: string
	account_manager: string
	account_manager_phone: string
	salesrep: string
	salesrep_phone: string
	revenue: number
	subhire_costs: number
	transport: number
	external_crew_cost: number
	internal_crew_cost: number
	other_costs: number
}

export type RentmanProjectAnalyticsWithtStatus = RentmanProjectAnalytics & {
	status: 'confirmed' | 'proposed' | 'cancelled'
}

export const getRentmanProjectAnalytics = async (
	company: string,
	from: string,
	to: string,
	external: number[],
	internal: number[],
	project_id?: number,
	projectstate?: 'proposed' | 'cancelled' | 'confirmed'
): Promise<RentmanProjectAnalytics[]> => {
	company = company.replace(/[^\w]+/g, '')

	if (internal?.length < 1 || external?.length < 1 || !((from && to) || project_id)) {
		return []
	}

	const res = await backend.get(`/rentman/${company}/analytics/projects`, {
		params: {
			project_id,
			from,
			to,
			external: external.join(','),
			internal: internal.join(','),
			projectstate,
		},
	})
	return res.data.map((data: RentmanProjectAnalytics) => ({
		...data,
		account_manager_phone: (data.account_manager_phone || '').replace(/^\+47/, '').replace(/[^+0-9]/g, ''),
		salesrep_phone: (data.salesrep_phone || '').replace(/^\+47/, '').replace(/[^+0-9]/g, ''),
	}))
}

export type RentmanProjectAnalyticsHours = {
	id: number
	number: number
	project_type_id: number
	displayname: string
	customer_id: number
	usageperiod_start: string
	customer_displayname: string
	account_manager: string
	account_manager_phone: string
	salesrep: string
	salesrep_phone: string
	has_subprojects: number
	income: number
	sold_hours: number
	internal_crew_cost: number
}

export const getRentmanProjectHourAnalytics = async (
	company: string,
	from: string,
	to: string,
	internal: number[],
	project_id?: number
): Promise<RentmanProjectAnalyticsHours[]> => {
	company = company.replace(/[^\w]+/g, '')

	if (internal?.length < 1 || !((from && to) || project_id)) {
		return []
	}

	const res = await backend.get(`/rentman/${company}/hours/projects`, {
		params: {
			from,
			to,
			internal: internal.join(','),
			project_id,
		},
	})

	return res.data.map((data: RentmanProjectAnalyticsHours) => ({
		...data,
		account_manager_phone: (data.account_manager_phone || '').replace(/^\+47/, '').replace(/[^+0-9]/g, ''),
		salesrep_phone: (data.salesrep_phone || '').replace(/^\+47/, '').replace(/[^+0-9]/g, ''),
	}))
}

export type RentmanFolder = {
	id: number
	name: string
	path: string
	parent_id: number | null
	order: number
	itemtype: string
}

export const getRentmanFolders = async (company: string): Promise<RentmanFolder[]> => {
	company = company.replace(/[^\w]+/g, '')
	const res = await backend.get(`/rentman/${company}/folders`)
	return res.data
}

export type RentmanContact = {
	id: number
	created: string
	modified: string
	email: string | null
	phone: string | null
	creator_id: number
	displayname: string
	folder_id: number
	type: string
	ext_name_line: string
	firstname: string
	distance: number
	travel_time: number
	surfix: string
	surname: string
	longitude: number
	latitude: number
	code: string
	accounting_code: string
	name: string
	gender: string
	mailing_city: string
	mailing_street: string
	mailing_number: string
	mailing_postalcode: string
	mailing_state: string
	mailing_country: string
	visit_city: string
	visit_street: string
	visit_number: string
	visit_postalcode: string
	visit_state: string
	country: string
	invoice_city: string
	invoice_street: string
	invoice_number: string
	invoice_postalcode: string
	invoice_state: string
	invoice_country: string
	phone_1: string
	phone_2: string
	email_1: string
	email_2: string
	website: string
	VAT_code: string
	fiscal_code: string
	commerce_code: string
	purchase_number: string
	bic: string
	bank_account: string
	default_person_id: number | null
	admin_contactperson_id: number | null
	discount_crew: number
	discount_transport: number
	discount_rental: number
	discount_sale: number
	discount_total: number
	projectnote: string
	projectnote_title: string
	contact_warning: string
	discount_subrent: number
	tags: string
	custom: string
	updateHash: string
}

export const searchRentmanContacts = async (company: string, search: string): Promise<RentmanContact[]> => {
	company = company.replace(/[^\w]+/g, '')
	const res = await backend.get(`/rentman/${company}/contacts/search`, { params: { search }, timeout: 10000 })
	return res.data
}

export type Config = {
	key: string
	value: any
	updatedAt: Date
	createdAt: Date
	updatedBy: string
	createdBy: string
	description: string
}

export type SearchProjectProjectResult = {
	id: number
	name: string
	projectManager: { displayName: string }
	startDate: string
	customer: { name: string }
}
export type SearchProjectResult = {
	result: {
		fullResultSize: number
		from: number
		count: number
		versionDigest: string
		values: SearchProjectProjectResult[]
	}
}

export const searchProjects = async (
	company: string,
	query: string,
	count: number = 10
): Promise<SearchProjectResult> => {
	const res = await backend.get(`/projects/search`, { params: { company, query, count } })
	return res.data
}

export type TripletexLedgerPosting = {
	id: number
	date: string
	description: string
	account: number
	project_id: number
	project: string
	amount: number
	created_at: string
	updated_at: string
}

export const getTripletexAcccountPostings = async (
	company: string,
	from: string,
	to: string,
	projects: number[],
	refresh: boolean = false
) => {
	const res = await backend.post(
		`/accounting/getposting`,
		{ company, from, to, projects, refresh },
		{ timeout: 60000 }
	)
	return res.data as TripletexLedgerPosting[]
}

export type TripletexTimesheetEntry = {
	id: number
	date: string
	project_id: number
	project: string
	activity: number
	hours: number
	created_at: string
	updated_at: string
}

export const getTripletexTimesheet = async (
	company: string,
	from: string,
	to: string,
	projects: number[],
	refresh: boolean = false
) => {
	const res = await backend.post(
		`/accounting/gettimesheet`,
		{ company, from, to, projects, refresh },
		{ timeout: 60000 }
	)
	return res.data as TripletexTimesheetEntry[]
}

export type TripletexOrgCustomer = {
	id: number
	name: string
	organizationNumber: string
}

export const getTripletexCustomerFromOrgnr = async (
	company: string,
	orgnr: string
): Promise<TripletexOrgCustomer | null> => {
	const res = await oalbackend.get(`/accounting/${company}/customer/${orgnr}`)
	return res.data
}

export type RentmanSubProjectTP = {
	id: number
	displayname: string
	order: number
	project_total_price: number
}

export const getRentmanSubprojectPrices = async (company: string, project_id: number) => {
	const res = await oalbackend.get(`/rentman/${company}/subprojects/${project_id}`, { timeout: 60000 })
	return res.data as RentmanSubProjectTP[]
}

// Loyality API

export type LoyalityCustomer = {
	id?: number
	name: string
	orgnr: string
	agent_id: string
	tmlb_rentman_id: number
	tmlb_tripletex_id: number
	opti_rentman_id: number
	opti_tripletex_id: number
	bonus_level?: number
}

export type LoyalityCustomerWithUsers = LoyalityCustomer & { users: LoyalityUser[] }

export type LoyalityUser = {
	id?: number
	username: string
	name: string
	email: string
	metadata: Record<string, any>
}

export type LoyalityAudit = {
	id: number
	customer_id: number
	contract_id: number | null
	user_id: number | null
	description: string
	changed_by: string
	changed_at: Date
}

export const addLoyalityCustomer = async (customer: LoyalityCustomer) => {
	const res = await oalbackend.post(`/loyality/customer`, customer)
	return res.data
}

export const updateLoyalityCustomer = async ({ id, ...customer }: Partial<LoyalityCustomer> & { id: number }) => {
	if (!id) {
		throw new Error('Missing id')
	}
	const res = await oalbackend.put(`/loyality/customer/${id}`, customer)
	return res.data
}

type LoyalityCustomersWithOrWithoutUsers<T extends boolean> = T extends false
	? LoyalityCustomer[]
	: LoyalityCustomerWithUsers[]
type LoyalityCustomerWithOrWithoutUsers<T extends boolean> = T extends false
	? LoyalityCustomer
	: LoyalityCustomerWithUsers

export const getLoyalityCustomer = async <T extends boolean>(id: number, withUsers?: T) => {
	if (id === 0) {
		return null
	}
	const res = await oalbackend.get(`/loyality/customer/${id}`, { params: { withUsers: withUsers ? '1' : '' } })

	return res.data as LoyalityCustomerWithOrWithoutUsers<T>
}

export const getLoyalityCustomers = async <T extends boolean>(withUsers: T) => {
	const res = await oalbackend.get(`/loyality/customers`, { params: { withUsers: withUsers ? '1' : '' } })

	return res.data as LoyalityCustomersWithOrWithoutUsers<T>
}

export const getLoyalityUsersForCustomer = async (customer_id: number): Promise<LoyalityUser[]> => {
	const res = await oalbackend.get(`/loyality/customer/${customer_id}/users`)
	return res.data
}

export const findLoyalityUser = async (
	query: Partial<{ username: string; id: number; email: string }>
): Promise<LoyalityUser> => {
	const res = await oalbackend.get(`/loyality/user`, { params: query })
	return res.data
}

export const addLoyalityUser = async (user: LoyalityUser, customer_id: number) => {
	const res = await oalbackend.post(`/loyality/customer/${customer_id}/user`, user)
	return res.data
}

export const addLoyalityUserToCustomer = async (user_id: number, customer_id: number) => {
	const res = await oalbackend.put(`/loyality/customer/${customer_id}/user/${user_id}`)
	return res.data
}

export const removeLoyalityUserFromCustomer = async (user_id: number, customer_id: number) => {
	const res = await oalbackend.delete(`/loyality/customer/${customer_id}/user/${user_id}`)
	return res.data
}

export const findLoyalityAuditLog = async (
	query: Partial<{ user_id: number; contract_id: number; customer_id: number }>
): Promise<LoyalityAudit[]> => {
	const res = await oalbackend.get(`/loyality/audit`, { params: query })
	return res.data
}

export type LoyalityCustomerProject = {
	id: number
	number: number
	usageperiod_end: string
	project_total_price: number
}

export type LoyalityCustomerStatus = {
	loyalitylevel: number
	contractExpectation: number
	contracts: DBLoyalityContract[]
	yearsum: number
	offers: LoyalityCustomerProject[]
	minYear: number
	maxYear: number
	projects: {
		id: number
		number: number
		name: string
		usageperiod_end: string
		project_total_price: number
		is_confirmed: string
		company: string
		rentman_id?: number
		customer_id?: number
		year?: number
		bonus_level?: number
		bonus_level_override?: number
		bonus_level_override_by?: number
		confirmed_bonus?: number
		confirmed_by?: number
		received_by?: number
	}[]
}
export const getLoyalityStatusForCustomer = async (customer_id: number, year?: number) => {
	const res = await oalbackend.get(`/loyality/customer/${customer_id}/status`, { params: { year } })
	return res.data as LoyalityCustomerStatus
}

export type LoyalityLevel = {
	id: number
	level: number
	amount: number
	bonus_percent: number
	year: number
}

export const getLoyalityLevels = async (year?: string): Promise<LoyalityLevel[]> => {
	const res = await oalbackend.get(`/loyality/levels`, { params: { year } })
	return res.data
}

export type DBLoyalityContract = {
	id: number
	customer_id: number
	year: number
	expectation: number
	bonus_level: number
	active_from: string
	signed_by: string
	updated_at: string
	created_at: string
}

export const addLoyalityContract = async (
	customer_id: number,
	bonus_level: number,
	active_from: Date,
	signed_by: string
) => {
	const res = await oalbackend.post(`/loyality/customer/${customer_id}/contract`, {
		bonus_level,
		active_from,
		signed_by,
	})
	return res.data as DBLoyalityContract
}
