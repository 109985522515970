import { useEffect, useState } from 'react'
import { TicketCard } from '../../Components/Ticket/Card'

import Container from './Container'

export function TicketShow(props: { match: { params: { id: number | null } } }) {
	const [id, setId] = useState<number | null>(null)

	useEffect(() => {
		if (props.match.params.id !== id) {
			setId(props.match.params.id)
		}
	}, [id, props.match.params.id])

	return <Container>{id !== null ? <TicketCard id={id} onSave={() => {}} /> : <>Not found</>}</Container>
}

export default TicketShow
