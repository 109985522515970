import { H2 } from '@blueprintjs/core'
import { Col, Row } from 'reactstrap'
import Container from './Container'

export function ServicesIndex() {
	return (
		<Container>
			<Row>
				<Col xl={4}>
					<H2>x</H2>
				</Col>
			</Row>
		</Container>
	)
}

export default ServicesIndex
