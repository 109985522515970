import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

export class NdiContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'ndi',
						title: 'Skjermer',
						icon: 'desktop',
						link: '/ndi',
						active: isActivePage(['/ndi']),
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
