import Configuration from '../Utils/Configuration'
import storage from 'local-storage-fallback'
import axios from 'axios'

let backend = axios.create({
	baseURL: Configuration.OAL_API_URL + '/rentman',
	headers: { oaljwt: storage.getItem('oaljwt') },
	timeout: 5000,
})

export async function exportTripletexProject(company: string, number: string): Promise<any[]> {
	let data = await backend.post('/dashboard/tripletex/project', {
		company,
		number,
	})

	return data.data
}
