import Configuration from '../Utils/Configuration'
import storage from 'local-storage-fallback'
import axios from 'axios'
import { IInvoiceAdminInvoiceItem } from '../Domain/Invoice/Admin'

let backend = axios.create({
	baseURL: Configuration.OAL_API_URL + '/rentman',
	headers: { oaljwt: storage.getItem('oaljwt') },
	timeout: 180000,
})

export async function getAllInvoicedJobs(company: string): Promise<IInvoiceAdminInvoiceItem[]> {
	let data = await backend.get('/accounting/invoices', {
		params: {
			company,
		},
	})

	return data.data
}

export async function exportJobReference(id: string, reference: string, company: string): Promise<any> {
	let data = await backend.put('/accounting/invoices', {
		id,
		reference,
		company,
	})

	return data.data
}

export async function approveInvoice(reference: string, company: string): Promise<any> {
	let data = await backend.put('/accounting/invoices/approve', {
		reference,
		company,
	})

	return data.data
}
