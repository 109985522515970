import { ProjectAnalyticsContainerNoSubmenu } from './Container'
import { useContext } from 'react'
import { PageContext } from '../../../Utils/LoginContext'
import AccessGroups from '../../../Utils/AccessGroups'
import { useHistory } from 'react-router-dom'

import companies from './RentmanCompanies'

export const ProjectAnalytics = (props: {}) => {
	const context = useContext(PageContext)
	const history = useHistory()
	let groups = context.user?.groups || []

	const companyAccess = companies.filter((data) =>
		groups.find(
			(group) =>
				AccessGroups.DashboardAdmin.includes(group) ||
				groups.includes('DA-' + data.company.toUpperCase() + '-PROJECTMANAGER') ||
				groups.includes('DA-' + data.company.toUpperCase() + '-EMPLOYED')
		)
	)

	if (companyAccess.length === 1) {
		history.push('/economy/projectanalytics/' + companyAccess[0].company + '/overview')
		return null
	}

	return (
		<ProjectAnalyticsContainerNoSubmenu>
			<p>Velg firma i toppmenyen</p>
		</ProjectAnalyticsContainerNoSubmenu>
	)
}

export default ProjectAnalytics
