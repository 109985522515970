import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectAnalyticsContainer } from './Container'
import { Card, Callout, H3, FormGroup, HTMLSelect, Spinner, Dialog, Classes, H4, Button } from '@blueprintjs/core'
import { Col, Row } from 'reactstrap'
import { cloneDeep } from 'lodash'
import './Analytics.scss'
import {
	Config,
	RentmanProjectAnalytics,
	RentmanProjectAnalyticsWithtStatus,
	getConfigs,
	getRentmanProjectAnalytics,
	getRentmanProjectTypes,
} from '../../../Services/OalApi'
import { useApiData } from '../../../Utils/UseAPI'
import { useHistory } from 'react-router-dom'
import companies from './RentmanCompanies'

const companyDetails = companies.reduce((acc, company) => {
	acc[company.company] = {
		rentmanId: company.rentmanId,
		tripletexContext: company.tripletexContextId,
	}
	return acc
}, {} as { [key: string]: { rentmanId: string; tripletexContext: string } })

const numberFormatting = (num: number) => {
	return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

const getMonth = (idx: number) => {
	var objDate = new Date()
	objDate.setDate(1)
	objDate.setMonth(idx)

	var locale = 'no-nb',
		month = objDate.toLocaleString(locale, { month: 'long' })

	return month.substring(0, 1).toUpperCase() + month.substring(1)
}

type ProjectAnalyticsYear = {
	month0: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month1: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month2: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month3: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month4: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month5: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month6: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month7: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month8: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month9: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month10: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
	month11: {
		revenue: number
		other_costs: number
		subhire_costs: number
		transport: number
		internal_crew_cost: number
	}
}

type ProjectAnalyticsRow = {
	id: string
	label: string
	totals: { confirmed: number; proposed: number; cancelled: number }
	proposed: ProjectAnalyticsYear
	confirmed: ProjectAnalyticsYear
	cancelled: ProjectAnalyticsYear
}

const empty = {
	month0: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month1: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month2: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month3: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month4: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month5: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month6: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month7: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month8: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month9: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month10: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
	month11: { revenue: 0, other_costs: 0, subhire_costs: 0, transport: 0, internal_crew_cost: 0 },
}

const sumProjects = (
	id: string,
	label: string,
	project: RentmanProjectAnalyticsWithtStatus,
	sum: ProjectAnalyticsRow[]
): ProjectAnalyticsRow[] => {
	const month = new Date(project.usageperiod_start).getMonth()
	const output = [...(sum ?? [])]

	const oldRow = output.find((o) => o.id === id) as ProjectAnalyticsRow | undefined
	if (!oldRow) {
		const newRow: ProjectAnalyticsRow = {
			id,
			label,
			totals: { confirmed: 0, proposed: 0, cancelled: 0 },
			confirmed: cloneDeep(empty),
			proposed: cloneDeep(empty),
			cancelled: cloneDeep(empty),
		}
		// @ts-ignore
		newRow[project.status][`month${month}`] = {
			revenue: project.revenue,
			other_costs: project.other_costs,
			subhire_costs: project.subhire_costs,
			transport: project.transport,
			internal_crew_cost: project.internal_crew_cost,
		}
		newRow.totals[project.status] = project.revenue
		output.push(newRow)
	} else {
		// @ts-ignore
		const oldData = oldRow[project.status][`month${month}`]
		// @ts-ignore
		oldRow[project.status][`month${month}`] = {
			revenue: oldData.revenue + project.revenue,
			other_costs: oldData.other_costs + project.other_costs,
			subhire_costs: oldData.subhire_costs + project.subhire_costs,
			transport: oldData.transport + project.transport,
			internal_crew_cost: oldData.internal_crew_cost + project.internal_crew_cost,
		}
		oldRow.totals[project.status] += project.revenue
	}

	return output
}

const fields = {
	customer: { id: 'customer_id' as const, name: 'customer_displayname' as const, label: 'Kunde' },
	salesrep: { id: 'salesrep_phone' as const, name: 'salesrep' as const, label: 'Kunderådgiver' },
	projectmanager: {
		id: 'account_manager_phone' as const,
		name: 'account_manager' as const,
		label: 'Prosjektleder',
	},
	projecttype: {
		id: 'project_type_id' as const,
		name: 'customer_displayname' as const,
		label: 'Prosjekttype',
	},
} as const

export const SortField = ({
	name,
	children,
	style,
}: {
	name: string
	children: React.ReactNode
	style?: React.CSSProperties
}) => {
	const history = useHistory()
	const params = new URLSearchParams(window.location.search)
	const currentField = String(params.get('sort'))
	const currentDirection = String(params.get('d'))

	const url = new URL(window.location.href)
	url.searchParams.set('sort', name)
	url.searchParams.set('d', currentField === name && currentDirection === 'asc' ? 'desc' : 'asc')
	const newLocation = url.pathname + '?' + url.searchParams.toString()

	return (
		<th
			className={
				'sortable' + (currentField === name ? (currentDirection === 'asc' ? ' sort-asc' : ' sort-desc') : '')
			}
			style={style}
			onClick={() => history.push(newLocation)}
		>
			{children}
		</th>
	)
}

type DetailsData = {
	title: string
	field: string
	total: number
	selected: { state: 'proposed' | 'confirmed' | 'cancelled'; id: string; month: number }
	data: {
		number: number
		id: number
		name: string
		value: number
	}[]
}

export const ProjectAnalyticsOverview = ({ company }: { company: string }) => {
	const history = useHistory()
	const qs = new URLSearchParams(history.location.search)
	const year = qs.get('year') ? parseInt(qs.get('year') ?? '') : new Date().getFullYear()
	const [externalFolders, setExternalFolders] = useState<number[]>([])
	const [internalFolders, setInternalFolders] = useState<number[]>([])
	const [projectAnalyticsConfirmed, setProjectAnalyticsConfirmed] = useState<RentmanProjectAnalytics[] | null>(null)
	const [projectAnalyticsProposed, setProjectAnalyticsProposed] = useState<RentmanProjectAnalytics[] | null>(null)
	const [projectAnalyticsCancelled, setProjectAnalyticsCancelled] = useState<RentmanProjectAnalytics[] | null>(null)
	const [projectAnalyticsLoading, setProjectAnalyticsLoading] = useState(0)
	const [projectAnalyticsError, setProjectAnalyticsError] = useState('')
	const [showTypes, setShowTypes] = useState<('confirmed' | 'proposed' | 'cancelled')[]>([
		'proposed',
		'confirmed',
		'cancelled',
	])
	const [detailsData, setDetailsData] = useState<DetailsData | null>(null)

	const sort = useMemo(() => {
		console.log('Sort field changed?')
		const params = new URLSearchParams(history.location.search)
		const field = params.get('sort') ?? undefined
		const direction = params.get('d') ?? undefined
		return { field, direction }
	}, [history.location])

	const { data: projectTypes } = useApiData(getRentmanProjectTypes, company)

	const [filterBy, setFilterBy] = useState<string>(company === 'opti' ? 'projectmanager' : 'salesrep')

	const { data: configuration, error: configurationError } = useApiData<Config[]>(
		() => getConfigs(`pa-rentman-folder-*`),
		[]
	)

	const cfgGet = useCallback(
		(key: string) => {
			const config = configuration?.find((config) => config.key === key)
			return config?.value
		},
		[configuration]
	)

	useEffect(() => {
		setExternalFolders(cfgGet('pa-rentman-folder-external-' + company) ?? [])
		setInternalFolders(cfgGet('pa-rentman-folder-internal-' + company) ?? [])
	}, [configuration, cfgGet, company])

	useEffect(() => {
		console.log('Fetching project analytics')
		setProjectAnalyticsLoading((num) => num + 1)
		getRentmanProjectAnalytics(
			company,
			year + '-01-01',
			year + '-12-31',
			externalFolders,
			internalFolders,
			undefined,
			'confirmed'
		)
			.then((data) => {
				setProjectAnalyticsConfirmed(data?.length > 0 ? data : null)
				setProjectAnalyticsLoading((num) => num - 1)
			})
			.catch((e: any) => {
				setProjectAnalyticsError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
				setProjectAnalyticsLoading((num) => num - 1)
				console.error(e)
			})

		setProjectAnalyticsLoading((num) => num + 1)
		getRentmanProjectAnalytics(
			company,
			year + '-01-01',
			year + '-12-31',
			externalFolders,
			internalFolders,
			undefined,
			'proposed'
		)
			.then((data) => {
				setProjectAnalyticsProposed(data?.length > 0 ? data : null)
				setProjectAnalyticsLoading((num) => num - 1)
			})
			.catch((e: any) => {
				setProjectAnalyticsError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
				setProjectAnalyticsLoading((num) => num - 1)
				console.error(e)
			})

		setProjectAnalyticsLoading((num) => num + 1)
		getRentmanProjectAnalytics(
			company,
			year + '-01-01',
			year + '-12-31',
			externalFolders,
			internalFolders,
			undefined,
			'cancelled'
		)
			.then((data) => {
				setProjectAnalyticsCancelled(data?.length > 0 ? data : null)
				setProjectAnalyticsLoading((num) => num - 1)
			})
			.catch((e: any) => {
				setProjectAnalyticsError(
					e.response?.data?.message ? 'Data fetching error: ' + e.response?.data?.message : e.message
				)
				setProjectAnalyticsLoading((num) => num - 1)
				console.error(e)
			})
	}, [externalFolders, internalFolders, company, year])

	const filteredData = useMemo(() => {
		let filterData: ProjectAnalyticsRow[] = []
		if ((projectAnalyticsProposed === null && projectAnalyticsConfirmed === null) || filterBy === '') {
			return []
		}
		const field = fields[filterBy as keyof typeof fields]

		const projectAnalytics: RentmanProjectAnalyticsWithtStatus[] = [
			...(projectAnalyticsConfirmed !== null
				? projectAnalyticsConfirmed.map((a) => ({ ...a, status: 'confirmed' as const }))
				: []),
			...(projectAnalyticsProposed !== null
				? projectAnalyticsProposed.map((a) => ({ ...a, status: 'proposed' as const }))
				: []),
			...(projectAnalyticsCancelled !== null
				? projectAnalyticsCancelled.map((a) => ({ ...a, status: 'cancelled' as const }))
				: []),
		].filter((a) => a[field.id] !== null)

		projectAnalytics.forEach((project) => {
			filterData = sumProjects(String(project[field.id]), project[field.name], project, filterData)
		})

		return filterData
			.map((obj) => ({
				...obj,
				label:
					filterBy === 'projecttype'
						? projectTypes?.find((o) => String(o.id) === obj.id)?.name ?? 'N/A'
						: obj.label,
			}))
			.filter((a) => a.label)
			.sort((a, b) => {
				if (sort.field === undefined || sort.field === 'name') {
					const s =
						filterBy === 'projecttype'
							? a.label.localeCompare(b.label, 'nb-no', { numeric: true })
							: a.label
									.trim()
									.toLocaleLowerCase()
									.localeCompare(b.label.trim().toLocaleLowerCase(), 'nb-no')
					return sort.direction === 'asc' ? s : -s
				} else if (sort.field === 'total') {
					const s = a.totals[showTypes[0]] - b.totals[showTypes[0]]
					return sort.direction === 'asc' ? s : -s
				} else {
					let subfield: keyof ProjectAnalyticsRow = 'confirmed'
					if (!showTypes.includes('confirmed') && showTypes.includes('proposed')) {
						subfield = 'proposed'
					} else if (!showTypes.includes('confirmed') && !showTypes.includes('proposed')) {
						subfield = 'cancelled'
					}

					const s =
						a[subfield][sort.field as keyof ProjectAnalyticsYear].revenue -
						b[subfield][sort.field as keyof ProjectAnalyticsYear].revenue
					return sort.direction === 'asc' ? s : -s
				}
			})
	}, [
		projectAnalyticsConfirmed,
		projectAnalyticsProposed,
		projectAnalyticsCancelled,
		filterBy,
		projectTypes,
		showTypes,
		sort,
	])

	const yearList = useMemo(() => {
		const years = []
		for (let i = 2020; i <= new Date().getFullYear() + 1; i++) {
			years.push(i)
		}
		return years
	}, [])

	const showDetails = useCallback(
		(state: 'proposed' | 'confirmed' | 'cancelled', id: string, month: number) => {
			const list = {
				proposed: projectAnalyticsProposed,
				confirmed: projectAnalyticsConfirmed,
				cancelled: projectAnalyticsCancelled,
			}[state]
			if (list === null) {
				return
			}
			const data = list.filter(
				(a) =>
					String(a[fields[filterBy as keyof typeof fields].id]) === id &&
					new Date(a.usageperiod_start).getMonth() === month
			)
			const title =
				(filterBy === 'projecttype'
					? projectTypes?.find((pt) => String(pt.id) === id)?.name || 'N/A'
					: data[0]?.[fields[filterBy as keyof typeof fields].name] ?? 'N/A') +
				', ' +
				(state.substr(0, 1).toLocaleUpperCase() + state.substr(1)) +
				' i ' +
				getMonth(month) +
				`(${filterBy} og id ${id})`

			const total = data.reduce((sum, a) => (sum += a.revenue), 0)

			if (total === 0) {
				return
			}

			setDetailsData({
				title,
				field: fields[filterBy as keyof typeof fields].name,
				total,
				selected: { state, id, month },
				data: data.map((a) => ({
					number: a.number,
					id: a.id,
					name: a.displayname,
					value: a.revenue,
				})),
			})
		},
		[projectAnalyticsConfirmed, projectAnalyticsProposed, projectAnalyticsCancelled, filterBy, projectTypes]
	)

	const isSelected = useCallback(
		(type: 'proposed' | 'confirmed' | 'cancelled', id: string, month: number) => {
			if (detailsData === null) {
				return {}
			}

			if (
				detailsData.selected.id === id &&
				detailsData.selected.month === month &&
				detailsData.selected.state === type
			) {
				return { backgroundColor: 'rgba(0, 0, 0, 0.2)', border: '2px solid black' }
			}
		},
		[detailsData]
	)

	return (
		<ProjectAnalyticsContainer company={company}>
			<Card elevation={1} className={'mb-4'}>
				<H3>Filter</H3>
				<Row>
					<Col md={7}>
						<Row>
							<Col md={5}>
								<FormGroup label="Gruppering">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={filterBy}
										onChange={(e) => setFilterBy(e.currentTarget.value as any)}
									>
										{/* IKKE SPØR */}
										{Object.keys(fields).map((field) =>
											company === 'tmlb' || field !== 'salesrep' ? (
												<option key={fields[field as keyof typeof fields].id} value={field}>
													{fields[field as keyof typeof fields].label}
												</option>
											) : null
										)}
									</HTMLSelect>
								</FormGroup>
								<FormGroup label="Tall">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={showTypes.join(',')}
										onChange={(e) => setShowTypes(e.currentTarget.value.split(',') as any)}
									>
										<option value="confirmed,proposed,cancelled">- Alle -</option>
										<option value="confirmed">Kun bekreftede</option>
										<option value="proposed">Kun tilbud</option>
										<option value="cancelled">Kun avslag</option>
										<option value="confirmed,proposed">Bekreftede og tilbud</option>
									</HTMLSelect>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup label="Årstall">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={year}
										onChange={(e) => history.push(`?year=${e.currentTarget.value}`)}
									>
										{yearList.map((year) => (
											<option key={year} value={year}>
												{year}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
						</Row>
					</Col>
					<Col md={5}>
						{projectAnalyticsError ? <Callout intent={'danger'}>{projectAnalyticsError}</Callout> : null}
					</Col>
				</Row>
			</Card>
			<Dialog isOpen={detailsData !== null} style={{ width: '50vw' }} onClose={() => setDetailsData(null)}>
				<div className={Classes.DIALOG_HEADER}>
					<H4>{detailsData?.title}</H4>
				</div>
				<div className={Classes.DIALOG_BODY + ' overviewdetails'}>
					<table
						className={
							'bp3-html-table modifier overviewanalyticstable has-proposed has-confirmed has-cancelled'
						}
					>
						{projectAnalyticsLoading ? (
							<tr>
								<th>
									<Spinner size={128} />
								</th>
							</tr>
						) : (
							<>
								{detailsData?.data.map((a, ii) => (
									<tr key={ii} style={{ backgroundColor: 'transparent' }}>
										<td>
											<a
												href={`https://${companyDetails[company].rentmanId}.rentmanapp.com/#/projects/${a.id}/details`}
												target="_blank"
												rel="noreferrer"
											>
												{a.number}
											</a>
										</td>
										<td>{a.name}</td>
										<td style={{ textAlign: 'right' }}>{numberFormatting(a.value)}</td>
									</tr>
								))}
								<tr style={{ backgroundColor: 'transparent' }}>
									<td></td>
									<td></td>
									<td style={{ fontWeight: 600, textAlign: 'right' }}>
										{numberFormatting(detailsData?.total ?? 0)}
									</td>
								</tr>
							</>
						)}
					</table>
				</div>
				<div className={Classes.DIALOG_FOOTER} style={{ marginTop: '1em' }}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => setDetailsData(null)}>Lukk</Button>
					</div>
				</div>
			</Dialog>
			<Card elevation={1} className={'mb-4'} style={{ position: 'relative' }}>
				<H3>
					Oversikt over {year} per{' '}
					{(fields[filterBy as keyof typeof fields]?.label ?? '??').toLocaleLowerCase()}
				</H3>
				<div className="overviewlegend">
					{showTypes.includes('proposed') ? (
						<div>
							<span className="proposed"></span>
							<label>Tilbud</label>
						</div>
					) : null}
					{showTypes.includes('confirmed') ? (
						<div>
							<span className="confirmed"></span>
							<label>Bekreftet</label>
						</div>
					) : null}
					{showTypes.includes('cancelled') ? (
						<div>
							<span className="cancelled"></span>
							<label>Avslått</label>
						</div>
					) : null}
				</div>
				<table className="bp3-html-table modifier overviewanalyticstable has-proposed has-confirmed has-cancelled">
					<thead>
						<tr>
							<SortField name="name" style={{ textAlign: 'left' }}>
								{fields[filterBy as keyof typeof fields].label}
							</SortField>
							{[...Array(12).keys()].map((idx) => (
								<Fragment key={'id' + idx}>
									<SortField name={`month${idx}`} style={{ textAlign: 'left' }}>
										{getMonth(idx)}
									</SortField>
								</Fragment>
							))}
							<SortField name="total" style={{ textAlign: 'right' }}>
								Total
							</SortField>
						</tr>
					</thead>
					<tbody>
						{projectAnalyticsLoading ? (
							<tr>
								<th colSpan={14}>
									<Spinner size={128} />
								</th>
							</tr>
						) : filteredData?.length ? (
							filteredData.map((a, ii) =>
								showTypes.map((type, i) => (
									<tr key={type} className={`has-${type}`}>
										{i === 0 ? (
											<td
												rowSpan={showTypes.length}
												style={{ verticalAlign: 'middle' }}
												className={'title' + (ii % 2 === 0 ? 'even' : 'odd')}
											>
												{a.label}
											</td>
										) : null}
										{[...Array(12).keys()].map((idx) => {
											const month = `month${idx}` as keyof ProjectAnalyticsYear
											const current = a[type][month]

											return (
												<td
													key={idx}
													onClick={() => showDetails(type, a.id, idx)}
													className={'number' + (current.revenue > 0 ? ' clickable' : '')}
													style={isSelected(type, a.id, idx)}
												>
													{numberFormatting(current.revenue)}
												</td>
											)
										})}
										<td className="number">{numberFormatting(a.totals[type])}</td>
									</tr>
								))
							)
						) : null}
						{filteredData?.length
							? showTypes.map((type, i) => (
									<tr key={type} className={`has-${type} totalrow${i}`}>
										{i === 0 ? (
											<th
												rowSpan={showTypes.length}
												style={{ verticalAlign: 'middle' }}
												className={filteredData.length % 2 === 0 ? 'titleeven' : 'titleodd'}
											>
												<strong>Total</strong>
											</th>
										) : null}
										{[...Array(12).keys()].map((idx) => {
											const month = `month${idx}` as keyof ProjectAnalyticsYear
											const sum = filteredData.reduce(
												(sum, data) => (sum += data[type][month].revenue),
												0
											)

											return (
												<th key={idx} className="number">
													{numberFormatting(sum)}
												</th>
											)
										})}
										<th className="number">
											{numberFormatting(filteredData.reduce((l, a) => (l += a.totals[type]), 0))}
										</th>
									</tr>
							  ))
							: null}
					</tbody>
				</table>
				{configurationError ? (
					<Callout intent={'danger'} className="mt-3">
						{configurationError}
					</Callout>
				) : null}
			</Card>
		</ProjectAnalyticsContainer>
	)
}

export default ProjectAnalyticsOverview
