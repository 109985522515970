import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { getComments } from '../api'
import { Comment } from '../types'
import { useMe } from '../../Ticket/hooks/useMe'

//let backendComments: Comment[]

export function useComments(reference: string): {
	comments?: Comment[]
	setComments: Dispatch<SetStateAction<Comment[] | undefined>>
	loading: boolean
	setLoading: Dispatch<SetStateAction<boolean>>
	refreshComments: () => void
} {
	const [comments, setComments] = React.useState<Comment[]>()
	const [loading, setLoading] = React.useState(true)

	const { me } = useMe()

	useEffect(() => {
		const fetchComments = async () => {
			const newComments = await getComments(reference)
			setLoading(false)
			setComments(newComments)
		}

		fetchComments()

		const timer = setInterval(fetchComments, 5000)

		return () => clearTimeout(timer)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me])

	const refreshComments = async () => {
		const newComments = await getComments(reference)
		setComments(newComments)
	}

	return {
		comments,
		setComments,
		setLoading,
		loading,
		refreshComments,
	}
}
