import React from 'react'
import { TicketUpdateTitle } from './types'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { Icon } from '@blueprintjs/core'

export function RenderTicketUpdateTitle({ change }: { change: TicketUpdateTitle }) {
	return (
		<RenderTicketWrapper change={change}>
			<Icon icon="text-highlight" /> <span style={{ textDecoration: 'line-through', fontWeight: 300 }}>{change.payload[0]}</span>{' '}
			<Icon icon="arrow-right" /> <span style={{ fontWeight: 600 }}>{change.payload[1]}</span>
		</RenderTicketWrapper>
	)
}
