import { Colors } from '@blueprintjs/core'

interface OALGroupProps {
	id?: string
	style?: React.CSSProperties
	hideUnresolved?: boolean
}

interface Resolve {
	id: string
	label: string
	style: React.CSSProperties
}

const resolve: Resolve[] = [
	{
		id: 'DA-ANY-EMPLOYED',
		label: 'Ansatt',
		style: { backgroundColor: '#ff5b43', color: '#fff', fontWeight: 'bold' },
	},

	{
		id: 'BASIC',
		label: 'Basistilgang',
		style: { backgroundColor: Colors.GRAY5, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-ANY-FREELANCE',
		label: 'Freelance',
		style: { backgroundColor: '#006157', color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OAL-EMPLOYED',
		label: 'Ansatt i OAL',
		style: { backgroundColor: Colors.ORANGE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-DANTE-ADMIN',
		label: 'Dante',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-KVM-ADMIN',
		label: 'KVM',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPS-MCR',
		label: 'MCR',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPTI-ACCOUNTING',
		label: 'Optilux Økonomi',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMLB-ACCOUNTING',
		label: 'TMLB Økonomi',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMCV-ACCOUNTING',
		label: 'TMCV Økonomi',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OSS-ACCOUNTING',
		label: 'OSS Økonomi',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'ORG-PIXOTOPE',
		label: 'Pixotope',
		style: { backgroundColor: Colors.COBALT2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-DASHBOARD-ADMIN',
		label: 'Dashboard Admin',
		style: { backgroundColor: Colors.GREEN2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-DASHBOARD-CONTENT',
		label: 'Dashboard Content',
		style: { backgroundColor: Colors.GREEN2, color: '#fff', fontWeight: 'bold' },
	},

	{
		id: 'DA-OPTI-FREELANCE',
		label: 'Optilux Rentman',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMLB-FREELANCE',
		label: 'TMLB Rentman',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OSS-FREELANCE',
		label: 'OSS Rentman',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMCV-FREELANCE',
		label: 'TMCV Rentman',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TDAC-ADMIN',
		label: 'TDAC',
		style: { backgroundColor: Colors.RED3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'LOC-OAL-IT-INFRA',
		label: 'Apparatrom',
		style: { backgroundColor: Colors.GRAY1, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'OAL-INTERNAL-WIFI',
		label: 'OAL-AD WIFI',
		style: { backgroundColor: Colors.GRAY1, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'NET-VPN-OAL',
		label: 'VPN',
		style: { backgroundColor: Colors.ORANGE3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMLB-EMPLOYED',
		label: 'TMLB Ansatt',
		style: { backgroundColor: Colors.RED2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMCV-EMPLOYED',
		label: 'TMCV Ansatt',
		style: { backgroundColor: Colors.RED2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-TMCV-CHURCH',
		label: 'Kirkeprosjekt',
		style: { backgroundColor: Colors.BLUE2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OSS-EMPLOYED',
		label: 'OSS Ansatt',
		style: { backgroundColor: Colors.RED2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPTI-EMPLOYED',
		label: 'TMLB Ansatt',
		style: { backgroundColor: Colors.RED2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'ORG-NRK',
		label: 'NRK',
		style: { backgroundColor: Colors.BLUE3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-SAMLA-USER',
		label: 'Samla',
		style: { backgroundColor: Colors.BLUE3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-SAMLA-CEO',
		label: 'Samla CEOs',
		style: { backgroundColor: Colors.RED3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'NET-KVM-OSS',
		label: 'KVM SHP4',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-KVM-TMCV',
		label: 'KVM KG5',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OAL-ACCOUNTING',
		label: 'Økonomi',
		style: { backgroundColor: Colors.GREEN3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'LOC-OAL-COMMON',
		label: 'Dører',
		style: { backgroundColor: Colors.GREEN3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'OAL-AD-COMPUTER-ADMIN',
		label: 'Win Local Admin',
		style: { backgroundColor: Colors.VIOLET2, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPS-NET-RW',
		label: 'Kjernenettverk',
		style: { backgroundColor: Colors.ROSE3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPS-NET-RO',
		label: 'Kantswitcher',
		style: { backgroundColor: Colors.ROSE3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-NDI-RW',
		label: 'NDI Admin',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-OPS-K8S',
		label: 'Kubernetes Admin',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'DA-DASHBOARD-OAL-CONTACTS',
		label: 'Kontaktutlisting',
		style: { backgroundColor: Colors.COBALT3, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'NET-MIKROTIK-RACK-RW',
		label: 'Rack Router Admin',
		style: { backgroundColor: Colors.BLACK, color: '#fff', fontWeight: 'bold' },
	},
	{
		id: 'EVERYONE',
		label: 'For kunder / Public',
		style: { backgroundColor: Colors.BLUE4, color: '#fff', fontWeight: 'bold' },
	},
]

export const OALGroup = (props: OALGroupProps) => {
	let { id } = props

	if (!id) id = '?'

	let resolved = resolve.find((r) => r.id === id)
	if (resolved) {
		return (
			<>
				<span
					className="oal-group"
					title={id}
					style={{
						display: 'inline-block',
						padding: '1px 6px',
						borderRadius: 3,
						...resolved.style,
						...props.style,
					}}
				>
					{resolved.label}
				</span>{' '}
			</>
		)
	}

	if (props.hideUnresolved) return <></>

	return (
		<>
			<span
				className="oal-group"
				style={{
					display: 'inline-block',
					padding: '1px 6px',
					borderRadius: 3,
					color: '#aaa',
					backgroundColor: '#f0f0f0',
					...props.style,
				}}
			>
				{id}
			</span>{' '}
		</>
	)
}
