import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { PageContext } from '../../../Utils/LoginContext'

//let backendTicket: Ticket | undefined

export function useMe(): {
	me: string | undefined
	setMe: Dispatch<SetStateAction<string | undefined>>
} {
	const [me, setMe] = React.useState<string | undefined>()
	const pageContext = React.useContext(PageContext)

	useEffect(() => {
		if (pageContext.user) {
			setMe(pageContext.user.sAMAccountName)
		}
	}, [pageContext.user])

	return {
		me,
		setMe,
	}
}
