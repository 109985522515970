import { Button, Callout, ControlGroup, HTMLSelect, InputGroup, ResizeEntry } from '@blueprintjs/core'
import { DynamicContentModuleProps, DynamicContentModuleEditProps, UploadVideoButtonProps } from '../interface'
import YouTube from 'react-youtube'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { ResizeSensor2 } from '@blueprintjs/popover2'
import { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { getPresignedVideoUploadUrl } from '../../../Services/OalApi'

export const View = (props: DynamicContentModuleProps) => {
	const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 })
	const [resize, setResize] = useState<number>(0)

	const handleResize = (entries: ResizeEntry[]) => {
		const e = entries[0]
		setSize({ width: e.contentRect.width, height: (e.contentRect.width / 16) * 9 })
		if (resize > 5) {
			//window.location.reload();
		}
		setResize(resize + 1)
	}

	return (
		<div style={{ width: '100%', display: 'block' }}>
			<ResizeSensor2 onResize={(entries) => handleResize(entries)}>
				<div>
					{(!props.block.payload.type || props.block.payload.type === 'youtube') && (
						<YouTube
							opts={{
								width: size.width + '',
								height: size.height + '',
							}}
							videoId={props.block.payload.videoId}
						/>
					)}
					{props.block.payload.type === 'upload' && (
						<ReactPlayer url={props.block.payload.videoUrl} controls={true} />
					)}
				</div>
			</ResizeSensor2>
			{resize > 5 && (
				<Callout>
					På grunn av vår manglende tid i livet, må du reloade browseren for å få riktig størrelse på videoen.
					<p>
						<Button onClick={() => window.location.reload()} intent="primary" className="mt-2">
							Last på nytt nå
						</Button>
					</p>
				</Callout>
			)}
		</div>
	)
}

export const UploadVideoButton = (props: UploadVideoButtonProps) => {
	const { onUploaded, inputProps, text, ...rest } = props
	const fileRef = useRef<HTMLInputElement>(null)
	const [uploading, setUploading] = useState(false)

	const upload = async () => {
		const fileExtension = fileRef.current!.files![0].name.split('.').pop() || 'mp4'
		setUploading(true)
		const info = await getPresignedVideoUploadUrl(fileExtension)
		const file = fileRef.current?.files?.[0]
		if (file) {
			fetch(info.url, {
				method: 'PUT',
				body: file,
			})
				.then((res) => {
					setUploading(false)
					onUploaded(info.finalURL)
				})
				.catch(() => {
					setUploading(false)
					alert('Noe gikk galt under opplastingen av filen')
				})
		}
	}

	return (
		<>
			<input type="file" ref={fileRef} onChange={() => upload()} hidden {...props.inputProps} />
			<Button {...rest} loading={uploading} disabled={uploading} onClick={() => fileRef?.current?.click()}>
				{text}
			</Button>
		</>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	return (
		<div className="pt-3 pb-3">
			<HTMLSelect
				className="mb-3"
				value={props.block.payload.type}
				onChange={(e) => {
					props.onChange({
						...props.block,
						payload: {
							...props.block.payload,
							type: e.target.value,
						},
					})
				}}
			>
				<option value="youtube">Youtube</option>
				<option value="upload">Opplastet video</option>
			</HTMLSelect>
			{(!props.block.payload.type || props.block.payload.type === 'youtube') && (
				<InputGroup
					className="mb-3"
					placeholder="YouTube Video id"
					value={props.block.payload.videoId}
					onChange={(e) => {
						props.onChange({
							...props.block,
							payload: {
								...props.block.payload,
								videoId: e.target.value,
							},
						})
					}}
				/>
			)}
			{props.block.payload.type === 'upload' && (
				<ControlGroup>
					<InputGroup
						fill
						className="mb-3"
						placeholder="Videoadresse (mp4/m3u8)"
						value={props.block.payload.videoUrl}
						onChange={(e) => {
							props.onChange({
								...props.block,
								payload: {
									...props.block.payload,
									videoUrl: e.target.value,
								},
							})
						}}
					/>
					<UploadVideoButton
						intent="primary"
						className="mb-3"
						text="Last opp"
						onUploaded={(url) => {
							props.onChange({
								...props.block,
								payload: {
									...props.block.payload,
									videoUrl: url,
								},
							})
						}}
					/>
				</ControlGroup>
			)}
			<div>
				<View block={props.block} />
			</div>
		</div>
	)
}
