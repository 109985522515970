import React, { useCallback, useContext, useEffect } from 'react'
import { HomeContainerProps } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'
import { TESContext } from '../../Utils/TESContext'
import { Block } from '../../Components/DynamicContent'

function DocContainer(props: React.PropsWithChildren<HomeContainerProps>) {
	const TES = useContext(TESContext)
	const [blockNotifications, setBlockNotifications] = React.useState<number>(0)

	const checkBlockNotifications = useCallback(() => {
		TES.rpc('getMyBlocks.dashboard.oal.no', {}, (err: Error, res: Block[]) => {
			if (err) {
				console.error("Error getting user's blocks", err)
			} else {
				const newCount = res.filter((block) => block.markedAt).length;

				if (newCount !== blockNotifications) {
					setBlockNotifications(res.filter((block) => block.markedAt).length)
				}
			}
		})
	}, [TES, blockNotifications])

	useEffect(() => {
		checkBlockNotifications()
		const timer = setInterval(checkBlockNotifications, 60000)
		return () => clearInterval(timer)
	}, [checkBlockNotifications])

	return (
		<Nav
			subMenuItems={[
				{
					id: 'home-frontpage',
					title: 'Forsiden',
					icon: 'info-sign',
					link: '/',
					active: isActivePage(['/']),
					visibleOnlyTo: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				},
				{
					id: 'doc-selskap',
					title: 'Selskap',
					icon: 'data-lineage',
					link: '/doc/selskap',
					active: isActivePage([/\/doc\/selskap/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				},
				{
					id: 'doc-ansatt',
					title: 'Ansatt',
					icon: 'user',
					link: '/doc/ansatt',
					active: isActivePage([/\/doc\/ansatt/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED'],
				},
				{
					id: 'doc-infra',
					title: 'Infrastruktur & Tjenester',
					icon: 'applications',
					link: '/doc/infra',
					active: isActivePage([/\/doc\/infra/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				},
				{
					id: 'doc-lokasjon',
					title: 'Lokasjon',
					icon: 'office',
					link: '/doc/lokasjon',
					active: isActivePage([/\/doc\/lokasjon/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				},
				{
					id: 'doc-frilans',
					title: 'Frilans',
					icon: 'user',
					link: '/doc/frilans',
					active: isActivePage([/\/doc\/frilans/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				},
				{
					id: 'doc-my-blocks',
					title: 'Mine tekster',
					icon: 'document-share',
					link: '/doc/my-blocks',
					active: isActivePage([/\/doc\/my-blocks/]),
					visibleOnlyTo: ['DA-ANY-EMPLOYED'],
					notifications: blockNotifications,
				},
				{
					id: 'samla-alle',
					title: 'Samla',
					icon: 'user',
					link: '/doc/samla-alle',
					active: isActivePage([/\/doc\/samla-alle/]),
					visibleOnlyTo: ['DA-SAMLA-USER'],
				},
				{
					id: 'samla-ceo',
					title: 'Samla Daglig Leder',
					icon: 'user',
					link: '/doc/samla-ceo',
					active: isActivePage([/\/doc\/samla-ceo/]),
					visibleOnlyTo: ['DA-SAMLA-CEO'],
				}
			]}
		>
			{props.children}
		</Nav>
	)
}
export default DocContainer
