import { Button, Callout, H2, Icon, MenuItem, NonIdealState, Spinner } from '@blueprintjs/core'
import LoyalityContainer from '../Container'
import LoyalityBar, { LoyalityBarLine } from '../../../../Components/Loyality/LoyalityBar/Index'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useApiDataDependency } from '../../../../Utils/UseAPI'
import {
	LoyalityCustomerStatus,
	LoyalityCustomerWithUsers,
	LoyalityLevel,
	addLoyalityContract,
	getLoyalityCustomer,
	getLoyalityStatusForCustomer,
} from '../../../../Services/OalApi'
import { LoyalityContext } from '../../../../Components/Loyality/Context/LoyalityContext'
import { Col, Row } from 'reactstrap'
import { Select } from '@blueprintjs/select'
import { useHistory } from 'react-router-dom'
import { companies } from '../../ProjectAnalytics/RentmanCompanies'
import { AddContract } from '../../../../Components/Loyality/AddContract'

const formatter = new Intl.NumberFormat('nb-NO', {
	style: 'currency',
	currency: 'NOK',
	minimumFractionDigits: 0,
})

const companyDetails = companies.reduce((acc, company) => {
	acc[company.company] = {
		rentmanId: company.rentmanId,
		tripletexContext: company.tripletexContextId,
	}
	return acc
}, {} as { [key: string]: { rentmanId: string; tripletexContext: string } })

export default function LoyalityCustomer({ customer_id }: { customer_id: string }) {
	const {
		data: customer,
		loading: customerLoading,
		error: customerError,
	} = useApiDataDependency((cid: string) => getLoyalityCustomer(parseInt(cid ?? 0), true), [customer_id])

	const qs = new URLSearchParams(window.location.search)
	const year = qs.get('year') ? parseInt(qs.get('year') ?? '') : new Date().getFullYear()

	return (
		<LoyalityContainer customer_id={customer_id} customer={customer ?? undefined}>
			{customerError ? (
				<NonIdealState icon="warning-sign" title="En feil har oppstått!" description={customerError} />
			) : customerLoading ? (
				<Spinner size={64} />
			) : (
				<LoyalityCustomerView customer_id={customer_id} customer={customer} year={year} />
			)}
		</LoyalityContainer>
	)
}

export function LoyalityCustomerView({
	customer_id,
	customer,
	year,
}: {
	customer_id: string
	customer?: LoyalityCustomerWithUsers | null
	year: number
}) {
	const [showAddContract, setShowAddContract] = useState(false)
	const loyalityContext = useContext(LoyalityContext)
	const history = useHistory()

	const {
		data: status,
		loading: statusLoading,
		error: statusError,
		reload: reloadStatus,
	} = useApiDataDependency(
		(cid: string, y: number) => getLoyalityStatusForCustomer(parseInt(cid ?? 0), y),
		[customer_id, year]
	)
	const [sortedList, setSortedList] = useState<LoyalityCustomerStatus['projects']>([])

	const [values, setValues] = useState<LoyalityBarLine[]>([
		{ amount: 0, color: 'rgb(0, 105, 0)' },
		{ amount: 0, color: 'rgb(103, 197, 101)' },
		{ amount: 0, color: 'rgb(219, 171, 60)', name: 'Tilbud' },
	])

	useEffect(() => {
		if (status && !statusLoading && !statusError) {
			setSortedList(
				[...(status.projects ?? [])].sort((a, b) =>
					new Date(a.usageperiod_end) > new Date(b.usageperiod_end) ? -1 : 1
				)
			)
			const accepted =
				status.projects
					?.filter((proj) => proj.confirmed_by && !proj.received_by)
					.reduce((acc, project) => {
						acc += project.project_total_price
						return acc
					}, 0) ?? 0
			const paid =
				status.projects
					?.filter((proj) => proj.received_by)
					.reduce((acc, project) => {
						acc += project.project_total_price
						return acc
					}, 0) ?? 0
			const not_confirmed =
				status.projects
					?.filter((proj) => !proj.confirmed_by && !proj.received_by)
					.reduce((acc, project) => {
						acc += project.project_total_price
						return acc
					}, 0) ?? 0
			const offers =
				status.offers?.reduce((acc, offer) => {
					acc += offer.project_total_price
					return acc
				}, 0) ?? 0

			const newValues = [
				{ amount: paid, color: 'rgb(0, 105, 0)', name: 'Utbetalt' },
				{ amount: accepted, color: 'rgb(103, 197, 101)', name: 'Godkjent' },
				{ amount: not_confirmed, color: 'rgb(182, 188, 2)', name: 'Venter på godkjenning' },
				{ amount: offers, color: 'rgb(219, 171, 60)', name: 'Tilbud' },
			]

			setValues(newValues)
		}
	}, [status, statusLoading, statusError])

	const years = useMemo(() => {
		const newYears = []
		for (
			let i = status?.minYear ?? new Date().getFullYear();
			i <= (status?.maxYear ?? new Date().getFullYear() + 1);
			i++
		) {
			newYears.push({ label: i.toString(), value: i })
		}
		return newYears
	}, [status])

	return (
		<>
			<Callout intent="danger" className="mb-3">
				Denne siden er under utvikling.
				<br />
				<br />
				<strong>Bare gjør ting som er avtalt med Alpha</strong>
			</Callout>

			{showAddContract && customer ? (
				<AddContract
					customer={customer!}
					isOpen
					onClose={() => setShowAddContract(false)}
					onUpdate={async (data) => {
						console.log('New contract: ', data)
						try {
							const result = await addLoyalityContract(
								customer.id!,
								data.level,
								data.startDate,
								data.signedBy
							)
							if (typeof result?.id !== 'number') {
								console.log('Error, no contract id?', result)
								return 'En internfeil oppstod! 0x0001'
							}
							void reloadStatus()
							return true
						} catch (error) {
							console.error(error)
							return error instanceof Error ? error.message : String(error)
						}
					}}
				/>
			) : null}

			<div>
				<div style={{ textAlign: 'center' }}>
					<Select
						items={years}
						itemRenderer={(item, { handleClick, modifiers }) => (
							<MenuItem
								key={item.value}
								onClick={handleClick}
								className={modifiers.active ? 'bp3-active' : ''}
								label={item.label}
								active={item.value === year}
							/>
						)}
						onItemSelect={(item) => {
							history.push(`/economy/loyality/${customer_id}?year=${item.value}`)
						}}
						popoverProps={{ minimal: true }}
						filterable={false}
						activeItem={years.find((y) => y.value === year)}
					>
						<Button text={year} large rightIcon="double-caret-vertical" placeholder="Velg et år" />
					</Select>
				</div>
				<H2>
					{customer?.name} - {year}
				</H2>
				<LoyalityBar
					pointType="circle"
					values={values}
					contract={status?.contractExpectation}
					activeLevel={status?.loyalitylevel}
				/>

				{statusError ? (
					<Callout intent="danger" className="mt-3">
						Problemer med innhenting av data fra Rentman, {statusError}
					</Callout>
				) : null}
				{statusLoading ? <Spinner size={32} /> : null}

				<Row>
					<Col xs={12} md={6}>
						<h4>Aktive avtaler</h4>
						{status?.contracts?.length === 0 ? (
							<Callout intent="warning"><strong>OBS!</strong> Ingen aktive avtaler. Sett opp en avtale med knappen nedenfor.</Callout>
						) : (
							<table className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered bp3-interactive bp3-small">
								<thead>
									<tr>
										<th>Fra</th>
										<th>Nivå</th>
										<th>Omsetningsmål</th>
										<th>Bekreftet av</th>
									</tr>
								</thead>
								<tbody>
									{status?.contracts?.map((contract) => (
										<tr key={contract.id}>
											<td>{new Date(contract.active_from).toLocaleDateString('nb-NO')}</td>
											<td>{contract.bonus_level}</td>
											<td>{formatter.format(Math.round(contract.expectation))}</td>
											<td>{contract.signed_by}</td>
										</tr>
									))}
								</tbody>
							</table>
						)}
					</Col>
				</Row>
				<Button text="Legg til avtale" intent={status?.contracts?.length === 0 ? 'success' : undefined} className="mt-3" onClick={() => setShowAddContract(true)} />

				<h4>Prosjekter</h4>
				<Projects list={sortedList} admin levels={loyalityContext.globalLevels} year={year} />
			</div>
		</>
	)
}

function Projects({
	list,
	levels,
	admin,
	year,
}: {
	list: {
		id: number
		number: number
		name: string
		usageperiod_end: string
		project_total_price: number
		is_confirmed: string
		company: string
		rentman_id?: number | undefined
		customer_id?: number | undefined
		year?: number | undefined
		bonus_level?: number | undefined
		bonus_level_override?: number | undefined
		bonus_level_override_by?: number | undefined
		confirmed_bonus?: number | undefined
		confirmed_by?: number | undefined
		received_by?: number | undefined
	}[]
	admin: boolean
	levels: LoyalityLevel[]
	year: number
}) {
	const getBonus = useCallback(
		(level: number, price: number) => ((levels?.find((l) => l.level === level)?.bonus_percent ?? 0) / 100) * price,
		[levels]
	)
	const thisYear = new Date().getFullYear() === year

	return (
		<table className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered bp3-small">
			<thead>
				<tr>
					<th>Prosjektnr</th>
					<th>Prosjektnavn</th>
					<th>Omsetning</th>
					<th>Bonusnivå</th>
					<th>Estimert bonus</th>
					<th>Bekreftet bonus</th>
					<th>Mottatt</th>
				</tr>
			</thead>
			<tbody>
				{list.map((project) => {
					const showBonusConfig =
						thisYear && admin && project.bonus_level_override === null && project.confirmed_by === null
					const showReceivedButton = thisYear && admin && project.confirmed_by && !project.received_by
					return (
						<tr key={project.id}>
							<td>
								<a
									href={`https://${
										companyDetails[project.company].rentmanId
									}.rentmanapp.com/#/projects/${project.rentman_id}/details`}
								>
									{project.number} ({{ tmlb: 'Trippel-M', opti: 'Optilux' }[project.company]})
								</a>
							</td>
							<td>{project.name}</td>
							<td style={{ textAlign: 'right' }}>
								{formatter.format(Math.round(project.project_total_price))}
							</td>
							<td>
								{showBonusConfig ? (
									<>
										<select>
											{[...levels]
												.map((lev) => ({
													label: `Nivå ${lev.level} (${
														lev.bonus_percent
													}% = ${formatter.format(
														Math.round(getBonus(lev.level, project.project_total_price))
													)})${lev.level === project.bonus_level ? ' (*)' : ''}`,
													value: lev.level,
												}))
												.map((lev) => (
													<option
														key={lev.value}
														value={lev.value}
														selected={lev.value === project.bonus_level}
													>
														{lev.label}
													</option>
												))}
											{project.bonus_level}
										</select>
										<Button className="ml-2" text={`Godkjenn`} intent="success" small />
									</>
								) : (
									project.bonus_level
								)}
							</td>
							<td style={{ textAlign: 'right' }}>
								{formatter.format(
									Math.round(getBonus(project.bonus_level || 0, project.project_total_price))
								)}
							</td>
							<td style={{ textAlign: 'right' }}>
								<strong>
									{project.confirmed_bonus
										? formatter.format(Math.round(project.confirmed_bonus ?? 0))
										: '-'}
								</strong>
							</td>
							<td style={{ textAlign: 'center' }}>
								{showReceivedButton ? (
									<Button text="Mottatt" rightIcon="dollar" intent="primary" />
								) : project.received_by ? (
									<Icon intent="success" icon="tick" />
								) : (
									''
								)}
							</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}
