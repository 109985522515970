import { Button, Classes, Dialog, H4 } from "@blueprintjs/core"
import { ReactNode } from "react"

export const ConfirmModal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	message,
	okText,
	cancelText,
}: {
	isOpen: boolean
	onClose: () => void
	onConfirm: () => void
	title: string
	message: ReactNode
	okText?: string
	cancelText?: string
}) => {
	return (
		<Dialog isOpen={isOpen} onClose={() => onClose()}>
			<div className={Classes.DIALOG_HEADER}>
				<H4>{title}</H4>
			</div>
			<div className={Classes.DIALOG_BODY + ' loyalityform'}>
				{typeof message === 'string' ? <p>{message}</p> : message}
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => onConfirm()} intent="success">
						{okText ?? 'OK'}
					</Button>
					<Button onClick={() => onClose()} intent="danger">
						{cancelText ?? 'Avbryt'}
					</Button>
				</div>
			</div>
		</Dialog>
	)
}