// create function class react component
import React, { Fragment, useContext, useEffect } from 'react'
import { Button, H3, IconName, Intent, PopoverPosition } from '@blueprintjs/core'
import ContentEdit from './ContentEdit'
import ContentRead from './ContentRead'
import { InlineEdit } from '../InlineEdit'
import { Popover2 } from '@blueprintjs/popover2'
import { uuid4 } from '@sentry/utils'
import { TESContext } from '../../Utils/TESContext'
import { BlockType } from './module/registry'
import { useLocation } from 'react-router-dom'

interface DynamicContentProps {
	uri: string
	noTitle?: boolean
}

export interface PageV1 {
	id: string
	title: string
	tags: string[]
	version: number
	blocks: string[]
	accessRead: string[]
	accessWrite: string[]
	createdAt?: number
	createdBy?: string
	updatedAt?: number
	updatedBy?: string
	archivedAt?: number | null
	archivedBy?: string | null
	newestVersion?: number
}

export interface Block {
	id: string
	type: BlockType
	payload: any
	version: number
	pageId: string
	owner?: string
	accessRead: string[]
	accessWrite: string[]
	createdAt?: number
	createdBy?: string
	updatedAt?: number
	updatedBy?: string
	archivedAt?: number | null
	archivedBy?: string | null
	markedAt?: number | null
	markedBy?: string | null
	contributors?: string[]
}

export const DynamicContent = (props: DynamicContentProps) => {
	const [page, setPage] = React.useState<PageV1>()
	const [loading, setLoading] = React.useState<boolean>(true)
	const [blocks, setBlocks] = React.useState<(Block | null)[]>([])
	const [mode, setMode] = React.useState<'read' | 'edit'>('read')
	const [version, setVersion] = React.useState<number | undefined>(undefined)
	const TES = useContext(TESContext)
	const location = useLocation()

	const _blockUpdated = async (blocks: Block[]) => {
		if (blocks.length > 0 && page) {
			const version = (page.version || 0) + 1
			await _setBlocks(blocks.map((b) => ({ ...b, version })))
			await _setPage({ ...page, version })
		}
	}

	const _setBlocks = async (blocks: Block[]) => {
		if (page) {
			blocks.forEach((b) => _setBlock(b))

			const blockObjects = await Promise.all(
				blocks.map(async (block) => {
					let id = block.id
					try {
						return (await _getBlock(id)) as Block
					} catch (e) {
						return null
					}
				})
			)

			setBlocks(blockObjects)
		}
	}

	const _refreshBlocks = async (blocks: (Block | null)[]) => {
		if (page) {
			const blockObjects = await Promise.all(
				blocks.map(async (block) => {
					if (block === null) return null

					try {
						return (await _getBlock(block.id)) as Block
					} catch (e) {
						return null
					}
				})
			)

			setBlocks(blockObjects)
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const _getBlock = (id: string, customVersion?: number) => {
		const fetchVersion = customVersion || version || undefined

		return new Promise((resolve, reject) => {
			TES.rpc('getBlock.dashboard.oal.no', { id, version: fetchVersion }, (err, res) => {
				if (err) {
					if (fetchVersion && fetchVersion > 1) {
						return _getBlock(id, fetchVersion - 1)
							.then(resolve)
							.catch(reject)
					} else {
						reject(err)
					}
				} else {
					resolve(res)
				}
			})
		})
	}

	const _getPage = async () => {
		setLoading(true)
		let newestVersion: number | undefined

		if (version) {
			const page = await new Promise<PageV1 | undefined>((resolve, reject) => {
				TES.rpc('getPage.dashboard.oal.no', { id: props.uri }, (err, res) => {
					if (err) {
						return resolve(undefined)
					} else {
						return resolve(res)
					}
				})
			})
			if (page) {
				newestVersion = page.version
			}
		}

		TES.rpc('getPage.dashboard.oal.no', { id: props.uri, version: version }, (err, res) => {
			setLoading(false)
			if (err) {
				console.error(err)
			} else {
				setPage({ ...res, newestVersion })
			}
		})
	}

	useEffect(() => {
		const ver = new URLSearchParams(location.search).get('v')
		if (ver) {
			setVersion(parseInt(ver))
		} else {
			setVersion(undefined)
		}
	}, [location])

	useEffect(() => {
		setPage(undefined)
		setBlocks([])
		_getPage()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.uri, version])

	useEffect(() => {
		let blockObjects: (null | Block)[] = []
		if (page) {
			;(async () => {
				blockObjects = await Promise.all(
					page.blocks.map(async (id) => {
						try {
							return (await _getBlock(id)) as Block
						} catch (e) {
							return null
						}
					})
				)
				setBlocks(blockObjects)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	const _setPage = (newPage: PageV1): Promise<void> => {
		return new Promise((resolve, reject) => {
			return TES.rpc('setPage.dashboard.oal.no', { path: window.location.href, page: newPage }, (err, res) => {
				if (err) {
					console.error(err)
					reject(err)
				} else {
					setPage(newPage)
					resolve()
				}
			})
		})
	}

	const _setBlock = (newBlock: Block) => {
		TES.rpc('setBlock.dashboard.oal.no', { path: window.location.href, block: newBlock }, (err, res) => {
			if (err) {
				console.error(err)
			} else {
			}
		})
	}

	const addBlock = async (blockType: BlockType, bottom: boolean) => {
		if (page) {
			const newId = uuid4()
			page.version = (page.version || 0) + 1
			//const existingBlocks: string[] = page !== undefined && page.blocks ? page.blocks : []

			const newBlock: Block = {
				id: newId,
				type: blockType,
				payload: '',
				pageId: page.id,
				version: page.version || 1,
				accessRead: ['DA-ANY-EMPLOYED', 'DA-ANY-FREELANCE'],
				accessWrite: ['DA-ANY-EMPLOYED'],
			}
			if (!bottom) {
				const newBlocks = [newBlock, ...blocks]
					.filter((b) => b !== null)
					.map((b) => ({ ...b, version: page.version })) as Block[]
				_setBlocks(newBlocks)
				await _setPage({ ...page, blocks: [newId, ...page.blocks] })
			} else {
				const newBlocks = [...blocks, newBlock]
					.filter((b) => b !== null)
					.map((b) => ({ ...b, version: page.version })) as Block[]
				_setBlocks(newBlocks)
				await _setPage({ ...page, blocks: [...page.blocks, newId] })
			}
			_getPage()
		}
	}

	const CreateAPage = () => (
		<Button
			onClick={() => {
				const newPage = {
					id: props.uri,
					title: 'Untitled',
					version: 1,
					tags: [],
					blocks: [],
					accessRead: ['BASIC'],
					accessWrite: ['DA-ANY-EMPLOYED'],
				}
				_setPage(newPage)
			}}
		>
			Create page
		</Button>
	)

	let blockTypes: { type: BlockType; label: string; icon: IconName; intent: Intent }[] = [
		{
			type: 'md',
			label: 'Markdown',
			icon: 'align-left',
			intent: 'success',
		},
		{
			type: 'embedvideo',
			label: 'Embed video',
			icon: 'video',
			intent: 'success',
		},
		{
			type: 'files',
			label: 'Files',
			icon: 'document',
			intent: 'success',
		},
		{
			type: 'bigicons',
			label: 'Big icons',
			icon: 'grid-view',
			intent: 'success',
		},
		{
			type: 'product',
			label: 'Product',
			icon: 'box',
			intent: 'success',
		},
		{
			type: 'iframe',
			label: 'Iframe',
			icon: 'code-block',
			intent: 'success',
		},
	]

	let writeAccess = false

	if (TES?.authenticatedUser?.groups?.indexOf('DA-TDAC-ADMIN')) {
		blockTypes.push({
			type: 'tdacadmin',
			label: 'TDAC Engangskode',
			icon: 'credit-card',
			intent: 'warning',
		})
		blockTypes.push({
			type: 'tdacport',
			label: 'TDAC Portåpner',
			icon: 'known-vehicle',
			intent: 'warning',
		})
	}

	if (
		TES &&
		TES.authenticatedUser?.groups !== undefined &&
		TES.authenticatedUser?.groups !== null &&
		TES.authenticatedUser?.groups.indexOf('DA-ANY-EMPLOYED') !== -1
	)
		writeAccess = true

	return (
		<Fragment>
			{page ? (
				<Fragment>
					{writeAccess && !page.newestVersion && (
						<div style={{ float: 'right', marginLeft: 8, marginTop: 0 }} className="MobileHidden">
							{/* toggle between modes */}
							<Button
								onClick={() => setMode(mode === 'read' ? 'edit' : 'read')}
								intent={mode === 'read' ? 'success' : 'danger'}
								minimal
								text={mode === 'read' ? 'Endre' : 'Vis'}
								icon={mode === 'read' ? 'edit' : 'eye-open'}
							></Button>
						</div>
					)}

					{!props.noTitle && mode === 'read' && page && <H3>{page?.title || 'Untitled page'}</H3>}
					{!props.noTitle && mode === 'edit' && page && (
						<H3>
							<InlineEdit
								value={page?.title || 'Untitled page'}
								onChange={(value) => {
									_setPage({ ...page, version: (page.version || 0) + 1, title: value })
								}}
							/>
						</H3>
					)}

					{!props.noTitle && <div style={{ clear: 'both' }}></div>}
					{mode === 'read' && page && (
						<ContentRead blocks={blocks} page={page} onChanged={() => _refreshBlocks(blocks)} />
					)}
					{mode === 'edit' && page && (
						<>
							<div>
								<Popover2
									content={
										<div className="p-2">
											{blockTypes.map((btype) => (
												<Button
													key={btype.label}
													icon={btype.icon}
													minimal
													intent={btype.intent}
													onClick={() => addBlock(btype.type, false)}
												>
													{btype.label}
												</Button>
											))}
										</div>
									}
									position={PopoverPosition.RIGHT}
								>
									<Button icon="plus" className="mb-3" outlined>
										Legg til ny seksjon
									</Button>
								</Popover2>
							</div>
							<ContentEdit
								blocks={blocks}
								page={page}
								onBlocksChange={_blockUpdated}
								onPageChange={_setPage}
							/>
							<div>
								<Popover2
									content={
										<div className="p-2">
											{blockTypes.map((btype) => (
												<Button
													key={btype.label}
													icon={btype.icon}
													minimal
													intent={btype.intent}
													onClick={() => addBlock(btype.type, true)}
												>
													{btype.label}
												</Button>
											))}
										</div>
									}
									position={PopoverPosition.RIGHT}
								>
									<Button icon="plus" className="mb-3" outlined>
										Legg til ny seksjon
									</Button>
								</Popover2>
							</div>
						</>
					)}
					{!page && <p>Loading page..</p>}
				</Fragment>
			) : (
				<Fragment>
					{loading ? (
						<p>Loading page..</p>
					) : (
						<div>{writeAccess && !version ? <CreateAPage /> : <p>Denne siden finnes ikke.</p>}</div>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default DynamicContent
