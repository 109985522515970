import React, { Component } from 'react'
import { NetContainerProps, NetContainerState } from '../../Domain/Net/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

class NetContainer extends Component<NetContainerProps, NetContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'net-switch-interface',
						title: 'Porter',
						icon: 'flow-end',
						link: '/net/switch/interface',
						active: isActivePage(['/net/switch/interface']),
					},

					{
						id: 'net-dhcp',
						title: 'DHCP',
						icon: 'ip-address',
						link: '/net/dhcp',
						active: isActivePage(['/net/dhcp']),
					},
					{
						id: 'net-switch',
						title: 'Switch',
						icon: 'flow-review-branch',
						link: '/net/switch',
						active: isActivePage(['/net/switch']),
						visibleOnlyTo: ['DA-DASHBOARD-ADMIN'],
					},
					{
						id: 'net-firewall',
						title: 'Firewall',
						icon: 'flow-review-branch',
						link: '/net/firewall',
						active: isActivePage(['/net/firewall']),
						visibleOnlyTo: ['DA-DASHBOARD-ADMIN'],
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default NetContainer
