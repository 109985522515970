import { Card, Button } from '@blueprintjs/core'
import { uuid4 } from '@sentry/utils'
import React, { useState } from 'react'
import Editor from 'rich-markdown-editor'
import { ourTheme } from '../DynamicContent/module/MD'
import { CommentsCardProps } from './Card'
import { NewComment } from './types'

export type CommentsCardCreateProps = CommentsCardProps & {
	onCreate: (comment: NewComment) => void
}

export function CommentsCardCreate(props: CommentsCardCreateProps) {
	const [editValue, setEditValue] = React.useState('')
	const [loading, setLoading] = useState<boolean>(false)
	const [editorKey, setEditorKey] = useState<number>(1)

	return (
		<Card style={{ border: '0px solid #f0f0f0', marginTop: 0, marginLeft: 16 }}>
			<Editor
				key={editorKey}
				defaultValue={editValue || ''}
				placeholder={'Skriv en kommentar...'}
				onChange={async (val) => {
					setEditValue(val())
				}}
				autoFocus
				style={{
					paddingBottom: 10,
				}}
				theme={ourTheme}
			/>
			<div style={{}}>
				<Button
					intent="success"
					disabled={!!editValue.match(/^[\n ]*$/) || loading}
					onClick={async (e) => {
						setLoading(true)
						await props.onCreate({
							type: 'md',
							payload: editValue,
							id: uuid4(),
						})
						setLoading(false)
						setEditValue('')
						setImmediate(() => setEditorKey(editorKey + 1))
					}}
				>
					Send kommentar
				</Button>
			</div>
		</Card>
	)
}
