import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../../Domain/Home/Container'
import Nav from '../../../Components/Navigation/Nav'
import { isActivePage } from '../../../Utils/React'

export class GrynContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'gryn',
						title: 'Frontir',
						icon: 'search-around',
						link: '/economy/gryn',
						active: isActivePage(['/economy/gryn']),
					},
					{
						id: 'opti',
						title: 'Optilux',
						icon: 'office',
						link: '/economy/gryn/opti',
						active: isActivePage(['/economy/gryn/opti']),
					},
					{
						id: 'tmlb',
						title: 'Trippel-M',
						icon: 'office',
						link: '/economy/gryn/tmlb',
						active: isActivePage(['/economy/gryn/tmlb']),
					},
					{
						id: 'cv',
						title: 'Connected Venues',
						icon: 'office',
						link: '/economy/gryn/cv',
						active: isActivePage(['/economy/gryn/cv']),
					},
					{
						id: 'oss',
						title: 'Oslo Streamingsenter',
						icon: 'office',
						link: '/economy/gryn/oss',
						active: isActivePage(['/economy/gryn/oss']),
					},
					{
						id: 'nltv',
						title: 'Norway Live',
						icon: 'office',
						link: '/economy/gryn/nltv',
						active: isActivePage(['/economy/gryn/nltv']),
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
