import { GrynContainer } from './Container'
import DynamicContent from '../../../Components/DynamicContent'

export const EconomyProjects = (props: {}) => {
	const url = window.location.pathname.split('/')
	const uri = url[3]

	return (
		<GrynContainer>
			<DynamicContent uri={'gryn-' + (uri || 'frontir')} />
		</GrynContainer>
	)
}

export default EconomyProjects
