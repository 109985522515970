import React from 'react'
import { TicketUpdateTagRemoved } from './types'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { RenderTag } from './RenderTag'
import { Icon } from '@blueprintjs/core'

export function RenderTicketUpdateTagRemoved({ change }: { change: TicketUpdateTagRemoved }) {
	return (
		<RenderTicketWrapper change={change}>
			<Icon icon="minus" /> <RenderTag id={change.payload} />
		</RenderTicketWrapper>
	)
}
