import { Button, Card, Classes, Dialog, H4, Icon, NonIdealState, Spinner } from '@blueprintjs/core'
import DocContainer from './Container'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { TESContext } from '../../Utils/TESContext'
import { Block } from '../../Components/DynamicContent'
import { useHistory } from 'react-router-dom'
import { BlockTypeDescription, ShowBlockType } from '../../Components/DynamicContent/module/registry'
import Moment from 'react-moment'
import 'moment/locale/nb'
import { OALUser } from '../../Components/OAL/User'
import { Tooltip2 } from '@blueprintjs/popover2'

import './MyBlocks.scss'

const DAYS_THAT_IS_COSIDERED_OLD = 182

const pageUrls: Record<string, string> = {
	selskap: '/doc/selskap',
	ansatt: '/doc/ansatt',
	infra: '/doc/infra',
	lokasjon: '/doc/lokasjon',
	frilans: '/doc/frilans',
	frilansfakturering: '/economy/invoice',
	prosjektoversikt: '/economy/projects',
}

function urlForBlock(block: Block) {
	const part = block.pageId.split('-')
	let pagename = pageUrls[part[0]] ?? `/doc/${part[0]}`
	if (part[1] === 'root' || part[1] === undefined) {
		return `${pagename}#${block.id}`
	}
	console.log('part', part, `${pagename}/${part[1]}#${block.id}`)
	return `${pagename}/${part[1]}#${block.id}`
}

function previewBlock(block: Block) {
	let preview = ''

	try {
		switch (block.type) {
			case 'product':
				preview = block.payload?.name ?? 'Produkt'
				break
			case 'md':
				preview = block.payload?.replace(/([#\\]|:::(\S+)?|[^\w\s.,?!-_]+)/g, '') ?? 'Tekst'
				break
			case 'embedvideo':
			case 'iframe':
				preview = block.payload?.url ?? 'Video'
				break
			case 'files':
				preview = block.payload?.files?.map((file: any) => file.name).join(', ') ?? 'Filer'
				break
			case 'bigicons':
				if (!block.payload) {
					preview = 'Ikon-meny'
				} else {
					preview = block.payload?.map((icon: any) => icon.title).join(', ') ?? 'Ikon-meny'
				}
				break
			case 'tdacadmin':
				preview = 'TDAC Knapp'
				break
			case 'tdacport':
				preview = 'TDAC Portåpner'
				break
			default:
				preview = 'Ukjent'
				break
		}
	} catch (e) {
		console.error('Error getting preview for block', block, e)
		preview = BlockTypeDescription[block.type].description || 'Ukjent'
	}

	return preview.substr(0, 100)
}

type ConfirmDialogProps = {
	text: string
	onConfirm: () => void
}

export const DocumentMyBlocks = (props: {}) => {
	const history = useHistory()
	const TES = useContext(TESContext)
	const [blocks, setBlocks] = useState<Block[]>([])
	const [confirmDialogOpen, setConfirmDialogOpen] = useState<ConfirmDialogProps | null>(null)
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<string | null>(null)

	const updateBlocks = useCallback(() => {
		TES.rpc('getMyBlocks.dashboard.oal.no', {}, (err: Error, res: Block[]) => {
			if (err) {
				console.error("Error getting user's blocks", err)
				setError('Internfeil! Kunne ikke hente ditt innhold. Vennligst kontakt alpha@trippelm.no')
				setLoading(false)
			} else {
				const newBlocks = [...res]
				newBlocks.sort((a, b) => {
					if (a.markedAt && !b.markedAt) {
						return -1
					} else if (!a.markedAt && b.markedAt) {
						return 1
					} else if (a.updatedAt && b.updatedAt) {
						return a.updatedAt > b.updatedAt ? 1 : -1
					} else if (a.updatedAt) {
						return 1
					} else if (b.updatedAt) {
						return -1
					} else {
						return 0
					}
				})
				setBlocks(newBlocks)
				setLoading(false)
			}
		})
	}, [TES])

	useEffect(() => {
		updateBlocks()
	}, [updateBlocks])

	const BlockInfoLine = useMemo(
		() => (block: Block) => {
			const blockLink = (block: Block) => {
				history.push(urlForBlock(block))
			}

			const tickBlock = (e: React.MouseEvent, block: Block) => {
				const now = new Date()
				e.preventDefault()

				const updateBlock = () => {
					console.log('Marking block ' + block.id + ' as updated')

					TES.rpc(
						'markBlock.dashboard.oal.no',
						{ blockId: block.id, mark: false },
						(err: Error, res: Block) => {
							if (err) {
								console.error('Error marking block outdated', err)
							} else {
								updateBlocks()
							}
						}
					)
				}

				const blockDate = new Date(block.updatedAt ?? '1970-01-01 00:00:00')
				if (now.getTime() - blockDate.getTime() > 1000 * 3600 * 24 * 7) {
					setConfirmDialogOpen({
						text: 'Denne blokken har ikke vært oppdatert på en stund. Er du sikker på at du vil merke den som oppdatert?',
						onConfirm: () => updateBlock(),
					})
				} else {
					updateBlock()
				}
			}

			return (
				<>
					<div style={{ minWidth: 100, maxWidth: 100 }}>
						<ShowBlockType
							type={block.type}
							showIcon
							intent={block.markedAt ? 'warning' : 'primary'}
							style={{
								display: 'block',
								textAlign: 'center',
								padding: '15px 7px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
							}}
						/>
					</div>
					{block.markedAt ? (
						<div style={{}}>
							<Button
								icon="tick"
								intent="success"
								style={{ padding: 16 }}
								onClick={(e) => tickBlock(e, block)}
								text="Oppdatert"
							/>
						</div>
					) : null}
					<div
						style={{ padding: '6px 10px', minHeight: 35, width: '100%' }}
						className="my-block-link"
						onClick={() => blockLink(block)}
					>
						{block.markedAt ? (
							<div>
								<Icon icon="outdated" intent="warning" /> Merket utdatert av{' '}
								<OALUser id={block.markedBy ?? ''} />{' '}
								<Tooltip2
									content={new Date(block.markedAt ?? '').toLocaleDateString()}
									intent="primary"
								>
									<Moment fromNow>{block.updatedAt}</Moment>
								</Tooltip2>
								, sist endret{' '}
								<Tooltip2
									content={new Date(block.updatedAt ?? '').toLocaleDateString()}
									intent="primary"
								>
									<Moment fromNow>{block.updatedAt}</Moment>
								</Tooltip2>
							</div>
						) : (
							<div>
								<Icon icon="time" /> Sist endret{' '}
								<Tooltip2
									content={new Date(block.updatedAt ?? '').toLocaleDateString()}
									intent="primary"
								>
									<Moment fromNow>{block.updatedAt}</Moment>
								</Tooltip2>
							</div>
						)}
						<div
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontSize: 11,
								overflowWrap: 'anywhere',
								marginTop: 5,
							}}
						>
							{previewBlock(block)}
						</div>
					</div>
				</>
			)
		},
		[history, updateBlocks, TES]
	)

	const markedBlocks = useMemo(() => {
		return blocks.filter((block) => block.markedAt)
	}, [blocks])

	const oldBlocks = useMemo(() => {
		return blocks.filter(
			(block) =>
				!block.markedAt &&
				new Date().getTime() - new Date(block.updatedAt ?? '').getTime() >
					1000 * 3600 * 24 * DAYS_THAT_IS_COSIDERED_OLD
		)
	}, [blocks])

	const now = new Date()

	return (
		<DocContainer>
			<Dialog
				icon={'updated'}
				title="Marker som oppdatert"
				isOpen={confirmDialogOpen !== null}
				onClose={() => setConfirmDialogOpen(null)}
			>
				<div className={Classes.DIALOG_BODY}>
					<p className="bp3-running-text">{confirmDialogOpen?.text}</p>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => setConfirmDialogOpen(null)} intent="warning">
							Avbryt
						</Button>
						<Button
							onClick={() => {
								console.log('Confirming')
								confirmDialogOpen?.onConfirm()
								setConfirmDialogOpen(null)
							}}
							intent="success"
						>
							Ok
						</Button>
					</div>
				</div>
			</Dialog>
			{loading ? (
				<Spinner size={128} />
			) : error ? (
				<NonIdealState icon="error" title="Feil" description={error} className='nonideal-red' />
			) : (
				<>
					<Card elevation={2}>
						<div>
							{markedBlocks.length === 0 ? (
								<NonIdealState
									icon="tick"
									title="Hurra!"
									description="Du har ingen områder som er markert som utdatert av andre."
								/>
							) : (
								<>
									<H4>Merket som utdatert av andre</H4>

									{markedBlocks.map((block) => (
										<div
											key={block.id}
											style={{
												display: 'flex',
												backgroundColor: '#f9f9f9',
												marginBottom: 4,
											}}
											className={
												block.markedAt
													? 'my-block-marked'
													: new Date(block.updatedAt ?? '').getTime() <
													  now.getTime() - 1000 * 3600 * 24 * DAYS_THAT_IS_COSIDERED_OLD
													? 'my-block-old'
													: ''
											}
										>
											{BlockInfoLine(block)}
										</div>
									))}
								</>
							)}
						</div>
					</Card>
					<Card elevation={2} className="mt-4">
						{oldBlocks.length === 0 ? (
							<NonIdealState
								icon="tick"
								title="Ingen gamle områder!"
								description="Du har ingen områder som er over 6 måneder gamle."
							/>
						) : (
							<>
								<H4>Over 6 måneder gammel tekst</H4>
								<p className="mb-4">
									Her er en liste over områder du har ansvar for, som ikke har vært endret på over 6
									måneder. Kanskje det er verdt å sjekke om de trengs litt oppdatering, eller om de
									bør eies av noen andre?
								</p>
								<div>
									{oldBlocks.map((block) => (
										<div
											key={block.id}
											style={{
												display: 'flex',
												backgroundColor: '#f9f9f9',
												marginBottom: 4,
											}}
											className={
												block.markedAt
													? 'my-block-marked'
													: new Date(block.updatedAt ?? '').getTime() <
													  now.getTime() - 1000 * 3600 * 24 * 182
													? 'my-block-old'
													: ''
											}
										>
											{BlockInfoLine(block)}
										</div>
									))}
								</div>
							</>
						)}
					</Card>
				</>
			)}
		</DocContainer>
	)
}

export default DocumentMyBlocks
