import { DashboardComponent, PageContext } from '../Utils/LoginContext'

interface Props {
	groups: Array<string>
	else?: JSX.Element
}

interface State {}

export class VisibleOnlyTo extends DashboardComponent<Props, State> {
	static contextType = PageContext

	render() {
		if (this.context.user?.groups === null) return null

		let groups = this.context.user?.groups || []
		let visible = false

		if (this.props.groups !== undefined) {
			this.props.groups.forEach((test) => {
				if (groups.indexOf(test) > -1) visible = true
			})
		} else {
			visible = true
		}

		if (!visible) return this.props.else || null
		return this.props.children
	}
}
