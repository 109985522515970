import { H3, PopoverInteractionKind, Spinner } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { useEffect } from 'react'
import { useState } from 'react'
import { OALProfileCard } from '../../Domain/Common/OAL'
import { getProfile, ProfileImageThumbnail } from '../../Services/OalApi'
import { OALGroup } from './Group'

interface OALUserProps {
	id?: string
	defaultText?: string
	style?: React.CSSProperties
}

interface OALLazyUserProps {
	id: string
	text?: string
	children?: React.ReactNode
}

export const OALLazyUser = (props: OALLazyUserProps) => {
	let { id, text, children } = props

	const [loading, setLoading] = useState<boolean>(true)
	const [profile, setProfile] = useState<OALProfileCard | null>(null)
	const [noinfo, setNoinfo] = useState<boolean>(false)

	const loadData = async () => {
		try {
			const profile = await getProfile(id)
			setLoading(false)
			setProfile(profile)
		} catch (e) {
			// No info
			setLoading(false)
			setNoinfo(true)
		}
	}

	return (
		<span className="oal-user" style={{ fontWeight: 'bold' }}>
			<Popover2
				interactionKind={PopoverInteractionKind.CLICK}
				onOpening={() => loadData()}
				content={
					<div style={{ maxWidth: 500, padding: 20 }} className="p-3">
						{loading ? (
							<Spinner />
						) : noinfo ? (
							<div>{text || children}</div>
						) : (
							profile && (
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div>
										<ProfileImageThumbnail
											username={profile.sAMAccountName}
											style={{ borderRadius: '100%' }}
										/>
									</div>
									<div style={{ paddingLeft: 20, marginTop: 15, paddingRight: 20 }}>
										<H3 style={{ marginBottom: 5 }}>{profile?.cn}</H3>
										<p style={{ fontSize: '1.2em' }}>
											{profile?.sAMAccountName}
											<br />
											{profile?.mail}
										</p>
										{profile.groups.map((group) => (
											<OALGroup
												hideUnresolved
												key={group}
												id={group}
												style={{ marginBottom: 5 }}
											/>
										))}
									</div>
								</div>
							)
						)}
					</div>
				}
			>
				<span style={{ cursor: 'pointer' }}>{text || children}</span>
			</Popover2>
		</span>
	)
}

export const OALUser = (props: OALUserProps) => {
	let { id, defaultText } = props
	if (!id) id = '?'
	const [loading, setLoading] = useState<boolean>(true)
	const [profile, setProfile] = useState<OALProfileCard | null>(null)
	const [noProfile, setNoProfile] = useState<boolean>(false)

	useEffect(() => {
		;(async () => {
			if (id) {
				try {
					const profile = await getProfile(id)
					setLoading(false)
					setProfile(profile)
				} catch (e) {
					setLoading(false)
					setNoProfile(true)
				}
			}
		})()
	}, [id])

	return (
		<span className="oal-user" style={{ fontWeight: 'bold', ...props.style }}>
			{loading || (profile === null && !noProfile) ? (
				defaultText || (
					<div style={{ display: 'inline-block', textAlign: 'center', width: 80 }}>
						<Spinner size={10} />
					</div>
				)
			) : noProfile ? (
				<>{defaultText || id}</>
			) : (
				profile && (
					<Popover2
						interactionKind={PopoverInteractionKind.CLICK}
						content={
							<div style={{ maxWidth: 500 }} className="p-3">
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									<div>
										<ProfileImageThumbnail
											username={profile.sAMAccountName}
											style={{ borderRadius: '100%' }}
										/>
									</div>
									<div style={{ paddingLeft: 20, marginTop: 15, paddingRight: 20 }}>
										<H3 style={{ marginBottom: 5 }}>{profile?.cn}</H3>
										<p style={{ fontSize: '1.2em' }}>
											{profile?.sAMAccountName}
											<br />
											{profile?.mail}
										</p>
										{profile.groups.map((group) => (
											<OALGroup
												hideUnresolved
												key={group}
												id={group}
												style={{ marginBottom: 5 }}
											/>
										))}
									</div>
								</div>
							</div>
						}
					>
						<span style={{ cursor: 'pointer' }}>{profile?.cn}</span>
					</Popover2>
				)
			)}
		</span>
	)
}
