import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

class McrContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'ticket-frontpage',
						title: 'Mine',
						icon: 'person',
						link: '/ticket/mine',
						active: isActivePage(['/ticket/mine']),
					},
					{
						id: 'ticket-all',
						title: 'Alle',
						icon: 'people',
						link: '/ticket/all',
						active: isActivePage(['/ticket/all']),
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default McrContainer
