import { Button, Dialog, H4, Icon, FormGroup, InputGroup, Callout, ControlGroup, Tag } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { NavProps, NavState, SubMenuItem } from '../../Domain/Navigation/Nav'
import { PageContext, DashboardComponent } from '../../Utils/LoginContext'
import { MainMenu } from './MainMenu'
import { MenuItem } from './MenuItem'
import storage from 'local-storage-fallback'
import { ProfileImageThumbnail, setPin } from '../../Services/OalApi'
import './Nav.scss'
import { VisibleOnlyTo } from '../../Utils/VisibleOnlyTo'
import { OALGroup } from '../OAL/Group'
import { Popover2 } from '@blueprintjs/popover2'
import { SearchPopup } from '../../Components/SearchPopup'
import AccessGroups from '../../Utils/AccessGroups'
export const MenuSection = (props: any) => (
	<li>
		<span className="NavSection">{props.children}</span>
	</li>
)

class Navbar extends DashboardComponent<NavProps, NavState> {
	static contextType = PageContext

	state = {
		mobileMenuVisible: false,
		supportModalOpen: false,
		pincodeModalOpen: false,
		pincodeSaving: false,
		pincode: '',
		pincodeError: '',
		pincodeSuccess: false,
	}

	toggleMenu() {
		this.setState({ mobileMenuVisible: !this.state.mobileMenuVisible })
	}

	showSupportModal() {
		this.setState({
			supportModalOpen: true,
		})
	}

	showPincodeModal() {
		this.setState({
			pincodeModalOpen: true,
		})
	}

	onPinChange(e: any) {
		const value = String(e.target.value).replace(/[^0-9]/g, '')

		this.setState({
			pincode: value.substring(0, 4),
		})
	}

	async setPin(e: any) {
		e.preventDefault()

		if (this.state.pincode.length < 4) {
			return
		}

		this.setState({
			pincodeSaving: true,
			pincodeSuccess: false,
			pincodeError: '',
		})

		try {
			await setPin(this.state.pincode)
			this.setState({
				pincodeSaving: false,
				pincodeSuccess: true,
				pincode: '',
			})
		} catch (e: any) {
			this.setState({
				pincodeSaving: false,
				pincodeSuccess: false,
				pincodeError: e.message,
			})
		}
	}

	isStaging() {
		const url = new URL(window.location.href)
		if (url.hostname.match(/^(staging\.|127\.0\.0\.|localhost)/)) {
			return true
		}
		return false
	}

	render() {
		const context = this.context || {}
		const groups = context.user?.groups || []
		const user = context.user

		let titleBar = (
			<ul className="NavSubMenu" style={{}}>
				{this.props.subMenuItems?.map((element: SubMenuItem) => (
					<VisibleOnlyTo key={element.id} groups={element.visibleOnlyTo}>
						<MenuItem
							link={element.link}
							icon={element.icon}
							active={!!element.active}
							style={{ position: 'relative' }}
						>
							{element.title}
							{element.notifications ? (
								<Tag
									round
									intent="danger"
									style={{ position: 'absolute', top: '2px', right: '-14px', fontWeight: 'bolder' }}
								>
									{element.notifications}
								</Tag>
							) : null}
						</MenuItem>
					</VisibleOnlyTo>
				))}
			</ul>
		)

		return (
			<>
				<div className="wrapper">
					<nav className={this.state.mobileMenuVisible ? 'visible' : undefined}>
						<header>
							{(this.isStaging() && (
								<a
									style={{
										height: '49px',
										display: 'block',
										color: 'white',
										textAlign: 'center',
										paddingRight: 10,
										fontWeight: 800,
									}}
									href="https://dashboard.oal.no/"
									title="Link to the published site"
								>
									DEVELOPMENT VERSION
								</a>
							)) || (
								<>
									{' '}
									<a href="/" style={{ textAlign: 'center', display: 'block' }}>
										<VisibleOnlyTo
											groups={[...AccessGroups.EmployedAny, ...AccessGroups.OrgNRK]}
											else={
												<>
													<img
														alt="logo"
														style={{
															maxWidth: '80%',
															marginLeft: -15,
															marginBottom: -3,
															marginTop: 3,
														}}
														src="/samla.png"
													/>
												</>
											}
										>
											<img
												alt="logo"
												style={{
													maxWidth: '80%',
													marginLeft: -15,
													marginBottom: -3,
													marginTop: 3,
												}}
												src="/logo.png"
											/>
										</VisibleOnlyTo>
									</a>
								</>
							)}
						</header>

						<MainMenu />
					</nav>

					<main>
						<SearchPopup />
						<div className="topbar">
							<div className="flexcontent">
								<Button
									icon="list"
									className="BurgerButton"
									onClick={() => this.toggleMenu()}
									large
									style={{ marginRight: 10, marginTop: -30 }}
								></Button>
								<span className="MobileHidden">{titleBar}</span>
								<span className="OnlyMobileVisible pl-2">
									{(this.isStaging() && 0 && (
										<a
											style={{ color: 'white', fontWeight: 800 }}
											href="https://dashboard.oal.no/"
											title="Link to the published site"
										>
											DEVELOPMENT VERSION
										</a>
									)) || (
										<>
											<VisibleOnlyTo
												groups={[...AccessGroups.EmployedAny, ...AccessGroups.OrgNRK]}
											>
												<a href="/">
													<img
														alt="logo"
														style={{ height: 38, marginTop: -5 }}
														src="/logo.png"
													/>
												</a>
											</VisibleOnlyTo>
										</>
									)}
								</span>
								{context.authenticated && (
									<div className="topright">
										<Popover2
											usePortal={false}
											position="bottom-right"
											content={
												<div className="p-3">
													<div style={{ maxWidth: 500 }}>
														<ControlGroup fill>
															<Link className="bp3-button bp3-popover-dismiss" to="/user">
																<Icon icon="label" />
																<span className="bp3-button-text">Din profil</span>
															</Link>
															<Button
																className="bp3-popover-dismiss"
																onClick={() => this.showPincodeModal()}
																icon="credit-card"
															>
																Kortkode
															</Button>
															<Button
																className="bp3-popover-dismiss"
																onClick={() => this.showSupportModal()}
																icon="lifesaver"
															>
																Support
															</Button>
															<Button
																className="bp3-popover-dismiss"
																onClick={() => context.logout && context.logout()}
																icon="log-out"
																intent="danger"
															>
																Logg ut
															</Button>
														</ControlGroup>
														<div className="mt-3">
															<b>Om deg</b>
															<div>Kontonavn: {user?.sAMAccountName || <i>null</i>}</div>
															<div>Epost: {user?.mail || <i>null</i>}</div>
														</div>
														<div className="mt-3">
															<b>Dine tilgangsgrupper</b>
															<div style={{ fontSize: 11, marginTop: 3 }}>
																{groups.map((group) => (
																	<OALGroup
																		id={group}
																		key={group}
																		style={{ marginBottom: 2 }}
																	/>
																))}
															</div>
														</div>
													</div>
												</div>
											}
										>
											<ControlGroup>
												<Button
													style={{
														padding: 0,
														paddingLeft: 0,
														paddingTop: 0,
														paddingBottom: 0,
														paddingRight: 0,
													}}
													large
												>
													<ProfileImageThumbnail
														username={context.user?.sAMAccountName || ''}
														style={{
															width: 38,
															height: 38,
															display: 'inline',
															borderTopLeftRadius: 2,
															borderBottomLeftRadius: 2,
															margin: -4,
															marginTop: -1,
														}}
													/>
												</Button>
												<span className="Below1130">
													<Button large>{context.user?.cn}</Button>
												</span>
											</ControlGroup>
										</Popover2>
										<Dialog
											isOpen={this.state.supportModalOpen}
											style={{
												width: '60vw',
												wordBreak: 'break-word',
												lineHeight: '1.3',
											}}
											onClose={() =>
												this.setState({
													supportModalOpen: false,
												})
											}
										>
											<div style={{ padding: '1em' }}>
												<H4>Din hemmelige supportnøkkel</H4>
												<p>
													Pass på å <strong>aldri</strong> dele denne nøkkelen med noen andre
													enn Alpha-avdelingen hos Trippel-M
												</p>
												<div style={{ fontSize: 12, padding: 10, backgroundColor: 'white' }}>
													{storage.getItem('oaljwt')}
												</div>
											</div>
										</Dialog>
										<Dialog
											isOpen={this.state.pincodeModalOpen}
											style={{
												wordBreak: 'break-word',
												lineHeight: '1.3',
											}}
											className="pb-1"
											onClose={() =>
												this.setState({
													pincodeModalOpen: false,
													pincodeSaving: false,
													pincodeSuccess: false,
													pincodeError: '',
													pincode: '',
												})
											}
										>
											<div style={{ padding: '1em' }}>
												<H4>Sett PIN-kode</H4>
												<p>
													Når man bruker kort for å komme seg inn i lokalene våre, vil du av
													og til trenge å skrive PIN kode. Spesielt når du kommer utenfor
													arbeidstid eller når ingen andre er tilstede. Dette er for å
													deaktivere alarmen.
												</p>
												<form onSubmit={(e) => this.setPin(e)}>
													<FormGroup
														labelInfo="(4 siffer)"
														label="Ny PIN"
														labelFor="pin-input"
													>
														<InputGroup
															id="pin-input"
															placeholder="0000"
															value={this.state.pincode}
															autoFocus={true}
															disabled={this.state.pincodeSaving}
															onChange={(e) => this.onPinChange(e)}
														/>
													</FormGroup>
													{this.state.pincodeSuccess && (
														<Callout className="mb-3" intent="success">
															Ny PIN kode er satt
														</Callout>
													)}
													{this.state.pincodeError && (
														<Callout className="mb-3" intent="danger">
															{this.state.pincodeError}
														</Callout>
													)}

													<Button
														text="Sett pin"
														loading={this.state.pincodeSaving}
														intent="success"
														icon="key"
														type="submit"
														disabled={this.state.pincode.length !== 4}
														onClick={(e) => this.setPin(e)}
													/>
												</form>
											</div>
										</Dialog>
									</div>
								)}
							</div>
						</div>
						<div className="OnlyMobileVisible mb-4">{titleBar}</div>
						<div className="flexcontent desktopPad">{this.props.children}</div>
					</main>
				</div>
			</>
		)
	}
}

export default Navbar
