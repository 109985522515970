import Filters from './components/Filters'
import { Button, ControlGroup, H4, InputGroup, Intent, Tag } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import React, { useEffect } from 'react'
import { Filter, NDIReceiver, NDISource } from './components/api'
import { NdiContainer } from './Container'
import { List } from './components/List'
import { filterGen } from './components/filterGen'
import { TESContext } from '../../Utils/TESContext'

export const NdiIndex = () => {
	const TES = React.useContext(TESContext)

	const [sourceFilters, setSourceFilters] = React.useState<Filter[]>([])
	const [targetFilters, setTargetFilters] = React.useState<Filter[]>([])
	const [sourceAndOr, setSourceAndOr] = React.useState<'and' | 'or'>('and')
	const [targetAndOr, setTargetAndOr] = React.useState<'and' | 'or'>('and')
	const [sourceSearch, setSourceSearch] = React.useState('')
	const [targetSearch, setTargetSearch] = React.useState('')
	const [bridge, setBridge] = React.useState<boolean>(false)
	const [sourceSelected, setSourceSelected] = React.useState<string | null>(null)
	const [targetsSelected, setTargetsSelected] = React.useState<string[]>([])
	const [validCrosspoint, setValidCrosspoint] = React.useState<number>(0)
	const [sources, setSources] = React.useState<NDISource[]>([])
	const [targets, setTargets] = React.useState<NDIReceiver[]>([])
	const modifierTarget = (target: NDIReceiver) => {
		return {
			...target,
			longLabel: target.label + ' / ' + target.location,
			extraLabel: target.status?.source?.label || 'No source',
		}
	}

	const modifierSource = (source: NDISource) => {
		return {
			...source,
			id: source.url,
			longLabel: source.label,
			extraLabel: source.url,
		}
	}

	// Target search
	useEffect(() => {
		if (targetSearch.length) {
			let oldCleaned = targetFilters.filter((filter) => filter.label !== 'Text Search')
			const reg = targetSearch
				.replace(/[\\[\]()+*^$]/g, ' ')
				.replace(/([a-z])/gi, ' $1 ')
				.replace(/ +/g, '.*')

			setTargetFilters([
				{ label: 'Text Search', target: true, source: true, regex: new RegExp(reg, 'i') },
				...oldCleaned,
			])
		} else {
			setTargetFilters(targetFilters.filter((filter) => filter.label !== 'Text Search'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetSearch])

	// Source search
	useEffect(() => {
		if (sourceSearch.length) {
			let oldCleaned = sourceFilters.filter((filter) => filter.label !== 'Text Search')
			const reg = sourceSearch
				.replace(/[\\[\]()+*^$]/g, ' ')
				.replace(/([a-z])/gi, ' $1 ')
				.replace(/ +/g, '.*')
			console.log('reg:', reg)
			setSourceFilters([
				{
					label: 'Text Search',
					target: true,
					source: true,
					regex: new RegExp(reg, 'i'),
				},
				...oldCleaned,
			])
		} else {
			setSourceFilters(sourceFilters.filter((filter) => filter.label !== 'Text Search'))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceSearch])

	function getTargetCallback(error: string | null, payload: NDIReceiver[]) {
		if (!error) {
			let newTargets = payload.map(modifierTarget)

			// sort newTargets by label
			newTargets.sort((a, b) => {
				if (a.label < b.label) {
					return -1
				}
				if (a.label > b.label) {
					return 1
				}
				return 0
			})

			setTargets(newTargets)
		}
	}

	const getSourceCallback = (error: string | null, payload: NDISource[]) => {
		if (!error) {
			let newSources = payload.map(modifierSource)

			// sort newSources by longLabel
			newSources.sort((a, b) => {
				if (a.longLabel < b.longLabel) {
					return -1
				}
				if (a.longLabel > b.longLabel) {
					return 1
				}
				return 0
			})

			setSources(newSources)
		}
	}

	useEffect(() => {
		TES.rpc('getSources.ndi-discovery.oal.no', {}, getSourceCallback)
		TES.rpc('getReceivers.ndi-receiver.oal.no', {}, getTargetCallback)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Target Update Callback
	useEffect(() => {
		function updateTargetCallback(
			ns: string,
			pl: { operation: 'remove' | 'add' | 'update'; payload: NDIReceiver }
		) {
			const { operation, payload } = pl
			const ndiReceiver = payload

			if (operation === 'add') {
				console.log('ADD', ndiReceiver)
				setTargets((oldTargets) => [...oldTargets, ndiReceiver].map(modifierTarget))
			} else if (operation === 'remove') {
				setTargets((oldTargets) => oldTargets.filter((target) => target.id !== ndiReceiver.id))
			} else if (operation === 'update') {
				console.log('UPDATE', ndiReceiver)
				setTargets((oldTargets) =>
					oldTargets
						.map((target) => {
							if (target.id === ndiReceiver.id) {
								return ndiReceiver
							}
							return target
						})
						.map(modifierTarget)
				)
			}
		}

		function updateSourceCallback(ns: string, pl: { operation: 'remove' | 'add' | 'update'; payload: NDISource }) {
			console.log('updateSourceCallback', ns, pl)
			const { operation, payload } = pl
			const ndiSource = payload

			if (operation === 'add') {
				setSources((oldSources) => [...oldSources, ndiSource].map(modifierSource))
			} else if (operation === 'remove') {
				setSources((oldSources) => oldSources.filter((source) => source?.url !== ndiSource?.url))
			} else if (operation === 'update') {
				setSources((oldSources) =>
					oldSources
						.map((source) => {
							if (source?.url === ndiSource?.url) {
								return ndiSource
							}
							return source
						})
						.map(modifierSource)
				)
			}
		}

		if (TES.subscribe) {
			console.log('SUBSCRIBE')
			TES.subscribe('receiver.ndi-receiver.oal.no', updateTargetCallback)
			TES.subscribe('source.ndi-discovery.oal.no', updateSourceCallback)
		}
		return () => {
			console.log('UNSUBSCRIBE')
			TES.unsubscribe('receiver.ndi-receiver.oal.no', updateTargetCallback)
			TES.unsubscribe('source.ndi-discovery.oal.no', updateSourceCallback)
		}
	}, [TES, TES.subscribe, TES.unsubscribe])

	useEffect(() => {
		if (sourceSelected !== null && targetsSelected.length > 0) {
			if (validCrosspoint !== targetsSelected.length) setValidCrosspoint(targetsSelected.length)
		} else {
			if (validCrosspoint !== 0) setValidCrosspoint(0)
		}
	}, [sourceSelected, targetsSelected, validCrosspoint])

	const executeCrosspoint = () => {
		console.log('execute crosspoint')
		targetsSelected.forEach((target) => {
			let targetObj = targets.find((t) => t.id === target)
			let sourceObj = sources.find((s) => s.url === sourceSelected)
			console.log('targetObj', targetObj, target)
			console.log('sourceObj', sourceObj, sourceSelected)
			if (sourceObj && targetObj) {
				TES.rpc(
					'setSource.ndi-receiver.oal.no',
					{ id: targetObj.id, label: sourceObj.label, url: sourceObj.url },
					(err, res) => {
						if (err) {
							throw new Error(err)
						}
						if (res === 'ok') {
							setTargetsSelected((old) => old.filter((t) => t !== target))
						} else {
							console.log('Error?', res)
						}
					}
				)
			}
		})
	}

	return (
		<NdiContainer>
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1 }}>
					<H4>Sources</H4>
					<ControlGroup fill>
						<Button
							icon="cross"
							style={{ maxWidth: 10 }}
							intent={sourceFilters.length > 0 ? Intent.DANGER : undefined}
							disabled={!(sourceFilters.length > 0)}
							onClick={() => {
								setSourceFilters([])
								setSourceSearch('')
							}}
						/>

						<Popover2
							transitionDuration={100}
							content={
								<div style={{ padding: 10, maxWidth: 400 }}>
									<Filters
										possibilities={sources.map((s) => s.label)}
										choices={filterGen('source')}
										value={sourceFilters}
										onChange={(filters) => setSourceFilters(filters)}
										andOr={sourceAndOr}
										onAndOr={(andOr) => setSourceAndOr(andOr)}
										search={sourceSearch}
									/>
								</div>
							}
							placement="bottom"
						>
							<Button
								icon="filter"
								style={{ maxWidth: 10 }}
								intent={sourceFilters.length > 0 ? Intent.WARNING : undefined}
							/>
						</Popover2>
						<InputGroup
							placeholder="Search..."
							fill
							value={sourceSearch}
							onChange={(e) => setSourceSearch(e.target.value)}
							rightElement={
								<>
									{sourceFilters
										.filter((f) => f.label !== 'Text Search')
										.map((filter) => (
											<Tag
												key={filter.label}
												onRemove={() =>
													setSourceFilters(sourceFilters.filter((f) => f !== filter))
												}
											>
												{filter.label}
											</Tag>
										))}
								</>
							}
						/>
						<Tooltip2 content="Bridged Sources">
							<Button
								icon="key-option"
								style={{ maxWidth: 10 }}
								intent={bridge ? Intent.PRIMARY : undefined}
								onClick={() => {
									setBridge(!bridge)
								}}
							/>
						</Tooltip2>
					</ControlGroup>

					<List
						items={sources.filter(
							(s) => (bridge && s.label.match(/-BRIDGE \(/)) || (!bridge && !s.label.match(/-BRIDGE \(/))
						)}
						filters={sourceFilters}
						multiselect={false}
						selected={sourceSelected}
						onSelect={(sel) => setSourceSelected(sel)}
						andOr={sourceAndOr}
						renderEdit={(id: string) => <div style={{ padding: 20 }}>edit! {id}</div>}
					/>
				</div>
				<div style={{ flex: 0, width: 200, paddingTop: 21 }}>
					<ControlGroup vertical style={{ margin: 10 }}>
						<Button
							large
							icon="link"
							style={{ height: 100, marginBottom: 2 }}
							disabled={!validCrosspoint}
							intent={validCrosspoint ? Intent.DANGER : undefined}
							onClick={() => {
								executeCrosspoint()
							}}
						>
							Connect
						</Button>

						<Button
							disabled={!(sourceSelected || targetsSelected.length > 0 ? Intent.PRIMARY : undefined)}
							onClick={() => {
								setSourceSelected(null)
								setTargetsSelected([])
							}}
						>
							Clear selection
						</Button>
						<Button
							disabled={
								!(sourceSelected ||
								targetsSelected.length > 0 ||
								sourceFilters.length > 0 ||
								targetFilters.length > 0
									? Intent.WARNING
									: undefined)
							}
							onClick={() => {
								setSourceSelected(null)
								setTargetsSelected([])
								setSourceFilters([])
								setTargetFilters([])
								setSourceSearch('')
								setTargetSearch('')
							}}
						>
							Clear all
						</Button>
					</ControlGroup>
				</div>
				<div style={{ flex: 1 }}>
					<H4>Destinations</H4>
					<ControlGroup fill>
						<Button
							icon="cross"
							style={{ maxWidth: 10 }}
							intent={targetFilters.length > 0 ? Intent.DANGER : undefined}
							disabled={!(targetFilters.length > 0)}
							onClick={() => {
								setTargetFilters([])
								setTargetSearch('')
							}}
						/>

						<Popover2
							transitionDuration={100}
							content={
								<div style={{ padding: 10, maxWidth: 400 }}>
									<Filters
										possibilities={targets.map((s) => s.label)}
										choices={filterGen('target')}
										value={targetFilters}
										onChange={(filters) => setTargetFilters(filters)}
										andOr={targetAndOr}
										onAndOr={(andOr) => setTargetAndOr(andOr)}
										search={targetSearch}
									/>
								</div>
							}
							placement="bottom"
						>
							<Button
								icon="filter"
								style={{ maxWidth: 10 }}
								intent={targetFilters.length > 0 ? Intent.WARNING : undefined}
							/>
						</Popover2>
						<InputGroup
							placeholder="Search..."
							fill
							value={targetSearch}
							onChange={(e) => setTargetSearch(e.target.value)}
							rightElement={
								<>
									{targetFilters
										.filter((f) => f.label !== 'Text Search')
										.map((filter) => (
											<Tag
												key={filter.label}
												onRemove={() =>
													setTargetFilters(targetFilters.filter((f) => f !== filter))
												}
											>
												{filter.label}
											</Tag>
										))}
								</>
							}
						/>
					</ControlGroup>
					<List
						items={targets}
						filters={targetFilters}
						multiselect={true}
						selected={targetsSelected}
						onSelect={(sel) => setTargetsSelected(sel)}
						andOr={targetAndOr}
						renderEdit={(id: string) => {
							const target = targets.find((fid) => fid.id === id)
							return (
								<div style={{ padding: 20 }}>
									{ target && <Button
										fill
										intent="warning"
										onClick={() => {
											window.open('http://' + target.ip)
										}}
									>
										Open webpage
									</Button>}
									<pre>{JSON.stringify(target, null, 2)}</pre>
								</div>
							)
						}}
					/>
				</div>
			</div>
		</NdiContainer>
	)
}

export default NdiIndex
