import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { Block, PageV1 } from './index'
import { DynamicContentBlockOwnership } from './BlockOwnership'
import { TESContext } from '../../Utils/TESContext'
import { Button, Callout, ControlGroup, Dialog } from '@blueprintjs/core'
import ModuleRegistry from './module/registry'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
interface ContentReadProps {
	blocks: (Block | null)[]
	page: PageV1
	onChanged: () => void
}

export const ContentRead = (props: ContentReadProps) => {
	const TES = useContext(TESContext)
	const [modalQR, setModalQR] = React.useState<boolean>(false)
	const me_groups = TES.authenticatedUser.groups
	const me = TES.authenticatedUser.sAMAccountName
	const qrElement = useRef(null)
	const [firstRender, setFirstRender] = React.useState<boolean>(true)
	const blockRefs = useRef<{ [key: string]: HTMLDivElement }>({})

	// Scroll to anchor on first render
	useEffect(() => {
		if (firstRender && props.blocks?.length > 0) {
			setFirstRender(false)

			if (window.location.hash) {
				const id = window.location.hash.substring(1)

				if (blockRefs.current[id]) {
					blockRefs.current[id].scrollIntoView()
					blockRefs.current[id].className += ' flash-update'

					// Cleanup
					setTimeout(() => {
						blockRefs.current[id].className = blockRefs.current[id].className.replace(
							' flash-update',
							''
						)
					}, 1050)
				}
			}
		}
	}, [props.blocks, firstRender])

	const getVersionUrl = (version?: number) => {
		const url = new URL(window.location.href)
		const params = new URLSearchParams(url.search)
		if (version && version !== props.page.newestVersion) {
			params.set('v', version.toString())
		} else {
			params.delete('v')
		}
		url.search = params.toString()

		return url.pathname + url.search
	}

	const hasAccess = (block: Block): boolean => {
		let hasAccess = false
		block.accessRead.forEach((group) => {
			if (me_groups.includes(group)) {
				hasAccess = true
			}
		})
		if (hasAccess) return true

		if (block.createdBy === me) {
			return true
		}
		if (block.accessRead.length === 0) {
			return true
		}
		return false
	}

	// Tidenes mest hacky måte å gjøre det her på, men vi får heller snuble på det en annen gang ;))))
	// Fant den i et eksempel på intervevet. Den som føler seg støtt først får fikse det på riktig måte! W out. kl 01:43.

	const downloadQRCode = () => {
		const elem = document.getElementById('qrCodeEl') as any
		if (elem) {
			const qrCodeURL = elem.toDataURL('image/png').replace('image/png', 'image/octet-stream')
			let aEl = document.createElement('a')
			aEl.href = qrCodeURL
			aEl.download = props.page.id + '-qr.png'
			document.body.appendChild(aEl)
			aEl.click()
			document.body.removeChild(aEl)
		}
	}

	return (
		<div>
			<Dialog isOpen={modalQR} onClose={() => setModalQR(false)}>
				<div style={{ padding: '20px 20px', width: '100%', textAlign: 'center' }}>
					<p>
						<strong>Her er en QR-kode som linker til denne siden</strong>
					</p>
					<p>
						Den kan f.eks printes ut som klistremerke og settes på flighter, steder, rom, utstyr, etc.
						Hvordan du bruker den, bestemmer du selv. Del gjerne med oss andre om du gjør noe lurt med den!
					</p>
				</div>

				<div style={{ margin: '0px auto 20px auto', borderRadius: 10, padding: 20, backgroundColor: 'white' }}>
					<QRCode
						id="qrCodeEl"
						value={'https://dashboard.oal.no' + getVersionUrl()}
						size={256}
						ref={qrElement}
					/>
				</div>
				<div style={{ padding: '0px 20px', width: '100%' }}>
					<Button
						fill
						intent="success"
						icon="download"
						onClick={(e) => {
							e.preventDefault()
							setModalQR(false)
							downloadQRCode()
						}}
					>
						Last ned QR som PNG
					</Button>
				</div>
			</Dialog>
			{props.blocks.map((block, index) => (
				<Fragment key={block ? block.id : index}>
					{block !== null && hasAccess(block) ? (
						<>
							{ModuleRegistry.view[block.type] !== undefined ? (
								<div ref={(el) => el && (blockRefs.current[block.id] = el)}>
									{((block) => {
										const View = ModuleRegistry.view[block.type]
										return <View block={block} />
									})(block)}

									<DynamicContentBlockOwnership
										block={block}
										page={props.page}
										public={block.type === 'product' && block.payload?.active}
										style={{ color: '#aaa', marginTop: 10, marginBottom: 20 }}
										onChanged={props.onChanged}
									/>
								</div>
							) : (
								<div>unsupported block {block.type}</div>
							)}
						</>
					) : (
						<Callout
							icon="lock"
							className="mb-3 mt-1"
							style={{ maxWidth: 'calc(100% - 100px)', opacity: 0.3 }}
						>
							Her finnes det innhold du ikke har tilgang til å vise. Freelance, ansatte og forskjellige
							brukergrupper har tilgang til forskjellig innhold. Om du mener du burde hatt tilgang, send
							en epost til alpha@trippelm.no
						</Callout>
					)}
				</Fragment>
			))}
			<div className="float-right">
				<ControlGroup>
					{me_groups.includes('DA-DASHBOARD-ADMIN') && props.page.version > 1 && (
						<Link to={getVersionUrl(props.page.version - 1)}>
							<Button intent="primary" icon="arrow-left"></Button>
						</Link>
					)}
					<Button disabled>v{props.page.version}</Button>
					{me_groups.includes('DA-DASHBOARD-ADMIN') &&
						props.page.newestVersion &&
						props.page.version < props.page.newestVersion && (
							<Link to={getVersionUrl(props.page.version + 1)}>
								<Button intent="primary" icon="arrow-right"></Button>
							</Link>
						)}
					<Button small icon="barcode" onClick={(e) => setModalQR(true)}>
						QR
					</Button>
				</ControlGroup>
			</div>
		</div>
	)
}
export default ContentRead
