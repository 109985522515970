import React, { Fragment } from 'react'
import { TicketChange } from '../Ticket/types'
import { CommentsCardProps } from './Card'
import { CommentsCardListItem } from './CardListItem'
import { Comment } from './types'

export type CommentsCardListProps = {
	reference: string
	comments: Comment[]
}

export type FlettingAnyElement = (FlettingTicketChangeElement | FlettingCommentElement) & { createdAt: string }

export type FlettingTicketChangeElement = {
	type: 'TicketChange'
	data: TicketChange
	wrapper: (props: any) => JSX.Element
}

export type FlettingCommentElement = {
	type: 'Comment'
	data: Comment
}

export function CommentsCardList(props: CommentsCardListProps & CommentsCardProps) {
	const [allElements, setAllElements] = React.useState<FlettingAnyElement[]>([])

	React.useMemo(() => {
		const elements: FlettingAnyElement[] = []

		props.comments.forEach((comment) => {
			elements.push({
				type: 'Comment',
				data: comment,
				createdAt: comment.createdAt,
			})
		})

		props.fletting.forEach((fletting) => {
			fletting.data.forEach((change) => {
				if (change.createdAt) {
					elements.push({
						type: fletting.type,
						data: change,
						createdAt: change.createdAt,
						wrapper: fletting.wrapper,
					})
				}
			})
		})

		//sort elements by createdAt
		elements.sort((a, b) => {
			return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
		})

		setAllElements(elements)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.comments])

	return (
		<div>
			{allElements.map((c) => (
				<Fragment key={c.createdAt}>
					{c.type === 'Comment' && <CommentsCardListItem item={c.data} />}
					{c.type === 'TicketChange' && c.wrapper(c.data)}
				</Fragment>
			))}
		</div>
	)
}
