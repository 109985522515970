import axios from 'axios'

const backend = axios.create({
	baseURL: "https://data.brreg.no/enhetsregisteret/api",
	timeout: 5000,
	headers: { Accept: 'application/vnd.brreg.enhetsregisteret.enhet.v2+json;charset=UTF-8' },
})

backend.interceptors.request.use((config) => {
	if (!config.url) {
		return config
	}

	const currentUrl = new URL(config.baseURL + config.url)
	// parse pathName to implement variables
	Object.entries(config.urlParams || {}).forEach(([k, v]) => {
		currentUrl.pathname = currentUrl.pathname.replace(`:${k}`, encodeURIComponent(v))
	})

	const authPart = currentUrl.username && currentUrl.password ? `${currentUrl.username}:${currentUrl.password}` : ''
	return {
		...config,
		baseURL: `${currentUrl.protocol}//${authPart}${currentUrl.host}`,
		url: currentUrl.pathname,
	}
})

const brregBackend = {
	get: async (url: string, config?: any) => {
		try {
			return await backend.get(url, config)
		} catch (e: any) {
			if (e.response?.status >= 400 && e.response?.status < 500) {
				throw new Error(
					(e.response.data?.feilmelding || e.response.data?.error || e.response.data || e.message) as string
				)
			}
			throw e
		}
	},
}

export type BRREGEnhet = {
	organisasjonsnummer: string
	navn: string
	organisasjonsform: {
		kode: string
		beskrivelse: string
	}
	hjemmeside: string
	registreringsdatoEnhetsregisteret: string
	registrertIMvaregisteret: boolean
	naeringskode1: {
		kode: string
		beskrivelse: string
	}
	antallAnsatte: number
	forretningsadresse: {
		land: string
		landkode: string
		postnummer: string
		poststed: string
		adresse: string[]
		kommune: string
		kommunenummer: string
	}
	stiftelsesdato: string
	institusjonellSektorkode: {
		kode: string
		beskrivelse: string
	}
	registrertIForetaksregisteret: boolean
	registrertIStiftelsesregisteret: boolean
	registrertIFrivillighetsregisteret: boolean
	sisteInnsendteAarsregnskap: string
	konkurs: boolean
	underAvvikling: boolean
	underTvangsavviklingEllerTvangsopplosning: boolean
	maalform: string	
}

export type BRREGEnhetSmall = Pick<BRREGEnhet, 'navn' | 'organisasjonsnummer'>

export const BRREGSearchEnheter = async (
	query: string
): Promise<BRREGEnhet[]> => {
	const res = await brregBackend.get(`/enheter`, { params: { navn: query } })
	return res.data?.['_embedded']?.enheter || []
}