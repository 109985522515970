import React from 'react'
import { isActivePage } from '../../Utils/React'
import { MenuSection } from './Nav'
import { MenuItem } from './MenuItem'
import { NavMainMenuProps, NavMainMenuState } from '../../Domain/Navigation/Nav'
import AccessGroups from '../../Utils/AccessGroups'
import { VisibleOnlyTo } from '../../Utils/VisibleOnlyTo'
import { PageContext } from '../../Utils/LoginContext'

export class MainMenu extends React.Component<NavMainMenuProps, NavMainMenuState> {
	static contextType = PageContext
	render() {
		return (
			<ul className="MainRightNav">
				{this.context.authenticated && (
					<MenuItem link="/" icon="home" active={isActivePage(['/', /^\/doc/])}>
						Dokumentasjon
					</MenuItem>
				)}

				<MenuItem link="/ticket/mine" icon="issue" active={isActivePage([/^\/ticket/])}>
					Sak
				</MenuItem>

				<VisibleOnlyTo groups={AccessGroups.ProductCategoryAdmin}>
					<MenuSection>Produkt</MenuSection>

					<MenuItem
						link="/product/categories"
						icon={'dollar'}
						active={isActivePage([/^\/product\/categories/])}
					>
						Kategorier
					</MenuItem>
				</VisibleOnlyTo>

				<VisibleOnlyTo
					groups={[
						...AccessGroups.InvoiceAny,
						...AccessGroups.ProjectManagerAny,
						...AccessGroups.EmployedAny,
					]}
				>
					<MenuSection>Prosjekt</MenuSection>
				</VisibleOnlyTo>

				<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
					<MenuItem
						link="/economy/projectanalytics"
						icon={'th'}
						active={isActivePage([/^\/economy\/projectanalytics/])}
					>
						Prosjektanalyse
					</MenuItem>
				</VisibleOnlyTo>

				<VisibleOnlyTo groups={AccessGroups.InvoiceAny}>
					<MenuItem link="/economy/invoice" icon={'dollar'} active={isActivePage([/^\/economy\/invoice/])}>
						Frilansfakturering
					</MenuItem>
				</VisibleOnlyTo>

				<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
					<MenuItem link="/economy/projects" icon="projects" active={isActivePage([/^\/economy\/projects/])}>
						Fellesprosjekter
					</MenuItem>
				</VisibleOnlyTo>

				<VisibleOnlyTo groups={[...AccessGroups.Net, ...AccessGroups.Mcr, ...AccessGroups.EmployedAny]}>
					<MenuSection>Drift</MenuSection>

					<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
						<MenuItem link="/economy/loyality" icon="user" active={isActivePage([/^\/economy\/loyality/])}>
							Lojalitetskunder
						</MenuItem>
					</VisibleOnlyTo>

					<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
						<MenuItem
							link="/economy/gryn"
							icon="timeline-line-chart"
							active={isActivePage([/^\/economy\/gryn/])}
						>
							Gryn &amp; grafer
						</MenuItem>
					</VisibleOnlyTo>

					<VisibleOnlyTo groups={AccessGroups.Net}>
						<MenuItem link="/net/switch/interface" icon="globe" active={isActivePage([/^\/net/])}>
							Nettverk
						</MenuItem>
					</VisibleOnlyTo>

					<VisibleOnlyTo groups={AccessGroups.NdiRw}>
						<MenuItem link="/ndi" icon="desktop" active={isActivePage([/^\/ndi/])}>
							NDI / Skjermer
						</MenuItem>
					</VisibleOnlyTo>

					{/*}
					<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
						<MenuItem link="/tdac" icon="key" active={isActivePage([/^\/tdac/])}>
							Dører
						</MenuItem>
					</VisibleOnlyTo>
					*/}
					<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
						<MenuItem link="/tools" icon="wrench" active={isActivePage([/^\/tools/])}>
							Verktøy
						</MenuItem>
					</VisibleOnlyTo>
					<VisibleOnlyTo groups={AccessGroups.EmployedAny}>
						<MenuItem link="/services" icon="cog" active={isActivePage([/^\/services/])}>
							Tjenester
						</MenuItem>
					</VisibleOnlyTo>
				</VisibleOnlyTo>
			</ul>
		)
	}
}
