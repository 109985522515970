import { View as ViewMD } from './MD'
import { Edit as MDEdit } from './MD'

import { View as ViewEmbedVideo } from './EmbedVideo'
import { Edit as EmbedVideoEdit } from './EmbedVideo'

import { View as BigIconsView } from './BigIcons'
import { Edit as BigIconsEdit } from './BigIcons'

import { View as FileView } from './Files'
import { Edit as FileEdit } from './Files'

import { View as TDACAdminView } from './TdacAdmin'
import { Edit as TDACAdminEdit } from './TdacAdmin'

import { View as TDACPortView } from './TdacPort'
import { Edit as TDACPortEdit } from './TdacPort'

import { View as IFrameView } from './IFrame'
import { Edit as IFrameEdit } from './IFrame'

import { View as ProductView } from './Product'
import { Edit as ProductEdit } from './Product'
import { Icon, IconName, Tag, TagProps } from '@blueprintjs/core'
import { PropsWithoutRef } from 'react'

export type BlockType = 'product' | 'md' | 'embedvideo' | 'files' | 'bigicons' | 'tdacadmin' | 'tdacport' | 'iframe'

export const BlockTypeDescription: { [key in BlockType]: { description: string; icon: IconName } } = {
	product: {
		description: 'Produkt',
		icon: 'shopping-cart',
	},
	md: {
		description: 'Tekst',
		icon: 'paragraph',
	},
	embedvideo: {
		description: 'Video',
		icon: 'video',
	},
	files: {
		description: 'Filer',
		icon: 'document',
	},
	bigicons: {
		description: 'Ikon-meny',
		icon: 'menu',
	},
	tdacadmin: {
		description: 'TDAC Knapp',
		icon: 'console',
	},
	tdacport: {
		description: 'TDAC Portåpner',
		icon: 'key',
	},
	iframe: {
		description: 'IFrame innhold',
		icon: 'application',
	},
}

export const ShowBlockType = (props: TagProps & PropsWithoutRef<{ type: BlockType; showIcon: boolean }>) => {
	const { type, title, showIcon, ...rest } = props

	if (showIcon) {
		return (
			<>
				<Tag minimal {...rest}><Icon icon={BlockTypeDescription[props.type].icon} title={title} /> {BlockTypeDescription[props.type].description}</Tag>
			</>
		)
	} else {
		return <>{BlockTypeDescription[props.type].description}</>
	}
}

const exportedModules = {
	edit: {
		product: ProductEdit,
		md: MDEdit,
		embedvideo: EmbedVideoEdit,
		bigicons: BigIconsEdit,
		files: FileEdit,
		tdacadmin: TDACAdminEdit,
		tdacport: TDACPortEdit,
		iframe: IFrameEdit,
	},
	view: {
		product: ProductView,
		md: ViewMD,
		embedvideo: ViewEmbedVideo,
		bigicons: BigIconsView,
		files: FileView,
		tdacadmin: TDACAdminView,
		tdacport: TDACPortView,
		iframe: IFrameView,
	},
}

export default exportedModules
