import React, { Component } from 'react'
import DynamicContent from '../../Components/DynamicContent'
import { McrIndexProps, McrIndexState } from '../../Domain/Mcr/Index'
import Container from './Container'

class McrIndex extends Component<McrIndexProps, McrIndexState> {
	render() {
		return (
			<Container>
				<DynamicContent uri="mcr" />
			</Container>
		)
	}
}
export default McrIndex
