import { Col, Row } from 'reactstrap'
import { DynamicContentModuleProps, ProductModuleEditProps } from '../interface'
import { Button, Classes, Dialog, FormGroup, Icon, InputGroup, Label, MenuItem, TextArea } from '@blueprintjs/core'
import { ImageCarousel } from '../../ImageCarousel'
import Editor, { theme } from 'rich-markdown-editor'
import { useEffect, useState } from 'react'
import { type Block } from '../'
import { ApiCategory, getPresignedUploadUrl, getProductCategories } from '../../../Services/OalApi'

import './Product.css'
import { useApiData } from '../../../Utils/UseAPI'
import { Suggest } from '@blueprintjs/select'
import { v4 } from 'uuid'
import { UploadButton } from './Files'

export const ourTheme = {
	...theme,
	link: '#ac1c21',
	noticeInfoBackground: 'rgba(100,190,255,0.2)',
	noticeWarningBackground: 'rgba(230,110,0,1)',
	noticeTipBackground: 'rgb(92, 112, 128)',
}

type ProductFile = {
	id: string
	name: string
	url: string
}

type ProductLink = {
	id: string
	name: string
	url: string
}

type ProductData = {
	name: string
	ingress: string
	productinfo: string
	facts: string
	images: string[]
	categories: ApiCategory[]
	links: ProductLink[]
	price: string
	priceterms: string
	productunit: string
}

const iconStyle = { marginLeft: '0.5em', cursor: 'pointer' }

const categoryVisual = (categories: ApiCategory[] | null, category: ApiCategory): string => {
	if (!category) {
		return ''
	}
	if (category.parent_id !== 0) {
		return (
			categoryVisual(categories, categories?.find((c) => c.id === category.parent_id) as ApiCategory) +
			' > ' +
			category.name
		)
	}
	return category.name
}

export const View1 = (props: DynamicContentModuleProps) => {
	const { data: categories, error: categoriesError } = useApiData<ApiCategory[]>(getProductCategories)

	const categoryFromId = (id: number): string | null => {
		const category = categories?.find((c) => c.id === id) as ApiCategory
		if (category) {
			return categoryVisual(categories, category)
		}
		return null
	}

	const data = props.block.payload || {}

	return (
		<Row>
			{categoriesError ? (
				<div className="bp3-callout bp3-intent-danger">
					<h4 className="bp3-heading">Feil</h4>
					<p>
						Noe gikk galt under henting av kategorier. Prøv å reload siden for å se om det hjelper, eller
						kontakt alpha avdelingen.
					</p>
				</div>
			) : null}

			<Col lg="12">
				{props.block?.id ? (
					<a
						href={`https://solutions.frontir.no/prod/${props.block.id}`}
						rel="noreferrer"
						target="_blank"
						className={'bp3-button' + (props.block.payload?.active ? '' : ' bp3-intent-warning')}
						style={{ float: 'right' }}
					>
						<Icon icon="globe" size={32} style={{ margin: '4px' }} />
					</a>
				) : null}
				<h1>{data.name}</h1>
				<Row>
					<Col lg="6">
						<ImageCarousel
							images={data.images || []}
							onChange={() => {}}
							style={{ border: '2px solid #eee' }}
						/>
					</Col>
					<Col lg="6">
						<div style={{ borderRadius: '5px', margin: '0', padding: '0 0.5em 0.5em 0.5em' }}>
							<h2>Kort fortalt</h2>
							<p>{data.ingress || ''}</p>
						</div>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col lg="6">
						<div style={{ borderRadius: '5px', margin: 0, padding: '0 0.5em 0.5em 0.5em' }}>
							<div style={{ fontSize: '1em', fontStyle: 'italic' }}>
								<Editor defaultValue={data.productinfo} readOnly theme={ourTheme} />
							</div>
						</div>
					</Col>
					<Col md="6">
						<Row>
							<Col md="6">
								{data.price ? (
									<>
										<strong>Pris:</strong> {props.block.payload.price}
										<br />
										<strong>Produktenhet:</strong> {props.block.payload.productunit}
										<br />
										<strong>Prisbetingelser/forklaring:</strong>
										<br />
										{props.block.payload.priceterms}
										<br />
										<br />
									</>
								) : null}
								<div
									style={{
										minHeight: '200px',
										borderRadius: '5px',
										margin: 0,
										padding: '0 0.5em 0.5em 0.5em',
									}}
								>
									<Editor defaultValue={data.facts || ''} readOnly theme={ourTheme} />
								</div>
							</Col>
							<Col md="6">
								{((props.block.payload.categories || []) as string[]).length > 0 ? (
									<>
										<h5>Kategorier</h5>
										<ul>
											{((props.block.payload.categories || []) as number[]).map((category, i) => (
												<li key={i}>{categoryFromId(category)}</li>
											))}
										</ul>
									</>
								) : null}

								{((props.block.payload.categories || []) as string[]).length > 0 ? (
									<>
										<h5>Nyttige lenker</h5>
										<ul>
											{((props.block.payload?.links || []) as ProductLink[]).map((link) => (
												<li key={link.id}>
													<a href={link.url} rel="noreferrer" target="_blank">
														{link.name}
													</a>
												</li>
											))}
										</ul>
									</>
								) : null}

								{((props.block?.payload?.files || []) as ProductFile[]).length > 0 ? (
									<>
										<h5>Filer</h5>
										<ul>
											{((props.block.payload?.files || []) as ProductFile[]).map((file) => (
												<li key={file.id}>
													<a href={file.url} target="_blank" rel="noreferrer">
														{file.name}
													</a>
												</li>
											))}
										</ul>
									</>
								) : null}
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export const View = View1

const myFormhandler = <T,>({
	values,
	defaultValues,
	onChange,
}: {
	values: T
	defaultValues: Partial<T>
	onChange: (data: any) => void
}) => {
	return {
		register: (name: keyof T, options: any = {}) => {
			if (values[name] === undefined) {
				onChange({
					...values,
					[name]: defaultValues[name] || '',
				})
			}

			return {
				onChange: (e: any) => {
					onChange({
						...values,
						[name]: options.maxLength
							? String(e.target.value).substring(0, options.maxLength)
							: e.target.value,
					})
				},
				value: String(values[name] || ''),
			}
		},
		registerEditor: (name: keyof T, options?: any) => {
			if (values[name] === undefined) {
				onChange({
					...values,
					[name]: defaultValues[name] || '',
				})
			}

			return {
				onChange: (data: any) => {
					onChange({
						...values,
						[name]: data() || '',
					})
				},
				defaultValue: String(values[name] || ''),
			}
		},
	}
}

const AddCategory = (props: {
	categories: ApiCategory[] | null
	block: Block
	isOpen: boolean
	onClose: () => void
	onAdd: (value: number) => void
}) => {
	const [chosen, setChosen] = useState<number | null>(null)

	useEffect(() => {
		if (props.isOpen) {
			setChosen(null)
		}
	}, [props.isOpen])

	const addCategory = () => {
		let value = chosen

		if (value === null) {
			return
		}

		props.onAdd(value)
		props.onClose()
	}

	return (
		<Dialog
			icon="add"
			title="Legg til kategori"
			isOpen={props.categories !== null && props.isOpen}
			onClose={() => props.onClose()}
		>
			<div className={Classes.DIALOG_BODY}>
				<p>
					Søk opp og legg til en kategori som passer dette produktet, hvis du ønsker å opprette en ny
					kategori; fyll ut kategorinavnet og trykk på pluss-symbolet.
				</p>
				<Suggest
					fill
					openOnKeyDown
					inputProps={{ autoFocus: true }}
					itemListPredicate={(query, items) => {
						if (query === '') return []
						return items
							.filter((item) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0)
							.slice(0, 5)
					}}
					itemsEqual={(a, b) => a.id === b.id}
					inputValueRenderer={(item) => categoryVisual(props.categories, item)}
					onItemSelect={(item) => {
						setChosen(item.id)
					}}
					noResults={<MenuItem disabled={true} text="Fant ingen tilsvarende kategori" />}
					itemRenderer={(item, { handleClick, modifiers, query }) => {
						return (
							<MenuItem
								active={modifiers.active}
								key={item.id}
								onClick={handleClick}
								text={categoryVisual(props.categories, item)}
								itemID={String(item.id)}
							/>
						)
					}}
					items={props.categories || []}
				/>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => props.onClose()} intent="warning">
						Avbryt
					</Button>
					<Button disabled={!chosen} onClick={() => addCategory()} intent="success">
						Legg til
					</Button>
				</div>
			</div>
		</Dialog>
	)
}

const AddLink = (props: { isOpen: boolean; onClose: () => void; onAdd: (text: string, url: string) => void }) => {
	const [text, setText] = useState<string>('')
	const [url, setUrl] = useState<string>('')

	useEffect(() => {
		if (props.isOpen) {
			setText('')
			setUrl('')
		}
	}, [props.isOpen])

	const isValid = text.length > 0 && url.length > 0

	const save = () => {
		if (!isValid) {
			return
		}

		if (
			url.indexOf('http://') !== 0 &&
			url.indexOf('https://') !== 0 &&
			url.indexOf('mailto:') !== 0 &&
			url.indexOf('/') !== 0
		) {
			props.onAdd(text, 'https://' + url)
		} else {
			props.onAdd(text, url)
		}

		props.onClose()
	}

	return (
		<Dialog icon="add" title="Legg til lenke" isOpen={props.isOpen} onClose={() => props.onClose()}>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					save()
				}}
			>
				<div className={Classes.DIALOG_BODY}>
					<p>Skriv inn navn og adresse til lenken</p>
					<FormGroup label="Navn" labelFor="text-input">
						<InputGroup
							id="text-input"
							placeholder="Navn"
							value={text}
							autoFocus
							onChange={(e: any) => setText(e.target.value)}
						/>
					</FormGroup>
					<FormGroup label="Adresse" labelFor="url-input">
						<InputGroup
							id="url-input"
							placeholder="https://frontirgroup.com/"
							value={url}
							onChange={(e: any) => setUrl(e.target.value)}
						/>
					</FormGroup>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => props.onClose()} intent="warning">
							Avbryt
						</Button>
						<Button type="submit" onClick={() => save()} disabled={!isValid} intent="success">
							Legg til
						</Button>
					</div>
				</div>
			</form>
		</Dialog>
	)
}

const AddFile = (props: {
	id: string
	isOpen: boolean
	onClose: () => void
	onAdd: (text: string, url: string) => void
}) => {
	const [text, setText] = useState<string>('')
	const [url, setUrl] = useState<string>('')

	useEffect(() => {
		if (props.isOpen) {
			setText('')
			setUrl('')
		}
	}, [props.isOpen])

	const isValid = text.length > 0 && url.length > 0

	const save = () => {
		if (!isValid) {
			return
		}

		props.onAdd(text, url)
		props.onClose()
	}

	return (
		<Dialog icon="add" title="Legg til fil" isOpen={props.isOpen} onClose={() => props.onClose()}>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					save()
				}}
			>
				<div className={Classes.DIALOG_BODY}>
					<p>Skriv inn navn til filen</p>
					<FormGroup label="Navn" labelFor="text-input">
						<InputGroup
							id="text-input"
							placeholder="Navn"
							value={text}
							autoFocus
							onChange={(e: any) => setText(e.target.value)}
						/>
					</FormGroup>
					<Label>
						<div>
							{url ? (
								<a href={url} target="_blank" rel="noreferrer">
									<Button type="button">
										<Icon icon="paperclip" />
									</Button>
								</a>
							) : (
								<UploadButton
									disabled={text === ''}
									onUploaded={(file) => setUrl(file)}
									onFilename={(filename) => {
										if (text === '') {
											const match = filename.match(/(^.+)\.[^.]+$/)
											if (match) {
												setText(match[1])
											} else {
												setText(filename)
											}
										}
									}}
									uid={props.id ? `product-${props.id}` : ''}
									text="Last opp en fil fra din maskin"
								/>
							)}
						</div>
					</Label>
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button onClick={() => props.onClose()} intent="warning">
							Avbryt
						</Button>
						<Button type="submit" onClick={() => save()} disabled={!isValid} intent="success">
							Legg til
						</Button>
					</div>
				</div>
			</form>
		</Dialog>
	)
}

const dashUploadImage = async (uid: string, file: File): Promise<string> => {
	const extension = file.name.split('.').pop() || 'jpg'
	const filename = `${Date.now()}.${extension}`

	const info = await getPresignedUploadUrl(filename, uid)

	await fetch(info.url, {
		method: 'PUT',
		body: file,
	})

	return info.finalURL
}

export const Edit = (props: ProductModuleEditProps) => {
	const images = props.block.payload?.images || []
	const block = props.block

	const [showAddCategory, setShowAddCategory] = useState(false)
	const [showAddLink, setShowAddLink] = useState(false)
	const [showAddFile, setShowAddFile] = useState(false)

	const { data: categories, error: categoriesError } = useApiData<ApiCategory[]>(getProductCategories)

	const { register, registerEditor } = myFormhandler<ProductData>({
		values: props.block.payload || {},
		defaultValues: {
			name: 'Produktnavn',
			ingress: '',
			productinfo: '',
			price: '',
			priceterms: '',
			productunit: '',
			images: [],
			categories: [],
			links: [],
		},
		onChange: (data: any) => {
			if (data.price) {
				data.price = data.price.replace(/[^0-9,.]/g, '').replace(/,/g, '.')
			}
			props.onChange({
				...block,
				payload: data,
			})
		},
	})

	const uploadImage = async (file: File) => {
		return await dashUploadImage(props.block.id, file)
	}

	const setImages = (images: string[]) => {
		props.onChange({
			...block,
			payload: {
				...block.payload,
				images: images,
			},
		})
	}

	const addCategory = (value: number) => {
		if (!value) {
			return
		}

		const existing = props.block.payload?.categories || []
		if (existing.indexOf(value) !== -1) {
			return
		}

		props.onChange({
			...block,
			payload: {
				...block.payload,
				categories: [...existing, value],
			},
		})
	}

	const categoryFromId = (id: number): string | null => {
		const category = categories?.find((c) => c.id === id) as ApiCategory
		if (category) {
			return categoryVisual(categories, category)
		}
		return null
	}

	const publishProduct = async (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>,
		active: boolean
	) => {
		e.preventDefault()

		if (props.onShouldSave) {
			props.onShouldSave({ ...props.block, payload: { ...props.block.payload, active } })
		}
	}

	return (
		<Row>
			{categoriesError ? (
				<div className="bp3-callout bp3-intent-danger">
					<h4 className="bp3-heading">Feil</h4>
					<p>
						Noe gikk galt under henting av kategorier. Prøv å reload siden for å se om det hjelper, eller
						kontakt alpha avdelingen.
					</p>
				</div>
			) : null}
			<Col lg="12">
				{props.block?.id && !props.block?.payload?.active ? (
					<Button
						intent="danger"
						style={{ float: 'right', marginRight: '1em', zIndex: 1000 }}
						title={'Trykk for å publisere'}
						onClick={(e) => publishProduct(e, true)}
					>
						<Icon icon="cross" style={{ margin: '0 0.3em 0 0' }} /> Ikke publisert
					</Button>
				) : props.block?.id && props.block.payload?.active ? (
					<Button
						className={'bp3-button bp3-intent-success'}
						style={{ float: 'right', marginRight: '1em', zIndex: 1000 }}
						title={'Trykk for å av-publisere'}
						onClick={(e) => publishProduct(e, false)}
					>
						<Icon icon="tick" style={{ margin: '0 0.3em 0 0' }} /> Publisert
					</Button>
				) : null}
			</Col>
			<Col lg="12">
				<InputGroup
					{...register('name')}
					style={{
						fontSize: '2em',
						fontWeight: 'bold',
						marginBlockStart: '0.83em',
						marginBlockEnd: '0.83em',
						marginInlineStart: '0px',
						marginInlineEnd: '0px',
					}}
				/>
				<Row>
					<Col lg="6">
						<ImageCarousel
							images={images}
							onChange={(images) => setImages(images)}
							editable
							uid={props.block.id}
							style={{ border: '2px solid #eee' }}
						/>
					</Col>
					<Col lg="6">
						<div style={{ margin: '0', padding: '0 0.5em 0.5em 0.5em' }}>
							<h2>Kort fortalt</h2>
							<TextArea
								className="editableSection"
								{...register('ingress', { maxLength: 1000 })}
								placeholder="Elevatorpitch..."
								fill
								style={{ minHeight: 240 }}
								autoFocus
							/>
						</div>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col lg="6">
						<div style={{ margin: 0, padding: '0 0.5em 0.5em 0.5em' }} className="editableSection">
							<Editor
								{...registerEditor('productinfo')}
								placeholder="Produkttekst..."
								style={{ minHeight: 20 }}
								uploadImage={uploadImage}
								theme={ourTheme}
							/>
						</div>
					</Col>
					<Col md="6">
						<Row>
							<Col md="6">
								Pris:
								<InputGroup placeholder="10000" {...register('price')} className="mb-2" />
								Produktenhet:
								<InputGroup placeholder="per rack" {...register('productunit')} className="mb-2" />
								Prisbetingelser/forklaring:
								<TextArea
									className="editableSection mb-3"
									{...register('priceterms', { maxLength: 1000 })}
									placeholder="Krever at du også leier xxx og at arrangementet varer i minst xxx dager....etc"
									fill
									style={{ minHeight: 80 }}
									autoFocus
								/>
								<div
									style={{ minHeight: '200px', margin: 0, padding: '0 0.5em 0.5em 0.5em' }}
									className="editableSection"
								>
									<Editor
										{...registerEditor('facts')}
										placeholder="Faktaseksjon..."
										style={{ minHeight: '30px' }}
										uploadImage={uploadImage}
										theme={ourTheme}
									/>
								</div>
							</Col>
							<Col md="6">
								<h5>Kategorier</h5>
								<ul>
									{((props.block.payload?.categories || []) as number[]).map((category) => (
										<li key={category}>
											{categoryFromId(category)}
											<Icon
												icon="trash"
												intent="danger"
												style={iconStyle}
												size={16}
												onClick={() => {
													props.onChange({
														...block,
														payload: {
															...block.payload,
															categories: (
																(props.block.payload?.categories || []) as number[]
															).filter((k) => k !== category),
														},
													})
												}}
											/>
										</li>
									))}
								</ul>
								<Button
									icon="add"
									text="Legg til kategori"
									small
									intent="success"
									onClick={() => setShowAddCategory(true)}
								/>
								<AddCategory
									categories={categories}
									block={block}
									isOpen={showAddCategory}
									onClose={() => {
										setShowAddCategory(false)
									}}
									onAdd={(value) => {
										addCategory(value)
										/*TODO: FIX if (categories?.indexOf(value) === -1) {
                                            categoriesReload()
                                        }*/
									}}
								/>

								<h5>Nyttige lenker</h5>
								<ul>
									{((props.block.payload?.links || []) as ProductLink[]).map((link) => (
										<li key={link.id}>
											<a href={link.url} rel="noreferrer" target="_blank">
												{link.name}
											</a>{' '}
											<Icon
												icon="trash"
												intent="danger"
												style={iconStyle}
												size={16}
												onClick={() => {
													props.onChange({
														...block,
														payload: {
															...block.payload,
															links: (
																(props.block.payload?.links || []) as ProductLink[]
															).filter((l) => l.id !== link.id),
														},
													})
												}}
											/>
										</li>
									))}
								</ul>
								<Button
									icon="add"
									text="Legg til lenke"
									onClick={() => setShowAddLink(true)}
									small
									intent="success"
								/>
								<AddLink
									isOpen={showAddLink}
									onClose={() => setShowAddLink(false)}
									onAdd={(name, url) => {
										props.onChange({
											...block,
											payload: {
												...block.payload,
												links: [...(props.block.payload?.links || []), { id: v4(), name, url }],
											},
										})
										setShowAddLink(false)
									}}
								/>

								<h5>Filer</h5>
								<ul>
									{((props.block.payload?.files || []) as ProductFile[]).map((file) => (
										<li key={file.id}>
											<a href={file.url} target="_blank" rel="noreferrer">
												{file.name}
											</a>
											<Icon
												icon="trash"
												intent="danger"
												style={iconStyle}
												size={16}
												onClick={() => {
													props.onChange({
														...block,
														payload: {
															...block.payload,
															files: (
																(props.block.payload?.files || []) as ProductFile[]
															).filter((f) => f.id !== file.id),
														},
													})
												}}
											/>
										</li>
									))}
								</ul>
								<Button
									icon="add"
									text="Legg til fil"
									small
									intent="success"
									onClick={() => setShowAddFile(true)}
								/>
								<AddFile
									id={props.block?.id}
									isOpen={showAddFile}
									onClose={() => {
										setShowAddFile(false)
									}}
									onAdd={(name, url) => {
										props.onChange({
											...block,
											payload: {
												...block.payload,
												files: [...(props.block.payload?.files || []), { id: v4(), name, url }],
											},
										})
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
