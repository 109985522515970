export default class Configuration {
	static OAL_API_URL =
		process.env.NODE_ENV === 'production'
			? 'https://api.oal.no/v1'
			: process.env.REACT_APP_OAL_API_URL
			? process.env.REACT_APP_OAL_API_URL
			: 'https://api.oal.no/v1'
	static OAL_API_TES_URL =
		process.env.NODE_ENV === 'production'
			? 'https://tes-api.oal.no'
			: process.env.REACT_APP_OAL_TES_API_URL
			? process.env.REACT_APP_OAL_TES_API_URL
			: 'https://tes-api.oal.no'
	static OAL_AUTH_URL =
		process.env.NODE_ENV === 'production'
			? 'https://auth.oal.no'
			: process.env.REACT_APP_OAL_AUTH_URL
			? process.env.REACT_APP_OAL_AUTH_URL
			: 'https://auth.oal.no'
	static OAL_AUTH_API_URL =
		(process.env.NODE_ENV === 'production'
			? 'https://auth.oal.no'
			: process.env.REACT_APP_OAL_AUTH_API_URL
			? process.env.REACT_APP_OAL_AUTH_API_URL
			: Configuration.OAL_AUTH_URL) + '/api/v1'
}
