import { HomeIndexProps } from '../../Domain/Home/Index'
import Container from '../Doc/Container'
import { DynamicContent } from '../../Components/DynamicContent'
import { H2 } from '@blueprintjs/core'

const Index = (props: HomeIndexProps) => {
	return (
		<Container>
			<div className="row">
				<div className="col-lg-4">
					<H2>Generelt</H2>
					<DynamicContent uri="index" noTitle />
				</div>
				<div className="col-lg-4">
					<H2>Utstyr</H2>
					<DynamicContent uri="index-equipment" noTitle />
				</div>
				<div className="col-lg-4">
					<H2>OAL</H2>

					<DynamicContent uri="index-right" noTitle />
				</div>
			</div>
		</Container>
	)
}

export default Index
