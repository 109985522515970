export const companies = [
	{
		company: 'tmlb',
		name: 'Trippel-M',
		tripletexContextId: '443934',
		rentmanId: 'trippelmlevendebilderas'
	},
	{
		company: 'opti',
		name: 'Optilux',
		tripletexContextId: '2441686',
		rentmanId: 'optilux'
	},
]

export default companies