import React, { createContext, Fragment, useContext } from 'react'
import { Block, PageV1 } from './index'
import { ContentEditBlock } from './ContentEditBlock'
import { TESContext } from '../../Utils/TESContext'
import { Callout } from '@blueprintjs/core'

interface ContentEditProps {
	blocks: (Block | null)[]
	onBlocksChange: (blocks: Block[]) => void
	page: PageV1
	onPageChange: (page: PageV1) => void
}

export const ContentEdit = (props: ContentEditProps) => {
	const { blocks, onBlocksChange, onPageChange, page } = props
	const TES = useContext(TESContext)

	// create context
	const globalEditContext = createContext({
		blocks,
		page,
	})

	const me_groups = TES.authenticatedUser?.groups
	const me = TES.authenticatedUser?.sAMAccountName

	const hasAccess = (block: Block): boolean => {
		let hasAccess = false
		block.accessWrite.forEach((group) => {
			if (group !== null && me_groups.includes(group)) {
				hasAccess = true
			}
		})
		if (hasAccess) return true

		if (block.createdBy === me) {
			return true
		}

		if (block.accessWrite.length === 0) {
			return true
		}
		return false
	}

	const cleanBlocks: Block[] = blocks.filter((b) => b !== null) as Block[]

	return (
		<div>
			{blocks.map((block, index) => (
				<Fragment key={block ? block.id : index}>
					{block !== null && hasAccess(block) ? (
						<div>
							<ContentEditBlock
								block={block}
								onPageChange={onPageChange}
								blocks={blocks.filter((b) => b !== null) as Block[]}
								onBlocksChange={onBlocksChange}
								page={page}
								editContext={globalEditContext}
								onChange={(updatedBlock) => {
									onBlocksChange(
										cleanBlocks.map((b) => (updatedBlock.id === b.id ? updatedBlock : b))
									)
								}}
							/>
						</div>
					) : (
						<Callout icon="lock" className="mb-3 mt-1" style={{ maxWidth: '100%', opacity: 1 }}>
							Her finnes det innhold du ikke har tilgang til å redigere. Freelance, ansatte og
							forskjellige brukergrupper har tilgang til forskjellig innhold. Om du mener du burde hatt
							tilgang, send en epost til alpha@trippelm.no
						</Callout>
					)}
				</Fragment>
			))}
		</div>
	)
}

export default ContentEdit
