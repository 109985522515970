import { Button, Callout, Classes, Dialog, FormGroup, H4, HTMLSelect, InputGroup, Spinner } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { useEffect, useState } from 'react'
import { LoyalityCustomer, LoyalityCustomerWithUsers, LoyalityLevel, getLoyalityLevels } from '../../Services/OalApi'

const formatter = new Intl.NumberFormat('nb-NO', {
	style: 'currency',
	currency: 'NOK',
	minimumFractionDigits: 0,
})

export const AddContract = ({
	customer,
	isOpen,
	onClose,
	onUpdate,
}: {
	customer: LoyalityCustomer | LoyalityCustomerWithUsers
	isOpen: boolean
	onClose: () => void
	onUpdate: (contract: {
		year: string
		level: number
		amount: number
		signedBy: string
		startDate: Date
	}) => Promise<true | string>
}) => {
	const now = new Date()

	const [error, setError] = useState<string>('')
	const [onSaving, setOnSaving] = useState<boolean>(false)
	const [year, setYear] = useState<string>(now.getFullYear().toString())
	const [contractStart, setContractStart] = useState<Date | undefined>()
	const [signedBy, setSignedBy] = useState<string>('')
	const [contractLevel, setContractLevel] = useState<string>('')
	const [loadingLoyalityLevels, setLoadingLoyalityLevels] = useState<boolean>(true)
	const [loyalityLevels, setLoyalityLevels] = useState<LoyalityLevel[]>([])
	const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

	const years = [now.getFullYear(), now.getFullYear() + 1]

	useEffect(() => {
		setLoadingLoyalityLevels(true)
		setLoyalityLevels([])
		setContractLevel('')
		setError('')
		setConfirmDialog(false)
		;(async () => {
			try {
				const levels = await getLoyalityLevels(year)
				// We only want the actual levels, not the default one
				setLoyalityLevels(levels.filter((level) => level.year === parseInt(year)))
			} catch (error) {
				console.error(error)
			} finally {
				setLoadingLoyalityLevels(false)
			}
		})()
	}, [year])

	const onSave = async () => {
		setError('')
		if (!confirmDialog && year && contractStart && contractLevel && signedBy) {
			setConfirmDialog(true)
		} else if (confirmDialog && year && contractStart && contractLevel && signedBy) {
			try {
				setOnSaving(true)
				const result = await onUpdate({
					year,
					level: parseInt(contractLevel),
					amount: loyalityLevels.find((l) => l.level === parseInt(contractLevel) - 1)?.amount ?? -1,
					signedBy,
					startDate: contractStart,
				})

				if (result === true) {
					setConfirmDialog(false)
					onClose()
				} else {
					setError(result)
				}
			} catch (error: any) {
				console.error(error instanceof Error ? error.message : error)
				setError(
					'En feil oppstod under lagring av avtalen: ' +
						String(error instanceof Error ? error.message : error)
				)
			} finally {
				setConfirmDialog(false)
				setOnSaving(false)
			}
		}
	}

	return (
		<Dialog isOpen={isOpen} onClose={() => onClose()}>
			<div className={Classes.DIALOG_HEADER}>
				<H4>Legg til ny kontrakt/avtale om omsetning</H4>
			</div>
			<div className={Classes.DIALOG_BODY + ' loyalityform'}>
				{!confirmDialog ? (
					<>
						<p>Pass på å legge inn riktig dato på når avtalen gjelder fra.</p>
						<FormGroup label="Avtalen gjelder årstall">
							<HTMLSelect
								className="bp3 form-control"
								value={year}
								onChange={(e) => setYear(e.target.value)}
							>
								{years.map((year) => (
									<option value={year} key={year}>
										{year}
									</option>
								))}
							</HTMLSelect>
						</FormGroup>
						<FormGroup label="Binder seg til å omsette for">
							{loadingLoyalityLevels ? (
								<Spinner size={16} />
							) : loyalityLevels.length === 0 ? (
								<p>
									<strong>Ingen lojalitetsnivåer definert for {year}</strong>
								</p>
							) : (
								<HTMLSelect
									value={contractLevel}
									onChange={(e) => setContractLevel(e.target.value)}
									disabled={loadingLoyalityLevels}
								>
									<option value="">Velg lojalitetsnivå</option>
									{loyalityLevels.map((level) => (
										<option value={level.level + 1} key={level.id}>
											{formatter.format(level.amount)} (Nivå {level.level + 1})
										</option>
									))}
								</HTMLSelect>
							)}
						</FormGroup>
						<FormGroup label="Avtalen gjelder fra">
							<DatePicker
								value={contractStart}
								shortcuts={false}
								minDate={new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0)}
								maxDate={new Date(now.getFullYear() + 1, 11, 31, 0, 0, 0, 0)}
								onChange={(date) => {
									setContractStart(date)
								}}
							/>
						</FormGroup>
						<FormGroup label="Avtalen ble signert av (person)">
							<InputGroup value={signedBy} onChange={(e) => setSignedBy(e.target.value)} />
						</FormGroup>
					</>
				) : (
					<>
						<p>Er du sikker på at du vil legge til denne avtalen?</p>
						<div className="tear-main">
							<div className="tear-content" style={{ background: 'white', padding: '2rem' }}>
								<dl>
									<dt>Firma</dt>
									<dd>{customer.name}</dd>
								</dl>
								<dl>
									<dt>Omsetningsmål</dt>
									<dd>
										{formatter.format(
											loyalityLevels.find((l) => l.level === parseInt(contractLevel) - 1)
												?.amount ?? -1
										)}
									</dd>
								</dl>
								<dl>
									<dt>Årstall</dt>
									<dd>{year}</dd>
								</dl>
								<dl>
									<dt>Lojalitetsnivå</dt>
									<dd>{contractLevel}</dd>
								</dl>
								<dl>
									<dt>Avtalen gjelder fra</dt>
									<dd>{contractStart?.toLocaleDateString('nb-NO')}</dd>
								</dl>
								<dl>
									<dt>Avtalen ble signert av</dt>
									<dd>{signedBy}</dd>
								</dl>
							</div>
						</div>
					</>
				)}
				{error ? (
					<Callout intent="danger" icon="warning-sign">
						<p>{error}</p>
					</Callout>
				) : null}
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					{confirmDialog && (
						<Button onClick={() => setConfirmDialog(false)} intent="primary">
							Tilbake
						</Button>
					)}
					<Button onClick={() => onClose()} intent="none">
						Avbryt
					</Button>
					<Button
						onClick={() => onSave()}
						intent="success"
						loading={onSaving}
						disabled={!year || !contractStart || contractLevel === '' || !signedBy}
					>
						{confirmDialog ? 'Legg til' : 'Neste'}
					</Button>
				</div>
			</div>
		</Dialog>
	)
}
