import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { getTickets } from '../api'
import { Ticket, TicketState } from "../types"

export function useTicketListMine(states: TicketState[]): {
	tickets: Ticket[]
	setTickets: Dispatch<SetStateAction<Ticket[]>>
	loading: boolean
	refreshTickets: () => void
} {
	const [tickets, setTickets] = React.useState<Ticket[]>([])
	const [loading, setLoading] = React.useState(true)

	useEffect(() => {
		const fetchTickets = async () => {
			const newTickets = await getTickets(states.includes('deleted') ? true : false)
			setLoading(false)
			setTickets(newTickets || [])
		}

		fetchTickets()

		const timer = setInterval(fetchTickets, 10000)

		return () => clearTimeout(timer)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const refreshTickets = async () => {
		setLoading(true)
		const newTickets = await getTickets(states.includes('deleted') ? true : false)
		setLoading(false)
		setTickets(newTickets || [])
	}

	return {
		tickets,
		setTickets,
		loading,
		refreshTickets,
	}
}
