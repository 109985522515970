import {
	Button,
	ButtonGroup,
	Callout,
	Card,
	Classes,
	Dialog,
	FormGroup,
	H3,
	H4,
	HTMLSelect,
	Icon,
	InputGroup,
	NonIdealState,
	Spinner,
} from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import {
	LoyalityCustomer,
	LoyalityCustomerWithUsers,
	TripletexOrgCustomer,
	addLoyalityCustomer,
	getLoyalityCustomers,
	getTripletexCustomerFromOrgnr,
	removeLoyalityUserFromCustomer,
	updateLoyalityCustomer,
} from '../../../Services/OalApi'
import LoyalityContainer from './Container'
import { SuggestCustomer } from '../../../Components/Loyality/SuggestCustomer'
import { SuggestRentmanContact } from '../../../Components/Loyality/SuggestRentmanContact'

import './Loyality.scss'
import { useApiData } from '../../../Utils/UseAPI'
import AddUser from '../../../Components/Loyality/AddUser'
import { AuditLog } from '../../../Components/Loyality/AuditLog'
import { BRREGEnhetSmall } from '../../../Services/Brreg'
import { Link, useHistory } from 'react-router-dom'
import { ConfirmModal } from '../../../Components/ConfirmModal'
import { getContactsAll } from '../../../Services/Contacts'
import { OALContactCard } from '../../../Domain/Common/OAL'

const customerElevation = 2
const userElevation = 0

export const TripletexCustomerField = ({
	company,
	orgnr,
	onChange,
}: {
	company: string
	orgnr: string
	onChange: (value: number) => void
}) => {
	const [loading, setLoading] = useState(false)
	const [customer, setCustomer] = useState<TripletexOrgCustomer | null>(null)

	useEffect(() => {
		setLoading(true)
		setCustomer(null)
		;(async () => {
			if (!orgnr) {
				setLoading(false)
				return
			}

			try {
				const customer = await getTripletexCustomerFromOrgnr(company, orgnr)
				setCustomer(customer)
				if (customer) {
					onChange(customer.id)
				}
			} catch (err) {
				console.error(err)
			} finally {
				setLoading(false)
			}
		})()
	}, [company, orgnr, onChange])

	return (
		<FormGroup
			className={customer ? 'valid' : ''}
			label="Firma Tripletex"
			labelInfo={customer ? 'ID: ' + customer.id : ''}
		>
			<InputGroup
				placeholder={loading ? 'Søker...' : customer ? '' : 'Firma ikke funnet'}
				value={customer ? customer.name : ''}
				disabled={loading}
				readOnly
				leftElement={
					loading ? (
						<div style={{ verticalAlign: 'text-bottom', display: 'inline-block', margin: '7px' }}>
							<Spinner size={16} />
						</div>
					) : !loading && customer === null ? (
						<div
							style={{
								verticalAlign: 'text-bottom',
								display: 'inline-block',
								margin: '7px',
							}}
						>
							<Tooltip2
								content={
									<Row style={{ padding: '0.8em', maxWidth: '600px' }}>
										<Col xs={1} style={{ textAlign: 'center', marginBottom: '0.5em' }}>
											<Icon icon="warning-sign" size={32} />{' '}
										</Col>
										<Col xs={11}>
											Firmaet ble ikke funnet, pass på at firmaet finnes med organisasjonsnummer{' '}
											<strong>{orgnr}</strong> i Tripletex og prøv igjen ved å lukke dette
											vinduet, og trykke på '<strong>Legg til kunde</strong>' igjen.
										</Col>
									</Row>
								}
								intent="danger"
							>
								<Icon icon="warning-sign" intent="danger" />
							</Tooltip2>
						</div>
					) : undefined
				}
			/>
		</FormGroup>
	)
}

export const LoyalityCustomers = (props: {}) => {
	const history = useHistory()
	const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false)
	const [showAddUserDialog, setShowAddUserDialog] = useState<LoyalityCustomerWithUsers | null>(null)
	const [showCustomerEditDialog, setShowCustomerEditDialog] = useState<LoyalityCustomer | null>(null)
	const [userDeleteError, setUserDeleteError] = useState<Record<string, string | undefined>>({})
	const [selectedCustomer, setSelectedCustomer] = useState<BRREGEnhetSmall | null>(null)
	const [employees, setEmployees] = useState<OALContactCard[]>([])
	const [showUserAdmin, setShowUserAdmin] = useState<LoyalityCustomerWithUsers | null>(null)

	useEffect(() => {
		;(async () => {
			const contacts = await getContactsAll()
			contacts.sort((a, b) => a.name.localeCompare(b.name))
			setEmployees(contacts.filter((c) => c.groups.includes('DA-ANY-EMPLOYED')))
		})()
	}, [])

	const {
		data: customers,
		loading: customersLoading,
		error: customersError,
		reload: reloadCustomers,
	} = useApiData(() => getLoyalityCustomers(true), [])

	const [confirmModal, setConfirmModal] = useState<{
		title: string
		message: React.ReactNode
		okText?: string
		cancelText?: string
		callback: (ok: boolean) => void
	} | null>(null)

	const addUser = useCallback((customer: LoyalityCustomerWithUsers) => {
		setShowAddUserDialog(customer)
	}, [])

	const removeUser = useCallback(
		(customer_id: number, user_id: number, user: string, customer: string) => {
			setConfirmModal({
				title: 'Fjern bruker',
				message: (
					<div>
						Er du sikker på at du vil fjerne brukeren <strong>{user}</strong> fra{' '}
						<strong>{customer}</strong>?
					</div>
				),
				okText: 'Ja, fjern brukeren',
				cancelText: 'Avbryt',
				callback: (ok) => {
					if (ok) {
						;(async () => {
							try {
								setUserDeleteError((old) => ({
									...old,
									[customer_id + '_' + user_id]: undefined,
								}))
								await removeLoyalityUserFromCustomer(user_id, customer_id)
								reloadCustomers()
							} catch (err: any) {
								setUserDeleteError((old) => ({
									...old,
									[customer_id + '_' + user_id]: err?.message ?? err ?? 'Uventet internfeil',
								}))
								console.error(err)
							}
						})()
					}
				},
			})
		},
		[reloadCustomers]
	)

	const UserFromNumber = useCallback(
		({ number }: { number: string }) => {
			const person = employees.find((emp) => emp.id === number)
			return person ? <a href={`mailto:${person.email}`}>{person.name}</a> : <>Ukjent ({number})</>
		},
		[employees]
	)

	const visMer = (customer_id: number) => {
		console.log('Vis mer')
		history.push(`/economy/loyality/${customer_id}`)
	}

	return (
		<LoyalityContainer>
			<ConfirmModal
				isOpen={confirmModal !== null}
				onClose={() => {
					confirmModal?.callback(false)
					setConfirmModal(null)
				}}
				onConfirm={() => {
					confirmModal?.callback(true)
					setConfirmModal(null)
				}}
				title={confirmModal?.title!}
				message={confirmModal?.message!}
				okText={confirmModal?.okText}
				cancelText={confirmModal?.cancelText}
			/>
			<Callout intent="danger" className="mb-3">
				Denne siden er under utvikling.
				<br />
				<br />
				<strong>Bare gjør ting som er avtalt med Alpha</strong>
			</Callout>
			<AddUser
				showDialog={showAddUserDialog !== null}
				onClose={() => setShowAddUserDialog(null)}
				customer={showAddUserDialog}
				onAddUser={() => {
					console.log('Added user')
					setShowAddUserDialog(null)
					reloadCustomers()
				}}
			/>
			{showAddCustomerDialog ? (
				<AddCustomerDialog
					employees={employees}
					onClose={() => setShowAddCustomerDialog(false)}
					reloadCustomers={() => {
						console.log('ReloadCustomers, set selected to null')
						setSelectedCustomer(null)
						reloadCustomers()
					}}
					selectedCustomer={selectedCustomer}
				/>
			) : null}
			{showCustomerEditDialog ? (
				<EditCustomerDialog
					onClose={() => setShowCustomerEditDialog(null)}
					reloadCustomers={reloadCustomers}
					employees={employees}
					customer={showCustomerEditDialog}
				/>
			) : null}
			<Row>
				<Col md={6} sm={12}>
					<Card elevation={userElevation}>
						<H3>Legg til ny lojalitetskunde</H3>
						<form>
							<FormGroup label="Firmanavn" labelInfo="(obligatorisk)">
								<SuggestCustomer
									onItemSelect={(item) => {
										setSelectedCustomer({
											navn: item.navn,
											organisasjonsnummer: item.organisasjonsnummer,
										})
										//resetForm()
									}}
									value={selectedCustomer}
								/>
							</FormGroup>
							<Tooltip2
								intent="primary"
								content={
									<p>
										Bruk firmanavn-feltet ovenfor til å søke opp firmaet i Brønnøysundregistrene
										<br />
										og trykk på firmanavnet i listen som kommer opp.
									</p>
								}
							>
								<FormGroup label="Organisasjonsnummer" labelInfo="(obligatorisk)">
									<InputGroup
										placeholder="Søk etter firma i feltet ovenfor"
										value={selectedCustomer?.organisasjonsnummer || ''}
										readOnly
										disabled
									/>
								</FormGroup>
							</Tooltip2>

							<FormGroup>
								<Button
									disabled={!selectedCustomer}
									intent="success"
									icon="add"
									text="Legg til kunde"
									onClick={() => setShowAddCustomerDialog(true)}
								/>
							</FormGroup>
						</form>
					</Card>
				</Col>
			</Row>
			<Row>
				{customersLoading ? (
					<Col md={12} style={{ marginTop: '2em' }}>
						<Spinner size={64} />
					</Col>
				) : customersError ? (
					<Col md={12} style={{ marginTop: '2em' }}>
						<Callout intent="danger">Klarte ikke hente data. Feil: {customersError}</Callout>
					</Col>
				) : customers?.length === 0 ? (
					<Col md={12} style={{ marginTop: '2em' }}>
						<NonIdealState
							icon="warning-sign"
							title="Ingen kunder"
							description="Det er ingen lojalitetkunder i systemet enda."
						/>
					</Col>
				) : (
					customers?.map((customer) => (
						<Col md={6} sm={12} className="mt-4" key={customer.id}>
							<Card elevation={customerElevation} style={{ minHeight: '200px' }}>
								<div style={{ minHeight: '90px' }}>
									<div className="float-right">
										<ButtonGroup>
											<Button icon="edit" onClick={() => setShowCustomerEditDialog(customer)} />
											<Popover2 content={<AuditLog customer_id={customer.id ?? 0} />}>
												<Button icon="list-columns" />
											</Popover2>
										</ButtonGroup>
									</div>
									<H3>
										{(customer.bonus_level ?? 0) > 0 ? (
											<Icon
												icon="star"
												style={{ marginBottom: '0.25em', paddingRight: '0.4em', color: 'gold' }}
											/>
										) : (
											<Icon
												icon="star-empty"
												style={{ marginBottom: '0.25em', paddingRight: '0.4em', color: '#ddd' }}
											/>
										)}
										<Link to={`/economy/loyality/${customer.id}`}>{customer.name}</Link>
									</H3>
									<div style={{ marginLeft: '1em' }}>
										{!customer.bonus_level ? (
											<p>
												<strong>Ikke aktiv, ingen kontrakt i inneværende år</strong>
											</p>
										) : (
											<p>
												<strong>Nåværende bonusnivå:</strong> {customer.bonus_level}
											</p>
										)}
										{!customer.agent_id ? null : (
											<p>
												<strong>Kundekontakt:</strong>{' '}
												<UserFromNumber number={customer.agent_id} />
											</p>
										)}
									</div>
								</div>
								{showUserAdmin?.id === customer.id ? (
									<>
										<H4 className="mt-4">Brukere</H4>
										{customer.users.length === 0 ? (
											<p>Ingen brukere</p>
										) : (
											customer.users.map((user) => (
												<Card elevation={userElevation} className="mt-3" key={user.id}>
													<div className="float-right">
														<ButtonGroup>
															<Button icon="edit" />
															<Popover2
																content={
																	<AuditLog
																		customer_id={customer.id ?? 0}
																		user_id={user.id ?? 0}
																	/>
																}
															>
																<Button icon="list-columns" />
															</Popover2>
															<Button
																icon="cross"
																onClick={() =>
																	removeUser(
																		customer.id ?? 0,
																		user.id ?? 0,
																		user.name,
																		customer.name
																	)
																}
															/>
														</ButtonGroup>
													</div>
													<H4>{user.name}</H4>
													<p>{user.username}</p>
													<p>{user.email}</p>
													{userDeleteError[customer.id + '_' + user.id] ? (
														<Callout intent="danger" className="mb-2">
															Problemer med å slette brukeren:{' '}
															{userDeleteError[customer.id + '_' + user.id]}
														</Callout>
													) : null}
												</Card>
											))
										)}
									</>
								) : null}
								<FormGroup className="mt-4 float-right">
									<Button intent="none" text="Vis mer" onClick={() => visMer(customer?.id ?? 0)} />
								</FormGroup>
								<FormGroup className="mt-4 float">
									<Button intent="success" icon="add" onClick={() => addUser(customer)}>
										Legg til bruker
									</Button>
									<Button
										intent="none"
										className="ml-2"
										icon="edit"
										onClick={() =>
											setShowUserAdmin(showUserAdmin?.id === customer.id ? null : customer)
										}
									>
										Administrèr brukere
									</Button>
								</FormGroup>
							</Card>
						</Col>
					))
				)}
			</Row>
		</LoyalityContainer>
	)
}

function AddCustomerDialog({
	onClose,
	reloadCustomers,
	selectedCustomer,
	employees,
}: {
	onClose: () => void
	reloadCustomers: () => void
	selectedCustomer: BRREGEnhetSmall | null
	employees: OALContactCard[]
}) {
	const [selectedTMLBRentmanContact, setSelectedTMLBRentmanContact] = useState<{
		displayname: string
		id: number
	} | null>(null)
	const [selectedOPTIRentmanContact, setSelectedOPTIRentmanContact] = useState<{
		displayname: string
		id: number
	} | null>(null)

	let name = selectedCustomer?.navn || ''
	name = name.replace(/[a-zA-ZæøåÆØÅ]{1,}/g, (txt) => {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})
	name = name.replace(/ (asa?|ans|ltd|inc\.|nuk)\s*$/gi, (txt) => txt.toUpperCase())

	const [newCustomerName, setNewCustomerName] = useState(name)

	const [agentId, setAgentId] = useState<string | null>(null)

	const [tmlbTripletexId, setTmlbTripletexId] = useState<number | null>(null)
	const [optiTripletexId, setOptiTripletexId] = useState<number | null>(null)

	const validForm =
		selectedCustomer !== null &&
		agentId !== null &&
		tmlbTripletexId !== null &&
		optiTripletexId !== null &&
		selectedTMLBRentmanContact !== null &&
		selectedOPTIRentmanContact !== null &&
		newCustomerName !== ''

	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const addCustomer = () => {
		if (!validForm) {
			return
		}

		;(async () => {
			try {
				setErrorMessage(null)
				await addLoyalityCustomer({
					name: newCustomerName || '',
					orgnr: selectedCustomer?.organisasjonsnummer || '',
					agent_id: agentId || '',
					tmlb_tripletex_id: tmlbTripletexId || 0,
					opti_tripletex_id: optiTripletexId || 0,
					tmlb_rentman_id: selectedTMLBRentmanContact?.id || 0,
					opti_rentman_id: selectedOPTIRentmanContact?.id || 0,
				})

				// Success
				reloadCustomers()
				onClose()
			} catch (err) {
				setErrorMessage('Kunne ikke legge til kunde. Prøv igjen senere, eller kontakt Alpha avdelingen.')
				console.error(err)
			}
		})()
	}

	if (selectedCustomer === null) {
		return null
	}

	return (
		<Dialog isOpen={true} onClose={() => onClose()}>
			<div className={Classes.DIALOG_HEADER}>
				<Icon icon="star" /> <H4>Legg til ny lojalitetskunde</H4>
			</div>
			<div className={Classes.DIALOG_BODY + ' loyalityform'}>
				<form>
					<FormGroup label={<strong>Firmanavn</strong>}>
						<InputGroup
							fill
							value={newCustomerName}
							onChange={(e: any) => setNewCustomerName(e.target.value)}
						/>
					</FormGroup>
					<FormGroup label="Kundekontakt">
						<HTMLSelect fill onChange={(e) => setAgentId(e.target.value)}>
							<option value="">Velg kundekontakt</option>
							{employees.map((emp) => (
								<option key={emp.id} value={emp.id}>
									{emp.name}
								</option>
							))}
						</HTMLSelect>
					</FormGroup>
					<Card elevation={userElevation} className="mt-3">
						<H4>Trippel-M relasjoner</H4>
						<FormGroup
							label="Firma i Rentman"
							labelInfo={selectedTMLBRentmanContact ? 'ID: ' + selectedTMLBRentmanContact.id : ''}
							className={selectedTMLBRentmanContact ? 'valid' : ''}
						>
							<SuggestRentmanContact
								autoFocus
								tryToFind={selectedCustomer?.organisasjonsnummer}
								value={selectedTMLBRentmanContact}
								company={'tmlb'}
								onItemSelect={setSelectedTMLBRentmanContact}
							/>
						</FormGroup>
						<TripletexCustomerField
							company={'tmlb'}
							orgnr={selectedCustomer?.organisasjonsnummer || ''}
							onChange={setTmlbTripletexId}
						/>
					</Card>

					<Card elevation={userElevation} className="mt-3">
						<H4>Optilux relasjoner</H4>
						<FormGroup
							label={<>Firma i Rentman</>}
							labelInfo={selectedOPTIRentmanContact ? 'ID: ' + selectedOPTIRentmanContact.id : ''}
							className={selectedOPTIRentmanContact ? 'valid' : ''}
						>
							<SuggestRentmanContact
								value={selectedOPTIRentmanContact}
								tryToFind={selectedCustomer?.organisasjonsnummer}
								company={'opti'}
								onItemSelect={setSelectedOPTIRentmanContact}
							/>
						</FormGroup>
						<TripletexCustomerField
							company={'opti'}
							orgnr={selectedCustomer?.organisasjonsnummer || ''}
							onChange={setOptiTripletexId}
						/>
					</Card>
				</form>
				<p></p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				{errorMessage ? (
					<Callout intent="danger" className="mb-2">
						{errorMessage}
					</Callout>
				) : null}
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => onClose()} intent="none">
						Avbryt
					</Button>
					<Button
						disabled={!validForm}
						onClick={addCustomer}
						title={!validForm ? 'Alle felter må fylles ut' : 'Legg til ny kunde'}
						intent="success"
					>
						Legg til kunde
					</Button>
				</div>
			</div>
		</Dialog>
	)
}

function EditCustomerDialog({
	onClose,
	reloadCustomers,
	employees,
	customer,
}: {
	onClose: () => void
	reloadCustomers: () => void
	employees: OALContactCard[]
	customer: LoyalityCustomer
}) {
	const [newCustomerName, setNewCustomerName] = useState(customer.name)
	const [agentId, setAgentId] = useState<string>(customer.agent_id)
	const [errorMesssage, setErrorMessage] = useState<string | null>(null)

	const validForm = newCustomerName !== '' && agentId !== ''

	const updateCustomer = () => {
		if (!validForm) {
			return
		}

		;(async () => {
			try {
				setErrorMessage(null)

				await updateLoyalityCustomer({
					id: customer.id!,
					name: newCustomerName,
					agent_id: agentId,
				})

				// Success
				reloadCustomers()
				onClose()
			} catch (err) {
				setErrorMessage('Kunne ikke oppdatere kunde. Prøv igjen senere, eller kontakt Alpha avdelingen.')
				console.error(err)
			}
		})()
	}

	return (
		<Dialog isOpen={true} onClose={() => onClose()}>
			<div className={Classes.DIALOG_HEADER}>
				<Icon icon="star" /> <H4>Endre lojalitetskunde</H4>
			</div>
			<div className={Classes.DIALOG_BODY + ' loyalityform'}>
				<form>
					<FormGroup label={<strong>Firmanavn</strong>}>
						<InputGroup
							fill
							value={newCustomerName}
							onChange={(e: any) => setNewCustomerName(e.target.value)}
						/>
					</FormGroup>
					<FormGroup label="Kundekontakt">
						<HTMLSelect fill value={agentId} onChange={(e) => setAgentId(e.target.value)}>
							<option value="">Velg kundekontakt</option>
							{employees.map((emp) => (
								<option key={emp.id} value={emp.id}>
									{emp.name}
								</option>
							))}
						</HTMLSelect>
					</FormGroup>
				</form>
				<p></p>
			</div>
			<div className={Classes.DIALOG_FOOTER}>
				{errorMesssage ? (
					<Callout intent="danger" className="mb-2">
						{errorMesssage}
					</Callout>
				) : null}
				<div className={Classes.DIALOG_FOOTER_ACTIONS}>
					<Button onClick={() => onClose()} intent="none">
						Avbryt
					</Button>
					<Button
						disabled={!validForm}
						onClick={updateCustomer}
						title={!validForm ? 'Alle felter må fylles ut' : 'Lagre kunde'}
						intent="success"
					>
						Lagre
					</Button>
				</div>
			</div>
		</Dialog>
	)
}

export default LoyalityCustomers
