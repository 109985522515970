import { OALContactCard } from '../Domain/Common/OAL'

import Configuration from '../Utils/Configuration'
import storage from 'local-storage-fallback'
import axios from 'axios'

let backend = axios.create({
	baseURL: Configuration.OAL_API_URL,
	headers: { oaljwt: storage.getItem('oaljwt') },
	timeout: 180000,
})

export function reInit(jwt: string) {
	backend = axios.create({
		baseURL: Configuration.OAL_API_URL,
		headers: { oaljwt: jwt },
		timeout: 30000,
	})
}

export async function getContactsAll(): Promise<OALContactCard[]> {
	let data = await backend.get('/contacts/cached', {
		params: {},
	})
	return data.data
}
