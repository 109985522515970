import React from 'react'
import { TicketUpdatePayload } from './types'
import { Popover2 } from '@blueprintjs/popover2'
import ReactDiffViewer from 'react-diff-viewer-continued'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { Icon } from '@blueprintjs/core'

export function RenderTicketUpdatePayload({ change }: { change: TicketUpdatePayload }) {
	return (
		<RenderTicketWrapper change={change}>
			<Popover2
				content={
					<>
						<div style={{ backgroundColor: '#fff', padding: 10, fontSize: 13, minWidth: '90vw' }}>
							<ReactDiffViewer
								oldValue={change.payload[0] || ''}
								newValue={change.payload[1] || ''}
								splitView={true}
							/>
						</div>
					</>
				}
			>
				<span><Icon icon="manually-entered-data" /> <span style={{ cursor: 'pointer', fontWeight:'bold' }}>innhold oppdatert</span></span>
			</Popover2>
		</RenderTicketWrapper>
	)
}
