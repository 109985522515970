import axios from 'axios'
import jose from 'node-jose'
import { User } from '../Domain/Common/User'
import Configuration from './Configuration'

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAwek4mZq1OPlJBa4gasJW
GqM6+yEMHON8hbeIc7GdsSuySn74BhK8h2buvaqbA7wbaxd4MJLnBzrDMWq4wJfj
RWSeWbQJhnKuoTdQKO20/R8cEeU2TBFq8CJ04aDidV1RyM+ElfWuFZxkh2uEowv6
Rw7jHzXZYRi8a6RAThYO1jivXxKQhPiQSk/D38M/6WmStRb7QpaXVu7sZWO+tQ1S
Ai79EPbAcSz1lFDqtrR2xnYFUkXz/sVMkkhFqr/Urap2UIIRxo/wveOwuSNtc22p
q7AJlBf4xrD4FOz/tud0obMdS4Spu6RH6u53nFYEwsnvckFJD1Co05S7CC10SzLV
Bn1htJWqndhH9XwcfHPk/KGSx9+JS8G9pOmRo+TMfmK6hq4D4L8bpkf3XDZofQ+R
o/A2d2TODRC8BLMZcm4GKRWn4PzqMd5XwHLKHPUpsGxBNl3+s3MSLSKpGTnyq1y4
WiDKkRBo9SHp2JqZJ72NnZR6t3dog41njFE8PuxezZfyXoTeVKk0BRMYKdG5rcfH
omJ32NcRVutuj+p3RnDIn7evpEcSdyXdHJ5MH3uH3SuqygSpEa6O0BL7Hvx8rE7E
LbTy8Y8VNZgZas9DShXjbI8R8XTmBKm2VDpNfx4KGouCN7HQAhsQHZZb/mWVSes+
Oau4gWDHXNtwGPeh15yu0a0CAwEAAQ==
-----END PUBLIC KEY-----`

const backend = axios.create({
	baseURL: Configuration.OAL_AUTH_API_URL,
	timeout: 10000,
	withCredentials: true,
})

export default class OalAuth {
	static keystore: jose.JWK.KeyStore | undefined

	static async init() {
		this.keystore = jose.JWK.createKeyStore()
		await this.keystore.add(PUBLIC_KEY, 'pem')
	}

	static logout(): Promise<{ data: { result: string } }> {
		return backend.post('/auth/logout')
	}

	static async validJWT(data: string): Promise<User | false> {
		try {
			if (this.keystore === undefined) {
				await this.init()
			}

			const jwt = jose.JWS.createVerify(this.keystore, { algorithms: ['RS256'] })
			return JSON.parse((await jwt.verify(data)).payload.toString())
		} catch (e) {
			console.error('JWT error:', e)
			return false
		}
	}

	static async fetchExistingJWT(): Promise<string | false> {
		try {
			const url = new URL(window.location.href)
			const data = await backend.get('/auth/jwt', {
				params: {
					logintoken: url.searchParams.get('logintoken'),
				},
			})
			return data.data.jwt
		} catch (e) {
			return false
		}
	}

	static redirectLogin() {
		const url = new URL(window.location.href)
		url.searchParams.delete('logintoken')
		window.location.href = Configuration.OAL_AUTH_URL + '/?return=' + encodeURIComponent(url.toString())
	}
}
