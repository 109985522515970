import React from 'react'
import { TicketUpdateTagAdded } from './types'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { RenderTag } from './RenderTag'
import { Icon } from '@blueprintjs/core'

export function RenderTicketUpdateTagAdded({ change }: { change: TicketUpdateTagAdded }) {
	return (
		<RenderTicketWrapper change={change}>
			<Icon icon="plus" /> <RenderTag id={change.payload} />
		</RenderTicketWrapper>
	)
}
