import React, { useEffect } from 'react'
import Search from '../../Containers/Doc/Search'

let escapeTimer: NodeJS.Timeout

export function SearchPopup() {
	const [open, setOpen] = React.useState(false)

	const [escape, setEscape] = React.useState(false)
	const [option, setOption] = React.useState(false)
	const [command, setCommand] = React.useState(false)
	const [lastOpenCombo, setLastOpenCombo] = React.useState(0)

	function downHandler({ key }: any): void {
		if (key === 'Escape') {
			setEscape(true)
			if (escapeTimer) {
				clearTimeout(escapeTimer)
			}
			escapeTimer = setTimeout(() => {
				setEscape(false)
			}, 500)
		}

		if (key === 'Alt') {
			setOption(true)
		}

		if (key === 'Meta') {
			setCommand(true)
		}
	}

	// If released key is our target key then set to false
	const upHandler = ({ key }: any): void => {
		if (key === 'Escape') {
			setEscape(false)
		}

		if (key === 'Alt') {
			setOption(false)
		}

		if (key === 'Meta') {
			setCommand(false)
		}
	}

	// If the user cmd+tabs or clicks away while holding down a key,
	// handle it here.
	const focusHandler = (): void => {
		setEscape(false)
		setOption(false)
		setCommand(false)
	}

	useEffect(() => {
		if (Date.now() - lastOpenCombo > 400 && !open && escape && (option || command)) {
			setOpen(true)
			setLastOpenCombo(Date.now())
		}

		if (Date.now() - lastOpenCombo > 400 && open && escape) {
			setOpen(false)
			setLastOpenCombo(0)
		}
	}, [open, lastOpenCombo, option, command, escape])

	// Add event listeners
	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)
		window.addEventListener('focus', focusHandler)
		window.addEventListener('blur', focusHandler)

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
			window.removeEventListener('focus', focusHandler)
			window.removeEventListener('blur', focusHandler)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Empty array ensures that effect is only run on mount and unmount

	return (
		<>
			{open && (
				<div
					onClick={() => {
						setLastOpenCombo(0)
						setOpen(false)
					}}
					style={{
						display: 'block',
						position: 'fixed',
						paddingTop: '5vh',
						backgroundColor: 'rgba(0,0,0,0.55)',
						top: 0,
						left: 0,
						zIndex: 10,
						width: '100%',
						height: '100vh',
					}}
				>
					<div
						onClick={(e) => e.stopPropagation()}
						style={{
							width: '600px',
							maxWidth: '90vw',
							margin: '0 auto',
							backgroundColor: '#fff',
							border: '1px solid #f0f0f0',
							borderRadius: 9,
							paddingLeft: 10,
							boxShadow: '0px 0px 31px -3px rgba(0,0,0,0.4)',
						}}
					>
						<Search large onSelect={() => setOpen(false)} />
					</div>
				</div>
			)}
		</>
	)
}
