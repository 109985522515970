import { Button, Callout, HTMLDivProps } from '@blueprintjs/core'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { uploadBlob } from '../DynamicContent/module/Files'

import 'react-image-crop/dist/ReactCrop.css'

type UploadCroppedProps = {
	/**
	 * Id referencing the entity that owns the image, f.ex 'product-12344124'
	 */
	id: string
	/**
	 * Minimum width and height of uploaded image, will give warning if smaller, and upscale to the minimum
	 */
	minWidth?: number
	minHeight?: number
	/**
	 * Aspect ratio, e.g. 16/9 or 1/1
	 */
	aspect?: 1
	/**
	 * Expected output width and height
	 */
	maxOutputWidth?: number
	maxOutputHeight?: number
	/**
	 * jpeg quality in percent, default 90
	 */
	jpegQuality?: number

	/**
	 * Upload button
	 */
	uploadButton?: boolean

	/**
	 * Returns a callback that triggers upload if uploadButton is false
	 *
	 * @param callback Callback to trigger upload
	 * @returns
	 */
	getUploadTrigger?: (callback: () => Promise<{ image: string; thumbnail: string } | undefined>) => void

	/**
	 * Returns a callback that triggers reset
	 *
	 * @param callback Callback to trigger reset
	 * @returns
	 */
	getResetTrigger?: (callback: () => void) => void

	/**
	 * Called when crop is active
	 */
	onCropActive?: (state: boolean) => void

	/**
	 * Called when image has been uploaded
	 * @param url Url to image
	 * @returns
	 */
	onChange?: (data: { image: string; thumbnail: string }) => void

	/**
	 * Set savig
	 */
	setSaving?: (saving: boolean) => void
} & HTMLDivProps

/**
 * Our own version of ReactCrop, with some extra features
 * @param props
 * @returns
 */
export default function UploadCropped(props: UploadCroppedProps) {
	const {
		id,
		minWidth = 512,
		minHeight = 512,
		aspect = 1,
		maxOutputWidth = 1024,
		maxOutputHeight = 1024,
		jpegQuality,
		uploadButton,
		getUploadTrigger,
		getResetTrigger,
		onCropActive,
		onChange,
		setSaving,
		...rest
	} = props

	const [url, setUrl] = useState<string>('')
	const [error, setError] = useState<string>('')
	const [warning, setWarning] = useState<string>('')
	const [crop, setCrop] = useState<Crop>()
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
	const previewCanvasRef = useRef<HTMLCanvasElement>(null)
	const imgRef = useRef<HTMLImageElement>(null)

	getResetTrigger &&
		getResetTrigger(() => {
			setUrl('')
			setCompletedCrop(undefined)
			setWarning('')
			setError('')
			onCropActive && onCropActive(false)
			setCrop({
				unit: '%',
				width: 50,
				height: 50,
				x: 0,
				y: 0,
			})
		})

	const save = useCallback(async () => {
		setSaving && setSaving(true)
		setError('')

		try {
			const squareBlob = await convertImage(
				maxOutputWidth,
				maxOutputHeight,
				minWidth,
				minHeight,
				imgRef,
				aspect,
				previewCanvasRef
			)
			const blob = await convertImage(maxOutputWidth, maxOutputHeight, minWidth, minHeight, imgRef, -1)

			if (!blob || !squareBlob) {
				console.error('Could not convert image to blob')
				setSaving && setSaving(false)
				return
			}

			const newUrl = await uploadBlob({
				blob,
				ext: 'jpg',
				uid: id || 'dashboard',
			})

			const newSquareUrl = await uploadBlob({
				blob: squareBlob,
				ext: 'jpg',
				uid: id || 'dashboard',
			})

			if (newUrl && newSquareUrl && onChange) {
				onChange({
					image: newUrl,
					thumbnail: newSquareUrl,
				})
			}

			return {
				image: newUrl ?? '',
				thumbnail: newSquareUrl ?? '',
			}
		} catch (e: any) {
			console.error('Upload error: ', e)
			setError('Upload error: ' + String(e.message))
		} finally {
			setSaving && setSaving(false)
		}
	}, [id, onChange, setSaving, maxOutputWidth, maxOutputHeight, minWidth, minHeight, aspect])

	useEffect(() => {
		if (!uploadButton && getUploadTrigger) {
			getUploadTrigger(() => save())
		}
	}, [getUploadTrigger, save, uploadButton])

	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) {
			setWarning('')
			setError('')
			setCrop({
				unit: '%',
				width: 50,
				height: 50,
				x: 0,
				y: 0,
			}) // Makes crop preview update between images.
			const reader = new FileReader()
			reader.addEventListener('load', () => setUrl(reader.result?.toString() || ''))
			reader.readAsDataURL(e.target.files[0])
		}
	}

	function onImageLoaded(_event: SyntheticEvent<HTMLImageElement, Event>) {
		if (!imgRef.current) {
			return
		}
		const width = imgRef.current.naturalWidth
		const height = imgRef.current.naturalHeight

		if (imgRef.current.naturalWidth < 512 || imgRef.current.naturalHeight < 512) {
			setWarning(
				`Bildet ditt er bare ${width}x${height} piksler stort. Det anbefales å bruke et bilde som er minst 512x512.`
			)
		}

		onCropActive && onCropActive(true)
	}

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0)
			}
		},
		[completedCrop],
		100
	)

	return (
		<div {...rest}>
			<div>
				<input type="file" accept="image/*,*.jpg,*.png" onChange={onSelectFile} />
				{warning ? (
					<Callout intent="warning" icon="warning-sign" className="mt-2">
						{warning}
					</Callout>
				) : null}
				{error ? (
					<Callout intent="danger" icon="error" className="mt-2">
						{error}
					</Callout>
				) : null}
			</div>
			{url ? (
				<ReactCrop
					style={{ marginTop: '1em' }}
					crop={crop}
					aspect={aspect}
					maxWidth={maxOutputWidth}
					maxHeight={maxOutputHeight}
					onChange={(_, percentCrop) => setCrop(percentCrop)}
					onComplete={(c) => setCompletedCrop(c)}
					minHeight={50}
				>
					<img src={url} alt="croppable" ref={imgRef} onLoad={onImageLoaded} />
				</ReactCrop>
			) : null}
			{completedCrop && url ? (
				<>
					<canvas
						ref={previewCanvasRef}
						style={{
							display: 'none',
							objectFit: 'contain',
							width: 1024,
							height: 1024,
						}}
					/>
					{uploadButton ? (
						<Button
							intent="success"
							onClick={(e) => {
								e.preventDefault()
								save()
							}}
						>
							Lagre
						</Button>
					) : null}
				</>
			) : null}
		</div>
	)
}

async function convertImage(
	maxOutputWidth: number,
	maxOutputHeight: number,
	minWidth: number,
	minHeight: number,
	imgRef: React.RefObject<HTMLImageElement>,
	aspect: number,
	previewCanvasRef?: React.RefObject<HTMLCanvasElement>
) {
	const obj = document.createElement('canvas')
	const img = imgRef.current

	if (!img) {
		return undefined
	}

	// For example minimum 512x512, but if bigger, minimum the size of image, max 1024
	obj.width = Math.max(
		Math.min(
			Math.min(maxOutputWidth, img.naturalWidth || maxOutputWidth),
			aspect === 1 ? img.naturalHeight || maxOutputWidth : maxOutputWidth
		),
		minWidth
	)
	obj.height = Math.max(
		Math.min(
			Math.min(maxOutputHeight, img.naturalHeight || maxOutputHeight),
			aspect === 1 ? img.naturalWidth || maxOutputHeight : maxOutputHeight
		),
		minHeight
	)

	const previewCanvas = previewCanvasRef?.current ?? undefined

	// If no previewCanvasRef, we are going to resize the original photo to the max size using it's own aspect ratio
	if (aspect === -1) {
		console.log('Aspect -1, resizing to max size with original aspect ratio', img.naturalWidth / img.naturalHeight)
		if (img.naturalWidth < maxOutputWidth && img.naturalHeight < maxOutputHeight) {
			obj.width = Math.round(img.naturalWidth)
			obj.height = Math.round(img.naturalHeight)
		} else if (img.naturalWidth > img.naturalHeight) {
			const aspect = img.naturalWidth / img.naturalHeight
			obj.width = maxOutputWidth
			obj.height = Math.round(maxOutputWidth / aspect)
		} else {
			// Smaller or equal, just use the original size
			const aspect = img.naturalHeight / img.naturalWidth
			obj.width = Math.round(maxOutputWidth / aspect)
			obj.height = maxOutputHeight
		}
		console.log('DrawImage', obj.width, obj.height)
		obj.getContext('2d')?.drawImage(img, 0, 0, obj.width, obj.height)
	} else if (previewCanvas) {
		// Todo, if we are going to support other aspect ratios than 1, something clever has to be done here I think
		console.log('square DrawImage', obj.width, obj.height)
		obj.getContext('2d')?.drawImage(previewCanvas, 0, 0, obj.width, obj.height)
	}

	const blob = await new Promise<Blob>((resolve, reject) =>
		obj.toBlob(
			(blob) => {
				if (blob) {
					resolve(blob)
				} else {
					reject(new Error('Could not generate jpeg image'))
				}
			},
			'image/jpeg',
			0.9
		)
	)
	return blob
}
