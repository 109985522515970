import { Callout, Colors, H2, Icon } from '@blueprintjs/core'
import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { TicketCreate } from '../../Components/Ticket'
import { TicketsList } from '../../Components/Ticket/List'
import { OALService } from '../../Domain/Common/OAL'
import { getServices } from '../../Services/OalApi'
import Container from './Container'

const CATEGORY_MAP: { [key: string]: string } = {
	GW: 'Gateways',
	SVC: 'Applikasjoner / APIs',
	MCR: 'MCR',
}
const STATUS_TEXT_MAP = {
	up: 'Operativ',
	down: 'Svarer ikke',
	pending: 'På vent',
	paused: 'Ingen monitorering',
	unstable: 'Ustabil',
}

export function ServicesIndex() {
	const [services, setServices] = React.useState<OALService[]>([])
	const [serviceCategories, setServiceCategories] = React.useState<string[]>([])

	useEffect(() => {
		;(async () => {
			// new category list
			let newServiceCategories: string[] = []

			// new service list
			const newServices = await getServices()

			// weed out categories
			newServices.forEach((ns) => {
				if (ns.category === 'INT') {
					return
				}
				if (!newServiceCategories.includes(ns.category)) {
					newServiceCategories.push(ns.category)
				}
			})

			// sort new categories
			newServiceCategories.sort()

			// sort new services by label
			newServices.sort((a, b) => {
				if (a.label < b.label) {
					return -1
				}
				if (a.label > b.label) {
					return 1
				}
				return 0
			})
			setServiceCategories(newServiceCategories)
			setServices(newServices)
		})()
	}, [])

	return (
		<Container>
			<Row>
				<Col xl={3} lg={4} sm={12}>
					<H2>Tjenester</H2>
					<p>
						Det er mange tannhjul i maskineriet vårt, og om du merker at noe lugger enten ute i felt, i
						studio eller på kontoret, kan du se om det er noe rødt på denne siden. Lyser det rødt her, har
						vi blitt varslet og er antakeligvis på saken allerede.
					</p>

					<Callout intent="danger" style={{ margin: '20px 0px' }}>
						Finner du potensielle feil på våre systemer, blir vi <strong>veldig glade</strong> om du
						rapporterer det inn sånn at vi kan finne ut av det.
					</Callout>

					<TicketCreate
						topic="service-reports"
						forcetags={['source:dashboard']}
						label={'Si fra om nedetid/feil'}
						intent="warning"
						createButtonText="Meld i fra!"
						describeElement={
							<>
								Forklar problemet så godt det lar seg gjøre i tekstfeltet under. Husk at vedkommende som
								leser dette ikke aner noe om <strong>hvor</strong> du er, <strong>hva</strong> du gjør
								eller <strong>hva du ønsker å oppnå</strong>.
							</>
						}
						createButtonProps={{
							large: true,
							fill: true,
							icon: 'document',
						}}
					/>

					<Callout title="Ikke nøl!" intent="warning" style={{ margin: '0px 0px 20px 0px' }}>
						Husk at om du har problemer med noe er sjansen stor for at andre også har det, så det er fint om
						alle bidrar med å melde fra, så vi alle får en bedre dag på jobb!
					</Callout>
					<TicketsList />
				</Col>
				<Col xl={9} lg={8} sm={12}>
					{serviceCategories.map((sc: string) => (
						<div key={sc}>
							<div style={{ fontSize: 20, paddingBottom: 15 }}>
								{CATEGORY_MAP[sc] !== undefined ? CATEGORY_MAP[sc] : sc}
							</div>
							<Row className="mb-3">
								{services
									.filter((s) => s.category === sc)
									.map((s) => (
										<Col key={s.id} sm={12} md={6} xl={3}>
											{RenderService(s)}
										</Col>
									))}
							</Row>
						</div>
					))}
				</Col>
			</Row>
		</Container>
	)

	function RenderService(s: OALService) {
		const COLOR_MAP = {
			paused: [Colors.GRAY5, Colors.GRAY1],
			pending: [Colors.GOLD5, Colors.GOLD1],
			up: [Colors.WHITE, '#333'],
			unstable: [Colors.RED2, Colors.WHITE],
			down: [Colors.RED2, Colors.WHITE],
		}
		return (
			<div
				style={{
					backgroundColor: COLOR_MAP[s.status][0],
					color: COLOR_MAP[s.status][1],
					marginBottom: 20,
					fontSize: 16,
					fontWeight: 400,
					padding: 10,
					borderRadius: 6,
					boxShadow: '1px 1px 13px -4px rgba(128,128,128,0.6)',
				}}
			>
				<span style={{ float: 'right' }}>
					{s.status === 'up' ? (
						<div className="pulsingwrapper">
							<Icon className="pulsingheart" icon="heart" iconSize={20} intent="danger" />
						</div>
					) : (
						<Icon icon="heart-broken" iconSize={20} color="#fff" />
					)}
				</span>
				<span>{s.label}</span>
				<div
					style={{
						fontWeight: s.status === 'up' ? 300 : 600,
						fontSize: 12,
						marginTop: 10,
						color: s.status === 'up' ? '#888' : '#fff',
					}}
				>
					{STATUS_TEXT_MAP[s.status]}
				</div>
			</div>
		)
	}
}

export default ServicesIndex
