import React from 'react'

interface IProps {
	waitBeforeShow: number
	children: any
}

const Delayed = ({ waitBeforeShow, children }: IProps) => {
	const [hidden, setHidden] = React.useState(true)

	React.useEffect(() => {
		let ts: NodeJS.Timeout = setTimeout(() => {
			setHidden(false)
		}, waitBeforeShow)
		return clearTimeout(ts)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return hidden ? <></> : children
}

export default Delayed
