import { Spinner, SpinnerSize } from '@blueprintjs/core'
import React from 'react'

export function LoadingLarge() {
	return (
		<div
			style={{
				textAlign: 'center',
				padding: '10vh',
			}}
		>
			<Spinner size={SpinnerSize.LARGE} />
		</div>
	)
}
