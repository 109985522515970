import React, { Component } from 'react'
import { InvoiceContainerProps, InvoiceContainerState } from '../../Domain/Invoice/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'
import AccessGroups from '../../Utils/AccessGroups'

class InvoiceContainer extends Component<InvoiceContainerProps, InvoiceContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'invoice',
						title: 'Hjem',
						icon: 'home',
						link: '/economy/invoice',
						active: isActivePage([/^\/economy\/invoice$/]),
						visibleOnlyTo: AccessGroups.InvoiceAny,
					},
					{
						id: 'invoice-tmlb',
						title: 'Trippel-M',
						icon: 'desktop',
						link: '/economy/invoice/tmlb',
						active: isActivePage([/\/economy\/invoice\/tmlb/]),
						visibleOnlyTo: AccessGroups.InvoiceTmlb,
					},
					{
						id: 'invoice-tmcv',
						title: 'Connected Venues',
						icon: 'satellite',
						link: '/economy/invoice/tmcv',
						active: isActivePage([/^\/economy\/invoice\/tmcv/]),
						visibleOnlyTo: AccessGroups.InvoiceTmcv,
					},
					{
						id: 'invoice-oss',
						title: 'Oslo Streamingsenter',
						icon: 'mobile-video',
						link: '/economy/invoice/oss',
						active: isActivePage([/^\/economy\/invoice\/oss/]),
						visibleOnlyTo: AccessGroups.InvoiceOss,
					},
					{
						id: 'invoice-opti',
						title: 'Optilux',
						icon: 'lightbulb',
						link: '/economy/invoice/opti',
						active: isActivePage([/^\/economy\/invoice\/opti/]),
						visibleOnlyTo: AccessGroups.InvoiceOpti,
					},
					{
						id: 'invoice-any',
						title: 'Support',
						icon: 'lifesaver',
						link: '/economy/invoice/any',
						active: isActivePage([/^\/economy\/invoice\/any/]),
						visibleOnlyTo: AccessGroups.DashboardAdmin,
					},
					{
						id: 'invoice-admin',
						title: 'Admin',
						icon: 'bank-account',
						link: '/economy/invoice/admin',
						active: isActivePage([/\/economy\/invoice\/admin/]),
						visibleOnlyTo: AccessGroups.AccountingAny,
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default InvoiceContainer
