import { Button, Colors, ControlGroup, Dialog, Intent } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { Filter } from './api'

interface ItemType {
	id: string
	label: string
	longLabel?: string
	extraLabel?: string
}

export const List = ({
	items,
	multiselect,
	selected,
	filters,
	onSelect,
	andOr,
	renderEdit,
}: {
	items: ItemType[]
	filters: Filter[]
	andOr: 'and' | 'or'
	renderEdit: (item: string) => JSX.Element
} & (
	| {
			multiselect: true
			selected: string[]
			onSelect: (items: string[]) => void
	  }
	| {
			multiselect: false
			selected: null | string
			onSelect: (item: string | null) => void
	  }
)) => {
	let resultItems: ItemType[] = []
	let showItems: ItemType[] = []
	let notInShowItems: ItemType[] = []

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [perPage, setPerPage] = useState(12)

	useEffect(() => {
		if (page > 0) setPage(0)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, andOr])

	const [page, setPage] = useState<number>(0)

	useEffect(() => {
		console.log('page changed')
	}, [page])

	if (filters.length === 0) {
		resultItems = items
		showItems = resultItems.slice(page * perPage, page * perPage + perPage)
		notInShowItems = resultItems.filter((item) => !showItems.includes(item))
	} else {
		resultItems = items.filter((item) => {
			const matches = filters.filter(
				(filter) => item.label.match(filter.regex) || (item.longLabel && item.longLabel.match(filter.regex))
			)
			if ((andOr === 'or' && matches.length > 0) || (andOr === 'and' && matches.length === filters.length)) {
				return true
			}
			return false
		})

		showItems = resultItems.slice(page * perPage, page * perPage + perPage)

		// array with items that match the filters but isn't in showItems
		notInShowItems = resultItems.filter((item) => !showItems.includes(item))
	}

	function countNumberOfSelectedThingsInNotShowItems() {
		// bastard way to see if  there's anything currently selected
		let selectedCount = 0
		notInShowItems.forEach((item) => {
			let currentlySelected = false
			if (multiselect) {
				currentlySelected = selected.includes(item.id)
			} else {
				currentlySelected = selected === item.id
			}
			if (currentlySelected) {
				selectedCount++
			}

		})
		return selectedCount
	}

	return (
		<div>
			{notInShowItems.length > 0 && countNumberOfSelectedThingsInNotShowItems() > 0 && (
				<div
					style={{
						padding: 10,
						backgroundColor: Colors.LIGHT_GRAY4,
						color: Colors.GRAY1,
						fontSize: 12,
						marginTop: 4,
						borderBottom: '2px solid #ccc',
					}}
				>
					Selected, but not visible in current search/filter/page
					{notInShowItems.map((item) => {
						let currentlySelected = false
						if (multiselect) {
							currentlySelected = selected.includes(item.id)
						} else {
							currentlySelected = selected === item.id
						}
						if (currentlySelected) {
							return (
								<ListItem
									key={item.id}
									item={item}
									selected={currentlySelected}
									onClick={() => {
										if (multiselect) {
											if (currentlySelected) {
												onSelect(selected.filter((s) => s !== item.id))
											} else {
												onSelect([...selected, item.id])
											}
										} else {
											if (currentlySelected) {
												onSelect(null)
											} else {
												onSelect(item.id)
											}
										}
									}}
									renderEdit={renderEdit}
								/>
							)
						} else {
							return <></>
						}
					})}
				</div>
			)}
			{showItems.map((item) => {
				let currentlySelected = false
				if (multiselect) {
					currentlySelected = selected.includes(item.id)
				} else {
					currentlySelected = selected === item.id
				}

				return (
					<ListItem
						key={item.id}
						item={item}
						selected={currentlySelected}
						onClick={() => {
							if (multiselect) {
								if (currentlySelected) {
									onSelect(selected.filter((s) => s !== item.id))
								} else {
									onSelect([...selected, item.id])
								}
							} else {
								if (currentlySelected) {
									onSelect(null)
								} else {
									onSelect(item.id)
								}
							}
						}}
						renderEdit={renderEdit}
					/>
				)
			})}
			{resultItems.length > perPage && (
				<ControlGroup fill style={{ marginTop: 20 }}>
					<Button icon="chevron-left" disabled={page === 0} onClick={() => setPage(page - 1)} />
					<Button disabled>
						{page + 1} / {Math.ceil(resultItems.length / perPage)}
					</Button>
					<Button
						icon="chevron-right"
						disabled={page === Math.floor(resultItems.length / perPage)}
						onClick={() => setPage(page + 1)}
					/>
				</ControlGroup>
			)}
		</div>
	)
}

const ListItem = ({
	item,
	selected,
	onClick,
	renderEdit,
}: {
	item: ItemType
	selected: boolean
	onClick: () => void
	renderEdit: (id: string) => React.ReactNode
}) => {
	const [edit, setEdit] = useState<boolean>(false)
	return (
		<ControlGroup>
			{edit && (
				<Dialog isOpen={edit} onClose={() => setEdit(false)}>
					<>{renderEdit(item.id)}</>
				</Dialog>
			)}
			<Button
				fill
				small
				style={{
					marginTop: 3,
					paddingTop: 4,
					paddingBottom: 5,
					border: '1px dotted rgba(100,100,100,0.2)',
					boxShadow: 'none',
				}}
				alignText="left"
				onClick={onClick}
				intent={selected ? Intent.PRIMARY : undefined}
				minimal={!selected}
			>
				{item.longLabel || item.label}
				{item.extraLabel !== undefined && (
					<div style={{ color: selected ? '#fff' : '#555', marginTop: 2, fontSize: 11 }}>
						{item.extraLabel}
					</div>
				)}
			</Button>
			<Button icon="edit" intent="warning" minimal onClick={() => setEdit(true)} />
		</ControlGroup>
	)
}
