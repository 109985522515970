import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Colors, ControlGroup, H3, InputGroup, PopoverPosition } from '@blueprintjs/core'
import { TESContext } from '../../../Utils/TESContext'
import Container from '../Container'
import { Switch } from '../Switch/interface'
import NetSwitchInterfaceRender from './Interface'
import { Col, Row } from 'reactstrap'
import { SwitchPendingChanges } from './SwitchPendingChanges'
import { NetworkSwitchSelect } from './NetworkSwitchSelect'
import { Popover2 } from '@blueprintjs/popover2'
import { MACInfo } from '../../../Domain/Net/Switch'
import { isEqual } from 'lodash'

interface Filter {
	id: string
	label: string
}

const filterUnsorted: Filter[] = [
	{ id: 'NDHD', label: 'NDHD' },
	{ id: 'HDND', label: 'HDND' },
	{ id: 'KVM', label: 'KVM' },
	{ id: 'EXT', label: 'EXT' },
	{ id: 'INT', label: 'INT' },
	{ id: 'COM', label: 'COM' },
	{ id: 'SURF', label: 'SURF' },
	{ id: 'VIDX', label: 'VIDX' },
	{ id: 'KVMTX', label: 'KVMTX' },
	{ id: 'DANTE', label: 'DANTE' },
	{ id: 'SPK', label: 'SPK' },
	{ id: 'MON', label: 'MON' },
	{ id: 'NEAT', label: 'NEAT' },
	{ id: 'PCR', label: 'PCR' },
	{ id: 'PTZ', label: 'PTZ' },
	{ id: 'ACR', label: 'ACR' },
	{ id: 'CCTV', label: 'CCTV' },
	{ id: 'RPI', label: 'RPI' },
	{ id: 'NUC', label: 'NUC' },
	{ id: 'MEET', label: 'MEET' },
	{ id: '#EMPTY#', label: '#EMPTY#' }, // edgecase modified later in the filter function
	{ id: '#ALL#', label: '#ALL#' }, // edgecase modified later in the filter function
]

// sort filterUnsorted by label
const filters: Filter[] = filterUnsorted.sort((a, b) => {
	if (a.label < b.label) return -1
	if (a.label > b.label) return 1
	return 0
})

export interface Interface {
	id: string
	switchId: string
	name: string
	description: string
	vlans: string[]
	trunkPort: boolean
	disabled: boolean
	poeEnabled: boolean
	state: boolean
	stpState: string
}

interface Props {}

interface SwitchUpdate {
	id: string
	operation: 'add' | 'update' | 'remove'
	data: Switch
}

function SwitchInterfaceIndex(props: Props) {
    const context = useContext(TESContext);

	const [ switches, setSwitches ] = useState<Switch[]>([]);
	const [ activeSwitch, setActiveSwitch ] = useState<string|null>(null);
	const [ activeTextFilter, setActiveTextFilter ] = useState<string>('');
	const [ activeFilter, setActiveFilter ] = useState<string[]>([]);
	const [ visibleActiveTextFilter, setVisibleActiveTextFilter ] = useState<string>('');
	const [ interfaces, setInterfaces ] = useState<Interface[]>([]);
	const [ macs, setMacs ] = useState<MACInfo[]>([]);

	const lastSwitch = useRef<string|null>(null);

	useEffect(() => {
		if (activeSwitch !== null && lastSwitch.current !== activeSwitch) {
			const current = switches.find(sw => sw.id === activeSwitch);
			if (current) {
				console.log("Request macs for ", current);

				context.rpc('getMacs.snmp.network.oal.no', { ip: current.ip, _timeout: 10000 }, (err: null | string, res?: MACInfo[]) => {
					if (res) {
						setMacs(oldMacs => {
							const newMacs = res
								.map(m => ({ ...m, switchIp: current.ip }))
								.filter(mac => oldMacs.find(oldMac => isEqual(oldMac, mac)) === undefined)
							return [...oldMacs, ...newMacs]
						})
						console.log(res)
					} else {
						console.error('tes(getMacs.snmp.network.oal.no) error: ', err)
					}
				})
			}
			lastSwitch.current = activeSwitch
		}
	}, [context, activeSwitch, switches])

	const throttleTimeout = useRef<ReturnType<typeof setTimeout>|null>(null);

	const handleUpdateInterfaces = (key: string, interfaces: Interface[], switchId?: string): void => {
		if (switchId) {
			setInterfaces(oldInterfaces => oldInterfaces.filter((i) => i.switchId !== switchId).concat(interfaces.map((i) => ({ ...i, switchId }))))
		}
	}

	const updateSwitch = useCallback((sw: Switch): void => {
		context.rpc('get.interface.switch.network.oal.no', { id: sw.id }, (err: any, int: Interface[]) => {
			if (!err) {
				if (int === null) {
					return
				}
				handleUpdateInterfaces(sw.id, int, sw.id)
			}
		})
	}, [context])

	useEffect(() => {
		if (context === undefined || context === null) return;

		const handleUpdateSwitches = (key: string, update: SwitchUpdate): void => {
			if (update.operation === 'add') {
				// only add if it's not already in the list
				setSwitches(oldSwitches => {
					if (oldSwitches.findIndex((s) => s.id === update.data.id) === -1) {
						return [ ...oldSwitches, update.data]
					} else {
						return oldSwitches.map((s) => {
							if (s.id === update.data.id) {
								return update.data
							}
							return s
						})
					}	
				})
				updateSwitch(update.data)
			} else if (update.operation === 'update') {
				setSwitches(oldSwitches => oldSwitches.map((sw) => {
					if (sw.id === update.id) {
						return update.data
					}

					return sw
				}))
				updateSwitch(update.data)
			} else if (update.operation === 'remove') {
				setSwitches(oldSwitches => oldSwitches.filter((sw) => sw.id !== update.id))
			}
		};
	
	
		const handleUpdateInterfaceState = (key: string, payload: any) => {
			setInterfaces(oldInterfaces => oldInterfaces.map((i) => {
				if (i.switchId === payload.id && i.id === payload.interface) {
					return { ...i, state: payload.state }
				}

				return i
			}))
		}
	
		const handleUpdateInterfaceSTP = (key: string, payload: any) => {
			setInterfaces(oldInterfaces => oldInterfaces.map((i) => {
				if (i.switchId === payload.id && i.id === payload.interface) {
					return { ...i, stpState: payload.stpState }
				}

				return i
			}))
		}

		context.subscribe('updates.switch.network.oal.no', handleUpdateSwitches)
		context.subscribe('updates.interface.switch.network.oal.no', handleUpdateInterfaces)
		context.subscribe('stp.interface.switch.network.oal.no', handleUpdateInterfaceSTP)
		context.subscribe('state.interface.switch.network.oal.no', handleUpdateInterfaceState)

		context.rpc('get.switch.network.oal.no', {}, (err: any, res: Switch[]) => {
			if (!err) {
				res.forEach((sw: Switch) => {
					updateSwitch(sw)
				})
				setSwitches(res)
			}
		})

		return () => {
			context.unsubscribe('updates.switch.network.oal.no', handleUpdateSwitches)
			context.unsubscribe('updates.interface.switch.network.oal.no', handleUpdateInterfaces)
			context.unsubscribe('stp.interface.switch.network.oal.no', handleUpdateInterfaceSTP)
			context.unsubscribe('state.interface.switch.network.oal.no', handleUpdateInterfaceState)
		}
	}, [context, updateSwitch])

	type MacIP = Record<string, string | null>
	const [macIps, setMacIps] = useState<MacIP>({})
	const updatingIp = useRef<Record<string, boolean>>({})

	const getIp = useCallback((mac: string) => {
		if (macIps[mac] !== undefined) return macIps[mac]

		console.log('getIp', mac, JSON.stringify(updatingIp.current, null, 2))
		if (!updatingIp.current[mac]) {
			if (Object.keys(updatingIp.current).length > 48) {
				console.log("Already updating 48 macs, not updating more, until we are finished with them")
				return null
			}

			// Prevent multiple requests for same mac
			updatingIp.current[mac] = true
			console.log("Ongoing requests(+1): ", Object.keys(updatingIp.current).length)

			context.rpc('getIpForMac.snmp.network.oal.no', { mac, _timeout: 10000 }, (err: null | string, ip?: string) => {
				if (!err) {
					delete updatingIp.current[mac]
					console.log("Ongoing requests(-1): ", Object.keys(updatingIp.current).length)
					setMacIps((prev) => {
						return {
							...prev,
							[mac]: ip || null
						}
					})
				} else {
					console.error('tes(getMacs.snmp.network.oal.no) error: ', err)
				}
			});
		}

		return macIps[mac] || null
	}, [ macIps, context, updatingIp ])

	// Not sure if it was an issue before, but I don't
	// think we need this throttling
	const throttleChangeFilter = (text: string) => {
		if (throttleTimeout.current) {
			clearTimeout(throttleTimeout.current)
		}
		
		throttleTimeout.current = setTimeout(() => {
			setActiveTextFilter(text)
		}, 100)
	}

	return (
		<Container>
			<Row>
				<Col>
					<H3>Nettverksporter</H3>
					<p>Her kan du søke opp nettverksporter du har tilgang til å endre i OAL-systemet</p>
				</Col>
			</Row>

			{switches === null ? (
				<div>Loading..</div>
			) : (
				<Row>
					<Col md={9}>
						<div>
							<ControlGroup>
								{activeSwitch !== null && (
									<>
										<Button
											icon="arrow-left"
											style={{ borderRightWidth: 0 }}
											onClick={() => {
												let found = false
												switches
													.slice()
													.reverse()
													.find((sw) => {
														if (found) {
															setActiveSwitch(sw.id)
															return true
														}
														if (sw.id === activeSwitch) found = true
														return false
													})
											}}
										/>
										<Button
											icon="arrow-right"
											style={{ borderRightWidth: 0 }}
											onClick={() => {
												let found = false
												switches.find((sw) => {
													if (found) {
														setActiveSwitch(sw.id)
														return true
													}
													if (sw.id === activeSwitch) found = true
													return false
												})
											}}
										/>
									</>
								)}
								<NetworkSwitchSelect
									onChange={(sw) => {
										setActiveSwitch(sw)
									}}
									switches={switches}
									value={activeSwitch}
								/>
								<InputGroup
									type="text"
									placeholder="Interface description.."
									value={visibleActiveTextFilter}
									rightElement={
										<>
											{visibleActiveTextFilter.length > 1 && (
												<Button
													icon="cross"
													intent={
														activeTextFilter.length > 0
															? 'danger'
															: undefined
													}
													onClick={() => {
														setActiveTextFilter('')
														setVisibleActiveTextFilter('')
													}}
												/>
											)}
										</>
									}
									onChange={(e) => {
										setVisibleActiveTextFilter(e.target.value)
										if (e.target.value === '' || e.target.value.length > 1) {
											throttleChangeFilter(e.target.value)
										}
									}}
									leftIcon="search"
								/>

								<Popover2
									defaultIsOpen={false}
									position={PopoverPosition.BOTTOM}
									content={
										<div className="p-2" style={{ maxWidth: '90vw', width: 400 }}>
											{filters.map((f) => (
												<Button
													minimal
													outlined={
														activeFilter.includes(f.id) ? true : undefined
													}
													onClick={(e) => {
														if (activeFilter.includes(f.id)) {
															// remove filter
															setActiveFilter(activeFilter.filter(
																(fid) => fid !== f.id
															))
														} else {
															setActiveFilter([...activeFilter, f.id])
														}
													}}
													key={f.id}
												>
													{f.label}
												</Button>
											))}
										</div>
									}
								>
									<Button
										icon="filter"
										intent={activeFilter.length ? 'success' : undefined}
									/>
								</Popover2>
								{activeFilter.length > 0 && (
									<>
										{activeFilter.map((currentActiveFilter: string) => (
											<Button
												key={currentActiveFilter}
												onClick={(e) => {
													// all filters without this one
													setActiveFilter(activeFilter.filter(
														(f) => f !== currentActiveFilter
													))
												}}
												intent="success"
												minimal
												rightIcon="cross"
											>
												{currentActiveFilter}
											</Button>
										))}
									</>
								)}
							</ControlGroup>
						</div>
						{switches.map((sw) => (
							<Fragment key={sw.id}>
								{(activeSwitch === sw.id ||
									(activeSwitch === null &&
										(activeTextFilter.length > 0 ||
											activeFilter.length > 0))) && (
									<>
										<div
											style={{
												borderBottom: '1px solid #eee',
												color: '#999',
												fontSize: 17,
												paddingTop: 14,
												paddingBottom: 6,
												paddingLeft: 16,
											}}
										>
											<strong>
												{sw.label} ({sw.ip})
											</strong>
										</div>
										{
											<SwitchInterfaces
												key={sw.id}
												sw={sw}
												getIp={getIp}
												interfaces={interfaces.filter(
													(i) => i.switchId === sw.id
												)}
												macs={macs.filter((m) => m.switchIp === sw.ip)}
												activeFilter={activeFilter}
												activeTextFilter={activeTextFilter}
												isOpen={activeSwitch === sw.id}
											/>
										}
									</>
								)}
							</Fragment>
						))}
					</Col>
					<Col md={3}>
						<SwitchPendingChanges></SwitchPendingChanges>
					</Col>
				</Row>
			)}
		</Container>
	)
}

const filterMatcher = (originalDescription: string, filters: string[], textFilter: string) => {
	let found = false

	if (filters.indexOf('#ALL#') !== -1) return true

	if (filters.indexOf('#EMPTY#') !== -1) {
		if (originalDescription.length === 0 || originalDescription === '(null)') {
			return true
		}
	} else if (
		filters.indexOf('#EMPTY#') === -1 &&
		(originalDescription.length === 0 || originalDescription === '(null)')
	) {
		return false
	}

	for (const filter of filters) {
		if (originalDescription.toLowerCase().includes(filter.toLowerCase())) {
			found = true
			break
		}
	}

	if (found && textFilter.length === 0) return true

	// make regexp with textFilter
	const regexp = new RegExp(textFilter, 'i')
	return filters.length === 0 ? regexp.test(originalDescription) : found && regexp.test(originalDescription)
}

interface SwitchInterfacesProps {
	sw: Switch
	interfaces: Interface[]
	isOpen: boolean
	activeFilter: string[]
	activeTextFilter: string
	macs: MACInfo[]
	getIp: (mac: string) => string | null
}

function SwitchInterfaces(props: SwitchInterfacesProps) {

	return (
		<>
			{props.interfaces === null ? (
				<div>Loading..</div>
			) : (
				<div>
					{props.interfaces.map((int, idx) => {
						return (
							<Fragment key={int.id}>
								{int &&
									int.description !== undefined &&
									filterMatcher(
										int.description,
										props.activeFilter,
										props.activeTextFilter
									) && (
										<div
											key={int.id}
											style={{
												backgroundColor: idx % 2 ? Colors.LIGHT_GRAY5 : Colors.LIGHT_GRAY4,
											}}
										>
											<NetSwitchInterfaceRender getIp={props.getIp} interface={int} switch={props.sw} macs={props.macs} />
										</div>
									)}
							</Fragment>
						)
					})}
				</div>
			)}
		</>
	)

}

export default SwitchInterfaceIndex
