import { Colors, ControlGroup, Icon, IconName } from '@blueprintjs/core'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

interface TreeMenuItem {
	id: string
	label: string
	parent: null | string
	deleted: boolean
	onClick?: () => void
	icon: IconName
}

export interface TreeMenuProps {
	items: TreeMenuItem[]
	structure: string
	title: string
	pageKey: string | null
	startKey: string | null
	topItems: { id: string; label: string }[]
}

export interface TreeMenuItemProps {
	item: TreeMenuItem
	items: TreeMenuItem[]
	pageKey: string | null
	structure: string
	depth: number
	depthSinceActive: null | number
}

export const TreeMenu = ({ items, title, structure, pageKey, startKey, topItems }: TreeMenuProps) => {
	return (
		<>
			<ControlGroup vertical>
				<Link to={'/doc/' + structure} className="muted-link" style={{ color: 'white' }}>
					<div
						style={{
							fontWeight: 'bold',
							backgroundColor: Colors.LIGHT_GRAY5,
							color: Colors.DARK_GRAY5,
							borderLeft:
								window.location.pathname.split('/').length === 3
									? '5px solid #ac1c21'
									: '5px solid transparent',
							padding: '10px',
							paddingLeft: 4,
							marginBottom: '2px',
							borderRadius: 4,
						}}
					>
						<Icon icon="chevron-down" /> {title}
					</div>
				</Link>
				{topItems.map((item) => (
					<Link
						key={item.id}
						to={'/doc/' + structure + '/' + item.id}
						className="muted-link"
						style={{ color: 'black', marginBottom: 3 }}
					>
						<div
							style={{
								backgroundColor: Colors.LIGHT_GRAY5,
								color: Colors.DARK_GRAY5,
								fontWeight: 'bold',
								padding: 10,
							}}
						>
							<Icon icon="chevron-down" /> {item.label}
						</div>
					</Link>
				))}
				{items
					.filter((f) => f.parent === (startKey || 'root'))
					.map((item) => (
						<Fragment key={item.id}>
							<TreeMenuItemElement
								pageKey={pageKey}
								item={item}
								items={items}
								structure={structure}
								depth={0}
								depthSinceActive={null}
							/>
						</Fragment>
					))}
			</ControlGroup>
		</>
	)
}

const TreeMenuItemElement = ({ item, items, structure, pageKey, depth, depthSinceActive }: TreeMenuItemProps) => {
	depth++

	return (
		<Fragment>
			{depthSinceActive === null && depth < 4 && !item.deleted && (
				<>
					<Link
						to={'/doc/' + structure + '/' + item.id}
						style={{
							padding: '5px 9px 5px 5px',
							display: 'block',
							color: '#444',
							backgroundColor: item.id === pageKey ? Colors.WHITE : Colors.WHITE,
							borderLeft: item.id === pageKey ? '5px solid #ac1c21' : '5px solid transparent',
							fontWeight: item.id === pageKey ? 'bold' : 'normal',
							marginBottom: 4,
							borderRadius: 4,
							paddingBottom: 5,
						}}
						className="muted-link treemenu-link"
					>
						<Icon icon={item.icon} /> {item.label}
					</Link>

					<div style={{ paddingLeft: 15, display: 'block' }}>
						<div style={{ paddingLeft: 15, borderLeft: '2px solid #ddd', display: 'block' }}>
							<div>
								{items
									.filter((f) => f.parent === item.id)
									.map((child) => (
										<TreeMenuItemElement
											pageKey={pageKey}
											structure={structure}
											key={child.id}
											item={child}
											items={items}
											depth={depth}
											depthSinceActive={depthSinceActive}
										/>
									))}
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	)
}
