import { DynamicContentModuleProps, DynamicContentModuleEditProps } from '../interface'
import { Col, Row } from 'reactstrap'
import { Button, Card, ControlGroup, FormGroup, H3, Icon, InputGroup } from '@blueprintjs/core'
import iconnames from './iconnames'
import { uuid4 } from '@sentry/utils'
import { Link } from 'react-router-dom'

export const View = (props: DynamicContentModuleProps) => {
	return (
		<div style={{ marginLeft: -10, marginBottom: 20 }}>
			<Row>
				{props.block.payload &&
					props.block.payload.items &&
					props.block.payload.items.map((item: any) => (
						<Link to={item.url} target={item.newTab ? '_new' : '_self'} className="col-md-6 muted-link">
							<Card interactive elevation={1} style={{ textAlign: 'center' }} className="pt-4 pb-3 mb-3">
								<Icon icon={item.icon} iconSize={40} />
								<H3 className="mt-3">{item.label}</H3>
							</Card>
						</Link>
					))}
			</Row>
		</div>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	const change = (value: string | boolean, id: string, key: string) => {
		props.onChange({
			...props.block,
			payload: {
				...props.block.payload,
				items: props.block.payload.items.map((i: any) => {
					if (i.id === id) {
						return {
							...i,
							[key]: value,
						}
					}
					return i
				}),
			},
		})
	}

	return (
		<FormGroup>
			<Button
				className="mb-2"
				onClick={() =>
					props.onChange({
						...props.block,
						payload: {
							...props.block.payload,
							items: [
								...(props.block.payload && props.block.payload.items ? props.block.payload.items : []),
								{
									id: uuid4(),
									icon: 'link',
									label: '',
									url: '',
									newTab: false,
								},
							],
						},
					})
				}
			>
				<Icon icon="plus" iconSize={20} />
				<span className="pl-2">Add</span>
			</Button>
			<Row>
				{props.block.payload &&
					props.block.payload.items &&
					props.block.payload.items.map((item: any) => (
						<Col md={6} key={item.id}>
							<Card interactive elevation={1} style={{ textAlign: 'center' }} className="pt-4 pb-3 mb-3">
								<Icon icon={item.icon} iconSize={40} />

								<div>
									<select onChange={(e: any) => change(e.target.value, item.id, 'icon')}>
										{iconnames.map((icon: string) => (
											<option key={icon} value={icon} selected={item.icon === icon}>
												{icon}
											</option>
										))}
									</select>
								</div>

								<InputGroup
									placeholder="Label"
									value={item.label}
									className="mt-1"
									onChange={(e: any) => change(e.target.value, item.id, 'label')}
								/>
								<InputGroup
									placeholder="URL"
									value={item.url}
									className="mt-1"
									onChange={(e: any) => change(e.target.value, item.id, 'url')}
								/>
								<ControlGroup fill className="mt-2">
									<Button
										intent={item.newTab ? 'success' : 'primary'}
										onClick={() => change(!item.newTab, item.id, 'newTab')}
									>
										{item.newTab ? 'Open in new tab' : 'Open in same tab'}
									</Button>

									<Button
										icon="trash"
										intent="danger"
										onClick={() =>
											props.onChange({
												...props.block,
												payload: {
													...props.block.payload,
													items: props.block.payload.items.filter(
														(i: any) => i.id !== item.id
													),
												},
											})
										}
									/>
								</ControlGroup>
							</Card>
						</Col>
					))}
			</Row>
		</FormGroup>
	)
}
