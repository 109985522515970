import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HomeIndexProps } from '../../Domain/Home/Index'
import Container from './Container'
import { DynamicContent } from '../../Components/DynamicContent'
import Crumbs from '../../Components/Crumbs'
import { TreeMenu } from '../../Components/TreeMenu'
import { Col, Row } from 'reactstrap'
import {
	BreadcrumbProps,
	Button,
	Callout,
	ControlGroup,
	Dialog,
	H3,
	HTMLSelect,
	Icon,
	IconName,
	Spinner,
} from '@blueprintjs/core'
import { uuid4 } from '@sentry/utils'
import { InlineEdit } from '../../Components/InlineEdit'
import { Popover2 } from '@blueprintjs/popover2'
import { TESContext } from '../../Utils/TESContext'
import { VisibleOnlyTo } from '../../Utils/VisibleOnlyTo'
import Search from './Search'

const lengthAdjust = 0

type Folder = {
	id: string
	label: string
	icon: IconName
	deleted: boolean
	parent: string | 'root' | null
}
const RenderParentOptions = (
	folders: Folder[],
	parent: string | null,
	exclude: string,
	depth: number = 0
): JSX.Element => {
	const self = folders.find((f) => f.id === parent)
	const children = folders.filter((f) => f.parent === parent)
	return (
		<Fragment key={parent}>
			{self && (
				<option value={self.id} disabled={self.id === exclude}>
					{'- '.repeat(depth)}
					{self.label}
				</option>
			)}
			{children && children.map((f) => RenderParentOptions(folders, f.id, exclude, depth + 1))}
		</Fragment>
	)
}

const RenderFolder = (
	folder: Folder,
	folders: Folder[],
	setFolders: React.Dispatch<React.SetStateAction<Folder[]>>,
	setDirty: React.Dispatch<React.SetStateAction<boolean>>
) => {
	return (
		<div key={folder.id}>
			<div className="d-flex align-items-center">
				{!folder.deleted && (
					<>
						<Popover2
							position="right"
							usePortal={false}
							content={
								<div className="p-3">
									<ControlGroup vertical>
										<Button
											onClick={() => {
												let newObjects: any = [
													...folders,
													{
														id: uuid4().substring(10, 20),
														label: 'Nytt dokument',
														icon: 'document',
														deleted: false,
														parent: folder.id,
													},
												]

												/*	newObjects = newObjects.map((obj:any) => {
											if (obj.id === '044ef287fa' || obj.id === '03452185f1') {
												return {
													...obj,
													parent: 'root',
												}
											}
											return obj;
										})*/

												setFolders(newObjects as any)
												setDirty(true)
											}}
											icon="add-to-folder"
										>
											Nytt underdokument
										</Button>
										<Popover2
											content={
												<Button
													intent="danger"
													onClick={() => {
														// set deleted flag
														setFolders(
															folders.map((f) => {
																if (f.id === folder.id) {
																	return {
																		...f,
																		deleted: true,
																	}
																}
																return f
															})
														)
														setDirty(true)
													}}
												>
													Bekreft
												</Button>
											}
										>
											<Button
												icon="trash"
												fill
												disabled={
													folder.id === 'root' ||
													!!folders.find((f) => f.parent === folder.id)
												}
											>
												Slett
											</Button>
										</Popover2>
										<HTMLSelect
											fill
											disabled={folder.id === 'root'}
											onChange={(e) => {
												const newFolders = folders.map((f) => {
													if (f.id === folder.id) {
														return {
															...f,
															parent: e.target.value,
														}
													}
													return f
												})
												setFolders(newFolders)
												setDirty(true)
											}}
										>
											<option>(Velg toppnivå)</option>
											<option value="root">(Toppnivå)</option>
											{RenderParentOptions(folders, 'root', folder.id, 0)}
										</HTMLSelect>
									</ControlGroup>
								</div>
							}
						>
							<span style={{ marginRight: 5 }}>
								<Icon icon={folder.icon} />
							</span>
						</Popover2>
						{folder.parent !== null ? (
							<InlineEdit
								value={folder.label}
								onChange={(label) => {
									const newFolders = folders.map((f) => {
										if (f.id === folder.id) {
											return { ...f, label }
										}
										return f
									})
									// sort newFolders alphabetically by label
									setFolders(
										newFolders.sort((a, b) => {
											if (a.label < b.label) {
												return -1
											}
											if (a.label > b.label) {
												return 1
											}
											return 0
										})
									)
									setDirty(true)
								}}
							/>
						) : (
							folder.label
						)}
					</>
				)}
			</div>
			<div style={{ paddingLeft: 30 }}>
				{folders
					.filter((f) => f.parent === folder.id)
					.map((f) => RenderFolder(f, folders, setFolders, setDirty))}
			</div>
		</div>
	)
}

type ServerStructure = {
	id: string
	folders: Folder[]
	createdAt?: number
	updatedAt?: number
	createdBy?: string
	updatedBy?: string
}

const Index = (props: HomeIndexProps) => {
	return (
		<Container>
			{props.structure && <Docs key={props.structure} structureLabel={props.label} structure={props.structure} />}
		</Container>
	)
}

const Docs = (props: { structure: string; structureLabel: string }) => {
	const [edit, setEdit] = useState<boolean>(false)
	const [folders, setFolders] = useState<Folder[]>([])
	const [serverStructure, setServerStructure] = useState<ServerStructure>({ id: 'service', folders: [] })
	const [foldersBusy, setFoldersBusy] = useState<boolean>(true)
	const [dirty, setDirty] = useState<boolean>(false)
	const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([])
	const [skippedMenuCrumbs, setSkippedMenuCrumbs] = useState<any[]>([])
	const [menuStartId, setMenuStartId] = useState<string | null>(null)
	const TES = useContext(TESContext)
	const [structureId] = useState<string>(props.structure)

	// useLocation
	const location = useLocation()
	//const [path, setPath] = useState<string>(location.pathname)
	const [pageKey, setPageKey] = useState<string | null>(null)

	useEffect(() => {
		setBreadcrumbs([{ text: props.structureLabel, href: `/doc/${structureId}`, icon: 'book' }])
	}, [props.structureLabel, structureId, pageKey])

	useEffect(() => {
		//setPath(location.pathname)
		let splitpath = location.pathname.split('/')
		if (splitpath.length === 4) {
			setPageKey(splitpath[3])
		} else {
			setPageKey(null)
		}
	}, [location])

	const handleGetStructure = async (id: string): Promise<ServerStructure> => {
		return new Promise((resolve, reject) => {
			setFoldersBusy(true)
			TES.rpc('getStructure.dashboard.oal.no', { id }, (err, res) => {
				setFoldersBusy(false)
				if (err) {
					reject(err)
					return
				}
				let _serverStructure: ServerStructure = res as ServerStructure
				setServerStructure(_serverStructure)
				resolve(res)
			})
		})
	}

	const handleSetStructure = async (structure: ServerStructure): Promise<ServerStructure> => {
		return new Promise((resolve, reject) => {
			TES.rpc('setStructure.dashboard.oal.no', { structure }, (err, res) => {
				if (err) {
					reject(err)
					return
				}
				let _serverStructure: ServerStructure = res as ServerStructure
				setServerStructure(_serverStructure)
				resolve(res)
			})
		})
	}

	useEffect(() => {
		const initial = async () => {
			if (structureId) {
				let structure: ServerStructure | null = null
				try {
					structure = await handleGetStructure(structureId)
				} catch (err) {
					structure = null
					console.error(err)
				}

				if (structure && structure.folders) {
					setFolders(structure.folders)
				}
			}
		}

		initial()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [structureId])

	useEffect(() => {
		if (folders && pageKey) {
			let folder = folders.find((f) => f.id === pageKey)
			if (folder) {
				let crumbs: Folder[] = []
				const recurse = (id: string) => {
					let folder = folders.find((f) => f.id === id)
					if (folder) {
						if (folder.parent) {
							recurse(folder.parent)
						}
						crumbs.push(folder)
					}
				}
				if (folder.parent !== null) recurse(folder.parent)
				let genCrumbs: any[] = crumbs.map((f) => ({
					text: f.label,
					href: `/doc/${structureId}/${f.id}`,
					id: f.id,
				}))
				if (genCrumbs.length > 1) {
					let skippedCrumbs: any[] = genCrumbs.slice(0, genCrumbs.length - lengthAdjust)

					if (genCrumbs[genCrumbs.length - 1]) {
						setMenuStartId(genCrumbs[genCrumbs.length - 1].id)
						setSkippedMenuCrumbs(skippedCrumbs)
					}
					//genCrumbs = genCrumbs.slice(genCrumbs.length - lengthAdjust)
				} else {
					setSkippedMenuCrumbs([])
					setMenuStartId(null)
				}
				setBreadcrumbs([
					{ text: props.structureLabel, href: `/doc/${structureId}`, icon: 'book' },
					...genCrumbs,
					{ text: folder.label, icon: 'document', href: `/doc/${structureId}/${folder.id}` },
				])
			}
		} else {
			if (menuStartId !== null || skippedMenuCrumbs.length > 0) {
				setMenuStartId(null)
				setSkippedMenuCrumbs([])
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folders, pageKey])

	useEffect(() => {
		// async effect

		const handleFolderUpdate = async () => {
			// folder updated
			setDirty(false)
			if (!structureId) {
				// no structure, ignoring
				return
			}

			const currentServerStructure = await handleGetStructure(structureId)

			if (JSON.stringify(currentServerStructure) !== JSON.stringify(serverStructure)) {
				console.error('FEIL SOM FAEN. KOLLIDERENDE')
				return
			}

			try {
				await handleSetStructure({ ...serverStructure, id: structureId, folders })
			} catch (err) {
				console.error('handleSetStructure', err)
			}
		}

		if (dirty) handleFolderUpdate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folders, dirty])

	const dynamicId = structureId + '-' + (pageKey || 'root')
	const pageTitle = folders.find((f) => f.id === pageKey)?.label
	return (
		<>
			<Dialog
				isOpen={edit}
				onClose={() => setEdit(false)}
				style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'scroll', paddingBottom: 20 }}
			>
				<div className="p-4">
					{RenderFolder(
						{
							id: 'root',
							label: 'Root',
							deleted: false,
							icon: 'folder-close',
							parent: null,
						},
						folders,
						setFolders,
						setDirty
					)}
				</div>
			</Dialog>
			{foldersBusy ? (
				<Spinner />
			) : (
				<Row>
					<Col md={3} id="sidemenu">
						<div style={{ paddingTop: 3, paddingBottom: 5 }}>
							<Search />
						</div>
						<TreeMenu
							title={props.structureLabel || '?'}
							structure={props.structure}
							pageKey={pageKey}
							items={folders}
							startKey={menuStartId}
							topItems={skippedMenuCrumbs.map((item) => ({ id: item.id, label: item.text }))}
						/>
						<VisibleOnlyTo groups={['DA-DASHBOARD-ADMIN', 'DA-SAMLA-DBADMIN']}>
							<Button
								className="mt-3 MobileHidden"
								icon="edit"
								onClick={() => setEdit(!edit)}
								intent={edit ? 'primary' : 'none'}
							>
								Endre
							</Button>
						</VisibleOnlyTo>
						<div className="MobileHidden">
							<VisibleOnlyTo groups={['DA-ANY-EMPLOYED', 'DA-SAMLA-USER']}>
								<Callout intent="primary" className="hide-on-mobile">
									Ønsker man å legge til eller endre kategorier i menyen, ta det opp på{' '}
									<code style={{ fontWeight: 'bold' }}>#dashboard</code> på Slack, så ordner vi det.
								</Callout>
							</VisibleOnlyTo>
						</div>
					</Col>
					<Col md={9} id="maincontent">
						<div
							style={{
								display: 'block',
								padding: '5px 0px',
							}}
							className="MobileHidden"
						>
							<Crumbs breadcrumbs={breadcrumbs} />
						</div>
						<div className="OnlyMobileVisible">
							<div style={{ paddingTop: 30, display: 'block' }}></div>
						</div>
						{pageTitle && <H3>{pageTitle}</H3>}
						<section id="content">
							<DynamicContent uri={dynamicId} key={dynamicId} noTitle />
						</section>
					</Col>
				</Row>
			)}
		</>
	)
}

export default Index
