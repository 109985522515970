import { Button, Callout, H4, InputGroup } from '@blueprintjs/core'
import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import Loading from '../../Components/Loading/Loading'
import { ToolsTripletexProps, ToolsTripletexState } from '../../Domain/Tools/Tripletex'
import { PageContext } from '../../Utils/LoginContext'
import { exportTripletexProject } from '../../Services/Tools'

import Container from './Container'

class ToolsTripletex extends Component<ToolsTripletexProps, ToolsTripletexState> {
	static contextType = PageContext
	constructor(props: ToolsTripletexProps) {
		super(props)
		this.state = {
			loading: false,
			statusMessage: '',
            statusMessageSeverity: 'none',
			projectNumber: '',
		}
	}

	findCompany() {
		if (this.state.projectNumber[0] === '1') return 'tmlb'
		else if (this.state.projectNumber[0] === '2') return 'oss'
		return ''
	}

	async exportInvoice() {
		this.setState({
			loading: true,
		})

		try {
			const data = await exportTripletexProject(this.findCompany(), this.state.projectNumber)

			if (data[0].value.name) {
				this.setState({
					projectNumber: '',
					statusMessage: 'Prosjekt opprettet',
                    statusMessageSeverity: 'success',
					loading: false,
				})
			} else {
				this.setState({
					projectNumber: '',
					statusMessage: 'Noe gikk galt',
                    statusMessageSeverity: 'danger',
					loading: false,
				})
			}

			setTimeout(() => {
				this.setState({
					statusMessage: '',
				})
			}, 2000)


		} catch (error) {
			this.setState({
				projectNumber: '',
				statusMessage: 'Noe gikk galt',
                statusMessageSeverity: 'danger',
				loading: false,
			})

			setTimeout(() => {
				this.setState({
					statusMessage: '',
				})
			}, 2000)

			console.error(error)
		}
	}

	render() {
		return (
			<>
				{this.state.loading ? (
					<>
						<Loading />
					</>
				) : (
					<Container>
						{this.state.statusMessage ? (
							<Row className="mb-4 mt-2">
								<Col sm={3}>
                                    <Callout intent={this.state.statusMessageSeverity as any}>
									    <H4>{this.state.statusMessage}</H4>
                                    </Callout>
								</Col>
							</Row>
						) : null}
						<H4>Eksporter prosjekt til Tripletex</H4>
						<Row className="mb-4">
							<Col sm={12} md={3}>
								<InputGroup
									placeholder="Prosjektnummer"
									onChange={(e) => {
										this.setState({ projectNumber: e.target.value })
									}}
									value={this.state.projectNumber}
								/>
							</Col>
							<Col sm={12} md={3}>
								<Button
									disabled={this.state.projectNumber.length === 0}
									onClick={() => this.exportInvoice()}
								>
									Eksporter
								</Button>
							</Col>
						</Row>
					</Container>
				)}
			</>
		)
	}
}
export default ToolsTripletex
