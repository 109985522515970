import {
	Button,
	Callout,
	Colors,
	Dialog,
	H3,
	IconName,
	InputGroup,
	Intent,
	Spinner,
	SpinnerSize,
	ButtonProps
} from '@blueprintjs/core'
import { useHistory } from 'react-router-dom'
import React, { CSSProperties } from 'react'
import Editor from 'rich-markdown-editor'
import { ourTheme } from '../DynamicContent/module/MD'
import { postTicket } from './api'
import { NewTicket } from './types'

export const DialogStyle: CSSProperties = {
	padding: 20,
	marginRight: 10,
	marginLeft: 10,
	maxHeight: '90vh',
	width: '90vw',
	maxWidth: 1200,
}

export function TicketCreate(props: {
	topic: string
	forcetags: string[]
	label: string
	intent: Intent
	buttonIcon?: IconName
	fill?: boolean
	createButtonProps: ButtonProps
	createButtonText: string
	describeElement?: React.ReactNode
	titlePlaceholder?: string
	bodyPlaceholder?: string
}) {
	const [isOpen, setIsOpen] = React.useState<boolean>(false)
	const [payload, setPayload] = React.useState<string>('')
	const [title, setTitle] = React.useState<string>('')
	const [working, setWorking] = React.useState<boolean>(false)
	const [error, setError] = React.useState<string | null>(null)
	const history = useHistory()
	//sendTicket

	const sendTicket = async () => {
		const newTicket: NewTicket = {
			title,
			payload,
			state: 'open',
			type: 'md',
			tags: ['topic:' + props.topic, ...props.forcetags],
		}

		const newTicketResult = await postTicket(newTicket)
		history.push('/sak/' + newTicketResult.id)
	}

	return (
		<div>
			<Dialog
				isOpen={isOpen}
				style={DialogStyle}
				onClose={() => {
					if (!working) {
						setIsOpen(false)
						setError(null)
					}
				}}
			>
				{working ? (
					<div style={{ margin: '0 auto', padding: 60 }}>
						<Spinner size={SpinnerSize.LARGE} />
					</div>
				) : (
					<>
						<H3>{props.label}</H3>
						{props.describeElement && <p>{props.describeElement}</p>}
						<InputGroup
							type="text"
							placeholder={props.titlePlaceholder || 'Overskrift...'}
							large
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							className="mb-2"
						/>
						<div style={{ backgroundColor: Colors.WHITE }}>
							<Editor
								defaultValue={payload}
								placeholder={props.bodyPlaceholder || 'Tekst...'}
								onChange={(val) => {
									setPayload(val())
								}}
								autoFocus
								style={{ minHeight: 75, padding: 30, maxHeight: 'calc(70vh)', overflow: 'scroll' }}
								theme={ourTheme}
							/>
						</div>

						<div>
							<Button
								intent={props.intent}
								large
								fill
								onClick={async () => {
									setWorking(true)

									try {
										await sendTicket()
										setIsOpen(false)
										setError(null)
										setTitle('')
										setPayload('')
									} catch (e: any) {
										setError(e?.message || 'Unknown error')
									} finally {
										setWorking(false)
									}
								}}
							>
								Send inn
							</Button>
						</div>
						{error !== null && (
							<div>
								<Callout intent="danger" title="Error">
									{error}
								</Callout>
							</div>
						)}
					</>
				)}
			</Dialog>
			<Button intent={props.intent} {...props.createButtonProps} onClick={() => setIsOpen(true)}>
				{props.createButtonText}
			</Button>
		</div>
	)
}
