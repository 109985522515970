import React from 'react'
import DynamicContent from '../../Components/DynamicContent'
import { NetIndexProps } from '../../Domain/Net/Index'
import Container from './Container'

const NetIndex: React.FC<NetIndexProps> = (props) => {
	return (
		<Container>
			<DynamicContent uri="net" />
		</Container>
	)
}

export default NetIndex
