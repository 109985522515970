const AccessGroups = {
	//Admin
	DashboardAdmin: ['DA-DASHBOARD-ADMIN'],
	DashboardOalContacts: ['DA-DASHBOARD-OAL-CONTACTS'],

	// Products
	ProductCategoryAdmin: ['DA-PRODUCT-CATEGORY-ADMIN'],

	//Applications
	AppsAny: ['DA-ESTORE-RW'],
	EstoreRw: ['DA-ESTORE-RW'],

	// Invoice
	InvoiceAny: [
		'DA-TMLB-FREELANCE',
		'DA-OSS-FREELANCE',
		'DA-TMCV-FREELANCE',
		'DA-OPTI-FREELANCE',
		'DA-OAL-ACCOUNTING',
		'DA-TMLB-ACCOUNTING',
		'DA-TMCV-ACCOUNTING',
		'DA-OPTI-ACCOUNTING',
		'DA-OSS-ACCOUNTING',
		'DA-NLTV-ACCOUNTING',
	],
	InvoiceOss: ['DA-OSS-FREELANCE'],
	InvoiceOpti: ['DA-OPTI-FREELANCE'],
	InvoiceTmlb: ['DA-TMLB-FREELANCE'],
	InvoiceTmcv: ['DA-TMCV-FREELANCE'],

	// Mcr
	Mcr: ['DA-OPS-MCR'],
	Net: ['DA-OPS-NET-RO', 'DA-OPS-NET-RW'],

	// Net
	NetRo: ['DA-OPS-NET-RO'],
	NetRw: ['DA-OPS-NET-RW'],

	Ndi: ['DA-NDI-RO', 'DA-NDI-RW'],
	NdiRo: ['DA-NDI-RO'],
	NdiRw: ['DA-NDI-RW'],

	// Vpn
	Vpn: ['NET-VPN-OAL'],

	//Accounting
	AccountingAny: [
		'DA-OAL-ACCOUNTING',
		'DA-TMLB-ACCOUNTING',
		'DA-TMCV-ACCOUNTING',
		'DA-OPTI-ACCOUNTING',
		'DA-OSS-ACCOUNTING',
		'DA-NLTV-ACCOUNTING',
	],
	AccountingOal: ['DA-OAL-ACCOUNTING'],
	AccountingTmlb: ['DA-TMLB-ACCOUNTING'],
	AccountingTmcv: ['DA-TMCV-ACCOUNTING'],
	AccountingOpti: ['DA-OPTI-ACCOUNTING'],
	AccountingNltv: ['DA-NLTV-ACCOUNTING'],
	AccountingOss: ['DA-OSS-ACCOUNTING'],

	// Employed
	EmployedAny: [
		'DA-TMCV-EMPLOYED',
		'DA-TMLB-EMPLOYED',
		'DA-OAL-EMPLOYED',
		'DA-OSS-EMPLOYED',
		'DA-OPTI-EMPLOYED',
		'DA-NLTV-EMPLOYED',
	],
	EmployedTmcv: ['DA-TMCV-EMPLOYED'],
	EmployedOss: ['DA-OSS-EMPLOYED'],
	EmployedTmlb: ['DA-TMLB-EMPLOYED'],
	EmployedOal: ['DA-OAL-EMPLOYED'],
	EmployedOpti: ['DA-OPTI-EMPLOYED'],
	EmployedNltv: ['DA-NLTV-EMPLOYED'],

	OrgNRK: ['ORG-NRK'],

	// Project managers
	ProjectManagerAny: ['DA-TMLB-PROJECTMANAGER', 'DA-OPTI-PROJECTMANAGER'],
	ProjectManagerTmlb: ['DA-TMLB-PROJECTMANAGER'],
	ProjectManagerOpti: ['DA-OPTI-PROJECTMANAGER'],
}

export default AccessGroups
