import React, { Component } from 'react'
import { McrEditProps, McrEditState } from '../../Domain/Mcr/Edit'
import Container from './Container'

class McrEdit extends Component<McrEditProps, McrEditState> {
	render() {
		return <Container>mcr edit</Container>
	}
}

export default McrEdit
