import React from 'react'
import './Loading.scss'

interface Props {
	type?: 'rentman'
}

interface State {}

export class Loading extends React.Component<Props, State> {
	render() {
		if (this.props.type === 'rentman')
			return (
				<div className="LoadingContainer">
					<img alt="rentman" src="/logos/rentman.png" className="LoadingRentmanBox" />
					<img src="/logos/trippelm.png" alt="trippelm" className="LoadingTrippelmBall" />
				</div>
			)

		return (
			<div className="LoadingContainer">
				<img src="/logos/trippelm.png" alt="trippelm" className="LoadingTrippelmSlowBall" />
			</div>
		)
	}
}

export default Loading
