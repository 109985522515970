import { Component } from 'react'
import { HomeTodoProps, HomeTodoState } from '../../Domain/Home/Todo'
import Container from '../Home/Container'
import App from '../../Components/Board/App'

class HomeTodo extends Component<HomeTodoProps, HomeTodoState> {
	render() {
		return (
			<Container>
				<div>
					<App />
				</div>
			</Container>
		)
	}
}

export default HomeTodo
