import { ProjectAnalyticsContainer, ProjectAnalyticsContainerNoSubmenu, useSortTH } from './Container'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { PageContext } from '../../../Utils/LoginContext'
import { Callout, Card, FormGroup, H3, HTMLSelect, Spinner } from '@blueprintjs/core'
import './Analytics.scss'
import { DateRangePicker } from '@blueprintjs/datetime'
import { Col, Row } from 'reactstrap'
import { OALContactCard } from '../../../Domain/Common/OAL'
import { getContactsAll } from '../../../Services/Contacts'
import { useApiData } from '../../../Utils/UseAPI'
import {
	Config,
	TripletexLedgerPosting,
	TripletexTimesheetEntry,
	getConfigs,
	getRentmanProjectHourAnalytics,
	getRentmanProjectTypes,
	getTripletexAcccountPostings,
	getTripletexTimesheet,
} from '../../../Services/OalApi'
import { Link } from 'react-router-dom'

const dateFormatter = new Intl.DateTimeFormat('nb-NO', {
	day: 'numeric',
	month: 'long',
	year: 'numeric',
})

const numberFormatting = (num: number) => {
	return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

const hourFormatting = (num: number) => {
	return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 1 })
}

export const ProjectAnalyticsHours = ({ company }: { company: string }) => {
	const context = useContext(PageContext)
	const now = new Date()
	const [startDate, setStartDate] = useState<Date | null>(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0))
	const [endDate, setEndDate] = useState<Date | null>(new Date(now.getFullYear(), now.getMonth() + 1, 1, 0, -1, 0, 0))
	const [contacts, setContacts] = useState<OALContactCard[]>([])
	const [externalFolders, setExternalFolders] = useState<number[]>([])
	const [internalFolders, setInternalFolders] = useState<number[]>([])
	const [tripletexPostingData, setTripletexPostingData] = useState<TripletexLedgerPosting[]>([])
	const [loadingTripletexPostingData, setLoadingTripletexPostingData] = useState<boolean>(false)
	const [tripletexTimesheetData, setTripletexTimesheetData] = useState<TripletexTimesheetEntry[]>([])
	const [loadingTripletexTimesheetData, setLoadingTripletexTimesheetData] = useState<boolean>(false)

	const [customerFilter, setCustomerFilter] = useState<string>('')
	const [accountManagerFilter, setAccountManagerFilter] = useState<string>('')
	const [salesrepFilter, setSalesrepFilter] = useState<string>('')
	const [projectTypeFilter, setProjectTypeFilter] = useState<string>('')

	const { data: configuration, error: configurationError } = useApiData<Config[]>(
		() => getConfigs(`pa-*-${company},pa-tooltip-*`),
		[]
	)

	const get = useCallback(
		(key: string) => {
			const config = configuration?.find((config) => config.key === key)
			return config?.value
		},
		[configuration]
	)

	const {
		data: rentmanData,
		reload: reloadData,
		loading: loadingRentmanData,
	} = useApiData(
		(...args) => getRentmanProjectHourAnalytics.apply(null, args as any),
		company,
		startDate,
		endDate,
		internalFolders
	)
	const dataRef = useRef<typeof rentmanData>()

	const [sorting, SortTH] = useSortTH(get)

	const { data: projectTypes } = useApiData(getRentmanProjectTypes, company)

	useEffect(() => {
		setExternalFolders(get('pa-rentman-folder-external-' + company) ?? [])
		setInternalFolders(get('pa-rentman-folder-internal-' + company) ?? [])
	}, [configuration, get, company])

	useEffect(() => {
		reloadData(
			company,
			startDate?.toISOString().substr(0, 10),
			endDate?.toISOString().substr(0, 10),
			internalFolders
		)
	}, [company, startDate, endDate, internalFolders, externalFolders, reloadData])

	useEffect(() => {
		if (!rentmanData?.length) {
			setTripletexPostingData([])
			setTripletexTimesheetData([])
			return
		}
		if (dataRef.current === rentmanData) {
			//console.log('Data is the same, skipping tripletex-data fetch')
			return
		}
		dataRef.current = rentmanData

		setLoadingTripletexPostingData(true)
		;(async () => {
			try {
				const data = await getTripletexAcccountPostings(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: []
				)
				setTripletexPostingData(data)
				// Request more live data in the meantime:
				const newData = await getTripletexAcccountPostings(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: [],
					true
				)
				setTripletexPostingData(newData)
			} catch (e) {
				console.error(e)
			} finally {
				setLoadingTripletexPostingData(false)
			}
		})()

		setLoadingTripletexTimesheetData(true)
		;(async () => {
			try {
				const data = await getTripletexTimesheet(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: []
				)
				setTripletexTimesheetData(data)
				// Request more live data in the meantime:
				const newData = await getTripletexTimesheet(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: [],
					true
				)
				setTripletexTimesheetData(newData)
			} catch (e) {
				console.error(e)
			} finally {
				setLoadingTripletexTimesheetData(false)
			}
		})()
	}, [company, startDate, endDate, rentmanData])

	useEffect(() => {
		;(async () => {
			const res = await getContactsAll()
			// sort res object by name
			res.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})

			setContacts(res)
		})()
	}, [setContacts])

	let groups = context.user?.groups || []
	const hasAccess = groups.find((group) => group === 'DA-' + company.toUpperCase() + '-PROJECTMANAGER')

	/*const rentmanSum = {
		income: 0,
		sold_hours: 0,
	}
	const tripletexSum = {
		revenue: 0,
		subhire_costs: 0,
		external_crew_cost: 0,
		internal_crew_cost: 0,
		transport: 0,
		other_costs: 0,
		db: 0,
		dbdifferanse: 0,
	}*/

	const inRange = (number: number, range: { from: number; to?: number }[]) => {
		return range.some((r) => {
			if (r.to) {
				return number >= r.from && number <= r.to
			} else {
				return number === r.from
			}
		})
	}

	const getTripletexSum = useCallback(
		(type: 'revenue' | 'subhire' | 'crew-internal' | 'crew-external' | 'transport' | 'rest', project: number) => {
			const pnr = String(project)

			return Math.abs(
				tripletexPostingData
					?.filter((posting) => {
						return (
							inRange(posting.account, get('pa-tripletex-' + type + '-' + company)) &&
							posting.project === pnr
						)
					})
					.reduce((acc, posting) => {
						acc += posting.amount
						return acc
					}, 0) || 0
			)
		},
		[tripletexPostingData, company, get]
	)

	const getTripletexHours = useCallback(
		(activity: 'tekniker' | 'prosjektleder' | 'forarbeid' | '*', project: number) => {
			const pnr = String(project)

			return Math.abs(
				tripletexTimesheetData
					?.filter((entry) => {
						return (
							(activity === '*' ||
								(get(`pa-tripletex-activity-${activity}-${company}`) || []).includes(entry.activity)) &&
							entry.project === pnr
						)
					})
					.reduce((acc, posting) => {
						acc += posting.hours
						return acc
					}, 0) || 0
			)
		},
		[tripletexTimesheetData, company, get]
	)

	const filteredList = useMemo(
		() =>
			(
				rentmanData?.filter((project) => {
					if (customerFilter !== '') {
						if (
							!(
								project.customer_id === parseInt(customerFilter) ||
								(project.customer_id === null && customerFilter === '-1')
							)
						) {
							return false
						}
					}
					if (accountManagerFilter !== '') {
						if (
							!(
								project.account_manager_phone === accountManagerFilter ||
								(project.account_manager_phone === null && accountManagerFilter === '-1')
							)
						) {
							return false
						}
					}
					if (salesrepFilter !== '') {
						if (
							!(
								project.salesrep_phone === salesrepFilter ||
								(project.salesrep === null && salesrepFilter === '-1')
							)
						) {
							return false
						}
					}
					if (projectTypeFilter !== '') {
						if (project.project_type_id !== parseInt(projectTypeFilter)) {
							return false
						}
					}
					return true
				}) || []
			)
				.map((project) => {
					const data: typeof project & {
						result: number
						tcost: number
						punched_hours: number
						diff: number
						tekniker: number
						prosjektleder: number
						forarbeid: number
					} = {
						...project,
						tcost: getTripletexSum('crew-internal', project.number),
						result: project.income - getTripletexSum('crew-internal', project.number),
						punched_hours: getTripletexHours('*', project.number),
						diff: project.sold_hours - getTripletexHours('*', project.number),
						tekniker: getTripletexHours('tekniker', project.number),
						prosjektleder: getTripletexHours('prosjektleder', project.number),
						forarbeid: getTripletexHours('forarbeid', project.number),
					} as any

					return data
				})
				.sort((a: any, b: any) => {
					if (!sorting) return 0
					const aVal = a[sorting.replace('-', '') as any]
					const bVal = b[sorting.replace('-', '') as any]

					if (aVal === bVal) return 0

					if (sorting.startsWith('-')) {
						if (aVal < bVal) return 1
						if (aVal > bVal) return -1
					} else {
						if (aVal < bVal) return -1
						if (aVal > bVal) return 1
					}

					return 0
				}),
		[
			rentmanData,
			customerFilter,
			accountManagerFilter,
			salesrepFilter,
			projectTypeFilter,
			sorting,
			getTripletexSum,
			getTripletexHours,
		]
	)

	const projectTypesList = useMemo(
		() =>
			projectTypes?.sort((a, b) => {
				const aNum = parseInt((a.name.match(/\d+/) || ['0'])[0])
				const bNum = parseInt((b.name.match(/\d+/) || ['0'])[0])

				// Move text to bottom
				if (aNum === 0 && bNum !== 0) return 1
				if (aNum !== 0 && bNum === 0) return -1

				// Sort numbered items
				if (aNum === 0 && bNum === 0) return aNum > bNum ? 1 : -1
				if (aNum < bNum) return -1
				if (aNum > bNum) return 1

				return 0
			}) || [],
		[projectTypes]
	)

	const customers = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				acc[project.customer_id !== null ? project.customer_id : -1] = project.customer_displayname
				return acc
			}, {} as Record<number, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: String(key), name: hash[parseInt(key)] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData])

	const accountManagerList = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				if (!project.account_manager_phone || project.account_manager_phone === null) {
					acc['-1'] = '- Uten prosjektleder -'
				} else {
					acc[project.account_manager_phone] =
						contacts.find(
							(contact) =>
								contact.groups.includes('DA-' + company.toUpperCase() + '-EMPLOYED') &&
								contact.phone === project.account_manager_phone
						)?.name || `(Ikke ansatt!) ${project.account_manager} (${project.account_manager_phone})`
				}
				return acc
			}, {} as Record<string, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: key, name: hash[key] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData, contacts, company])

	const salesrepList = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				if (!project.salesrep_phone || project.salesrep_phone === null) {
					acc['-1'] = '- Uten kunderådgiver -'
				} else {
					acc[project.salesrep_phone] =
						contacts.find(
							(contact) =>
								contact.groups.includes('DA-' + company.toUpperCase() + '-EMPLOYED') &&
								contact.phone === project.salesrep_phone
						)?.name || `(Ikke ansatt!) ${project.salesrep} (${project.salesrep_phone})`
				}
				return acc
			}, {} as Record<string, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: key, name: hash[key] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData, contacts, company])

	if (!hasAccess) {
		return (
			<ProjectAnalyticsContainerNoSubmenu>
				<p>Du har ikke tilgang til dette selskapet</p>
			</ProjectAnalyticsContainerNoSubmenu>
		)
	}

	const sums = {
		income: 0,
		tcost: 0,
		result: 0,
		sold_hours: 0,
		punched_hours: 0,
		diff: 0,
		tekniker: 0,
		prosjektleder: 0,
		forarbeid: 0,
	}

	return (
		<ProjectAnalyticsContainer company={company}>
			<Card elevation={1} className={'mb-4'}>
				<H3>Filter</H3>
				<Row>
					<Col md={5}>
						<div style={{ position: 'relative', display: 'inline-block' }}>
							<DateRangePicker
								value={[startDate, endDate]}
								shortcuts={false}
								allowSingleDayRange
								maxDate={new Date(now.getFullYear() + 1, 11, 31, 0, 0, 0, 0)}
								onChange={(dates) => {
									setStartDate(dates[0])
									setEndDate(dates[1])
								}}
							/>
							{loadingTripletexPostingData || loadingTripletexTimesheetData || loadingRentmanData ? (
								<div
									style={{
										backgroundColor: 'white',
										opacity: 0.5,
										position: 'absolute',
										top: 0,
										left: 0,
										zIndex: 100,
										width: '100%',
										height: '100%',
									}}
								>
									<div style={{ marginTop: '30px' }}>
										<Spinner size={128} />
									</div>
								</div>
							) : null}
						</div>
					</Col>
					<Col md={7}>
						<Row>
							<Col md={5}>
								<FormGroup label="Kunde">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={customerFilter}
										onChange={(e) => setCustomerFilter(e.target.value)}
									>
										<option value="">- Alle -</option>
										{customers.map((customer) => (
											<option key={customer.id} value={customer.id}>
												{customer.name || '- Uten kunde -'}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup label="Kunderådgiver">
									<HTMLSelect
										className={'mb-3'}
										value={salesrepFilter}
										onChange={(e) => setSalesrepFilter(e.target.value)}
										fill
									>
										<option value="">- Alle -</option>
										{salesrepList.map((contact) => (
											<option key={contact.id} value={contact.id}>
												{contact.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={5}>
								<FormGroup label="Prosjektleder">
									<HTMLSelect
										className={'mb-3'}
										value={accountManagerFilter}
										onChange={(e) => setAccountManagerFilter(e.target.value)}
										fill
									>
										<option value="">- Alle -</option>
										{accountManagerList.map((contact) => (
											<option key={contact.id} value={contact.id}>
												{contact.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup label="Prosjekttype">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={projectTypeFilter}
										onChange={(e) => setProjectTypeFilter(e.target.value)}
									>
										<option value="">- Alle -</option>
										{projectTypesList.map((type) => (
											<option value={type.id} key={type.id}>
												{type.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
			<Card elevation={1} className={'mb-4'} style={{ position: 'relative' }}>
				{loadingTripletexPostingData || loadingTripletexTimesheetData ? (
					<div title="Updating tripletex data" style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
						<Spinner size={16} />
					</div>
				) : null}
				<H3>Bemanning internt</H3>
				<table className="bp3-html-table modifier analyticstable">
					<thead>
						<tr>
							<SortTH name="number" style={{ textAlign: 'left' }}>
								Prosjekt
							</SortTH>
							<SortTH name="income" tooltipName="hours_income">
								Inntekt
							</SortTH>
							<SortTH name="tcost" tooltipName="hours_cost">
								Kostnad
							</SortTH>
							<SortTH name="result" tooltipName="result">
								Resultat
							</SortTH>
							<SortTH name="sold_hours" tooltipName="sold_hours">
								Solgte timer
							</SortTH>
							<SortTH name="punched_hours" tooltipName="punched_hours">
								Stemplede timer
							</SortTH>
							<SortTH name="diff" tooltipName="hours_diff">
								Diff
							</SortTH>
							<SortTH name="tekniker" tooltipName="tekniker">
								Teknikertimer
							</SortTH>
							<SortTH name="prosjektleder" tooltipName="prosjektleder">
								Prosjektledertimer
							</SortTH>
							<SortTH name="forarbeid" tooltipName="forarbeid">
								Forarbeidstimer
							</SortTH>
						</tr>
					</thead>
					<tbody>
						{filteredList?.map((project) => {
							sums.income += project.income
							sums.tcost += project.tcost
							sums.result += project.result
							sums.sold_hours += project.sold_hours
							sums.punched_hours += project.punched_hours
							sums.diff += project.diff
							sums.tekniker += project.tekniker
							sums.prosjektleder += project.prosjektleder
							sums.forarbeid += project.forarbeid

							return (
								<tr key={project.id}>
									<td>
										<strong>
											<Link
												title={'Information about ' + project.displayname}
												to={`/economy/projectanalytics/${company}/info/${project.id}`}
											>
												{project.displayname}
											</Link>
										</strong>
										<div style={{ fontSize: '0.85em', opacity: 0.5 }}>
											{project.usageperiod_start
												? dateFormatter.format(new Date(project.usageperiod_start))
												: 'N/A'}{' '}
											- {project.number}
										</div>
									</td>
									<td>
										<div>{numberFormatting(project.income)}</div>
									</td>
									<td>
										<div>{numberFormatting(project.tcost)}</div>
									</td>
									<td>
										<div>{numberFormatting(project.result)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.sold_hours)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.punched_hours)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.diff)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.tekniker)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.prosjektleder)}</div>
									</td>
									<td>
										<div>{hourFormatting(project.forarbeid)}</div>
									</td>
								</tr>
							)
						})}
						<tr>
							<th>Sum</th>
							<th>{numberFormatting(sums.income)}</th>
							<th>{numberFormatting(sums.tcost)}</th>
							<th>{numberFormatting(sums.result)}</th>
							<th>{hourFormatting(sums.sold_hours)}</th>
							<th>{hourFormatting(sums.punched_hours)}</th>
							<th>{hourFormatting(sums.diff)}</th>
							<th>{hourFormatting(sums.tekniker)}</th>
							<th>{hourFormatting(sums.prosjektleder)}</th>
							<th>{hourFormatting(sums.forarbeid)}</th>
						</tr>
					</tbody>
				</table>
				{configurationError ? (
					<Callout intent={'danger'} className="mt-3">
						{configurationError}
					</Callout>
				) : null}
			</Card>
		</ProjectAnalyticsContainer>
	)
}

export default ProjectAnalyticsHours
