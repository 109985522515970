import { Button, Callout, Classes, Dialog, H1, InputGroup, Label, NonIdealState, TextArea } from '@blueprintjs/core'
import ProdContainer from './Container'
import { useApiData } from '../../Utils/UseAPI'
import { ApiCategory, deleteCategory, getProductCategories, postCategory } from '../../Services/OalApi'
import { useCallback, useState } from 'react'
import UploadCropped from '../Crop/UploadCropped'

type SaveDialogOptions = {
	id?: number
	parent_id?: number
	name?: string
	description?: string
	image?: string
	fullimage?: string
}

export const ProductCategories = () => {
	const [saveDialog, setSavedialog] = useState<SaveDialogOptions | null>(null)

	const { data: categories, error: categoriesError, reload: reloadData } = useApiData(getProductCategories)
	const hasChildren =
		categories?.reduce<{ [key: number]: number }>((acc, cat) => {
			acc[cat.id] = categories.filter((subcat) => subcat.parent_id === cat.id).length
			return acc
		}, {}) || {}

	if (categoriesError)
		return (
			<ProdContainer>
				<NonIdealState title="Kunne ikke hente kategorier" icon="error" />
			</ProdContainer>
		)

	return (
		<ProdContainer>
			<H1>Produktkategorier</H1>
			<p>
				Hvis man trykker på den gule knappen eller dobbeltklikker på en kategori, så vil du se beskrivelse og
				bilde også, og har muligheten til å endre på disse i dialogen som popper opp.
			</p>
			{saveDialog !== null ? (
				<SaveDialog
					options={saveDialog}
					reloadData={reloadData}
					closeDialog={() => setSavedialog(null)}
					saveData={(category) => postCategory(category)}
				/>
			) : null}
			{categories !== null ? (
				categories?.length === 0 ? (
					<NonIdealState title="Ingen tidsregistreringer ennå!" icon="search" />
				) : (
					<Categories
						categories={categories}
						hasChildren={hasChildren}
						parentId={0}
						selected={saveDialog?.id}
						setSaveDialog={setSavedialog}
						reloadData={reloadData}
					/>
				)
			) : (
				<NonIdealState title="Laster kategorier..." icon="search" />
			)}
		</ProdContainer>
	)
}

const SaveDialog = ({
	options,
	closeDialog,
	saveData,
	reloadData,
}: {
	options: SaveDialogOptions
	closeDialog: () => void
	saveData: (category: ApiCategory) => void
	reloadData: () => void
}) => {
	const [name, setName] = useState<string>(options.name || '')
	const [description, setDescription] = useState<string>(options.description || '')
	const [error, setError] = useState<string>('')
	const [uploadTrigger, setUploadTrigger] = useState<() => Promise<{ image: string, thumbnail: string } | undefined>>()
	const [cropActive, setCropActive] = useState<boolean>(false)
	const [image, setImage] = useState<string>(options && typeof options.image === 'object' ? (options.image as any).thumbnail : options.image || '')
	const [saving, setSaving] = useState<boolean>(false)

	const handleUploadTrigger = useCallback((callback) => {
		setUploadTrigger(() => callback)
	}, [])

	const save = async () => {
		setSaving(true)

		try {
			let newImages: { image: string, thumbnail: string } | undefined
			if (cropActive) {
				newImages = await uploadTrigger?.()

				setImage(newImages?.thumbnail || '')
			}

			await saveData({
				id: options.id || 0,
				name: name,
				description: description,
				image: newImages?.thumbnail || image || '',
				fullimage: newImages?.image || options.fullimage || '',
				parent_id: options.parent_id || 0,
			})

			reloadData()
			closeDialog()
		} catch (e: any) {
			console.error('Upload error: ', e)
			setError('Upload error: ' + String(e.message))
		} finally {
			setSaving(false)
		}
	}

	return (
		<Dialog
			icon="info-sign"
			title={options.id ? 'Endre kategori' : 'Ny kategori'}
			isOpen={options !== null}
			onClose={() => closeDialog()}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					save()
				}}
			>
				<div className={Classes.DIALOG_BODY}>
					<Label>
						Kategorinavn
						<InputGroup
							autoFocus
							placeholder="Kategorinavn"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</Label>
					<Label>
						Beskrivelse
						<TextArea
							fill
							growVertically={true}
							placeholder="Beskrivelse"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</Label>
					<Label>Bilde</Label>
					{image && !cropActive ? <img src={image} style={{ maxWidth: '100%' }} alt={options.name} /> : null}
					<UploadCropped
						id={'category-' + (options.id || 0)}
						minWidth={512}
						minHeight={512}
						aspect={1}
						maxOutputWidth={1024}
						maxOutputHeight={1024}
						getUploadTrigger={handleUploadTrigger}
						onCropActive={setCropActive}
						setSaving={setSaving}
					/>
					{error ? (
						<Callout intent="danger" icon="error" className="mt-2">
							{error}
						</Callout>
					) : null}
				</div>
				<div className={Classes.DIALOG_FOOTER}>
					<div className={Classes.DIALOG_FOOTER_ACTIONS}>
						<Button disabled={!name} type="submit" intent="success" loading={saving}>
							{options.id ? 'Lagre' : 'Opprett'}
						</Button>
						<Button onClick={() => closeDialog()} intent="warning">
							Avbryt
						</Button>
					</div>
				</div>
			</form>
		</Dialog>
	)
}

const Categories = ({
	categories,
	parentId,
	hasChildren,
	selected,
	setSaveDialog,
	reloadData,
}: {
	categories: ApiCategory[]
	parentId: number
	hasChildren: { [key: number]: number }
	selected?: number
	setSaveDialog: (options: SaveDialogOptions) => void
	reloadData: () => void
}) => {
	const list = categories.filter((cat) => cat.parent_id === parentId)

	const deleteCat = async (id: number, text: string) => {
		if (window.confirm('Er du sikker på at du vil slette kategorien "' + text + '"?')) {
			try {
				await deleteCategory(id)
				reloadData()
			} catch (e: any) {
				alert('Kunne ikke slette kategorien... ' + e.message)
				console.error(e)
			}
		}
	}

	/* TODO: check if there are any products assigned to a category before you allow it to be deleted */

	return (
		<div>
			{categories?.length === 0 ? (
				<>
					<NonIdealState title="Ingen kategorier ennå!" icon="search" />
					<Button small icon="add" style={{ marginRight: '4px', marginBottom: '4px' }}>
						Ny kategori
					</Button>
				</>
			) : (
				<div>
					{list.map((category, index) => (
						<div key={index}>
							<div
								style={{
									display: 'inline-block',
									width: '200px',
									margin: '2px',
									padding: '4px',
									backgroundColor: selected === category.id ? '#fea' : '#eee',
									borderRight: '#a00',
									borderRightWidth: '8px',
									borderRightStyle: 'solid',
								}}
								onDoubleClick={() =>
									setSaveDialog({
										id: category.id,
										name: category.name,
										description: category.description,
										image: category.image,
										fullimage: category.fullimage,
										parent_id: category.parent_id,
									})
								}
							>
								{category.name}
							</div>
							<Button
								small
								intent="warning"
								icon="edit"
								style={{ marginRight: '4px' }}
								onClick={() =>
									setSaveDialog({
										id: category.id,
										name: category.name,
										description: category.description,
										image: category.image,
										fullimage: category.fullimage,
										parent_id: category.parent_id,
									})
								}
							/>
							{!hasChildren[category.id] ? (
								<Button
									small
									intent="success"
									icon="git-new-branch"
									style={{ marginRight: '4px' }}
									onClick={() => {
										setSaveDialog({
											parent_id: category.id,
										})
									}}
								></Button>
							) : null}
							{!hasChildren[category.id] ? (
								<Button
									small
									intent="danger"
									icon="trash"
									onClick={() => deleteCat(category.id, category.name)}
								/>
							) : null}
							<div style={{ marginLeft: '1rem' }}>
								{hasChildren[category.id] > 0 ? (
									<Categories
										categories={categories}
										parentId={category.id}
										hasChildren={hasChildren}
										selected={selected}
										setSaveDialog={setSaveDialog}
										reloadData={reloadData}
									/>
								) : null}
							</div>
						</div>
					))}
					<Button
						small
						icon="add"
						style={{ marginRight: '4px', marginBottom: '4px' }}
						onClick={() => setSaveDialog({ parent_id: parentId })}
					>
						Ny kategori
					</Button>
				</div>
			)}
		</div>
	)
}
