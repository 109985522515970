import React, { useCallback, useEffect } from 'react'
import { Block, PageV1 } from './index'
import { PopoverBody } from 'reactstrap'
import { Button, ButtonGroup, H5 } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { OALGroup } from '../OAL/Group'
import ModuleRegistry from './module/registry'
import { AccessEditor } from '../AccessEditor'
import { Prompt } from 'react-router-dom'
import { OwnerEditor } from '../OwnerEditor'

interface ContentEditBlockProps {
	block: Block
	blocks: Block[]
	onBlocksChange: (blocks: Block[]) => void
	onPageChange: (page: PageV1) => void
	page: PageV1
	onChange: (block: Block) => void
	editContext: React.Context<any>
}

export const ContentEditBlock = (props: ContentEditBlockProps) => {
	const { block, onChange, page, onPageChange } = props
	const [blockOur, setBlockOur] = React.useState<Block>(block)
	const [blockDirty, setBlockDirty] = React.useState<boolean>(false)
	const [blockChangedFromOutside, setBlockChangedFromOutside] = React.useState<boolean>(false)

	useEffect(() => {
		if (!blockDirty && blockChangedFromOutside) {
			setBlockOur(block)
			setBlockChangedFromOutside(false)
		}

		// Si ifra at man har ulagrede endringer (meldingen leveres av <Prompt />)
		if (blockDirty) {
			window.onbeforeunload = () => true
		} else {
			window.onbeforeunload = null
		}
	}, [block, blockDirty, blockChangedFromOutside])

	const moveUp = (id: string) => {
		const newBlocks = [...page.blocks]
		const i = newBlocks.indexOf(id)
		newBlocks.splice(i, 1)
		newBlocks.splice(i - 1, 0, id)
		onPageChange({ ...page, version: (page.version || 0) + 1, blocks: newBlocks })
	}

	const moveDown = (id: string) => {
		const newBlocks = [...page.blocks]
		const i = newBlocks.indexOf(id)
		newBlocks.splice(i, 1)
		newBlocks.splice(i + 1, 0, id)
		onPageChange({ ...page, version: (page.version || 0) + 1, blocks: newBlocks })
	}

	const blockChange = useCallback((block: Block) => {
		setBlockOur(block)
		setBlockDirty(true)
	}, [ setBlockOur, setBlockDirty ]);

	const shouldSave = useCallback((block: Block) => {
		setBlockOur(block)
		onChange(block)
		setBlockChangedFromOutside(false)
		setBlockDirty(false)
	}, [ onChange, setBlockChangedFromOutside, setBlockDirty ]);

	useEffect(() => {
		console.log("DEBUG: shouldSave was updated");
	}, [ shouldSave ]);

	const Edit = ModuleRegistry.edit[block.type]

	return (
		<div style={{ paddingBottom: 4, padding: 0, marginTop: 10 }} key={props.block.id}>
			{Edit && (
				<div className="stylethiseditor">
					<Edit uid={block.id} block={blockOur} onChange={blockChange} onShouldSave={shouldSave} />
				</div>
			)}
			<Prompt when={blockDirty} message="Du har ulagrede endringer, sikker på at du vil forlate siden?" />
			<div style={{ marginBottom: 20 }}>
				<ButtonGroup fill>
					<Button
						onClick={() => {
							onChange(blockOur)
							setBlockChangedFromOutside(false)
							setBlockDirty(false)
						}}
						disabled={!blockDirty}
						intent="success"
					>
						Lagre
					</Button>
					<Button
						onClick={() => {
							setBlockOur({ ...block })
							setBlockChangedFromOutside(false)
							setBlockDirty(false)
						}}
						disabled={!blockDirty && !blockChangedFromOutside}
						intent="warning"
					>
						Angre
					</Button>

					<Button
						icon="trash"
						onClick={() => {
							onPageChange({
								...page,
								version: (page.version || 0) + 1,
								blocks: page.blocks.filter((b) => b !== block.id),
							})
						}}
					>
						Slett
					</Button>
					<Button
						icon="chevron-up"
						onClick={() => moveUp(block.id)}
						style={{ paddingLeft: 15, paddingRight: 15 }}
						//disabled={page.blocks.indexOf(block.id) === 0}
					>
						Flytt opp
					</Button>
					<Button
						icon="chevron-down"
						onClick={() => moveDown(block.id)}
						//disabled={page.blocks.indexOf(block.id) === blocks.length - 1}
						style={{
							paddingLeft: 15,
							paddingRight: 15,
						}}
					>
						Flytt ned
					</Button>
					<Popover2
						position="top-right"
						content={
							<PopoverBody style={{ width: 400, padding: '8px' }}>
								<H5>Leserettigheter</H5>
								{/* READ */}
								<AccessEditor
									style={{ minHeight: 100 }}
									values={blockOur.accessRead}
									onChange={(newArray: string[]) => {
										setBlockDirty(true)
										setBlockOur({ ...blockOur, accessRead: newArray })
									}}
								/>
								<H5>Endringsrettigheter</H5>
								{/* READ */}
								<AccessEditor
									values={blockOur.accessWrite}
									onChange={(newArray: string[]) => {
										setBlockDirty(true)
										setBlockOur({ ...blockOur, accessWrite: newArray })
									}}
								/>
								<H5>Eier/Ansvarlig</H5>
								{/* OWNER, this is only visible for employees at the moment, which should be suficient */}
								<OwnerEditor
									value={blockOur.owner || ''}
									onChange={(newOwner: string) => {
										setBlockDirty(true)
										setBlockOur({ ...blockOur, owner: newOwner })
									}}
								/>
							</PopoverBody>
						}
					>
						<Button icon="eye-open">
							{blockOur.accessRead.length === 0
								? 'Synlig for alle'
								: blockOur.accessRead.map((group) => (
										<span key={group}>
											{' '}
											<OALGroup hideUnresolved={false} id={group} />
										</span>
								  ))}
							{blockOur.type === 'product' && blockOur.payload?.active ? (
								<OALGroup id={"EVERYONE"} />
							) : null}
						</Button>
					</Popover2>
				</ButtonGroup>
			</div>
		</div>
	)
}
