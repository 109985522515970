import { H3 } from '@blueprintjs/core'
import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import { TicketsList } from '../../Components/Ticket/List'
import { TicketMineProps, TicketMineState } from '../../Domain/Ticket'
import Container from './Container'

class TicketAll extends Component<TicketMineProps, TicketMineState> {
	render() {
		return (
			<Container>
				<Row>
					<Col sm={6} md={4}>
						<H3>Siste med aktivitet</H3>
						<TicketsList />
					</Col>
					<Col sm={6} md={4}>
						<H3>Nylig opprettet</H3>
						<TicketsList />
					</Col>
					<Col sm={6} md={4}>
						<H3>Nylig lukket</H3>
						<TicketsList />
					</Col>
				</Row>
			</Container>
		)
	}
}
export default TicketAll
