import { MenuItem, Spinner } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import { BRREGEnhet, BRREGEnhetSmall, BRREGSearchEnheter } from '../../Services/Brreg'
import { useMemo, useState } from 'react'
import { debounce } from 'lodash'

const useBrregRequest = () => {
	const [error, setError] = useState<string | null>(null)
	const [loading, setLoading] = useState(false)

	return {
		request: useMemo(() => {
			const debounced = debounce((query: string, cb: (results: BRREGEnhet[]) => void) => {
				setError(null)
				setLoading(true)

				BRREGSearchEnheter(query)
					.then((results) => {
						setLoading(false)
						cb(results)
					})
					.catch((err) => {
						setLoading(false)
						setError('Kunne ikke hente data fra Brønnøysundregistrene')
						cb([])
					})
			}, 250)
			return (query: string, cb: (results: BRREGEnhet[]) => void) => {
				debounced(query, cb)
			}
		}, []),
		error,
		loading,
	}
}

export function SuggestCustomer({
	onItemSelect,
	value,
}: {
	onItemSelect: (item: BRREGEnhet) => void
	value: BRREGEnhetSmall | null
}) {
	const { request: brregRequest, loading: brregLoading, error: brregError } = useBrregRequest()
	const [companies, setCompanies] = useState<BRREGEnhet[]>(value ? [value as BRREGEnhet] : [])
	const [suggestQuery, setSuggestQuery] = useState<string>('')
	const [focus, setFocus] = useState<boolean>(false)

	const itemSubRenderer = (_items: BRREGEnhet[] | null, item: BRREGEnhet): string => {
		if (!item || !item?.organisasjonsnummer) {
			return ''
		}
		return item.navn
	}

	const style: React.CSSProperties = {}
	if (brregError) {
		style['border'] = '1px solid red'
	}

	return (
		<>
			<Suggest
				fill
				resetOnSelect={true}
				inputProps={{
					autoFocus: true,
					placeholder: 'Søk...',
					style,
					rightElement: brregLoading ? (
						<div style={{ verticalAlign: 'text-bottom', display: 'inline-block', margin: '7px' }}>
							<Spinner size={16} />
						</div>
					) : undefined,
					onFocus: () => {
						setFocus(true)
					},
					onBlur: ({ currentTarget, relatedTarget, target }) => {
						// Auto close popover when clicking outside
						if (relatedTarget !== null && currentTarget.contains(target)) {
							return
						}
						setFocus(false)
					},
				}}
				itemListPredicate={(query, items) => {
					if (query === '') return []
					return items.slice(0, 5)
				}}
				popoverProps={{
					minimal: true,
					isOpen: focus && (brregLoading || (suggestQuery.length > 0 && companies.length > 0)),
				}}
				itemsEqual={(a, b) => a.organisasjonsnummer === b.organisasjonsnummer}
				inputValueRenderer={(item) => itemSubRenderer(companies, item)}
				onItemSelect={(item) => {
					onItemSelect(item)
				}}
				onQueryChange={(query, e) => {
					setSuggestQuery(query)
					if (query === '') {
						setCompanies([])
						return true
					}

					brregRequest(query, (results) => {
						setCompanies(results)
					})

					return true
				}}
				selectedItem={
					value === null
						? undefined
						: companies.find((item) => item.organisasjonsnummer === value?.organisasjonsnummer)
				}
				noResults={
					brregLoading ? (
						<Spinner size={32} />
					) : (
						<MenuItem disabled={true} text="Fant ingen enheter med det navnet" />
					)
				}
				itemRenderer={(item, { handleClick, modifiers, query }) => {
					return (
						<MenuItem
							key={item.organisasjonsnummer}
							active={modifiers.active}
							onClick={(e) => {
								e.preventDefault()
								onItemSelect(item)
								handleClick(e)
							}}
							text={itemSubRenderer(companies, item)}
							itemID={String(item.organisasjonsnummer)}
						/>
					)
				}}
				items={companies}
			/>
			<small>{brregError && <p style={{ color: 'red' }}>{brregError}</p>}</small>
		</>
	)
}
