import { Colors, H3, InputGroup, Button, Tag, ControlGroup, Card, H5, Icon } from '@blueprintjs/core'
import React, { CSSProperties } from 'react'
import { stateIntent, stateLabel, stateStyle } from './api'
import { Ticket, TicketChange } from './types'
import { useTicket } from './hooks/useTicket'
import Editor from 'rich-markdown-editor'
import { ourTheme } from '../DynamicContent/module/MD'
import Moment from 'react-moment'
import 'moment/locale/nb'
import { OALUser } from '../OAL/User'
import { Col, Row } from 'reactstrap'
import { RenderTicketUpdateAssigned } from './RenderTicketUpdateAssigned'
import { RenderTicketUpdateTitle } from './RenderTicketUpdateTitle'
import { RenderTicketUpdatePayload } from './RenderTicketUpdatePayload'
import { RenderTicketUpdateState } from './RenderTicketUpdateState'
import { RenderTicketUpdateTagAdded } from './RenderTicketUpdateTagAdded'
import { RenderTicketUpdateTagRemoved } from './RenderTicketUpdateTagRemoved'
import { RenderTag } from './RenderTag'
import { LoadingLarge } from './LoadingLarge'
import { ProfileImageThumbnail } from '../../Services/OalApi'
import { CommentsCard } from '../Comments/Card'
import { useMe } from './hooks/useMe'

export function TicketCard(params: { id: number; onSave?: () => void; style?: CSSProperties }) {
	const { ticket, loading, setLoading, setTicket } = useTicket(params.id)
	const [editValue, setEditValue] = React.useState('')
	const [editWhat, setEditWhat] = React.useState<'payload' | 'title' | null>(null)
	const [tag, setTag] = React.useState('')
	const { me } = useMe()

	let wrapperStyle: CSSProperties = {}
	if (params.style) {
		wrapperStyle = { ...params.style }
	}

	const reversedChanges = ticket?.changes.slice().reverse()

	const changeRenderer = (change: TicketChange) => {
		if (change.type === 'TicketUpdateAssigned')
			return (
				<div key={change.id}>
					<RenderTicketUpdateAssigned change={change} />
				</div>
			)
		if (change.type === 'TicketUpdateTitle')
			return (
				<div key={change.id}>
					<RenderTicketUpdateTitle change={change} />
				</div>
			)
		if (change.type === 'TicketUpdatePayload')
			return (
				<div key={change.id}>
					<RenderTicketUpdatePayload change={change} />
				</div>
			)
		if (change.type === 'TicketUpdateState')
			return (
				<div key={change.id}>
					<RenderTicketUpdateState change={change} />
				</div>
			)
		if (change.type === 'TicketUpdateTagAdded')
			return (
				<div key={change.id}>
					<RenderTicketUpdateTagAdded change={change} />
				</div>
			)
		if (change.type === 'TicketUpdateTagRemoved')
			return (
				<div key={change.id}>
					<RenderTicketUpdateTagRemoved change={change} />
				</div>
			)
		return <>{JSON.stringify(change)}</>
	}

	return (
		<div>
			{loading ? (
				<LoadingLarge />
			) : (
				<div>
					{ticket !== undefined && (
						<div style={{ ...wrapperStyle }}>
							{editWhat === 'title' ? (
								<form
									onSubmit={async (e) => {
										await setLoading(true)
										await setTicket({
											...ticket,
											title: editValue,
										})
										await setLoading(false)
										await setEditWhat(null)
										if (params.onSave) params.onSave()
									}}
								>
									<ControlGroup>
										<InputGroup
											type="text"
											placeholder={'Overskrift...'}
											value={editValue}
											large
											intent="danger"
											fill
											onKeyUp={(e) => {
												// abort if escape key is pressed
												e.preventDefault()
												if (e.key === 'Escape') {
													setEditWhat(null)
													setEditValue('')
												}
											}}
											onChange={(e) => setEditValue(e.target.value)}
										/>
										<Button type="submit" disabled={editValue === ''} intent="danger">
											Lagre
										</Button>
									</ControlGroup>
								</form>
							) : (
								<div style={{ paddingBottom: 4 }}>
									<div>
										{ticket.state === 'open' && (
											<div style={{ float: 'right' }}>
												<Button
													large
													intent="success"
													onClick={(e) => {
														setEditWhat('title')
														setEditValue(ticket.title || '')
													}}
												>
													Endre
												</Button>
											</div>
										)}
										<H3 style={{ ...stateStyle[ticket.state] }}>
											{ticket.title || ''}{' '}
											<span style={{ opacity: 0.5, fontWeight: 400 }}>#{ticket.id}</span>
										</H3>
									</div>
								</div>
							)}
							<div style={{ marginTop: 10, marginBottom: 15 }}>
								<Tag
									round
									intent={stateIntent[ticket.state]}
									large
									style={{ textTransform: 'capitalize', fontWeight:'bold' }}
								>
									{stateLabel[ticket.state]}
								</Tag>{' '}
								opprettet <Moment fromNow>{ticket.createdAt}</Moment> av{' '}
								<OALUser id={ticket.authorId} />
							</div>

							<Row>
								<Col md={7} xl={8}>
									<div style={{ display: 'flex' }}>
										<div style={{ marginLeft: 15, borderLeft: '1px solid #ddd' }}>
											<div style={{ marginLeft: -15, paddingRight: 9 }}>
												<ProfileImageThumbnail
													style={{
														width: 30,
														height: 30,
														marginTop: 5,
														borderRadius: '100%',
													}}
													username={ticket.authorId || ''}
												/>
											</div>
										</div>
										<Card
											style={{
												backgroundColor: Colors.WHITE,
												borderRadius: 6,
												marginBottom: 10,
												padding: 0,
												width: '100%',
												boxShadow:
													editWhat === 'payload'
														? 'inset 0 0 14px rgba(0,0,0,0.3)'
														: undefined,
											}}
										>
											<div
												style={{
													borderBottom: '1px solid #eee',
													padding: 10,
													backgroundColor: Colors.LIGHT_GRAY4,
												}}
											>
												<OALUser id={ticket.authorId} /> opprettet sak{' '}
												<Moment fromNow>{ticket.createdAt}</Moment>
											</div>
											<div style={{ padding: 10 }}>
												{ticket.type === 'md' &&
													MD(
														editWhat,
														editValue,
														setLoading,
														setEditWhat,
														params,
														setEditValue,
														me,
														ticket,
														setTicket
													)}
											</div>
										</Card>
									</div>
									<CommentsCard
										reference={'ticket:' + ticket.id}
										fletting={[
											{
												type: 'TicketChange',
												data: reversedChanges || [],
												wrapper: (props: any) => changeRenderer(props),
											},
										]}
									/>
								</Col>
								<Col md={5} lg={4}>
									<ControlGroup>
										<Button
											icon="issue"
											onClick={async () => {
												const newTicket: Ticket = {
													...ticket,
													state: 'open',
												}
												await setTicket(newTicket)
											}}
											intent={ticket.state === 'open' ? stateIntent['open'] : undefined}
											large
											fill
										>
											{stateLabel['open']}
										</Button>

										<Button
											icon="tick"
											onClick={async () => {
												const newTicket: Ticket = {
													...ticket,
													state: 'closed',
												}

												await setTicket(newTicket)
											}}
											intent={ticket.state === 'closed' ? stateIntent['closed'] : undefined}
											large
											fill
										>
											{stateLabel['closed']}
										</Button>
										<Button
											icon="archive"
											onClick={async () => {
												const newTicket: Ticket = {
													...ticket,
													state: 'deleted',
												}

												await setTicket(newTicket)
											}}
											disabled={ticket.state !== 'closed'}
											intent={ticket.state === 'deleted' ? stateIntent['deleted'] : undefined}
											large
											fill
										>
											{stateLabel['deleted']}
										</Button>
									</ControlGroup>

									<Card style={{ marginTop: 20, paddingBottom: 10 }}>
										<H5>
											<Icon icon="crown" size={20} /> Ansvarlig
										</H5>
										<div>
											{ticket.assignedId !== null ? (
												<div style={{ display: 'flex' }}>
													<div>
														<ProfileImageThumbnail
															username={ticket.assignedId || ''}
															style={{
																maxWidth: 64,
																maxHeight: 64,
																borderRadius: '100%',
															}}
														/>
													</div>
													<div style={{ fontSize: 16, paddingTop: 13, paddingLeft: 10 }}>
														<OALUser id={ticket.assignedId} />
														<br /> <div style={{ marginTop: 4, fontSize: 11 }}>endre</div>
													</div>
												</div>
											) : (
												<i>Ingen</i>
											)}
										</div>
									</Card>

									<Card style={{ marginTop: 20 }}>
										<H5>Tags</H5>
										{ticket.tags.map((tag) => {
											return (
												<span style={{ margin: 3, display: 'inline-block' }}>
													<RenderTag key={tag} id={tag} fill />
												</span>
											)
										})}
										<InputGroup
											type="text"
											value={tag}
											leftIcon="tag"
											onChange={(e) => setTag(e.target.value)}
											onKeyUp={(e) => {
												if (e.key === 'Enter') {
													if (tag.match(/^[a-z]{3,}$/) && !ticket.tags.includes(tag)) {
														setTicket({
															...ticket,
															tags: [...ticket.tags, tag],
														})
													}
												}
											}}
										/>
									</Card>
								</Col>
							</Row>
						</div>
					)}
				</div>
			)}
		</div>
	)
}

function MD(
	editWhat: string | null,
	editValue: string,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setEditWhat: React.Dispatch<React.SetStateAction<'payload' | 'title' | null>>,
	params: { id: number; onSave?: (() => void) | undefined; style?: React.CSSProperties | undefined },
	setEditValue: React.Dispatch<React.SetStateAction<string>>,
	me: any | undefined,
	ticket: Ticket,
	setTicket?: React.Dispatch<React.SetStateAction<Ticket | undefined>>
): React.ReactNode {
	return (
		<>
			{editWhat === 'payload' ? (
				<>
					<span style={{ float: 'right', paddingLeft: 10, paddingRight: 10 }}>
						<Button
							intent="danger"
							onClick={async () => {
								if (setTicket) {
									await setLoading(true)
									await setTicket({
										...ticket,
										payload: editValue,
									})
									await setLoading(false)
									await setEditWhat(null)
									if (params.onSave) params.onSave()
								}
							}}
						>
							Lagre
						</Button>
					</span>
					<Editor
						defaultValue={editValue || ''}
						placeholder={'Tekst...'}
						onChange={async (val) => {
							setEditValue(val())
						}}
						autoFocus
						style={{
							minHeight: 75,
						}}
						theme={ourTheme}
					/>
				</>
			) : (
				<>
					{ticket.state === 'open' && ticket.authorId === me && (
						<span style={{ float: 'right' }}>
							<Button
								intent="success"
								onClick={() => {
									setEditWhat('payload')
									setEditValue(ticket.payload || '')
								}}
								style={{ marginRight: 10, marginLeft: 10 }}
							>
								Endre
							</Button>
						</span>
					)}
					<Editor
						defaultValue={ticket.payload || ''}
						readOnly
						style={{ minHeight: 30 }}
						theme={{ ...ourTheme, background: 'white' }}
					/>
				</>
			)}
		</>
	)
}
