import { ProjectAnalyticsContainer, ProjectAnalyticsContainerNoSubmenu, useSortTH } from './Container'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { PageContext } from '../../../Utils/LoginContext'
import { Callout, Card, FormGroup, H3, HTMLSelect, Spinner } from '@blueprintjs/core'
import './Analytics.scss'
import { DateRangePicker } from '@blueprintjs/datetime'
import { Col, Row } from 'reactstrap'
import { OALContactCard } from '../../../Domain/Common/OAL'
import { getContactsAll } from '../../../Services/Contacts'
import { useApiData } from '../../../Utils/UseAPI'
import {
	Config,
	TripletexLedgerPosting,
	getConfigs,
	getRentmanProjectAnalytics,
	getRentmanProjectTypes,
	getTripletexAcccountPostings,
} from '../../../Services/OalApi'
import { Link } from 'react-router-dom'

const dateFormatter = new Intl.DateTimeFormat('nb-NO', {
	day: 'numeric',
	month: 'long',
	year: 'numeric',
})

export const Analytics = ({ company }: { company: string }) => {
	const context = useContext(PageContext)
	const now = new Date()
	const [startDate, setStartDate] = useState<Date | null>(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0))
	const [endDate, setEndDate] = useState<Date | null>(new Date(now.getFullYear(), now.getMonth() + 1, 1, 0, -1, 0, 0))
	const [contacts, setContacts] = useState<OALContactCard[]>([])
	const [externalFolders, setExternalFolders] = useState<number[]>([])
	const [internalFolders, setInternalFolders] = useState<number[]>([])
	const [tripletexData, setTripletexData] = useState<TripletexLedgerPosting[]>([])
	const [loadingTripletexData, setLoadingTripletexData] = useState<boolean>(false)

	const [customerFilter, setCustomerFilter] = useState<string>('')
	const [accountManagerFilter, setAccountManagerFilter] = useState<string>('')
	const [salesrepFilter, setSalesrepFilter] = useState<string>('')
	const [projectTypeFilter, setProjectTypeFilter] = useState<string>('')

	const { data: configuration, error: configurationError } = useApiData<Config[]>(
		() => getConfigs(`pa-*-tmlb,pa-*-opti,pa-tooltip-*`),
		[]
	)

	const get = useCallback(
		(key: string) => {
			const config = configuration?.find((config) => config.key === key)
			return config?.value
		},
		[configuration]
	)

	const {
		data: rentmanData,
		loading: rentmanDataLoading,
		reload: reloadData,
	} = useApiData(
		(...args) => getRentmanProjectAnalytics.apply(null, args as any),
		company,
		startDate,
		endDate,
		externalFolders,
		internalFolders
	)
	const dataRef = useRef<typeof rentmanData>()

	const { data: projectTypes } = useApiData(getRentmanProjectTypes, company)

	useEffect(() => {
		setExternalFolders(get('pa-rentman-folder-external-' + company) ?? [])
		setInternalFolders(get('pa-rentman-folder-internal-' + company) ?? [])
	}, [configuration, get, company])

	useEffect(() => {
		reloadData(
			company,
			startDate?.toISOString().substr(0, 10),
			endDate?.toISOString().substr(0, 10),
			externalFolders,
			internalFolders
		)
	}, [company, startDate, endDate, internalFolders, externalFolders, reloadData])

	const [sorting, SortTH] = useSortTH(get)

	useEffect(() => {
		if (!rentmanData?.length) {
			setTripletexData([])
			return
		}
		if (dataRef.current === rentmanData) {
			//console.log('Data is the same, skipping tripletex-data fetch')
			return
		}
		dataRef.current = rentmanData

		setTripletexData([])
		setLoadingTripletexData(true)
		;(async () => {
			try {
				const data = await getTripletexAcccountPostings(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: []
				)
				setTripletexData((old) => data)
				// Request more live data in the meantime:
				const newData = await getTripletexAcccountPostings(
					company,
					startDate?.toISOString().substr(0, 10) ?? '',
					endDate?.toISOString().substr(0, 10) ?? '',
					rentmanData
						? Array.from(
								rentmanData
									?.reduce((acc, project) => {
										acc.set(project.number, 1)
										return acc
									}, new Map<number, number>())
									.keys() as Iterable<number>
						  )
						: [],
					true
				)
				setTripletexData((old) => newData)
			} catch (e) {
				console.error(e)
			} finally {
				setLoadingTripletexData(false)
			}
		})()
	}, [company, startDate, endDate, rentmanData])

	useEffect(() => {
		;(async () => {
			const res = await getContactsAll()
			// sort res object by name
			res.sort((a, b) => {
				if (a.name < b.name) return -1
				if (a.name > b.name) return 1
				return 0
			})

			setContacts(res)
		})()
	}, [])

	let groups = context.user?.groups || []
	const hasAccess = groups.find((group) => group === 'DA-' + company.toUpperCase() + '-PROJECTMANAGER')

	const numberFormatting = (num: number) => {
		return (num ?? 0).toLocaleString('nb-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
	}

	const rentmanSum = {
		revenue: 0,
		subhire_costs: 0,
		external_crew_cost: 0,
		internal_crew_cost: 0,
		transport: 0,
		other_costs: 0,
		db: 0,
	}
	const tripletexSum = {
		revenue: 0,
		subhire_costs: 0,
		external_crew_cost: 0,
		internal_crew_cost: 0,
		transport: 0,
		other_costs: 0,
		db: 0,
		dbdifferanse: 0,
	}

	const inRange = (number: number, range: { from: number; to?: number }[]) => {
		return range.some((r) => {
			if (r.to) {
				return number >= r.from && number <= r.to
			} else {
				return number === r.from
			}
		})
	}

	const getTripletexSum = useCallback(
		(type: 'revenue' | 'subhire' | 'crew-internal' | 'crew-external' | 'transport' | 'rest', project: number) => {
			const pnr = String(project)

			if (!configuration) return 0

			return Math.abs(
				tripletexData
					?.filter((posting) => {
						return (
							inRange(posting.account, get('pa-tripletex-' + type + '-' + company)) &&
							posting.project === pnr
						)
					})
					.reduce((acc, posting) => {
						acc += posting.amount
						return acc
					}, 0) || 0
			)
		},
		[tripletexData, company, get, configuration]
	)

	const filteredList = useMemo(
		() =>
			(
				rentmanData?.filter((project) => {
					if (customerFilter !== '') {
						if (
							!(
								project.customer_id === parseInt(customerFilter) ||
								(project.customer_id === null && customerFilter === '-1')
							)
						) {
							return false
						}
					}
					if (accountManagerFilter !== '') {
						if (
							!(
								project.account_manager_phone === accountManagerFilter ||
								(project.account_manager_phone === null && accountManagerFilter === '-1')
							)
						) {
							return false
						}
					}
					if (salesrepFilter !== '') {
						if (
							!(
								project.salesrep_phone === salesrepFilter ||
								(project.salesrep === null && salesrepFilter === '-1')
							)
						) {
							return false
						}
					}
					if (projectTypeFilter !== '') {
						if (project.project_type_id !== parseInt(projectTypeFilter)) {
							return false
						}
					}
					return true
				}) || []
			)
				.map((project) => {
					const data: typeof project & {
						trevenue: number
						tsubhire: number
						tcrew_internal: number
						tcrew_external: number
						ttransport: number
						trest: number
						dekningsbidrag: number
						dbprosent: number
						tother: number
						tdekningsbidrag: number
						tdbprosent: number
						dbdifferanse: number
					} = {
						...project,
						trevenue: getTripletexSum('revenue', project.number),
						tsubhire: getTripletexSum('subhire', project.number),
						tcrew_internal: getTripletexSum('crew-internal', project.number),
						tcrew_external: getTripletexSum('crew-external', project.number),
						ttransport: getTripletexSum('transport', project.number),
						trest: getTripletexSum('rest', project.number),
						dekningsbidrag:
							project.revenue -
							(project.subhire_costs +
								project.external_crew_cost +
								project.internal_crew_cost +
								project.transport +
								project.other_costs),
					} as any

					data.dbprosent = (data.dekningsbidrag / project.revenue) * 100
					data.tother =
						data.trest - (data.tsubhire + data.tcrew_internal + data.tcrew_external + data.ttransport)
					data.tdekningsbidrag = data.trevenue - data.trest
					data.tdbprosent = (data.tdekningsbidrag / data.trevenue) * 100

					data.dbdifferanse = data.dekningsbidrag - data.tdekningsbidrag

					return data
				})
				.sort((a: any, b: any) => {
					if (!sorting) return 0
					const aVal = a[sorting.replace('-', '') as any]
					const bVal = b[sorting.replace('-', '') as any]

					if (aVal === bVal) return 0

					if (sorting.startsWith('-')) {
						if (aVal < bVal) return 1
						if (aVal > bVal) return -1
					} else {
						if (aVal < bVal) return -1
						if (aVal > bVal) return 1
					}

					return 0
				}),
		[rentmanData, customerFilter, accountManagerFilter, projectTypeFilter, getTripletexSum, sorting, salesrepFilter]
	)

	const projectTypesList = useMemo(
		() =>
			projectTypes?.sort((a, b) => {
				const aNum = parseInt((a.name.match(/\d+/) || ['0'])[0])
				const bNum = parseInt((b.name.match(/\d+/) || ['0'])[0])

				// Move text to bottom
				if (aNum === 0 && bNum !== 0) return 1
				if (aNum !== 0 && bNum === 0) return -1

				// Sort numbered items
				if (aNum === 0 && bNum === 0) return aNum > bNum ? 1 : -1
				if (aNum < bNum) return -1
				if (aNum > bNum) return 1

				return 0
			}) || [],
		[projectTypes]
	)

	const customers = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				acc[project.customer_id !== null ? project.customer_id : -1] = project.customer_displayname
				return acc
			}, {} as Record<number, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: String(key), name: hash[parseInt(key)] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData])

	const accountManagerList = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				if (!project.account_manager_phone || project.account_manager_phone === null) {
					acc['-1'] = '- Uten prosjektleder -'
				} else {
					acc[project.account_manager_phone] =
						contacts.find(
							(contact) =>
								contact.groups.includes('DA-' + company.toUpperCase() + '-EMPLOYED') &&
								contact.phone === project.account_manager_phone
						)?.name || `(Ikke ansatt!) ${project.account_manager} (${project.account_manager_phone})`
				}
				return acc
			}, {} as Record<string, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: key, name: hash[key] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData, contacts, company])

	const salesrepList = useMemo(() => {
		const hash =
			rentmanData?.reduce((acc, project) => {
				if (!project.salesrep_phone || project.salesrep_phone === null) {
					acc['-1'] = '- Uten kunderådgiver -'
				} else {
					acc[project.salesrep_phone] =
						contacts.find(
							(contact) =>
								contact.groups.includes('DA-' + company.toUpperCase() + '-EMPLOYED') &&
								contact.phone === project.salesrep_phone
						)?.name || `(Ikke ansatt!) ${project.salesrep} (${project.salesrep_phone})`
				}
				return acc
			}, {} as Record<string, string>) || {}
		return Object.keys(hash)
			.map((key) => ({ id: key, name: hash[key] }))
			.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
	}, [rentmanData, contacts, company])

	if (!hasAccess) {
		return (
			<ProjectAnalyticsContainerNoSubmenu>
				<p>Du har ikke tilgang til dette selskapet</p>
			</ProjectAnalyticsContainerNoSubmenu>
		)
	}

	return (
		<ProjectAnalyticsContainer company={company}>
			<Card elevation={1} className={'mb-4'}>
				<H3>Filter</H3>
				<Row>
					<Col md={5}>
						<div style={{ position: 'relative', display: 'inline-block' }}>
							<DateRangePicker
								value={[startDate, endDate]}
								shortcuts={false}
								allowSingleDayRange
								maxDate={new Date(now.getFullYear()+1, 11, 31, 0, 0, 0, 0)}
								onChange={(dates) => {
									setStartDate(dates[0])
									setEndDate(dates[1])
								}}
							/>
							{rentmanDataLoading || loadingTripletexData ? (
								<div
									style={{
										backgroundColor: 'white',
										opacity: 0.5,
										position: 'absolute',
										top: 0,
										left: 0,
										zIndex: 100,
										width: '100%',
										height: '100%',
									}}
								>
									<div style={{ marginTop: '30px' }}>
										<Spinner size={128} />
									</div>
								</div>
							) : null}
						</div>
					</Col>
					<Col md={7}>
						<Row>
							<Col md={5}>
								<FormGroup label="Kunde">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={customerFilter}
										onChange={(e) => setCustomerFilter(e.target.value)}
									>
										<option value="">- Alle -</option>
										{customers.map((customer) => (
											<option key={customer.id} value={customer.id}>
												{customer.name || '- Uten kunde -'}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup label="Kunderådgiver">
									<HTMLSelect
										className={'mb-3'}
										value={salesrepFilter}
										onChange={(e) => setSalesrepFilter(e.target.value)}
										fill
									>
										<option value="">- Alle -</option>
										{salesrepList.map((contact) => (
											<option key={contact.id} value={contact.id}>
												{contact.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={5}>
								<FormGroup label="Prosjektleder">
									<HTMLSelect
										className={'mb-3'}
										value={accountManagerFilter}
										onChange={(e) => setAccountManagerFilter(e.target.value)}
										fill
									>
										<option value="">- Alle -</option>
										{accountManagerList.map((contact) => (
											<option key={contact.id} value={contact.id}>
												{contact.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
							<Col md={5}>
								<FormGroup label="Prosjekttype">
									<HTMLSelect
										className={'mb-3'}
										fill
										value={projectTypeFilter}
										onChange={(e) => setProjectTypeFilter(e.target.value)}
									>
										<option value="">- Alle -</option>
										{projectTypesList.map((type) => (
											<option value={type.id} key={type.id}>
												{type.name}
											</option>
										))}
									</HTMLSelect>
								</FormGroup>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card>
			<Card elevation={1} className={'mb-4'} style={{ position: 'relative' }}>
				{loadingTripletexData ? (
					<div title="Updating tripletex data" style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
						<Spinner size={16} />
					</div>
				) : null}
				<H3>Kostnadsanalyse</H3>
				<table className="bp3-html-table modifier analyticstable costtable">
					<thead>
						<tr>
							<SortTH name="number" style={{ textAlign: 'left' }}>
								Prosjekt
							</SortTH>
							<SortTH name="revenue" tooltipName="revenue">
								Omsetning
							</SortTH>
							<SortTH name="subhire_costs" tooltipName="subhire">
								Subhire
							</SortTH>
							<SortTH name="external_crew_cost" tooltipName="crew-external">
								Bemanning ekstern
							</SortTH>
							<SortTH name="internal_crew_cost" tooltipName="crew-internal">
								Bemanning intern
							</SortTH>
							<SortTH name="transport" tooltipName="transport">
								Transport
							</SortTH>
							<SortTH name="other_costs" tooltipName="other">
								Annet
							</SortTH>
							<SortTH name="dekningsbidrag" colSpan={2} tooltipName="db">
								DB
							</SortTH>
							<SortTH name="dbdifferanse" tooltipName="db_diff">
								Diff
							</SortTH>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredList?.map((project) => {
							const { dbprosent, tdbprosent, dekningsbidrag, tdekningsbidrag, tother } = project
							const dbClass =
								dbprosent < get('pa-rentman-db-' + company) && project.revenue !== 0 ? 'dbnegativ' : ''

							const tdbClass =
								tdbprosent < get('pa-tripletex-db-' + company) && project.trevenue !== 0
									? 'dbnegativ'
									: ''

							const revClass = project.revenue - project.trevenue > 100 ? 'dbnegativ' : ''

							rentmanSum.revenue += project.revenue
							rentmanSum.subhire_costs += project.subhire_costs
							rentmanSum.external_crew_cost += project.external_crew_cost
							rentmanSum.internal_crew_cost += project.internal_crew_cost
							rentmanSum.transport += project.transport
							rentmanSum.other_costs += project.other_costs
							rentmanSum.db += dekningsbidrag

							tripletexSum.revenue += project.trevenue
							tripletexSum.subhire_costs += project.tsubhire
							tripletexSum.external_crew_cost += project.tcrew_external
							tripletexSum.internal_crew_cost += project.tcrew_internal
							tripletexSum.transport += project.ttransport
							tripletexSum.other_costs += tother
							tripletexSum.db += tdekningsbidrag
							tripletexSum.dbdifferanse += project.dbdifferanse

							return (
								<tr key={project.id}>
									<td>
										<strong>
											<Link
												title={'Information about ' + project.displayname}
												to={`/economy/projectanalytics/${company}/info/${project.id}`}
											>
												{project.displayname}
											</Link>
										</strong>
										<div style={{ fontSize: '0.85em', opacity: 0.5 }}>
											{project.usageperiod_start
												? dateFormatter.format(new Date(project.usageperiod_start))
												: 'N/A'}{' '}
											- {project.number}
										</div>
									</td>
									<td>
										<div>{numberFormatting(project.revenue)}</div>
										<div className={revClass}>{numberFormatting(project.trevenue)}</div>
									</td>
									<td>
										{numberFormatting(project.subhire_costs)}
										<br />
										{numberFormatting(project.tsubhire)}
									</td>
									<td>
										{numberFormatting(project.external_crew_cost)}
										<br />
										{numberFormatting(project.tcrew_external)}
									</td>
									<td>
										{numberFormatting(project.internal_crew_cost)}
										<br />
										{numberFormatting(project.tcrew_internal)}
									</td>
									<td>
										{numberFormatting(project.transport)}
										<br />
										{numberFormatting(project.ttransport)}
									</td>
									<td>
										{numberFormatting(project.other_costs)}
										<br />
										{numberFormatting(tother)}
									</td>
									<td>
										<div className={'dbprosent ' + dbClass}>
											{project.revenue === 0 ? '' : `(${Math.round(dbprosent)}%)`}
										</div>
										<div className={'dbprosent ' + tdbClass}>
											{project.trevenue === 0 ? '' : `(${Math.round(tdbprosent)}%)`}
										</div>
									</td>
									<td>
										<div>{numberFormatting(dekningsbidrag)}</div>
										<div>{numberFormatting(tdekningsbidrag)}</div>
									</td>
									<td>{numberFormatting(project.dbdifferanse)}</td>
									<td>
										<span title="Rentman">R</span>
										<br />
										<span title="Tripletex">T</span>
									</td>
								</tr>
							)
						})}
						<tr className="sum">
							<td>Sum</td>
							<td>
								{numberFormatting(rentmanSum.revenue)}
								<br />
								{numberFormatting(tripletexSum.revenue)}
							</td>
							<td>
								{numberFormatting(rentmanSum.subhire_costs)}
								<br />
								{numberFormatting(tripletexSum.subhire_costs)}
							</td>
							<td>
								{numberFormatting(rentmanSum.external_crew_cost)}
								<br />
								{numberFormatting(tripletexSum.external_crew_cost)}
							</td>
							<td>
								{numberFormatting(rentmanSum.internal_crew_cost)}
								<br />
								{numberFormatting(tripletexSum.internal_crew_cost)}
							</td>
							<td>
								{numberFormatting(rentmanSum.transport)}
								<br />
								{numberFormatting(tripletexSum.transport)}
							</td>
							<td>
								{numberFormatting(rentmanSum.other_costs)}
								<br />
								{numberFormatting(tripletexSum.other_costs)}
							</td>
							<td className={rentmanSum.db < 0 ? 'dbnegativ' : ''}>
								<span className="dbprosent">
									{rentmanSum.revenue === 0
										? ''
										: `(${Math.round((rentmanSum.db / rentmanSum.revenue) * 100)}%)`}
								</span>
								<br />
								<span className="dbprosent">
									{tripletexSum.revenue === 0
										? ''
										: `(${Math.round((tripletexSum.db / tripletexSum.revenue) * 100)}%)`}
								</span>
							</td>
							<td className={rentmanSum.db < 0 ? 'dbnegativ' : ''}>
								{numberFormatting(rentmanSum.db)}
								<br />
								{numberFormatting(tripletexSum.db)}
							</td>
							<td>{numberFormatting(tripletexSum.dbdifferanse)}</td>
							<td>
								<span title="Rentman">R</span>
								<br />
								<span title="Tripletex">T</span>
							</td>
						</tr>
					</tbody>
				</table>
				{configurationError ? (
					<Callout intent={'danger'} className="mt-3">
						{configurationError}
					</Callout>
				) : null}
			</Card>
		</ProjectAnalyticsContainer>
	)
}

export default Analytics
