import { Breadcrumbs, BreadcrumbProps, Icon } from '@blueprintjs/core'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const Crumbs = (props: { breadcrumbs: BreadcrumbProps[] }) => {
	const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbProps[]>(props.breadcrumbs)

	useEffect(() => {
		setBreadcrumbs(props.breadcrumbs)
	}, [props.breadcrumbs])

	const renderCurrentBreadcrumb = ({ text, ...restProps }: BreadcrumbProps) => {
		// customize rendering of last breadcrumb
		return (
			<Link to={restProps.href || ''} className="muted-link" style={{fontWeight:'bold'}}>
				<Icon icon={restProps.icon} /> {text}
			</Link>
		)
	}

	return (
		<div style={{ borderBottom: '1px solid #eee', paddingBottom: 8, marginBottom: 8 }}>
			<Breadcrumbs
				breadcrumbRenderer={renderCurrentBreadcrumb}
				currentBreadcrumbRenderer={renderCurrentBreadcrumb}
				items={breadcrumbs}
			/>
		</div>
	)
}

export default Crumbs
