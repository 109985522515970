import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import uuid from 'shortid'

const itemsFromBackend = [
	{ id: uuid(), locked: true, content: 'First task' },
	{ id: uuid(), content: 'Second task' },
	{ id: uuid(), content: 'Third task' },
	{ id: uuid(), content: 'Fourth task' },
	{ id: uuid(), content: 'Fifth task' },
]

const columnsFromBackend = {
	[uuid()]: {
		name: 'Todo',
		items: itemsFromBackend,
	},
	[uuid()]: {
		name: 'In progress',
		items: [],
	},
	[uuid()]: {
		name: 'Done',
		items: [],
	},
}

const onDragEnd = (result: any, columns: any, setColumns: any) => {
	if (!result.destination) return
	const { source, destination } = result

	if (source.droppableId !== destination.droppableId) {
		const sourceColumn = columns[source.droppableId]
		const destColumn = columns[destination.droppableId]
		const sourceItems = [...sourceColumn.items]
		const destItems = [...destColumn.items]
		const [removed] = sourceItems.splice(source.index, 1)
		destItems.splice(destination.index, 0, removed)
		setColumns({
			...columns,
			[source.droppableId]: {
				...sourceColumn,
				items: sourceItems,
			},
			[destination.droppableId]: {
				...destColumn,
				items: destItems,
			},
		})
	} else {
		const column = columns[source.droppableId]
		const copiedItems = [...column.items]
		const [removed] = copiedItems.splice(source.index, 1)
		copiedItems.splice(destination.index, 0, removed)
		setColumns({
			...columns,
			[source.droppableId]: {
				...column,
				items: copiedItems,
			},
		})
	}
}

function App() {
	const [columns, setColumns] = useState(columnsFromBackend)
	return (
		<div style={{ display: 'flex', height: '100%' }}>
			<DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
				{Object.entries(columns).map(([columnId, column], index) => {
					return (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
							key={columnId}
						>
							<div style={{
								padding: 8,
								background: '#eee',
								borderBottom: '3px solid #ccc',
								borderRadius: 3,
								marginRight: 4,
								marginBottom: 4,

							}}>{column.name}</div>
							<div style={{ marginRight: 4 }}>
								<Droppable droppableId={columnId} key={columnId}>
									{(provided, snapshot) => {
										return (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={{
													background: snapshot.isDraggingOver ? '#f6f6f6' : '#fafafa',
													padding: 2,
													borderRadius: 4,
													minHeight: 'calc(100vh - 200px)',
												}}
											>
												{column.items.map((item, index) => {
													return (
														<Draggable
															key={item.id}
															draggableId={item.id}
															index={index}
															isDragDisabled={!!item.locked}
														>
															{(provided, snapshot) => {
																return (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={{
																			userSelect: 'none',
																			cursor: item.locked
																				? 'not-allowed'
																				: 'move',
																			padding: 5,
																			minHeight: 60,
																			borderRadius: 3,
																			margin: '2px 0 5px 0',
																			backgroundColor: item.locked
																				? '#333'
																				: '#ac1c21',
																			color: 'white',
																			...provided.draggableProps.style,
																		}}
																	>
																		{item.content}

																	</div>
																)
															}}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</div>
										)
									}}
								</Droppable>
							</div>
						</div>
					)
				})}
			</DragDropContext>
		</div>
	)
}

export default App
