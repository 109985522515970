import { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Button, Checkbox, H5, Text, Dialog, Callout, H4, InputGroup } from '@blueprintjs/core'
import { InvoicedJob } from '../../Domain/Invoice/Crew'
import { PageContext, PageContextProviderState } from '../../Utils/LoginContext'
import { getCrew, exportJobReference, getNewCrewJobs, getInvoicedCrewJobs } from '../../Services/CrewJobs'
import { InvoiceProps, InvoiceState } from '../../Domain/Invoice/Invoice'
import Loading from '../../Components/Loading/Loading'
import InvoiceContainer from './Container'

let selected: any[] = []

interface InvoiceLoginAsState extends InvoiceState {
	company: string
	phone: string
}

class InvoiceLoginAs extends Component<Omit<InvoiceProps, 'company'>, InvoiceLoginAsState> {
	static contextType = PageContext

	constructor(props: InvoiceProps) {
		super(props)

		this.state = {
			showingApproved: false,
			sumSelectedInvoices: 0,
			invoices: [],
			creatingRef: false,
			loading: false,
			error: null,
			showingInfoModal: false,
			showingReferenceModal: false,
			reference: '',
			company: '',
			phone: '',
		}
	}

	async updateInvoices() {
		const context: PageContextProviderState = this.context

		this.setState({
			loading: true,
		})

		if (context.user) {
			try {
				const crewInfo = await getCrew(this.state.phone, this.state.company)
				if (!crewInfo) {
					this.setState({
						invoices: [],
						loading: false,
					})
					console.error('Crew information missing!')
					//throw new Error('Crew information missing!')
				} else {

					let newInvoices = await getNewCrewJobs(crewInfo.id, this.state.phone, this.state.company)
					let oldInvoices = await getInvoicedCrewJobs(this.state.phone, this.state.company)

					let invoices = newInvoices.concat(oldInvoices)

					this.setState({
						invoices,
						loading: false,
					})
				}
			} catch (error) {
				console.error(error)

				this.setState({
					loading: false,
				})
			}
		} else {
			this.setState({
				loading: false,
			})
		}
	}

	async componentDidMount() {
		this.setState({
			loading: false,
		})
	}

	handleSelect = (data: any) => {
		if (!selected.includes(data)) {
			selected.push(data)
			if (data.cost) {
				let prevCost = this.state.sumSelectedInvoices
				this.setState({
					sumSelectedInvoices: (prevCost += parseInt(data.cost)),
				})
			}
		} else {
			let index = selected.indexOf(data)
			selected.splice(index, 1)

			if (data.cost) {
				let prevCost = this.state.sumSelectedInvoices
				this.setState({
					sumSelectedInvoices: (prevCost -= parseInt(data.cost)),
				})
			}
		}
	}

	async createReference() {
		this.setState({
			creatingRef: true,
		})

		let ref = Math.random().toString(36).substr(2, 6).toUpperCase()

		selected.forEach(async (data) => {
			await exportJobReference(data.id, ref, this.state.phone, this.state.company).catch((error) =>
				console.error(error)
			)
		})

		this.setState({
			showingReferenceModal: true,
			reference: ref,
		})
	}

	async modalClose() {
		this.setState({
			creatingRef: false,
			showingReferenceModal: false,
			reference: '',
			sumSelectedInvoices: 0,
			loading: true,
		})

		selected = []

		await this.updateInvoices()
	}

	async infoModalClose() {
		this.setState({
			showingInfoModal: false,
		})
	}

	async infoModalOpen() {
		this.setState({
			showingInfoModal: true,
		})
	}

	render() {
		return (
			<InvoiceContainer>
				{this.state.loading ? (
					<>
						<Loading type="rentman" />
					</>
				) : (
					<>
						<Dialog
							title={'Referansekode: ' + this.state.reference}
							onClose={() => this.modalClose()}
							canEscapeKeyClose={true}
							isOpen={this.state.showingReferenceModal}
							style={{ width: '80%' }}
						>
							<Card elevation={1} className={'mb-4'}>
								<table className="bp3-html-table .modifier">
									<thead>
										<tr>
											<th>Prosjekt</th>
											<th>Prosjektnummer</th>
											<th>Funksjon</th>
											<th>Start</th>
											<th>Slutt</th>
											<th>Hyre (eks. mva)</th>
										</tr>
									</thead>
									<tbody>
										{selected.map((data: InvoicedJob, index) => (
											<tr key={index}>
												<td>
													<span title={data.projectName}>{data.projectName}</span>
												</td>
												<td>
													<span title={data.projectNumber}>{data.projectNumber}</span>
												</td>
												<td>
													<span title={data.role}>{data.role}</span>
												</td>
												<td>
													<span title={data.startDate}>
														{new Date(data.startDate).toLocaleString()}
													</span>
												</td>
												<td>
													<span title={data.endDate}>
														{new Date(data.endDate).toLocaleString()}
													</span>
												</td>
												<td>
													<span title={data.cost}>
														{parseInt(data.cost) > 0
															? data.cost.toLocaleString()
															: 'Ingen hyre'}
													</span>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</Card>
						</Dialog>
						<Row className="mb-4">
							<Col sm={12} lg={3}>
								<InputGroup
									placeholder="Phone number"
									onChange={(e) => {
										this.setState({ phone: e.target.value })
									}}
									value={this.state.phone}
								/>
							</Col>
							<Col sm={12} lg={3}>
								<InputGroup
									placeholder="Company id"
									onChange={(e) => {
										this.setState({ company: e.target.value })
									}}
									value={this.state.company}
								/>
							</Col>
							<Col sm={12} lg={3}>
								<Button onClick={() => this.updateInvoices()}>Hent data </Button>
							</Col>
						</Row>
						<Row>
							<Col sm={12} lg={3}>
								<Card elevation={1} className={'mb-4'}>
									<H5>Til behandling:</H5>
									<Text>
										Kr:{' '}
										{this.state.invoices
											.filter(
												(data) =>
													data.reference === '' ||
													data.reference === null ||
													data.reference === undefined
											)
											.reduce((prev, cur) => prev + parseInt(cur.cost), 0)
											.toLocaleString()}
									</Text>
								</Card>
							</Col>
							<Col sm={12} lg={3}>
								<Card elevation={1} className={'mb-4'}>
									<H5>Valgt beløp:</H5>
									<Text>Kr: {this.state.sumSelectedInvoices.toLocaleString()}</Text>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col>
								<Callout intent="primary" title="Nice to know" style={{ marginBottom: '1em' }}>
									Jobber blir synlig 24 timer etter slutt
								</Callout>
								<Card elevation={1} className={'mb-4'}>
									<div className="float-right">
										<Button icon="info-sign" onClick={() => this.infoModalOpen()} className="mr-2">
											Hvordan bruke referanse?
										</Button>
										<Dialog
											onClose={() => this.infoModalClose()}
											canEscapeKeyClose={true}
											title="Hjelp"
											isOpen={this.state.showingInfoModal}
											style={{
												width: 1100,
												maxWidth: 1100,
											}}
										>
											<div style={{ margin: '1em' }}>
												<Row>
													<Col md={12} xl={4}>
														<Card className="mb-4">
															<H4>Opprett referansekode, før du sender en faktura.</H4>
															<p>
																Velg prosjektene du ønsker å fakturere for og trykk{' '}
																<strong>Opprett referanse</strong>
															</p>
															<p>
																Referansekoden setter du under "Referanse" på fakturaen
																din, mens på hver enkelt ordrelinje setter du inn
																prosjektnummeret.
															</p>
															<Callout intent="warning" className="mt-3">
																Finner du noe feil i listen, må dette avklares med
																prosjektleder som oppdaterer denne listen før du
																oppretter referansekoden og sender faktura.
															</Callout>
														</Card>
													</Col>
													<Col md={12} xl={8}>
														<Card>
															<Callout intent="success" className="mb-3">
																Eksempel på en godkjent faktura.
															</Callout>
															<div
																style={{
																	border: '1px solid #f0f0f0',
																}}
																className=""
															>
																<img
																	alt="faktura"
																	style={{
																		width: '100%',
																		maxWidth: '100%',
																	}}
																	src="/faktura.png"
																/>
															</div>
														</Card>
													</Col>
												</Row>
											</div>
										</Dialog>
										<Button
											disabled={selected.length === 0}
											className="mr-2"
											icon="add"
											intent="success"
											onClick={() => this.createReference()}
										>
											Opprett referanse
										</Button>
									</div>
									<h4 className="mb-4">Hyrer til fakturering</h4>

									<table className="bp3-html-table .modifier">
										<thead>
											<tr>
												<th></th>
												<th>Prosjekt</th>
												<th>Prosjektnummer</th>
												<th>Funksjon</th>
												<th>Start</th>
												<th>Slutt</th>
												<th>Hyre (eks. mva)</th>
											</tr>
										</thead>
										<tbody>
											{this.state.invoices
												.filter(
													(data) =>
														data.reference === '' ||
														data.reference === null ||
														data.reference === undefined
												)
												.map((data: InvoicedJob, index) => (
													<tr key={index}>
														<td>
															<Checkbox onClick={() => this.handleSelect(data)} />
														</td>
														<td>
															<span title={data.projectName}>{data.projectName}</span>
														</td>
														<td>
															<span title={data.projectNumber}>{data.projectNumber}</span>
														</td>
														<td>
															<span title={data.role}>{data.role}</span>
														</td>
														<td>
															<span title={data.startDate}>
																{new Date(data.startDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.endDate}>
																{new Date(data.endDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.cost}>
																{parseInt(data.cost) > 0
																	? data.cost.toLocaleString()
																	: 'Ingen hyre'}
															</span>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</Card>
								<Card>
									<h4 className="mb-4">Fakturerte hyrer</h4>

									<table className="bp3-html-table .modifier">
										<thead>
											<tr>
												<th>Referanse</th>
												<th>Prosjekt</th>
												<th>Prosjektnummer</th>
												<th>Funksjon</th>
												<th>Start</th>
												<th>Slutt</th>
												<th>Hyre (eks. mva)</th>
											</tr>
										</thead>
										<tbody>
											{this.state.invoices
												.filter(
													(data) =>
														data.reference !== '' &&
														data.reference !== null &&
														data.reference !== undefined
												)
												.map((data: InvoicedJob, index) => (
													<tr key={index}>
														<td>
															<span title={data.reference}>{data.reference}</span>
														</td>
														<td>
															<span title={data.projectName}>{data.projectName}</span>
														</td>
														<td>
															<span title={data.projectNumber}>{data.projectNumber}</span>
														</td>
														<td>
															<span title={data.role}>{data.role}</span>
														</td>
														<td>
															<span title={data.startDate}>
																{new Date(data.startDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.endDate}>
																{new Date(data.endDate).toLocaleString()}
															</span>
														</td>
														<td>
															<span title={data.cost}>
																{parseInt(data.cost) > 0
																	? data.cost.toLocaleString()
																	: 'Ingen hyre'}
															</span>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</Card>
							</Col>
						</Row>
					</>
				)}
			</InvoiceContainer>
		)
	}
}

export default InvoiceLoginAs
