import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'

class ProdContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav>
				{this.props.children}
			</Nav>
		)
	}
}
export default ProdContainer
