import axios, { AxiosInstance } from 'axios'
import storage from 'local-storage-fallback'
import Configuration from '../../Utils/Configuration'
import { Comment, NewComment } from './types'
import { Md5 } from 'ts-md5/dist/md5'

function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

let api: AxiosInstance

// race condition shait. dette må vi fikse ordentlig

function refresh_api() {
	const oaljwt = storage.getItem('oaljwt')
	api = axios.create({
		baseURL: Configuration.OAL_API_URL,
		headers: { oaljwt },
		timeout: 10000,
	})
	if (!oaljwt) {
		console.log('missing oaljwt, retrying in 10ms')
		setTimeout(refresh_api, 10)
	}
	return api
}

api = refresh_api()

const apiDelay = 0

// "sikkerhetshull" - gjort med vilje.. bare lett obfuscation.
const pepper = 'hav og maldon'

export const getComments = async (referenceId: string): Promise<Comment[]> => {
	const response = await api.get('/comments/' + Md5.hashAsciiStr(pepper + referenceId), {})
	await sleep(apiDelay)
	return response.data?.result || []
}

export const getComment = async (id: number): Promise<Comment> => {
	const response = await api.get('/comment/' + id)
	await sleep(apiDelay)
	return response.data.result
}

export const putComment = async (comment: Comment): Promise<Comment> => {
	const response = await api.put('/comment/' + comment.id, comment)
	await sleep(apiDelay)
	return response.data.result
}

export const deleteComment = async (comment: Comment): Promise<void> => {
	await api.delete('/comment/' + comment.id)
	await sleep(apiDelay)
}

export const postComment = async (newComment: NewComment, referenceId: string): Promise<Comment> => {
	const response = await api.post('/comments/' + Md5.hashAsciiStr(pepper + referenceId), newComment)
	await sleep(apiDelay)
	return response.data?.result
}

export default api
