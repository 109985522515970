import React from 'react'
import { EditableText } from '@blueprintjs/core'

interface InlineEditProps {
	value: string
	onChange: (value: string) => void
	style?: React.CSSProperties
	minWidth?: number
}

interface InlineEditState {
	currentValue: string
	editing: boolean
}

export class InlineEdit extends React.Component<InlineEditProps, InlineEditState> {
	state: InlineEditState = {
		currentValue: this.props.value,
		editing: false,
	}

	componentDidMount() {
		this.setState({ currentValue: this.props.value })
	}

	componentDidUpdate() {
		if (!this.state.editing && this.props.value !== this.state.currentValue) {
			this.setState({ currentValue: this.props.value })
		}
	}

	render() {
		return (
			<div style={this.props.style}>
				<EditableText
					minWidth={this.props.minWidth || 300}
					onEdit={() => this.setState({ editing: true })}
					onCancel={() => this.setState({ editing: false, currentValue: this.props.value })}
					defaultValue={this.state.currentValue}
					onConfirm={(value) => {
						this.setState({ editing: false })
						this.props.onChange(value)
					}}
				/>
			</div>
		)
	}
}
