import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'

class VpnContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'tdac-frontpage',
						title: 'Dører',
						icon: 'key',
						link: '/tdac',
						active: isActivePage(['/tdac']),
					},
					/*					{
						id: "vpn-edit",
						title: "Endre vpn",
						icon: "edit",
						link: "/vpn/edit",
						active: isActivePage(["/vpn/edit"]),
					},*/
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default VpnContainer
