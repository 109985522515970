import React from 'react'
import { TicketChange } from '../Ticket/types'
import { postComment } from './api'
import { CommentsCardCreate } from './CardCreate'
import { CommentsCardList } from './CardList'
import { useComments } from './hooks/useComment'

export interface CommentsCardProps {
	reference: string
	fletting: Flette[]
}

export type Flette = {
	type: 'TicketChange'
	data: TicketChange[]
	wrapper: (props: any) => JSX.Element
}

export function CommentsCard(props: CommentsCardProps) {
	const { comments, setComments } = useComments(props.reference)

	return (
		<div>
			<CommentsCardList {...props} comments={comments || []} />
			<CommentsCardCreate
				{...props}
				onCreate={async (comment) => {
					const newComment = await postComment(comment, props.reference)
					const oldComments = comments || []
					setComments([...oldComments, newComment])
				}}
			/>
		</div>
	)
}
