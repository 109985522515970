import React from 'react'
import { OALUser } from '../OAL/User'
import { TicketUpdateBase } from './types'
import Moment from 'react-moment'
import 'moment/locale/nb'
import { ProfileImageThumbnail } from '../../Services/OalApi'

export function RenderTicketWrapper(props: { change: TicketUpdateBase; children: React.ReactNode }) {
	return (
		<div
			style={{
				display: 'flex',
				borderLeft: '1px solid #ddd',
				paddingTop: 4,
				paddingBottom: 8,
				marginLeft: 15,
				paddingLeft: 26,
			}}
		>
			<div>
				<ProfileImageThumbnail
					username={props.change.authorId}
					style={{ borderRadius: '100%', width: 20, height: 20 }}
				/>
			</div>
			<div style={{ paddingTop: 1, paddingLeft: 5 }}>
				<span style={{ marginTop: 6 }}>{props.children}</span>
				<span style={{ opacity: 0.3, marginLeft: 10 }}>
				<OALUser id={props.change.authorId} style={{ fontWeight: 'normal' }} />{', '}
					<Moment fromNow>{props.change.createdAt}</Moment>
				</span>
			</div>
		</div>
	)
}
