import { DynamicContentModuleProps, DynamicContentModuleEditProps } from '../interface'
import { Button, Callout, Card } from '@blueprintjs/core'
import { openGate } from '../../../Services/OalApi'
import React from 'react'
import { Col } from 'reactstrap'

export const View = (props: DynamicContentModuleProps) => {
	const [successful, setSuccessful] = React.useState<boolean>(false)
	const [failed, setFailed] = React.useState<string>('')
	const [sending, setSending] = React.useState<boolean>(false)

	const openTheGate = async () => {
		try {
			if (!window.confirm('Er du sikker på at du vil åpne porten?')) {
				return
			}
			setSuccessful(false)
			setFailed('')

			setSending(true)
			await openGate('optilux')
			setSuccessful(true)

			setTimeout(() => {
				setSuccessful(false)
			}, 5000)
		} catch (e: any) {
			console.error(e)
			setFailed(String(e.message))

			setTimeout(() => {
				setFailed('')
			}, 5000)
		} finally {
			setSending(false)
		}
	}

	return (
		<div style={{ marginBottom: 20 }}>
			<h3>Optilux port</h3>
			<p>
				Klikk på knappen nedenfor for å åpne porten hos Optilux. Kun bruk denne knappen hvis du har avtale om det. All bruk logges med navn og tidspunkt.
			</p>
			<Col xs={6} md={6}>
				{sending && (
					<Callout intent="primary" icon="info-sign" className="mt-2 mb-4">
						<p>
							<b>Åpner porten, vennligst vent...</b>
						</p>
					</Callout>
				)}
				{successful && (
					<Card elevation={1} className="mt-4 mb-4 p-0">
						<Callout intent="success" icon="tick">
							<b>Porten åpnes</b>
						</Callout>
					</Card>
				)}
				{failed && (
					<Callout intent="danger" icon="error" className="mt-2 mb-4">
						<p>
							<b>
								Klarte ikke åpne porten, kontakt Trygve Andresen på +47 454 19 707 for å få den manuelt
								åpnet.
							</b>
						</p>
					</Callout>
				)}
			</Col>
			<Button
				intent="primary"
				icon="mobile-phone"
				className="mb-2"
				loading={sending}
				disabled={sending}
				onClick={() => !props.editMode && openTheGate()}
			>
				Åpne porten
			</Button>
		</div>
	)
}

export const Edit = (props: DynamicContentModuleEditProps) => {
	return View({ ...props, editMode: true })
}
