import React from 'react'
import { TicketUpdateAssigned } from './types'
import { RenderTicketWrapper } from './RenderTicketWrapper'
import { OALUser } from '../OAL/User'
import { Icon } from '@blueprintjs/core'

export function RenderTicketUpdateAssigned({ change }: { change: TicketUpdateAssigned }) {
	if (change.payload[0] === null && change.payload[1] !== null) {
		return (
			<RenderTicketWrapper change={change}>
				<Icon icon="crown" /> <OALUser id={change.payload[1]} />
			</RenderTicketWrapper>
		)
	}
	if (change.payload[0] !== null && change.payload[1] === null) {
		return (
			<RenderTicketWrapper change={change}>
				<Icon icon="crown" /> <OALUser id={change.payload[0]} /> <Icon icon="arrow-right" /> <Icon icon="cross" />
			</RenderTicketWrapper>
		)
	}
	if (change.payload[0] !== null && change.payload[1] !== null) {
		return (
			<RenderTicketWrapper change={change}>
				<Icon icon="crown" /> <OALUser id={change.payload[0]} /> <Icon icon="arrow-right" />{' '}
				<OALUser id={change.payload[1]} />
			</RenderTicketWrapper>
		)
	}
	return <RenderTicketWrapper change={change}>delegerte noe??</RenderTicketWrapper>
}
