import { InvoicedJob, Crew } from '../Domain/Invoice/Crew'
import Configuration from '../Utils/Configuration'
import storage from 'local-storage-fallback'
import axios from 'axios'

let backend = axios.create({
	baseURL: Configuration.OAL_API_URL + '/rentman',
	headers: { oaljwt: storage.getItem('oaljwt') },
	timeout: 180000,
})

export function reInit(jwt: string) {
	backend = axios.create({
		baseURL: Configuration.OAL_API_URL + '/rentman',
		headers: { oaljwt: jwt },
		timeout: 180000,
	})
}

export async function getNewCrewJobs(id: string | number, phone: string, company: string): Promise<InvoicedJob[]> {
	let data = await backend.get('/crew/invoices', {
		params: {
			id,
			company,
			phone,
		},
	})

	return data.data
}

export async function getInvoicedCrewJobs(phone: string, company: string): Promise<InvoicedJob[]> {
	let data = await backend.get('/crew/invoices/reference', {
		params: {
			company,
			phone,
		},
	})

	let filter = data.data.filter(
		(data: any) => data.reference !== '' && data.reference !== null && data.reference !== undefined
	)

	return filter
}

export async function getCrew(phone: string, company: string): Promise<Crew | undefined> {
	let data = await backend.get('/crew', {
		params: {
			phone,
			company,
		},
	})

	return data.data && data.data.length > 0 && data.data[0]
}

/* export høres ut som å hente ut, men denne setter job reference? */
export async function exportJobReference(id: string, reference: string, phone: string, company: string): Promise<any> {
	let data = await backend.put('/crew/invoices', {
		id,
		reference,
		company,
		phone,
	})

	return data.data
}
