import React, { Component } from 'react'
import { HomeContainerProps, HomeContainerState } from '../../Domain/Home/Container'
import Nav from '../../Components/Navigation/Nav'
import { isActivePage } from '../../Utils/React'
import AccessGroups from '../../Utils/AccessGroups'

class ToolContainer extends Component<HomeContainerProps, HomeContainerState> {
	render() {
		return (
			<Nav
				subMenuItems={[
					{
						id: 'tools-frontpage',
						title: 'Kontakter',
						icon: 'phone',
						link: '/tools',
						active: isActivePage(['/tools']),
						visibleOnlyTo: [ ...AccessGroups.InvoiceAny, ...AccessGroups.DashboardOalContacts ]
					},
					{
						id: 'tools-tripletex',
						title: 'Tripletex',
						icon: 'dollar',
						link: '/tools/tripletex',
						active: isActivePage(['/tools/tripletex']),
						visibleOnlyTo: AccessGroups.InvoiceAny,
					},
				]}
			>
				{this.props.children}
			</Nav>
		)
	}
}
export default ToolContainer
