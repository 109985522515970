import { Card } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

import './LoyalityBar.scss'
import { Fragment, useCallback, useContext, useMemo } from 'react'
import { LoyalityLevel } from '../../../Services/OalApi'
import { LoyalityContext } from '../Context/LoyalityContext'

export type LoyalityBarLine = {
	amount: number
	color: string
	name?: string
	noPoint?: boolean
}

export default function LoyalityBar({
	values,
	pointType,
	levels,
	contract,
	activeLevel,
}: {
	values: LoyalityBarLine[]
	pointType?: 'circle' | 'line'
	levels?: LoyalityLevel[]
	contract?: number
	activeLevel?: number
}) {
	if (!pointType) {
		pointType = 'circle'
	}

	const loyalityContext = useContext(LoyalityContext)

	// Default levels
	const bars = useMemo(
		() => (levels !== undefined ? levels : loyalityContext.globalLevels),
		[levels, loyalityContext.globalLevels]
	)

	const calcPercent = useCallback(
		(value: number) => {
			const moneylevels = bars.map((level) => level.amount)
			let percent = 0
			for (let i = 0; i < moneylevels.length; i++) {
				const level = moneylevels[i]
				const lastLevel = i > 0 ? moneylevels[i - 1] : 0
				const sectionWorth = 1 / moneylevels.length

				if (value > level) {
					percent += sectionWorth * 100
				} else {
					return percent + ((value - lastLevel) / (level - lastLevel)) * sectionWorth * 100
				}
			}
			return 100
		},
		[bars]
	)

	/* If we ever would need to show absolute values on the bars instead of appending them, this would be one way:
	let newValues = [...values].sort((a,b) => a.amount - b.amount).map((value) => {
			let percent = calcPercent(value.amount) - currentPrcnt;
			if (percent < 0) {
				console.error("Sort function is broken?!");
				percent = 0;
			}
			percent -= currentPrcnt;
			currentPrcnt += percent;
			return { ...value, percent }
		})
	*/

	const [lineValues /*, _total*/] = useMemo(() => {
		let newValues = [...values].map((value) => ({ ...value, percent: calcPercent(value.amount) }))
		let newTotal = newValues.reduce((acc, value) => (acc += value.percent), 0)
		if (newTotal > 100) {
			console.log('Error: newTotal amount of percent is over 100%: ' + JSON.stringify(values))

			// Clean up values
			let amount = 0
			let abort = false
			newValues = newValues.map((value, index) => {
				if (abort) {
					return { ...value, percent: 0 }
				}
				if (index === values.length - 1 || amount + value.percent > 100) {
					let newPercent = 100 - amount
					if (newPercent > 100) {
						newPercent = 100
					}
					newTotal = 100
					abort = true
					return { ...value, percent: newPercent }
				} else {
					let newPercent = Math.round((value.percent / newTotal) * 100)
					amount += newPercent
					return { ...value, percent: newPercent }
				}
			})
		}
		return [
			(newTotal < 100
				? [...newValues, { percent: 100 - newTotal, color: 'rgb(222, 222, 222)', noPoint: true, amount: -1 }]
				: newValues) as (LoyalityBarLine & { percent: number })[],
			newTotal,
		]
	}, [values, calcPercent])

	return bars?.length > 0 ? (
		<Card elevation={0} className="mb-3" style={{ borderRadius: '10px' }}>
			<div className="loyality-bar">
				<div className="loyality-bar-content">
					{bars.map((bar, index) => (
						<div key={index}>
							<div className="crossbar" style={{ left: ((index + 1) / bars.length) * 100 + '%' }}></div>
							<div className="crossbarAmount" style={{ left: ((index + 1) / bars.length) * 100 + '%' }}>
								{Math.round(bar.amount / 100000) / 10}
							</div>
							<div
								className="crossbarLevel"
								style={{
									left: (index / bars.length) * 100 + '%',
									width: 100 / bars.length + '%',
									fontWeight: activeLevel === bar.level ? 'bolder' : 'normal',
								}}
							>
								{bar.level}
							</div>
						</div>
					))}
					{lineValues.map((value, index) => (
						<Fragment key={value.color}>
							<div
								style={{
									width: Math.round(value.percent * 100) / 100 + '%',
									backgroundColor: value.color,
								}}
								className="line"
							></div>
							{!value.noPoint && value.percent !== 0 ? (
								<Tooltip2
									content={
										<>
											{value.name ? (
												<p>
													<strong>{value.name}</strong>
												</p>
											) : null}
											{Math.round(value.amount || 0).toLocaleString('nb-NO')},-
										</>
									}
									intent="none"
									position="bottom"
								>
									<div className={`point${pointType}`} style={{ backgroundColor: value.color }}></div>
								</Tooltip2>
							) : null}
						</Fragment>
					))}
				</div>
				{contract ? (
					<div className="loyality-bar-contract">
						<div
							className="contractline"
							style={{
								width: calcPercent(contract || 0) + '%',
								backgroundColor: 'rgb(50, 255, 255)',
								opacity: 0,
							}}
						></div>
						<Tooltip2
							content={
								<>
									<p>
										<strong>Avtalt minsteomsetning</strong>
									</p>
									{Math.round(contract || 0).toLocaleString('nb-NO')},-
								</>
							}
							intent="success"
							position="bottom"
						>
							<div
								className={`pointline`}
								style={{
									backgroundColor: 'rgb(50, 160, 160)',
									opacity: 0.5,
									width: '6px',
									marginLeft: '-2px',
									height: '20px',
								}}
							></div>
						</Tooltip2>
					</div>
				) : null}
				<div className="description">
					<div className="amount">M.NOK</div>
					<div className="level">Nivå</div>
				</div>
			</div>
		</Card>
	) : null
}
