// create hook component
import React, { useState, useEffect } from 'react'
import Container from '../Container'
import { NetDHCPProps, NetDHCPLease } from './interface'
import { TESContext } from '../../../Utils/TESContext'
import uuid from '../../../Utils/uuid'
import { Callout, H3, InputGroup, Intent } from '@blueprintjs/core'

const NetDHCP = (props: NetDHCPProps) => {
	const [data, setData] = useState<NetDHCPLease[]>([])
	const [spesificSearch, setSpesificSearch] = useState<null | string>(null)
	const [viewData, setViewData] = useState<NetDHCPLease[]>([])
	const TES = React.useContext(TESContext)

	const updateData = React.useCallback(async () => {
		if (spesificSearch !== null && spesificSearch !== '') {
			// create regexp
			const regexp = new RegExp(spesificSearch, 'i')
			setViewData(
				data.filter(
					(item) =>
						(item['mac-address'] !== undefined && item['mac-address'].match(regexp)) ||
						(item['host-name'] !== undefined && item['host-name'].match(regexp)) ||
						(item['client-id'] !== undefined && item['client-id'].match(regexp)) ||
						(item['address'] !== undefined && item['address'].match(regexp))
				)
			)
		} else {
			setViewData(data.slice(0, 100))
		}
	}, [spesificSearch, data, setViewData])

	const updateLeaseDataCallback = React.useCallback(
		(ns: string, d: NetDHCPLease) => {
			if (!d.status) return
			let oldData = [...data]
			if (oldData.length > 500) oldData.pop()
			setData([{ ...d, id: uuid() }, ...oldData])
			updateData()
		},
		[data, updateData]
	)

	useEffect(() => {
		if (TES.subscribe) TES.subscribe('lease.dhcp.mikrotik.network.oal.no', updateLeaseDataCallback)
		return () => {
			TES.unsubscribe('lease.dhcp.mikrotik.network.oal.no', updateLeaseDataCallback)
		}
	}, [updateLeaseDataCallback, TES, TES.subscribe, TES.unsubscribe])

	let routers: string[] = []
	viewData.forEach((item) => {
		if (!routers.includes(item.router_name)) routers.push(item.router_name)
	})

	// sourt routers alphabetically
	routers.sort()

	return (
		<Container>
			<H3>Nettverk &gt; DHCP</H3>
			<p>
				Her vil du se alle DHCP leases som gis ut fra alle DHCP-servere i nettverket vårt. Leter du etter en
				spesiell lease kan du spesifisere MAC-adresse, hostname eller ip i feltet, og powercycle enheten for å
				se hva den får som IP. Denne siden viser kun forespørsler som oppstod etter du lastet siden.
			</p>
			<Callout className="mb-3" intent={Intent.WARNING}>
				Husk at det kan ta godt oppimot et minutt før enkelte enheter spør om IP fra DHCP-server, mens det kan
				være snakk om kun sekunder på noen andre. <strong>OBS!</strong> 10.20.30.0/24 filtreres vekk fra denne
				lista.
			</Callout>
			<InputGroup
				type="input"
				value={spesificSearch || ''}
				onChange={(e) => {
					setSpesificSearch(e.target.value)
					updateData()
				}}
				onKeyUp={(e) => {
					updateData()
				}}
				placeholder="Søk"
			/>

			<div style={{ display: 'flex', width: '100%' }}>
				{routers.map((k) => (
					<div style={{ width: 'calc(100% / ' + routers.length + ')' }} key={k}>
						<H3 style={{ marginTop: 10 }}>{k}</H3>
						<div style={{}}>
							{viewData
								.filter(
									(fi) =>
										fi.router_name === k &&
										fi.status === 'bound' &&
										!fi['active-address'].match(/^10\.20\.30\./)
								)
								.map((item) => (
									<div
										key={item.id}
										style={{
											backgroundColor: '#f0f0f0',
											marginBottom: 4,
											borderRadius: 5,
											borderBottom: '2px solid #eee',
											fontSize: 12,
											padding: 2,
											paddingLeft: 5,
										}}
									>
										<span style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
											{item['mac-address']}
										</span>
										{' - '}
										{item['active-address']}
										{' ('}
										{item['host-name']}
										{')'}
									</div>
								))}
						</div>
					</div>
				))}
			</div>
		</Container>
	)
}
export default NetDHCP
